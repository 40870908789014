@import '../../styles/colors.less';

.cc-meeting-status-notification {
  width: 100%;
  height: 64px;
  text-align: center;
  padding-top: 18px;
  color: @cc-white;
}

.cc-meeting-status-notification svg {
  margin-right: 10px;
  font-size: 24px;
  padding-top: 4px;
}

.cc-meeting-status-notification-upcoming {
  background: @cc-info;
}

.cc-meeting-status-notification-ongoing {
  background: @cc-purple;
}

.cc-meeting-status-contact {
  font-weight: 900;
}

.cc-meeting-status-button {
  margin-left: 16px;
  width: 160px;
  height: 32px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;