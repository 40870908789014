@import '../../styles/colors.less';

.profile-link {
  color: @cc-primary;
  margin-bottom: 20px;
  // font-size: 14px;
  display: flex;
  overflow-wrap: anywhere;
  align-items: baseline;
  & > svg {
    margin-right: 5px;
  }
}

.profile-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  justify-content: center;
  line-height: 40px;
}

.deal-info-text {
  color: @cc-greyscale-darkestblack;
  line-height: 21px;
  font-weight: 600;
  margin: 8px 0px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;