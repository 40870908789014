.returns-update-form {
  table thead {
    @apply sticky top-0 z-1;
    @apply bg-white;
  }

  table thead :is(th, td) > div {
    @apply border-0 border-b border-solid border-grey-300;
    @apply -mx-px;
  }

  table tbody :is(th, td) {
    @apply p-0.5;
  }
}
