@import '../../styles/colors.less';

.ServiceProviderPeopleCard {
  margin: 8px 8px 24px 8px;
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  padding: 32px 16px;
  box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.15);
  transition: border 300ms;

  &-click {
    cursor: pointer;
    border: 1px solid transparent;
  }

  &-click:hover {
    box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.51);
    background: theme('colors.secondary.2');
    border-color: theme('colors.secondary.100');
  }

  &-title {
    color: #3d4858;
    font-size: 18px;
    font-weight: 600;
  }
  &-subtitle {
    color: @cc-dark-grey;
    font-size: 14px;
  }
  &-description {
    color: @cc-greyscale-darkestblack;
    font-size: 14px;
  }
  &-row {
    display: flex;
    align-items: center;
  }
  &-text {
    margin: 16px 16px 0px 0px;
  }
  &-avatar {
    height: 70px;
    display: flex;
    align-items: flex-start;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;