.cc-back-icon {
  background: #ffffff;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 12px;
  vertical-align: middle;
  display: inline;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bolder;
}

.cc-info-container {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.cc-info > span {
  display: block;
}

.cc-fund-details-header {
  display: flex;
  justify-content: space-between;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;