.cc-meeting-details-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
}
.cc-meeting-details-tabs-radio-buttons {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  padding: 2px 2px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;