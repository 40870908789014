@import '../../styles/colors.less';

.AvatarGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &-start {
    justify-content: start;
  }

  & &-avatar {
    border: 2px solid @cc-white;
  }

  & &-avatar-plus {
    font-size: 12px;
    font-weight: bolder;
  }
  &-popover {
    & .ant-popover-inner {
      background-color: rgba(0, 0, 0, 0.75);
      color: @cc-white;
    }
    & .ant-popover-title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      color: @cc-white;
    }
    &.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
      border-bottom-color: rgba(0, 0, 0, 0.75);
      border-right-color: rgba(0, 0, 0, 0.75);
    }
    &-no-image {
      & .ant-popover-inner-content {
        padding: 0px;
      }
      & .ant-popover-title {
        border-bottom: none;
        color: @cc-white;
      }
    }
  }
  &-dropdown-popover-no-image.ant-popover {
    display: none;
    z-index: 2000;
  }
  &-dropdown-popover.ant-popover {
    z-index: 2000;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;