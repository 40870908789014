.IlliquidFirm {
  &-header {
    font-weight: 600;
    font-size: 14px;
    color: #4d4f56;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &-stat {
    font-size: 24px;
    font-weight: 600;

    &-header {
      color: #92949c;
      font-size: 12px;
    }
  }

  .ContactWrapper {
    border: 1px solid #f2f3f3;
    padding: 24px;
    margin-bottom: 12px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;