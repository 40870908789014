.cc-responsive-card-block {
  display: flex;
  width: '100%';
  margin-bottom: 20px;
  justify-content: space-between;
}
.cc-card-body-group {
  div {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  .cc-responsive-card-block {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 8px;
    .cc-card-body-group {
      margin-bottom: 16px;
    }
  }

  .cc-meeting-actions {
    margin-top: 0;
  }
}

.cc-meeting-actions {
  //float: right;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;