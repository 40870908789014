.cc-step1 {
  width: 30vw;
  .cc-step1-section1 {
    width: 558px;
    .cc-heading3 {
      margin-bottom: 32px;
    }
    margin-bottom: 24px;
  }
  .cc-step1-section2 {
    width: 558px;
    .cc-step1-section2-header {
      margin-bottom: 16px;
    }
    .cc-step1-section2-footer {
      span {
        display: block;
      }
    }
    margin-bottom: 15px;
  }
  .cc-step1-section3 {
    width: 558px;
    .cc-step1-section3-header {
      margin-bottom: 16px;
    }
    .cc-step1-section2-footer {
      span {
        display: block;
      }
    }
    margin-bottom: 15px;
  }
}

.FormHeader {
  font-size: 30px;
  font-weight: 600;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;