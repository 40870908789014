@import './colors.less';

.discover {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: -24px -24px 0px -24px;
    align-items: center;
    justify-content: flex-start;
    overflow-y: hidden;
  }

  &-header {
    &-title {
      color: white;
      text-align: center;
      margin: 40px 32px;
    }

    &-smaller {
      background-color: @cc-primary;
      width: 100%;
      height: 148px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: -10px;
      z-index: 110;
    }
  }
}

.discoverSearch {
  & > .ant-input-lg {
    border: 1px solid #eff1f4;
  }

  &-container {
    background-color: white;
    padding: 16px;
    border-radius: 12px;
    width: 80vw;
  }
}

.discoverTitleText {
  margin: 16px 16px 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  color: #3d4858;

  &-small {
    margin: 16px 16px 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #3d4858;
  }
}

.discoverDescriptionText {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #8492a6;

  &-small {
    text-align: center;
    width: 70%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: #8492a6;
  }
}

.cards-scrolled {
  min-height: calc(100vh - 300px);

  & > div > .discover-tab-containerLine {
    width: 80vw;
    height: auto;
    position: fixed;
    top: 165px;
  }
}

.discoverOptions {
  padding: 50px 80px 30px 80px;
  width: 100%;

  &-Container-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 25vh;
    padding: 24px;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      cursor: pointer;
      background-color: white;
      box-shadow: 0px 4px 20px rgba(186, 186, 201, 0.25);
    }
  }

  &-item-small {
    width: 70px;
    height: 70px;
    border-radius: 60px;
    background-color: white;
    text-align: center;
    padding: 20px;
    box-shadow: 0px 4px 20px rgba(186, 186, 201, 0.25);
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;