@import '../../styles/colors.less';

.cc-columns-preferences-info {
  margin-left: 6px;
}

.cc-columns-preferences-info span {
  display: block;
}

.cc-columns-preferences-info-header {
  color: @cc-light-black;
}

.cc-columns-preferences-info-aux {
  color: @cc-dark-grey;
  margin-top: 8px;
}

.cc-columns-preferences-column-list {
  margin-top: 16px;
  margin-bottom: 60px;
}

.cc-columns-preferences-item {
  margin-bottom: 16px;
}

.cc-columns-preferences-item-icon {
  color: @cc-grey;
  margin: 0px 8px;
}

.cc-columns-preferences-item-checkbox {
  color: @cc-light-black;
  & > .ant-checkbox {
    margin-right: 8px;
  }
}

.cc-columns-preferences-bottom {
  position: fixed;
  bottom: 0;
  height: 60px;
  padding-bottom: 24px;
  padding-top: 16px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: white;
  width: 390px;
}

.cc-columns-preferences-cancel-button {
  position: absolute;
  right: 32px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;