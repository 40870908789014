@import '../../styles/colors.less';

.UpcomingMeetingRequests {
  &-info {
    display: flex;
    align-items: center;
  }
  &-meeting-actions {
    display: inline-flex;
    margin-top: 8px;
  }
  &-date {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: @cc-primary;
  }
  &-tbd {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 56px;
    text-align: center;
    color: @cc-primary;
  }
  &-month {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: @cc-greyscale-darkestblack;
    text-transform: uppercase;
  }
  &-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: @cc-greyscale-darkestblack;
  }
  &-hour {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: @cc-greyscale-darkestblack;
  }
  &-participants {
    display: flex;
    flex-wrap: wrap;
    &-icons {
      margin-right: 4px;
    }

    &-confirmed {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: @cc-greyscale-lightblack;
    }
    &-pending {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      color: @cc-dark-grey;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;