@import '../../styles/colors.less';

.Login {
  flex-direction: row;
  height: 100vh;

  &-field-email {
    background-color: @cc-lightest-grey;
    border: 0;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    color: @cc-greyscale-darkestblack;
    padding-left: 16px;
    padding-right: 16px;
  }

  &-field-methods {
    background-color: @cc-lightest-grey;
    border: 0;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    color: @cc-greyscale-darkestblack;
    padding-left: 16px;
    padding-right: 16px;
  }

  &-sidebar {
    background-color: @cc-dark-black;
    flex: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-info {
      margin-top: auto;

      &-header {
        font-weight: 300;
        font-size: 36px;
      }
    }

    &-description {
      color: @cc-white;
      text-align: left;
      margin-top: auto;
      margin-bottom: 32px;
    }
  }

  &-main {
    flex: 2;

    &-content {
      max-width: 364px;

      &-logo {
        max-width: 364px;
        height: 64px;
        background-image: url(../../assets/new-logo.svg);
        background-repeat: no-repeat;
      }

      &-form {
        &-title {
          font-size: 30px;
          line-height: 36px;
          font-weight: 600;
          color: @cc-darkest-grey;
          margin-bottom: 36px;
        }

        &-field {
          margin-top: 12px;
          margin-bottom: 24px;

          & > .ant-alert {
            margin-top: 8px;
            margin-bottom: 8px;
          }

          &-otp {
            & input {
              font-size: 20px;
              font-weight: 600;
              line-height: 25px;
              background-color: @cc-white;
              border: 1px solid @cc-grey;
              border-radius: 4px;
              text-align: center;
              margin-right: 22px;
              outline: none;
            }
          }
        }

        &-contact-column {
          margin-top: 20px;
        }
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;