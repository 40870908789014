@import '../../../styles/colors.less';

.FormField {
  margin-bottom: 16px;

  & .Field-error {
    font-size: 12px;
    line-height: 16px;
    color: @cc-danger;
    border: none;

    & .Field-error-message {
      margin-left: 6px;
    }
  }

  & .Field-deals {
    & > .ant-picker {
      & > .ant-picker-input > input {
        margin: 0px !important;
      }
    }
  }

  & .Field {
    position: relative;
    margin-bottom: 8px;
    & > .ant-picker {
      width: 100%;
      height: 48px;
      border: 1px solid #cbcbcb;
      border-radius: 3px;
      & > .ant-picker-input > input {
        margin: 15px 0px 0px 5px;
      }
    }
    & > .ant-select-selector {
      transition: 0.12s;
      position: relative;
      height: 32px;
      cursor: pointer;
    }
    & > .ant-input-search:not(.ant-input-search-enter-button) {
      padding-right: 0;
      &
        > .ant-input-wrapper
        > .ant-input-group-addon
        > .ant-input-search-button {
        height: 48px;
      }
    }

    &
      > .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 60px;
      margin-left: 5px;
    }
    & .ant-input-affix-wrapper {
      border: none;
      webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      -webkit-font-feature-settings: 'tnum', 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-flex;
      width: 100%;
      text-align: start;
    }
    & > .ant-input,
    & > .ant-input-number .ant-input-number-input,
    & > .ant-input-password > .ant-input,
    & > .ant-input-search > .ant-input,
    & > .ant-calendar-picker .ant-calendar-picker-input,
    & > .ant-select > .ant-select-selection--single {
      border: 1px solid #cbcbcb;
      border-radius: 3px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 24px;
      color: @cc-greyscale-darkestblack;
      height: 48px;
    }

    & > span > .ant-upload {
      background: #ffffff;
    }

    & > .ant-input-search {
      background: @cc-lightest-grey;
      border-radius: 3px;
    }

    & > .ant-input-search > .ant-input {
      border: none;
    }

    & > .ant-input-search > .ant-input-suffix {
      margin-left: 0px;
    }

    & > .ant-input-search > .ant-input-suffix > .ant-input-search-icon {
      margin-left: 0px;
    }

    & > .ant-select > .ant-select-selector {
      border: 1px solid #cbcbcb;
      border-radius: 3px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 24px;
      color: @cc-greyscale-darkestblack;
      height: 48px;
    }

    & > .ant-select > .ant-select-selector {
      border: none;
      background: @cc-lightest-grey;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 24px;
      color: @cc-greyscale-darkestblack;
      height: 48px;
    }

    & > .ant-input-number {
      border: none;
      height: 48px;
    }

    & > .ant-input-number .ant-input-number-input {
      height: 48px;
    }

    & > .ant-select-auto-complete.ant-select .ant-input {
      border-width: 0;
      padding-left: 0;
      background-color: transparent;
    }

    & > .ant-input-number,
    & > .ant-calendar-picker,
    & > .ant-select {
      width: 100%;
    }

    & .ant-input:focus,
    & > .ant-input-number .ant-input-number-input:focus,
    & > .ant-input-search > .ant-input:focus,
    & > .ant-calendar-picker .ant-calendar-picker-input:focus {
      outline: 0;
      background: @cc-white;
      border: 1px solid @cc-primary;
      box-shadow: none;
    }

    & .ant-input.Field-has-value,
    & .ant-input-search.Field-has-value .ant-input,
    & .ant-input-number.Field-has-value .ant-input-number-input,
    & .ant-calendar-picker.Field-has-value .ant-calendar-picker-input {
      padding-top: 20px;
      padding-bottom: 4px;
    }

    &
      > .ant-select
      > .ant-select-selection--single
      > .ant-select-selection__rendered {
      line-height: 36px;
      margin-right: 0px;
      margin-left: 0;
    }

    & > .ant-checkbox-group > .ant-checkbox-wrapper {
      display: flex;
      margin-bottom: 4px;
    }

    & > label.Field-label {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: @cc-greyscale-lightblack;
    }

    & > label.Field-floating-label {
      position: absolute;
      pointer-events: none;
      left: 16px;
      top: 12px;
      font-size: 14px;
      line-height: 24px;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      transition: 0.2s ease all;
      color: @cc-greyscale-darkestblack;
    }

    &-required > label.Field-floating-label {
      font-weight: 600;
    }

    &-required > label.Field-floating-label > b {
      color: @cc-greyscale-darkestblack;
      padding-left: 4px;
    }

    & > .Field-has-focus ~ label.Field-floating-label,
    & > .Field-has-value ~ label.Field-floating-label {
      color: @cc-grey-dark;
      top: 6px;
      left: 16px;
      font-size: 12px;
      line-height: 16px;
    }

    &-info {
      margin: 8px 0 16px;
      padding-left: 24px;
    }
  }
  &-helpText {
    color: rgba(130, 130, 130, 0.75);
    margin-top: 5px;
    margin-left: 10px;
  }
}

#OpportunityDetails {
  min-height: 120px;
}

#DistributorEmail {
  padding-top: 0px;
  padding-bottom: 0px;
}

#ManagerZipCode {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 48px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;