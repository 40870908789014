@import '../../styles/colors.less';

.EventListing {
  background-color: @cc-white;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

  &-header {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;

    & > h1,
    & > &-filters {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      height: 22px;
      vertical-align: middle;
    }

    &-filters > &-dropdown-trigger > span {
      margin-right: 8px;
    }

    & > h1 {
      color: @cc-black;
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &-name {
      font-size: 14px;
      line-height: 22px;
      font-weight: normal;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      & > .AvatarGroup {
        display: flex;
        align-items: center;
      }
      & > .AvatarGroup,
      & > a,
      & > button {
        margin: 0 8px;
      }
    }
  }

  &-body {
    padding: 12px;
  }

  &-body > &-item:last-child {
    margin-bottom: 0;
  }

  &-registration-btn {
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
  }

  &-registration-text {
    font-size: 12px;
    line-height: 20px;
    color: @cc-success;
    display: flex;
    align-items: center;
    & > svg {
      margin-right: 4px;
    }
  }

  &-link {
    background-color: @cc-white;
    border: 1px solid @cc-primary;
    border-radius: 4px;
    color: @cc-primary;
    font-size: 12px;
    line-height: 20px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;