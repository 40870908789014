@import '../../styles/colors.less';

.meeting-avatar {
  height: 100%;
}

.meeting-avatar-pending {
  background-color: #008dca !important;
}

.meeting-avatar-confirmed {
  background-color: @cc-success !important;
}

.meeting-avatar-declined {
  background-color: @cc-danger !important;
}

.meeting-avatar-cancelled {
  background-color: @cc-notice !important;
}

.cc-name-column {
  max-width: 250px;
  padding: 5px !important;
}

.cc-column-linkbutton-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
}

.cc-column-linkbutton-div .SummitAttendees-btn {
  max-width: 180px;
  width: 180px;
  text-align: left;
  overflow-x: hidden;
  word-wrap: break-word;
  max-height: fit-content;
  height: fit-content;
}

.cc-column-linkbutton-div .SummitAttendees-btn span {
  overflow-wrap: break-word;
  max-width: 180px;
  white-space: pre-wrap;
  height: fit-content;
  max-height: fit-content;
}

.cc-contacts-cell,
.cc-contacts-column {
  padding: 8px 12px 5px !important;
}

.cc-actions-cell,
.cc-actions-column {
  padding: 5px !important;
}

.ant-table-fixed-left {
  z-index: 200;
}

.cc-context-table .ant-table-placeholder {
  min-height: 500px;
  vertical-align: middle;
}

.cc-context-table {
  min-height: 500px;
}
.cc-context-table .ant-table-header table {
  min-width: max-content;
  @supports (-moz-appearance: none) {
    min-width: fit-content;
  }
}
.cc-context-table .ant-table-empty .ant-table-header table {
  @supports (-moz-appearance: none) {
    table-layout: auto !important;
  }
}

// Pending = 1,
// Confirmed = 2,
// Declined = 3,
// CANCELLED = 4

.cc-context-table .ant-table-tbody:empty::after {
  content: '-';
  font-size: 0px;
}

.cc-name-cell,
.cc-name-cell button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cc-table-filters {
  background-color: @cc-white;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;