.cc-portfolio-card {
  height: 124px;
  vertical-align: middle;

  .cc-card-money {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    /* identical to box height, or 120% */

    color: #000000;
  }
  .cc-card-money-description {
    margin-top: 0;
    padding-top: 0;

    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* Greyscale/Dark Grey */

    color: #92949c;
  }
  .cc-portfolio-change-container {
    display: flex;
    justify-content: space-between;
  }
  .cc-percentage-change {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height, or 121% */
    margin-right: 10px;
    /* Actions/Success */

    color: #24c477;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;