@import '../../styles/colors.less';

.PresentationWizard {
  margin: auto;
  width: calc(100vw - 548px);
  max-width: 1372px;
  min-width: 768px;
  background: #ffffff;
  min-height: calc(100vh - 340px);
  max-height: calc(100vh - 340px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 60px;
  border-radius: 8px 8px 0px 0px;
  overflow-y: auto;
  & &-steps {
    min-width: 390px;
    min-height: calc(~'100vh - 136px');

    &
      > .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description {
      visibility: hidden;
    }
  }
  &-footer {
    width: calc(~'100vw - 548px');
    background: @cc-white;
    box-shadow: 0 16px 28px rgba(47, 56, 62, 0.08);
    max-width: 1372px;
    min-width: 768px;
    margin: auto;
    border-radius: 0px 0px 8px 8px;
    &-button {
      padding: 12px 60px;
      display: flex;
    }
  }
}

.timepicker {
  & .ant-picker-footer {
    display: none;
  }
}

.select {
  & .ant-select-selector {
    height: 48px !important;
    align-items: center;
  }
}

.cc-timezone__indicator {
  padding-right: 8px;
}
.learnMoreBody {
  line-height: 2;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;