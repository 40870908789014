.ChartLabel {
  font-size: 16px;
  color: #4d4f56;
  height: 28px;
  left: 43px;
  top: 134px;
  padding-bottom: 10px;
  text-align: center;
}

.cc-chart-table .ant-table-row-cell-break-word {
  font-size: 12px;
  padding: 10px;
}

.cc-table-row > td {
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.cc-table-th {
  .ant-table-column-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #7f848a;
    text-transform: uppercase;
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
    font-size: 12px;
  }

  .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: #f9f9f9;
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    color: #7a7a7d;
    padding: 8px;
    font-weight: 800;
    font-size: 14px;
  }

  .ant-table-thead > tr > th {
    @apply text-header;
    padding: 8px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;