.cc-chart-table .ant-table-row-cell-break-word {
  font-size: 12px;
  padding: 10px;
}

.ChartLabel {
  font-size: 16px;
  color: #4d4f56;
  height: 28px;
  left: 43px;
  top: 134px;
  padding-bottom: 10px;
}

.cc-table-row > td {
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.cc-table-th {
  .ant-table-column-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #7f848a;
    text-transform: uppercase;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 2px;
  }

  .ant-table-thead > tr > th {
    padding: 12px 2px;
  }
}

.discover-fund-chart {
  overflow-x: auto;
  flex-shrink: 0;

  &-container {
    position: relative;
  }

  &-tooltip {
    background-color: white;
    box-shadow: #aeaeae 0 0 10px;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.9;
    overflow: hidden;
    padding: 8px 12px;
    pointer-events: none;
    position: absolute;

    &-title {
      text-align: center;
      margin-bottom: 4px;
    }

    &-legend {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      margin-right: 8px;
    }

    &-value {
      text-align: right;
      padding-left: 8px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;