@import '../../styles/colors.less';

.cc-layout-default-header {
  display: block;
  width: 100%;
  text-align: center;

  margin-top: 40px;
}

.cc-layout-default {
  width: 100%;
  text-align: center;
  margin: auto;
  &-alert {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    background: #d3f3e4;
    text-align: left;
  }
}

.cc-layout-default-back {
  position: absolute;
  top: 40px;
  left: 150px;
  background-color: theme('colors.primary.5');
  border: none;
  width: 127px;
  height: 40px;
  color: @cc-primary;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.cc-layout-default-back:hover {
  background: theme('colors.primary.2');
}
.cc-layout-default-logout {
  position: absolute;
  top: 40px;
  right: 150px;
  background-color: fade(@cc-danger, 10%);
  border: none;
  width: 127px;
  height: 40px;
  color: @cc-danger;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.cc-layout-default-context {
  display: block;
}

.cc-layout-default-welcome {
  display: block;
  margin-top: 40px;
  color: black;
}

.cc-layout-default-form-space {
  display: block;
  margin-top: 27px;
  width: 364px;
  margin-left: auto;
  margin-right: auto;
}

.cc-layout-default-footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  margin-top: 48px;
  margin-bottom: 120px;
}

.cc-layout-footer-back-button {
  background-color: theme('colors.primary.5');
  border: none;
  height: 48px;
  color: @cc-primary;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;

  &:hover {
    background: theme('colors.primary.2');
  }
}

.cc-layout-default-title {
  margin-top: 108px;
  color: @cc-black;
}

.cc-company-selection-item {
  height: 70px;
  border: 1px solid @cc-light-grey;
  box-sizing: border-box;
  border-radius: 4px;
  width: 364px;
  display: inline-block;
  max-width: 364px;
  margin: 0px;
  text-align: left;
  padding-left: 17px;
  display: block;
  margin-bottom: 24px;
}

.cc-company-selection-item .ant-radio {
  position: absolute;
  top: 27px;
}

.cc-company-selection-item-text {
  display: inline-block;
  margin-left: 25px;
  height: 100%;
  padding-top: 13px;
}

.cc-company-selection-name {
  color: @cc-light-black;
}

.cc-company-selection-type {
  color: @cc-dark-grey;
}
// .cc-generic-form-container {

// }

.cc-generic-form-control-container {
  //border-bottom: 1px solid @cc-light-grey;
  display: inline-block;
}

.cc-generic-form-checkbox-group {
  text-align: left;
  margin-top: 8px;
  color: @cc-light-black;
}

.cc-generic-form-checkbox-group .ant-checkbox-group {
  margin-top: 16px;
  width: 100%;
}

.cc-generic-form-checkbox-header {
  width: 100%;
  color: @cc-light-black;
  display: block;
}

.cc-generic-form-date-header {
  width: 100%;
  color: #92949c;
  display: block;
  font-size: 11px;
  background: @cc-lightest-grey;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 600;
}

.cc-generic-form-checkbox-group .ant-checkbox-group-item {
  width: 48%;
  color: @cc-light-black;
}

.cc-investor-status-form-subselections .ant-checkbox-group-item {
  width: 100%;
}

.cc-generic-form-radio-group {
  text-align: left;
  margin-bottom: 16px;
}

.cc-generic-form-radio-header {
  display: block;
  color: @cc-light-black;
  margin-bottom: 16px;
}

.cc-generic-form-radio-header-capital {
  color: @cc-light-black;
  margin-bottom: 18px;
}

.cc-generic-form-date-picker {
  margin-bottom: 16px;
  width: 100%;
  .ant-input {
    border: none;
  }
}

.year-picker-dropdown {
  .ant-calendar-header .ant-calendar-year-select {
    visibility: hidden;
  }
  .ant-calendar-header .ant-calendar-next-year-btn {
    visibility: hidden;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select {
    visibility: hidden;
  }
  .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select {
    visibility: hidden;
  }
  .ant-calendar-header .ant-calendar-prev-year-btn {
    visibility: hidden;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    visibility: hidden;
  }
  .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    visibility: hidden;
  }
  &-disable-prev {
    .ant-calendar-header .ant-calendar-prev-month-btn {
      visibility: hidden;
    }
  }
}

.cc-layout-confirm {
  text-align: center;
  width: 100%;
}

.cc-layout-confirm-form-space {
  display: block;
  margin-top: 27px;
  text-align: left;
  width: 556px;
  margin-left: auto;
  margin-right: auto;
}

.cc-layout-confirm-footer {
  margin-left: auto;
  margin-right: auto;
  width: 364px;
  margin-top: 48px;
  margin-bottom: 120px;
}

.cc-layout-confirm-question-answer {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 36px;
}

.cc-layout-confirm-question {
  color: @cc-dark-grey;
}

.cc-layout-confirm-answer {
  color: @cc-light-black;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.cc-layout-wizard-steps {
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  width: 1000px;
  text-align: left;
}

.cc-layout-wizard-steps-big {
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  width: 1300px;
  text-align: left;
}

.cc-horizontal-spawning-radio {
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.cc-investor-status-form {
  width: 800px;
  margin-left: -200px;
}

.cc-investor-status-form-separator {
  margin: 24px;
  border-bottom: 1px solid @cc-light-grey;
  height: 1px;
}

.cc-investor-details-form-separator {
  border-bottom: 1px solid @cc-light-grey;
  height: 1px;
  margin-bottom: 24px;
}

.cc-investor-status-form-subselections {
  text-align: left;
}

.cc-investor-status-item {
  display: inline-block;
  width: unset;
}

.cc-investor-status-item-checkbox {
  text-align: left;
}

.cc-investor-status-item-checkbox {
  margin-top: 16px;
}

.cc-investor-status-item-checkbox .ant-checkbox + span {
  display: inline-block;
  width: unset;
}

.cc-investor-status-item-details {
  margin-left: 24px;
  color: @cc-darkest-grey;
}

.cc-investor-status-item-label {
  color: @cc-light-black;
}

.cc-info-icon {
  //color: @cc-dark-grey;
  color: @cc-dark-grey;
}

.cc-generic-form-checkbox-group-grid {
  text-align: left;
}

.cc-generic-form-checkbox-group-grid .ant-checkbox-group {
  margin-top: 16px;
  vertical-align: top;
}

.cc-generic-form-checkbox-group-grid .ant-checkbox-group-item {
  width: 48%;
  color: @cc-light-black;
  vertical-align: top;
}

.cc-onboard-section {
  text-align: left;
}

.cc-onboard-section-title {
  color: @cc-primary;
}

.cc-onboard-section-row-title {
  color: @cc-black;
  margin-top: 16px;
}
.cc-onboard-section-row-controls {
  margin-top: 16px;
}

.cc-onboard-section-row-control {
  width: 45%;
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
}

.cc-onboarding-anything-else-textarea {
  border: none;
  margin-top: 16px;
  height: 148px !important;
}

.cc-onboarding-percentage {
  border: none;
  margin-top: 16px;
  width: 126px;
}

.cc-onboarding-percentage .ant-input {
  border: none;
}

.cc-onboarding-percentage .ant-input-group-addon {
  border: none;
  background: @cc-light-grey;
}

.cc-onboarding-invalid .ant-input {
  border: 1px solid red;
}

.cc-substrategy-group {
  padding-left: 20px;
}

.cc-strategies-checkbox .ant-checkbox-wrapper {
  color: @cc-light-black;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  width: 45%;
}

.cc-strategies-checkbox {
  margin-bottom: 32px;
  width: 100%;
}

.cc-strategies-header {
  margin-top: 16px;
}

.cc-broadstrategy-title {
  margin-bottom: 24px;
}

.cc-broad-strategy {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: @cc-light-black;
}

.cc-substrategy-group .ant-checkbox-wrapper {
  display: flex;
  margin-top: 16px;
}

.cc-substrategy-group .ant-checkbox-group {
  margin-top: 0px;
}

.cc-login-logo-onboard {
  text-align: center;
  font-size: 40px;
  width: 600px;
  height: 40px;
  background-repeat: no-repeat;
  margin: 0 0 50px 0;
  background-size: contain;
  margin-left: auto;
  width: 100%;
  background-position: center;
}

.cc-domicile-countries {
  margin-left: 24px;
  margin-top: -16px;
  margin-bottom: 16px;
}

.cc-additional-info {
  display: block;
  font-size: 14px;
  color: @cc-light-black;
  margin-left: 8px;
  margin-top: -4px;
}

.cc-domicile-all-countries-checkbox {
  color: @cc-light-black;
  margin-top: 16px;
}

.cc-address-zip-code-message {
  margin-top: -16px;
  text-align: left;
  width: 100%;
}

.cc-address-zip-code-suggestions {
  //position: absolute;
  z-index: 500;
  margin-top: -16px;
  margin-bottom: 16px;
  text-align: left;
}

.cc-address-zip-code-suggestion-item {
  cursor: pointer;
}

.cc-address-zip-code-suggestion-item:hover {
  background-color: theme('colors.primary.2');
}

.cc-onboarding-validation-error {
  color: @cc-danger;
  text-align: left;
  padding-left: 6px;
  margin-bottom: 8px;
}

.cc-onboarding-validation-error-topmargin {
  margin-top: 8px;
}

.cc-generic-form-floating-input-group {
  width: 50%;
  margin-right: 25px;
  margin-top: 25px;
}

.cc-generic-form-child-checkbox {
  margin-left: 80px;
}
.cc-generic-form-parent-checkbox {
  margin-left: 40px;
}
.cc-holding-col {
  padding-left: 0px !important;
  padding-right: 20px;
}
.cc-holding-row {
  padding-top: 16px;
}
.cc-holding-divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.cc-holding-add-button {
  text-align: center;
  margin-bottom: 24px;
}
.cc-investmet-policy-radio-group {
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
}
.cc-investmet-policy-radio-item {
  width: 100%;
}
.cc-investment-policy-upload {
  margin-bottom: 16px;
}
.cc-familyNetwork-investment-form {
  max-width: 556px !important;
}
.cc-familyNetwork-deals-text {
  margin-top: 50px;
  margin-bottom: 15px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;