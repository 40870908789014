.content-video-player {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__contacts-info {
    margin-left: 12px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  & &__close {
    @close-button-gap: 4px;
    @close-button-size: 28px;

    color: white;
    cursor: pointer;
    position: absolute;
    width: @close-button-size;
    left: -(@close-button-size + @close-button-gap);
    top: -(@close-button-size + @close-button-gap);
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;