@import '../../styles/colors.less';

.form-container {
  padding-bottom: 24px;
  background: white;

  &-header {
    text-align: center;
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #151517;
    padding-top: 40px;
  }

  &-description {
    color: #92949c;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    width: 100%;
  }
}

.form-section {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 31px;
  padding-right: 31px;
  padding-bottom: 32px;
}

.form-column-grouping {
  color: @cc-darkest-grey;
  padding-top: 10px;
  flex: 1;
}

.form-column-inputs {
  flex: 2;
  border-left: solid 1px @cc-light-grey;
  padding-left: 25px;
}

.form-column-upload {
  flex: 1.5;
}

.upload-container {
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.form-section-agreements {
  padding-top: 50px;
  padding-left: 97px;
  padding-right: 31px;
  padding-top: 20px;
}

.checkbox-agreement {
  display: block;
  margin-top: 26px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.form-section-submit {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 97px;
  padding-right: 31px;
}

.form-section-submit-buttons {
  flex: 4;
  display: inline-flex;
  justify-content: center;
}

.form-section-submit button {
  margin-left: 24px;
}

.form-section-btn-save {
  width: 200px;
}

.form-section-btn-cancel {
  width: 150px;
}

.empty-column-left,
.empty-column-right {
  flex: 1;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;