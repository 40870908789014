@import '../../styles/colors.less';

.fund-details-values-container {
  vertical-align: top;
  display: inline-block;
  width: 100%;
}

.fund-details-details-container {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
}

.fund-details-details-section {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}

.fund-details-details-section-heading {
  border-bottom: solid 1px #e2e8ee;
  padding-bottom: 8px;
}

.fund-details-details-data-section {
  display: flex;
  width: 100%;
}

.fund-details-detail-column {
  justify-self: left;
}

.fund-details-detail {
  padding-top: 16px;
  padding-bottom: 8px;
  padding-right: 16px;
}

.fund-details-detail-content {
  display: flex;
  justify-content: left;
}

.fund-details-detail-piece {
  justify-self: left;
  flex: 3;
}

.fund-details-detail-piece-value {
  color: @cc-darkest-grey;
  font-weight: bolder;
  font-family: Helvetica;
}

.fund-details-detail-piece-footer {
  color: @cc-dark-grey;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.fund-details-detail-type-Bold,
.fund-details-detail-type-BoldCurrency,
.fund-details-detail-type-BoldNumeric,
.fund-details-detail-type-BoldDateTime {
  font-weight: bold;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;