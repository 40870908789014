@import '../../styles/colors.less';

.modalHeader {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  color: @cc-greyscale-darkestblack;
}

.modalSearch {
  background: @cc-white;
  border: 1px solid @cc-light-grey;
  box-sizing: border-box;
  border-radius: 4px;
  width: 90%;
  align-items: center;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.marker-list {
  overflow-y: auto;
  max-height: 600px;
  min-height: 350px;
}

.marker-item {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  align-items: center;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: inset 0px -1px 0px @cc-light-grey;

  &:hover {
    background: @cc-lightest-grey;
  }

  &:active {
    background: @cc-light-grey;
    box-shadow: inset 0px -1px 0px @cc-light-grey;
  }

  &-name {
    color: @cc-greyscale-lightblack;
    font-size: 14px;
    line-height: 24px;
  }

  &-companyname {
    color: @cc-dark-grey;
    font-size: 12px;
    line-height: 16px;
  }

  &-roadshowDates {
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    color: @cc-dark-pink-darker;
  }

  &-footer {
    display: flex;
    margin-top: 5px;
    &-title {
      font-size: 9px;
      line-height: 12px;
      color: @cc-primary;
      &-roadshow {
        font-size: 9px;
        line-height: 12px;
        color: @cc-dark-pink-darker;
      }
    }
    &-broad {
      width: 85%;
    }
    &-match {
      width: 15%;
    }
  }
}

.marker-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: @cc-greyscale-darkestblack;
  padding-left: 34px;
  border-bottom: 1px solid #e2e8ee;
  padding-bottom: 20px;
}

.marker-header {
  position: sticky;
  top: 0;
  background-color: white;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;