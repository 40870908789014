@import '../../styles/colors.less';
.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: @cc-lightest-grey !important;
  border-style: none !important;
  border-radius: 4px;
}

.cc-videoSettings {
  &-header {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #24262b;
  }

  &-body {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #24262b;
  }

  &-title {
    display: flex;
    justify-content: space-between;
  }

  &-icon {
    margin-right: 5px;
  }
}

.pids-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pid {
  width: calc(8%);
  height: 4px;
  display: inline-block;
  border-radius: 2px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;