.ContextForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: wrap;
}

.Text {
  color: rgba(130, 130, 130, 0.75);
  margin-bottom: 10px;
}
.Label {
  width: 100%;
  & > .ant-col {
    padding-left: 0px;
    padding-right: 0px;
    &
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
    &
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      margin-left: 4px;
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      content: '*';
    }
    & > label {
      color: #828282;
    }
  }
}
.InputField {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px;
  height: 48px;
  width: 340px !important;
  max-width: 95%;
  &-number {
    display: flex;
    align-items: center;
    & > .ant-input-number-input-wrap {
      width: 100%;
    }
  }
}

.TextArea {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px;
}

.BooleanField {
  &-radio {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    & > div > label {
      color: #828282;
      margin-bottom: 10px;
    }
    & > div > .ant-row {
      margin-bottom: 20px;
      margin-top: 0px;
    }
    & > .ant-radio-wrapper {
      color: #828282;
      margin-bottom: 10px;
    }
    &-conditional {
      margin: 10px 0px 0px 20px;
    }
  }
}

.CheckboxField {
  &-box {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 340px !important;

    & > .ant-checkbox-wrapper {
      color: #828282;
      margin-bottom: 10px;
    }
    & > div > label {
      color: #828282;
      margin-bottom: 10px;
    }
    &-conditional {
      margin: 10px 0px 20px 20px;
    }
  }
}

.DropdownField {
  width: 340px !important;
  & > .ant-select-selector {
    border: 1px solid #e0e0e0 !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
    border-radius: 3px !important;
    height: 48px !important;
    & > .ant-select-selection-search > input {
      height: 48px !important;
    }

    & > .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}

.DateField {
  width: 340px !important;
  border: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px !important;
  height: 48px !important;
}

.LinkList {
  &-title {
    padding: 20px 0px 10px 0px;
    & > .ant-btn {
      padding: 0;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;