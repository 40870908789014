.message-day-header {
  @apply sticky top-0;
  @apply flex items-center gap-8 py-2 px-4 w-full;
  @apply bg-white text-grey-600 type-subtitle-sm;

  @screen md {
    @apply px-8;
  }

  &::before,
  &::after {
    content: '';
    @apply flex-1;
    @apply border-0 border-b border-solid border-grey-300;
  }
}

.conversation-header {
  .search-group.input-group .form-input,
  .search-group.input-group .form-input .form-input__input {
    min-width: 0;
  }
}
