.StatBox {
  padding: 15px;
  border-radius: 8px;
  background-color: #f2fbfc;
}

.StatValue {
  text-align: right;
}

.StatRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.TextSection {
  margin-bottom: 16px;
}

@media print {
  .HidePrint {
    display: none;
  }

  .TearSheetContainer {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .StatBox-Print {
    padding-right: 100px;
  }

  .NoBreaks {
    page-break-inside: avoid;
  }
}

@media screen {
  .TearSheetContainer {
    padding: 15px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .StatBox-Print {
    display: none;
  }

  .ShowPrint {
    display: none;
  }
}

h1 {
  margin-top: 12px;
  font-weight: bold;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;