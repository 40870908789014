@import '../../styles/colors.less';

.discover-service-provider-details {
  &-header {
    display: block;
    align-items: center;
  }

  &-title {
    padding: 30px 30px 15px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: @cc-lightest-grey;
  }

  &-tabSection {
    width: 100%;
    & > .ant-tabs-content-holder {
      width: 100%;
      padding: 0px 20px;
    }
    & > .ant-tabs-nav {
      background-color: @cc-lightest-grey;
    }
  }

  &-location {
    color: @cc-grey;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
  }

  &-actions {
    vertical-align: top;
    display: inline-block;
    margin-left: 105px;
  }

  &-actions div {
    display: inline-block;
  }

  &-actions button {
    vertical-align: top;
    height: 32px;
    width: 32px;
    display: inline-block;
    margin-left: 16px;
    font-size: 14px;
    padding: 0px;
  }

  &-actions-direct button {
    background: rgba(177, 52, 125, 0.1);
    font-size: 16px;
  }

  &-favicon {
    color: #f19f00;
    font-size: 21px !important;
  }

  &-document {
    background: #2d81a8;
  }

  &-reschedule {
    margin-left: 32px !important;
    color: #6a48c6;
  }

  &-attachment {
    color: #b1347d;
  }

  &-tabs {
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-footer {
    height: 64px;
    width: 100%;
    background: @cc-lightest-grey;
    display: block;
  }

  &-footer-buttons {
    float: right;
    padding-right: 24px;
    padding-top: 16px;
  }

  &-footer-buttons button {
    margin-right: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  &-footer-buttons button svg {
    margin-right: 10px;
  }

  &-send-message {
    background: @cc-secondary !important;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;