@import '../../styles/colors.less';

.discover-fund {
  margin-bottom: 20px;
  color: #3d4858;
}

.discover-fund-result {
  position: relative;
  text-align: left;

  &&--hidden {
    @apply border-grey-700 opacity-70;
  }
}

.meeting-status-badge .ant-badge-status-dot {
  @apply w-3 h-3 ml-1;
}

.discover-fund-favicon {
  @apply border-none shadow-none py-0;

  &,
  &:hover,
  &:focus {
    @apply bg-transparent text-gold-100;
  }
}

.discover-fund-result-info {
  @apply flex justify-between;
  align-items: center;

  > * {
    max-width: 50%;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;