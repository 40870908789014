@import '../../styles/colors';

.ant-col {
  padding-left: 0px;
}

.form-box {
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  box-shadow: 0px 7px 20px rgba(141, 136, 172, 0.15);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 12px;
}

.header-row {
  margin-bottom: 12px;
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.right-align {
  text-align: right;
}

.ApproveReturns {
  text-align: center;
  padding-bottom: 24px;
}

.ApproveReturns-title {
  font-size: 16px;
  color: gray;
}

.ApproveReturns-name {
  font-size: 20px;
  font-weight: bold;
  color: black;
  padding-bottom: 16px;
}

.ApproveReturns-light-text {
  color: #92949c;
}

.ApproveReturns-publish {
  margin-top: 24px;
  padding-bottom: 16px;
}

.ApproveReturns-edit {
  margin-top: 8px;
}

.Disclaimer {
  color: @cc-primary;
}

.ApproveReturns-Disclaimer-Tooltip > .ant-tooltip-content > .ant-tooltip-inner {
  width: 25vw;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;