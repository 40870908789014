@import '../../../styles/colors.less';

.cc-participant {
  display: flex;
  margin-bottom: 24px;
  &-identity {
    margin: 15px 0;
    text-align: left;
    width: 60%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-name {
      font-weight: 600;
      font-size: 16px;
      color: @cc-greyscale-lightblack;
    }

    &-title {
      font-size: 14px;
    }

    &-company {
      font-size: 14px;
      line-height: 18px;
      color: @cc-dark-grey;
    }
  }

  &-small {
    width: 30%;
    flex: 0 0 auto;
    margin: auto;
  }
  &-audio {
    text-align: center;
    margin: auto 0;
    width: 10%;
  }
  &-video {
    &-small {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &-on {
        color: @cc-white;
        text-align: center;
        position: absolute;
        z-index: 160;
      }

      &-off {
        color: @cc-white;
        background: @cc-dark-black;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-none {
        display: none;
      }
    }
  }
}

.sidebar-network-wrapper {
  width: 100%;
  color: @cc-white;
  margin-top: 10px;
  z-index: 999;
  position: absolute;
  top: 60%;
  left: 5%;
}

.sidebar-network {
  width: 3px;
  display: inline-block;
  margin-right: 0.5px;
  margin-left: 0.5px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;