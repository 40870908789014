.discover-result-card {
  @apply block;
  @apply bg-white;
  @apply border border-grey-300 border-solid;
  @apply rounded shadow-0;
  transition: border-color 300ms, background-color 300ms, box-shadow 300ms;

  @apply hover:bg-secondary-2 hover:border-secondary-100 hover:shadow-6;
}

.discover-result-card__header {
  @apply pt-4 px-7;
}

.discover-result-card__body {
  @apply px-7 pb-3;
}

.discover-result-card__footer {
  @apply py-2 px-4 border-t border-r-0 border-b-0 border-l-0 border-solid;
}
