.cc-divider-companydetails {
  min-width: '80%';
  margin-top: 10px;
}

.cc-ul-comp-details {
  padding-inline-start: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &-items {
    width: 50%;
    min-width: 120px;
    margin-bottom: 15px;
  }
}

.cc-container-compdetails-list {
  margin-bottom: 40px;
  max-width: 300px;
}

.cc-company-desc {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  &-numbers {
    margin-top: 7px;
    display: flex;
    width: 80%;
    justify-content: space-between;
    &-containers {
      width: 50%;
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .cc-company-desc {
    width: 100%;
    &-numbers {
      width: 80%;
      &-containers {
        width: 100%;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;