.fund-campaign-card {
  .campaign-stats {
    @apply w-full max-w-4xl mx-auto;
    @apply grid grid-flow-col gap-x-4;
    grid-template: repeat(4, auto) / repeat(2, 1fr);

    > :nth-child(3),
    > :nth-child(7) {
      @apply mt-4;
    }

    @screen sm {
      grid-template: repeat(2, auto) / repeat(4, 1fr);

      > :nth-child(3),
      > :nth-child(7) {
        @apply mt-0;
      }
    }

    @screen lg {
      grid-template: repeat(4, auto) / repeat(2, 1fr);

      > :nth-child(3),
      > :nth-child(7) {
        @apply mt-4;
      }
    }

    @screen xl {
      grid-template: repeat(2, auto) / repeat(4, 1fr);

      > :nth-child(3),
      > :nth-child(7) {
        @apply mt-0;
      }
    }

    .campaign-stats__data {
      @apply type-body-regular-lg text-grey-900;
      @apply self-end justify-self-center;
    }

    .campaign-stats__label {
      @apply type-body-semibold-xs text-grey-700;
      @apply self-start justify-self-center text-center;
    }
  }

  a.update-button,
  button.update-button,
  .menu-button-group.update-button .button {
    @apply bg-gold-110 hover:bg-gold-100;
  }
}
