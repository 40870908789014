@import '../../../styles/colors.less';

.chat-header {
  max-height: 72px;
  min-height: 72px;
  border-bottom: 1px solid @cc-light-grey;
  background: @cc-white;

  &-profile {
    margin-top: 26px;
    margin-left: 24px;
    display: inline-block;
    text-align: center;
  }

  &-name {
    color: @cc-light-black;
  }

  &-type {
    display: inline-block;
    margin-top: 29px;
    margin-left: 24px;
  }

  &-title {
    color: @cc-dark-grey;
    margin-top: 4px;
  }

  &-profile-pictures {
    float: right;
    margin-top: 28px;
  }
}

.chat-actions {
  float: right;
  margin-top: 28px;
  margin-right: 16px;

  & button {
    border: none;
    font-size: 20px;
    box-shadow: none;
  }
}

.messaging-meeting-type {
  &-summit span {
    background: @cc-primary;
  }

  &-online span {
    background: @cc-purple;
  }

  &-roadshow span {
    background: @cc-dark-pink-darker;
  }
}

.messaging-chat-action-item {
  padding-left: 24px !important;
  padding-right: 24px !important;
  box-shadow: inset 0px -1px 0px @cc-light-grey;
  color: @cc-darkest-grey;

  & svg {
    margin-right: 8px;
    color: @cc-grey;
  }
}

.messaging-chat-action-leave svg {
  color: @cc-danger;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;