@import '../../styles/colors';

.content__categories {
  @content-category-spacing: 8px;
  margin: 0 -@content-category-spacing;
  display: flex;
  justify-content: space-between;

  &-right {
    white-space: nowrap;
  }

  .content__category {
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: #6b6b60;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin: 0 @content-category-spacing;
    padding: 12px 24px;
    text-transform: uppercase;
    transition: background-color 200ms, color 200ms, border-color 200ms;

    &:hover {
      background-color: #d9d9dd;
      color: #6b6b60;
    }

    &--active,
    &--active:hover {
      border-color: #e4e7eb;
      background-color: white;
      color: black;
      cursor: default;
    }
  }

  .search-box {
    width: 200px;
    margin: 8px 10px;
  }
}

.content-featured-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__body {
    color: black;
    padding: 64px 20px 36px 0;
    width: 50%;
  }

  & &__category {
    text-transform: uppercase;
    color: lighten(#606062, 15%);
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    padding-top: 8px;
    padding-bottom: 20px;
  }

  &__cover-image {
    width: 50%;
  }

  &:hover &__title {
    color: @cc-primary;
  }
}

.content-entry__reactionCount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 14px;

  svg {
    margin-right: 4px;
    font-size: 8px;
  }
}

.content-entry__reactions {
  justify-content: center;
  align-items: inherit;
  & > button {
    margin: 0 3px 5px;
    vertical-align: middle;
  }
}

.aspect-ratio-16-9 {
  position: relative;

  &::after {
    display: block;
    content: '';
    padding-top: 56.25%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.content-entry {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & &__body {
    padding: 12px;
  }

  & &__category {
    text-transform: uppercase;
    color: lighten(#606062, 15%);
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  & &__title {
    color: #606062;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }

  & &__subtitle {
    color: #606062;
    font-size: 14px;
    line-height: 22px;
  }

  & &__description {
    color: #606062;
    font-size: 12px;
    line-height: 22px;
  }

  & &__publishDate {
    padding-top: 12px;
    color: #606062;
    font-size: 12px;
    line-height: 22px;
  }

  .content-contact-avatar {
    padding-top: 12px;
  }
}

.content-author-avatar {
  display: flex;
  flex-direction: row;

  & &__image {
    flex-shrink: 0;
    margin-right: 12px;
  }

  & &__name {
    color: rgba(0, 0, 0, 60%);
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
  }

  & &__job-title {
    color: rgba(0, 0, 0, 60%);
    font-size: 12px;
    line-height: 17px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;