@import '../../../styles/colors.less';

.messaging-message-container-mine {
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 120px;
  margin-bottom: 8px;
}

.messaging-message-mine {
  background: @cc-primary;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  padding: 8px 16px 8px 16px;
  display: block;
  min-height: fit-content;
  color: @cc-white;
  direction: ltr;
}

.messaging-message-attachment {
  border: 1px solid @cc-light-grey;
  border-radius: 8px;
  min-width: 180px;
  padding-top: 18px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  width: fit-content;
  margin-left: 30px;

  & span {
    color: @cc-dark-grey;
  }
}

.messaging-message-time {
  vertical-align: top;
  margin-right: 8px;
  color: @cc-grey;
}

.messaging-message-timestamp {
  direction: ltr;
  display: inline-block;
}

.messaging-message-readby-tick {
  font-size: 12px;
  margin-right: 4px;
}

.messaging-message-readby-tick {
  color: @cc-dark-grey;
}

.messaging-message-container-other .messaging-message-time {
  margin-left: 32px;
}

.messaging-message-readby-tick.read {
  color: @cc-success;
}

.messaging-message-attachment-icon {
  font-size: 30px;
  color: #c5000c;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.messaging-message-attachment-download-link {
  margin-top: 10px;
  padding-top: 4px;
  border-top: 1px solid @cc-light-grey;
  & button {
    max-height: 8px;
  }
  & button span {
    color: @cc-primary;
    font-weight: 600;
  }
  padding-bottom: 0px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;