@import '../../styles/colors.less';

.cc-summit-date-label {
  display: inline-block;
  background: @cc-lightest-grey;
  border: 1px solid @cc-light-grey;
  border-radius: 8px;
  padding: 8px 18px;

  & > span {
    color: @cc-primary;
    //color: #C2C6CC ;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }
  & > span .oneEventSpan {
    color: #c2c6cc;
    font-size: 18px;
  }

  & > &-days {
    margin-right: 8px;
  }

  & > &-month {
    color: @cc-dark-grey;
  }

  &-fancy {
    background: @cc-primary;
    border-width: 0;
  }

  &-oneEvent {
    background: none !important;
    border: 0px !important;
    border-radius: none !important;
    padding: none !important;
  }

  &-fancy > &-days,
  &-fancy > &-month {
    color: @cc-white;
  }

  &-fancy > &-month {
    opacity: 0.5;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;