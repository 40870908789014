@import '../../../styles/colors.less';

.forbidden-panel {
  &-container {
    width: 100%;
    padding-top: 240px;
  }

  &-icon {
    &-background {
      margin-left: auto;
      margin-right: auto;
      max-width: 91px;
      max-height: 91px;
      min-width: 91px;
      min-height: 91px;
      background-color: @cc-white;
      box-shadow: 0px 0px 50px rgba(154, 163, 197, 0.2);
      text-align: center;
      font-size: 11px;
      vertical-align: middle;
      border-radius: 46px 46px;
    }

    &-svg {
      width: 56px;
      margin-top: 16px;
    }
  }

  &-text {
    margin-top: 44px;
    max-width: 458px;
    text-align: center;
    line-height: 34px;
    font-family: Open Sans;
    margin-left: auto;
    margin-right: auto;
    color: #49494a;
    font-size: 24px;
  }
  &-upgrade {
    width: 214px;
    margin-left: auto;
    margin-right: auto;

    &-button {
      margin-top: 43px;
      box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06) !important;
      border-radius: 4px;
      min-height: 45px;
      max-height: 45px;
      min-width: 214px;
      max-width: 214px;

      & svg {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;