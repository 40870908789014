@import '../../styles/colors.less';

.ContactShortProfile {
  display: flex;
  align-items: center;

  & &-picture {
    flex: 0 0 auto;
    margin-right: 8px;
  }

  & &-info {
    flex: 1 0 auto;
    // margin-bottom: 14px;

    &-name {
      font-size: 16px;
      line-height: 28px;
      font-weight: normal;
      color: @cc-greyscale-darkestblack;
      margin-bottom: 2px;
    }

    &-position {
      font-size: 12px;
      line-height: 16px;
      color: @cc-dark-grey;
    }
  }

  &-small &-info {
    &-name {
      font-size: 12px;
      line-height: 16px;
      color: @cc-light-black;
    }

    &-position {
      font-size: 10px;
    }
  }

  &-bio {
    font-size: 14px;
    line-height: 24px;
    color: @cc-greyscale-darkestblack;
    width: 100%;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;