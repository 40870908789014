@import '../../styles/colors.less';
.mainHeading {
  /* H4 Heading */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height, or 122% */
  align-items: center;
  text-align: center;

  /* Greyscale/Light Black */
  color: #24262b;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: center;
  margin: 0px 16px;
}

.subHeading {
  /* H5 Heading */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* Greyscale/Dark Grey */
  color: #92949c;

  /* Inside Auto Layout */
  padding-top: 16px;
  order: 2;
  align-self: center;
  margin: 0px 16px;
}

.subInfo {
  /* Body */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-top: 16px;
  align-items: center;
  text-align: center;

  /* Greyscale/Dark Grey */
  color: #92949c;
}

.inputSearchAlert {
  background: #f7f8fa;
  border-radius: 4px;
  border: none;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  margin-top: 32px;
  color: #4d4f56;
}
.createAlertBody {
  & > .ant-tag,
  & > div > .ant-tag {
    border-color: @cc-light-grey;
    & > .ant-tag-close-icon {
      color: @cc-danger;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;