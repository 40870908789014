@import '../../../styles/colors.less';

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 0 transparent;
}

.messaging-chat-search-bar {
  height: 48px;
  background-color: @cc-white;
  right: 24px;
  left: 0px;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 48px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  z-index: 202;
  position: absolute;
  transition: right 200ms ease-in-out;
  width: 100%;
}

.messaging-chat-search-controls {
  border: 1px solid #eff1f4;
  border-radius: 4px;
  position: absolute;
  left: 8px;
  right: 50px;
  height: 40px;
}

.messaging-chat-search-input {
  //margin-right: 200px;
  top: 2px;
  position: absolute;
  left: 0px;
  right: 100px;
  transition: right 200ms ease-in-out;
}

.messaging-chat-search-input .ant-input {
  border: none;
  background-color: @cc-white;
  //background-color: @cc-grey;
  margin-left: 18px;
  height: 32px;
  color: @cc-light-black;
}

.messaging-chat-search-input .ant-input:focus,
.messaging-chat-search-input .ant-input:active {
  border: none;
  box-shadow: none;
}

.messaging-chat-search-input-control {
  border: none;
  background-color: @cc-white;
  height: 32px;
}

.messaging-chat-search-icon {
  margin-left: 8px;
  color: @cc-grey;
  font-size: 17px;
}

.messaging-chat-results,
.messaging-chat-results div,
.messaging-chat-search-input {
  display: inline;
}

.messaging-chat-results {
  //float: right;
  right: 8px;
  position: absolute;
  top: 3px;
}

.messaging-chat-results-label {
  margin-top: 2px;
  margin-right: 8px;
  display: inline-block;
  color: @cc-dark-grey;
}

.messaging-chat-results-cursor-previous {
  margin-right: 4px;
}

.messaging-chat-results-cursors svg {
  font-size: 20px;
  padding-top: 2px;
  color: @cc-dark-grey;
}

.messaging-chat-search-close {
  right: 0px;
  position: absolute;
  //background-color: red;
  width: 50px;
  height: 42px;
  font-size: 27px;
  padding-left: 16px;
  cursor: pointer;
  color: @cc-dark-grey;
}

.messaging-chat-search-icon {
  cursor: pointer;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;