@import '../../../styles/colors.less';

.messaging-message-log {
  text-align: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  //border-bottom: 1px solid @cc-light-grey ;

  &-time {
    color: @cc-dark-grey;
  }

  &-title {
    margin-top: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    & svg {
      margin-right: 8px;
      font-weight: normal;
    }
  }

  &-description {
    margin-top: 8px;
  }

  &-MeetingLogAccepted .messaging-message-log-title {
    color: @cc-success;
  }

  &-MeetingLogCancelled .messaging-message-log-title,
  &-MeetingLogDeclined .messaging-message-log-title {
    color: @cc-danger;
  }

  &-MeetingLogRequested .messaging-message-log-title,
  &-MeetingLogRescheduled .messaging-message-log-title,
  &-ConversationInvited .messaging-message-log-title,
  &-ConversationLeft .messaging-message-log-title {
    color: @cc-primary;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;