@import '../../styles/colors.less';

.discover-fund-details-profile {
  &-container {
    padding-top: 0px;
  }

  &-section {
    padding-top: 8px;
  }

  &-divider {
    margin: 16px 0px;
  }

  &-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: @cc-lightest-grey;
    padding: 24px 16px 24px 24px;
    border-radius: 8px;
  }
}

.fund-details-section {
  &-heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: @cc-grey;
  }

  &-content {
    padding: 8px 0px;
  }
}

.fundAUMandPerformance {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;

  &-numbers {
    min-width: 100px;
  }

  &-numbers-container {
    width: 45%;
    min-width: 320px;
  }
}

.custom-col {
  flex-wrap: unset;
  padding: 0;
  margin-right: 8px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;