@import '../../styles/colors.less';

.cc-check-participants {
  & {
    display: inline-flex;
    width: 100%;
  }
  & > .ant-checkbox-disabled + span {
    color: @cc-greyscale-darkestblack;
  }
  & > .ant-checkbox,
  & > .ant-checkbox.ant-checkbox-checked {
    margin: 11px 0px 10px 10px;
    height: 100%;
  }
  & > .ant-checkbox + span,
  & > .ant-checkbox.ant-checkbox-checked + span {
    margin-top: -5px;
    width: calc(100% - 20px);
  }
  & > .ant-checkbox.ant-checkbox-checked > .ant-checkbox-input {
    height: auto;
  }
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &-box {
    border: 1px solid @cc-light-grey;
    box-sizing: border-box;
    border-radius: 4px;
    width: calc(33% - 16px);
    margin-right: 16px;
    margin-top: 16px;
  }
  &-box-checked {
    border-color: @cc-primary;
  }

  & > .ant-checkbox > span {
    width: 20px;
    height: 20px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;