@import '../../styles/colors.less';

.category-tag {
  border: none;
}

.tag-clip-button {
  background: theme('colors.primary.2') !important;
  width: 20px;
  height: 20px !important;
  border: none !important;
  max-width: 20px;
  padding: 0px !important;
  box-shadow: none !important;
  top: 2px;
}

.tag-clip-button:hover {
  background: theme('colors.primary.5');
}

.tag-clip-button span {
  color: @cc-purple;
  height: 20px;
  text-shadow: none;
}

.tag-overflow-popover .popover__arrow {
  fill: white;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;