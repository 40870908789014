@import '../../styles/colors.less';

.upload-icon-upload {
  position: absolute;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 137px;
  border-radius: 64px;
}

.upload-icon-empty {
  border: dashed 1px @cc-grey;
  width: 137px;
  height: 137px;
  border-radius: 69px;
  background: @cc-lightest-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.upload-icon-empty .ant-upload-select-picture-card {
  border: none;
  background: rgba(0, 0, 0, 0);
  padding-right: 0px;
  padding-bottom: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.upload-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: @cc-black;
}

.upload-icon-text svg {
  font-size: 26px;
  margin-bottom: 10px;
  color: @cc-grey;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;