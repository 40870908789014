.roadshow-container {
  background: #ffffff;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  background: #f0f2f5;
}

.roadshow-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height, or 121% */

  /* Greyscale/Black */

  color: #151517;
  margin-left: 24px;
  padding-top: 24px;
}

.roadshow-emptybody {
  text-align: center;
  align-self: center;
  flex: none;
  &-header {
    color: #24262b;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin: 4px 24px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &-body {
    color: #92949c;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    font-style: normal;
    margin: 25px 24px;
    text-align: center;
    display: block;
  }

  &-footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    &-icon {
      margin-right: 10px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;