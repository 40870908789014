@import '../../../styles/colors.less';

.messaging-chat-info-panel {
  margin-top: 20px;
  padding: 24px;
  padding-top: 0px !important;
}

.messaging-chat-company {
  &-profile {
    text-align: center;
  }

  &-image img {
    width: 81px;
    height: 81px;
    border-radius: 42px 42px;
  }

  &-name {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: @cc-light-black;
  }

  &-actions {
    margin-top: 10px;
  }

  &-profile svg {
    margin-right: 8px;
  }

  &-no-funds {
    color: @cc-grey;
    font-size: 12px;
    line-height: normal;
    margin-top: 8px;
  }
}

.messaging-chat-documents-title {
  margin-top: 32px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: @cc-dark-grey;
}

.messaging-chat-document-container {
  margin-top: 16px;
}

.messaging-chat-document-image {
  width: 20px;
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
}

.messaging-chat-document-desc {
  width: 179px;
  display: inline-block;
  margin-left: 8px;
}

.messaging-chat-document-image img {
  width: 20px;
}

.messaging-chat-document-image svg {
  font-size: 28px;
}

.messaging-chat-document-red {
  color: @cc-danger;
}

.messaging-chat-document-green {
  color: @cc-success;
}

.messaging-chat-document-blue {
  color: @cc-primary;
}

.messaging-chat-document-orange {
  color: #d04423;
}

.messaging-chat-document-filename button,
.messaging-chat-document-filename a {
  color: @cc-black;
  text-align: left;
  max-width: 500px;
  width: 500px;
  overflow-x: hidden;
  //word-wrap: break-word;
  overflow-wrap: break-word;
}

.messaging-chat-document-filename button {
  padding-left: 0px;
}

.messaging-chat-action-item {
  padding-left: 24px !important;
  padding-right: 24px !important;
  box-shadow: inset 0px -1px 0px @cc-light-grey;
  color: @cc-darkest-grey;
}

.conversations-panel-right .ant-drawer {
  top: 136px;
  max-height: calc(100vh - 162px);
}

.conversations-panel-right .ant-drawer-content-wrapper {
  box-shadow: none !important;
  border-left: 1px solid @cc-light-grey;
  border-bottom: none;
}

.messages-drawer .ant-drawer-body {
  padding: 0px !important;
}

.messaging-chat-info-panel {
  padding: 24px;
}

.messaging-chat-info-panel-scrollbar {
  max-height: calc(100vh - 198px);
  margin-top: 36px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;