@import '../../styles/colors.less';

.round-profile-thumbnail-group {
  border: 2px solid @cc-white;
  margin-left: -11px !important;
  margin-top: -11px !important;
}

.round-profile-imagegroup {
  padding-left: 11px;
  padding-top: 11px;
  vertical-align: bottom;
}

.round-profile-ellipsis {
  margin-left: -11px;
  margin-top: -11px;
  margin-top: -2px;
  text-align: center;
  color: @cc-white;
  border: 2px solid @cc-white;
  object-fit: cover;
}

.round-profile-ellipsis span {
  vertical-align: middle;
}

.profile-ellipsis-online {
  background: @cc-purple;
}

.profile-ellipsis-roadshow {
  background: @cc-dark-pink-darker;
}

.profile-ellipsis-summit {
  background: @cc-primary;
}

.ant-avatar-string {
  margin-top: -2px;
}
.ProfilePictureGroup {
  &-popover {
    & .ant-popover-inner {
      background-color: rgba(0, 0, 0, 0.75);
      color: @cc-white;
      text-align: center;

      &-content img {
        object-fit: cover;
      }
    }
    & .ant-popover-title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      color: @cc-white;
    }
    &.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
      border-bottom-color: rgba(0, 0, 0, 0.75);
      border-right-color: rgba(0, 0, 0, 0.75);
    }
    &.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
      border-top-color: rgba(0, 0, 0, 0.75);
      border-left-color: rgba(0, 0, 0, 0.75);
    }
    &-no-image {
      & .ant-popover-inner-content {
        padding: 0px;
      }
      & .ant-popover-title {
        border-bottom: none;
        color: @cc-white;
      }
    }
  }
  &-dropdown-popover-no-image.ant-popover {
    display: none;
    z-index: 2000;
  }
  &-dropdown-popover.ant-popover {
    z-index: 2000;
  }
}
.dropdown-avatar {
  margin-top: -2px;
  text-align: center;
  color: @cc-white;
  border: 2px solid @cc-white;
  margin-right: 16px;
  object-fit: cover;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;