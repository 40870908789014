@import '../../styles/colors.less';

.ServiceProviderWizard {
  max-width: calc(~'100vw - 48px');
  background: @cc-white;
  min-height: calc(~'100vh - 108px');
  box-shadow: 0 16px 28px rgba(47, 56, 62, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &-form {
    padding: 20px;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  &-row {
    width: 100%;
  }
  &-steps {
    background: @cc-light-grey;
    &
      > .StepList
      > .ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-title {
      color: @cc-greyscale-darkestblack;
      font-weight: 600;
    }

    &
      > .StepList
      > .ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-description {
      display: none;
    }

    & > .StepList {
      display: flex;

      & > .ant-steps-vertical > .ant-steps-item {
        max-height: 80px;
        min-height: 80px;
        & > .ant-steps-item-container > .ant-steps-item-tail {
          background: #e0e0e0;
          margin-top: 34px;
          height: 35px;
        }
      }
    }
  }
  & &-steps {
    padding: 40px;
    min-height: calc(~'100vh - 136px');

    &
      > .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description {
      visibility: hidden;
    }
  }

  & > &-form {
    flex-grow: 1;
    padding: 0 84px;
    min-height: 100vh;

    &-actions {
      width: 95%;
      border-top: 1px solid @cc-light-grey;
      padding: 48px;
    }
  }

  & &-field {
    margin-bottom: 16px;

    &-label {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      color: @cc-greyscale-lightblack;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
    }

    // & .cc-floating-input {
    & .ant-input {
      height: 48px;
      border: none;
      border-radius: 4px;
      background-color: @cc-lightest-grey;
      font-size: 14px;
      line-height: 24px;
      color: @cc-greyscale-darkestblack;
      margin-bottom: 8px;
    }

    & .ant-input-number {
      border: none;
      height: 48px;
      width: 100%;
      margin-bottom: 8px;

      & .ant-input-number-input {
        height: 46px;
      }
    }

    & .ant-calendar-picker {
      display: inline-block;
      width: 100%;

      & .ant-calendar-picker-input {
        height: 48px;
      }
    }

    & .ant-select {
      & .ant-select-selection {
        height: 48px;
        border: none;
        background-color: @cc-lightest-grey;

        & .ant-select-selection__rendered {
          line-height: 46px;

          & .ant-select-selection__placeholder {
            height: 30px;
          }
        }
      }
    }

    &-checkbox > .ant-checkbox-group-item {
      display: block;
      margin-bottom: 7px;
    }
  }

  & &-field-boolean {
    &-options > .ant-radio-wrapper:first-child {
      margin-right: 48px;
    }
  }

  & &-field-footer {
    margin: 16px 0;
    padding-left: 24px;
  }

  & &-Step > &-actions {
    display: flex;
    border-top: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 10px 0;

    & > .ant-btn-lg {
      min-width: 50px;
    }
  }

  &.ServiceProviderWizard-initial {
    justify-content: center;
    align-items: center;
    align-content: stretch;
  }
  .ServiceProviderWizard-step-title {
    text-align: center;
    margin-bottom: 40px;
  }

  &-initial-form {
    & > .ServiceProviderWizard-step-title {
      text-align: center;
      color: @cc-greyscale-lightblack;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      margin-bottom: 64px;
    }

    &-actions {
      margin-top: 64px;
    }
  }
}

.ServiceProviderStep {
  &-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-helper {
      color: rgba(130, 130, 130, 0.5);
      font-size: 13px;
    }
  }
  &-container {
    width: 100%;

    & .ant-upload-list-picture-card-container {
      margin-top: 16px;
      margin-right: 24px;
      min-width: 160px;
      max-width: 160px;

      & .ant-upload-list-item {
        border-radius: 8px;
        //box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
        filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.19));
        border: none;
      }
    }
    & > div > .ant-row > .ant-form-item-label {
      padding-right: 10px !important;
      padding-left: 10px !important;
      & > label {
        width: 100%;
      }
      & > .ant-form-item-required {
        width: 100%;
      }
    }
  }
  &-container {
    width: 100%;
    &-title {
      margin-bottom: 10px;
      color: #828282;
      font-weight: 600;
    }
  }

  &-pre-icon {
    display: inline-block;
    margin-right: 8px;
    min-width: 24px;
    color: @cc-dark-grey;
    font-size: 18px;
    vertical-align: top;
    padding-top: 8px;
  }

  &-reduced,
  &-pre-reduced {
    display: inline-block;
    width: calc(100% - 40px) !important;
  }

  &-reduced&-pre-reduced {
    display: inline-block;
    width: calc(100% - 80px) !important;
  }

  &-info {
    vertical-align: top;
    margin-top: 12px;
    display: inline-block;
    width: 32px;
    font-size: 16px;
    margin-left: 8px;
    color: @cc-dark-grey;
  }
}

.ServiceProviderStepButton {
  margin-top: 10px;
  &-back {
    margin-top: 15px;
    color: @cc-dark-grey;
    cursor: pointer;
  }
  &-save {
    margin-top: 10px;
    color: @cc-dark-grey !important;
    border-color: @input-border-color !important;
  }

  &-save:hover {
    margin-top: 10px;
    color: #54d7de !important;
    border-color: #54d7de !important;
  }
}

.ServiceProviderStepPublishButton {
  margin-top: 10px;
  background: @cc-secondary;
  margin-left: 10px;
  color: @primary-color !important;
  border-color: @input-border-color !important;
}

.ServiceProviderStepPreviewButton {
  margin-top: 10px;
  background: @cc-secondary;
  margin-right: 10px;
  color: #54d7de !important;
  border-color: #e0e0e0 !important;
}

.ArrayField {
  &-delete {
    float: right;
    margin-top: -32px;

    & button {
      color: @cc-danger;
      font-weight: bold;
    }
  }
}

.PersonField {
  &-container {
    display: flex;
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid @cc-light-grey;
  }

  &-image-column {
    display: inline-block;
    vertical-align: top;
  }

  &-fields-column {
    display: inline-block;
  }

  &-name,
  &-position {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    padding: 0px 8px 0px 8px;
  }

  &-description {
    padding: 0px 8px 0px 8px;
  }
}

.TestimonialField {
  &-container {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid @cc-light-grey;
    text-align: center;
  }

  &-image-column {
    min-width: 250px;
    max-width: 250px;
    display: inline-block;
    vertical-align: top;
  }

  &-fields-column {
    display: inline-block;
    width: 100%;
  }

  &-title {
    padding: 0px 8px 0px 8px;
  }

  &-name,
  &-position {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    padding: 0px 8px 0px 8px;
  }

  &-description {
    padding: 0px 8px 0px 8px;
  }
}

.ClientsField {
  &-container {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid @cc-light-grey;
    text-align: center;
  }

  &-image-column {
    min-width: 250px;
    max-width: 250px;
    display: inline-block;
    vertical-align: top;
  }

  &-fields-column {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
  }

  &-title {
    padding: 0px 8px 0px 8px;
  }

  &-name,
  &-position {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    padding: 0px 8px 0px 8px;
  }

  &-description {
    padding: 0px 8px 0px 8px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;