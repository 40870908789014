@import '../../../styles/colors.less';

.SummitAttendees {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  .ant-spin-container {
    height: 100%;
  }

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: @cc-primary;
    padding-top: 12px;
  }

  &-list {
    & .ant-table-fixed-left table {
      width: min-content;
    }
  }

  &-menu {
    &-item {
      padding-right: 8px;

      &-icon {
        color: @cc-primary;
      }

      &-label {
        margin-left: 10px;
      }
    }
  }

  &-dropdown-btn {
    display: inline-block;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    background-color: @cc-lightest-grey;
    border: 0;
    border-radius: 8px;
    text-align: center;
    padding: 0;

    & > span {
      font-weight: 600;
      color: @cc-black;
      font-size: 17px;
      line-height: 18px;
      height: 18px;
    }
  }

  & .ant-badge-status-dot {
    height: 13px;
    width: 13px;
  }

  & .ant-badge-status-text {
    display: none;
  }

  & &-btn-cols {
    line-height: normal;
    background: @cc-primary;
    border-radius: 4px;
    height: 42px;
    width: 54px;
    font-size: 22px;
    background: rgba(47, 197, 210, 0.1);
    color: @cc-primary;
    border: none;
    box-shadow: none !important;
  }

  & &-profile-link {
    padding-left: 0;
  }

  & &-profile-link > span {
    display: block;
    max-width: 272px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.FilterMeetingStatus {
  &-pending {
    @apply bg-blue-100;
  }

  &-confirmed {
    background-color: @cc-success;
  }

  &-declined {
    background-color: @cc-danger;
  }

  &-cancelled {
    background-color: @cc-notice;
  }

  &-label {
    display: inline-block;
    margin-left: 8px;
  }
}
.filters {
  padding: 0px 22px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;