@import '../../styles/colors.less';

.FundMiniProfile {
  background-color: @cc-dark-black;
  width: 250px;
  margin-top: 10px;
  border-radius: 10px;
  &-Row {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &-Title {
    padding-top: 12px;
    color: @cc-white;
  }
  &-SubTitle {
    color: @cc-white;
    opacity: 0.75;
    padding-top: 5px;
  }
  &-Button {
    width: 185px;
    margin: 10px auto;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;