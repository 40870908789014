@import '../../styles/colors.less';
@import '../../styles/constants.less';

@transition: 120ms;
@transition-empty: 0ms;

.floating-select-row {
  position: relative;
  margin-bottom: 16px;
}

.cc-floating-select {
  width: 100%;
  color: @cc-darkest-grey;
  text-align: left;
}

.cc-floating-select div {
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.cc-floating-placeholder-select {
  position: absolute;
  animation-name: bring-placeholder-down;
  animation-duration: @transition;
  animation-fill-mode: forwards;
}

.placeholder-select-focused,
.placeholder-select-movement-text {
  position: absolute;
  animation-name: shift-placeholder-up;
  animation-duration: @transition;
  animation-fill-mode: forwards;
  color: @cc-dark-grey;
}

.cc-floating-select-text {
  height: 48px;
  text-align: left;
}

.cc-floating-select div:focus {
  transition: @transition;
  box-shadow: none;
  height: 48px;
  padding-top: 14px;
}

.cc-floating-select-text > div {
  box-shadow: none;
  height: 48px !important;
  padding-top: 8px !important;
}

.cc-floating-select div:focus div {
  height: 30px;
}

.ant-select-selection {
  transition: @transition;
}

.cc-floating-select div div {
  height: 30px;
}

.cc-floating-select-text div div {
  height: 30px;
}

.cc-floating-select-focused .ant-select-selection__placeholder {
  transition: @transition-empty;
  opacity: 0;
}

.cc-floating-select > .ant-select-selector > .ant-select-selection-search {
  top: 9px;
}

.cc-floating-select-nonfocused .ant-select-selection__placeholder {
  transition: @transition cubic-bezier(1, 0.01, 1, 0.01);
  padding-top: 6px;
  opacity: 1;
}

.cc-floating-select .ant-select-selection__placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 14px;
  color: @cc-dark-grey;
}

.cc-floating-select .ant-select-selection-selected-value {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.cc-floating-select-invalid {
  border: 1px solid @cc-danger !important;
  border-radius: 4px 4px;
}

.floating-select-row .cc-validation-message {
  color: @cc-danger;
  text-align: left;
  padding-left: 6px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 44px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;