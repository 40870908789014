@import '../../styles/colors.less';

.settings-funds-managers {
  &-header {
    color: @cc-darkest-grey;
    padding-bottom: 24px;
    border-bottom: 1px solid @cc-lightest-grey;
  }

  &-contacts {
    &-title {
      color: @cc-darkest-grey;

      &-fund {
        color: @cc-black;
        font-weight: bolder;
      }
    }

    &-list {
      margin-top: 18px;

      & .messages-compose-contact-checkbox-card {
        min-width: 263px;

        & .messages-compose-contact-checkbox-name {
          min-width: 186px;
        }

        & .messages-compose-contact-checkbox-title {
          min-width: 186px;
        }
      }
    }

    padding-bottom: 48px;
    padding-top: 16px;
  }

  &-footer {
    border-top: 1px solid @cc-lightest-grey;
    padding-top: 16px;
    margin-bottom: -16px;
    text-align: right;

    & button {
      margin-left: 16px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;