@import '../../styles/colors.less';

.ant-table-content {
  background: @cc-white;
  padding-top: 8px;
}

.ant-table-thead {
  background-color: @cc-white;
}

.ant-table-thead > tr {
  background-color: @cc-white !important;
}

.cc-positive-number {
  color: @cc-success;
}

.cc-negative-number {
  color: @cc-danger;
}

.ant-table-tbody > tr > td {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: @cc-light-black;
}

.ant-table-header-column {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: @cc-dark-grey;
}

.table-filter-icon {
  width: 32px !important;
  padding: 8px;
  color: @cc-grey;
  background: rgba(239, 241, 244, 0.4);
}

.ant-table-body tr td {
  border-bottom: solid 1px @cc-light-grey;
}

.ant-table-thead > tr > th {
  font-size: 12px;
  font-weight: 800;
}

.ant-table-thead tr th {
  border-bottom: solid 1px @cc-light-grey;
  text-transform: uppercase;
}

.ant-table-filter-column {
  display: block;
}

.ant-table-thead tr th span {
  margin-top: 0px;
  display: flex;
}

.ant-table-thead tr {
  height: 57px;
}
.ant-table-row:hover {
  background: #f0fffe;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f0fffe !important;
}

.ant-table-column-sorter {
  float: right;
}

.ant-table-column-sorter div {
  width: 16px;
  margin-top: -3px;
}

.anticon-caret-up,
.anticon-caret-down {
  font-size: 16px !important;
  margin-top: -10px;
}

.ant-table-fixed-right {
  top: 8px;
}

.ant-table-fixed-left {
  top: 8px;
}

// .ant-table-fixed-columns-in-body {
//     width:49px;
// }

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: @cc-light-grey;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;