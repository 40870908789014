@import '../../styles/colors.less';

.MeetingTypeSelector {
  width: 100%;
  display: flex;
  height: 200px;
  padding: 32px;
  &-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    border-radius: 4px;
    flex: 1;
    padding: 16px;
    & span {
      height: 100%;
      width: 100%;
    }
    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      width: 100%;
    }
    &-icon {
      color: @cc-grey;
      .ant-radio-button-wrapper-checked &,
      .ant-radio-button-wrapper:hover & {
        color: @cc-white;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;