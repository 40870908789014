.cc-initialstep {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
  justify-content: center;
  align-content: center;
  .cc-allocator-header {
    padding: 50px 0;
    margin-right: 30px;
  }
  .cc-allocator-input {
    width: 364px;
    height: 48px;
    margin: auto;
    margin-bottom: 16px;
  }
  .cc-floating-select {
    position: relative;
    margin-bottom: 16px;
  }
  .floating-select-row {
    position: relative;
    margin-bottom: 16px;
    width: 364px;
    margin: auto;
  }
  .cc-allocator-p {
    margin: 24px 34px 8px 34px;
    text-align: left;
  }
  .cc-allocator-radio {
    margin: 0 34px 32px 32px;
    text-align: left;
  }
  .btn-back {
    border: 0;
    box-shadow: 0 0 0 0;
    color: theme('colors.primary.100');
    margin: auto;
    width: 364px;
  }
}

.FormHeader {
  font-size: 30px;
  font-weight: 600;
}

.cc-initialstep .cc-floating-select {
  position: relative;
  margin-bottom: 16px;
  width: 90%;
  float: left;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;