@import '../../styles/colors.less';

.AllocatorDetailsModal {
  &-tabs {
    > .ant-tabs-nav {
      margin: 0 !important;
      max-height: 35px;
    }
  }
}

.allocator-disclaimer {
  margin-top: 18px;
  padding-bottom: 18px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;