@import '../../styles/colors.less';

.settingsRequest {
  background-color: theme('colors.primary.2');
  border-radius: 4px;
  border-style: none;
  width: 48px;
  height: 48px;
  padding: 0px;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid @cc-primary;
    background-color: @cc-primary;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    & .icon {
      color: @cc-white;
    }
  }

  & .icon {
    color: @cc-primary;
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }
}

.popover-limit-request {
  & .ant-popover-title {
    padding: 16px;
  }
}

.requestsSwitch {
  &.ant-switch {
    background-color: @cc-notice;
  }
  &.ant-switch.ant-switch-checked {
    background-color: @cc-success;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;