@import '../../styles/colors.less';

.NotificationEntry {
  & > &-icon {
    padding: 16px;
    display: flex;
  }

  & > &-icon > .ant-btn {
    color: @cc-primary;
    border: none;
    padding: 0;
    margin: 0;
    height: auto;
  }

  &-read > &-icon {
    color: @cc-grey;
  }

  & &-description {
    flex-grow: 1;
    padding-bottom: 16px;
    border-bottom: 1px solid @cc-light-grey;
    font-size: 14px;
    line-height: 24px;
    color: @cc-light-black;
  }

  & &-description > &-description-wrapper {
    color: @cc-light-black;
    cursor: pointer;

    &:hover {
      color: theme('colors.secondary.100');
    }
  }

  & > &-description &-subject {
    font-weight: 600;
  }

  & > &-description span {
    margin-right: 0.25rem;
  }

  & > &-description span:last-child {
    margin-right: 0;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;