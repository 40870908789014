@import '../../styles/colors.less';

.AllocatorOnlineMR {
  & > .ant-modal-header {
    padding: 24px 32px;
    & > .ant-modal-title {
      color: @cc-darkest-grey;
    }
  }
  & .ant-modal-content > .ant-modal-footer {
    padding: 0px;
  }
  &-participants {
    background-color: @cc-lightest-grey;
    padding: 16px 32px;
  }
}
.Invite-company-button {
  & > span {
    vertical-align: top;
  }
  &-popover .ant-popover-inner-content {
    padding: 8px;
  }
}
.remove-invited-button {
  &:hover {
    color: @cc-danger;
    opacity: 0.7;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;