@import '../../styles/colors.less';

.round-profile-imagegroup-name {
  display: inline-block;
  max-height: 20px;
  max-width: 112px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-wrap: unset;

  white-space: nowrap;
}

.dropdown-avatar-named {
  text-align: center;
  color: @cc-white;
  border: 2px solid @cc-white;
  object-fit: cover;
}

.named-avatar-ellipsis {
  border-radius: 48px;
  width: 72px !important;
  background-color: theme('colors.primary.2');
  color: @cc-primary;
  cursor: pointer;
  margin-left: 10px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;