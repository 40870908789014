.compare-funds-drawer {
  @apply fixed z-modal;
  @apply inset-x-0 md:left-24 bottom-0;
  @apply h-80 sm:h-96 lg:h-72;
  @apply flex flex-col;
  @apply filter drop-shadow-2xl;
  @apply transition-transform;

  /* This container should be invisible to the mouse, but let everything inside
     it behave like normal. */
  @apply pointer-events-none;
  * {
    @apply pointer-events-auto;
  }

  transform: translateY(85%);

  &&--open {
    transform: translateY(0);
  }

  & &__tab {
    @apply flex-shrink-0;
    @apply self-center relative -bottom-px z-10;
    @apply bg-white border-solid border border-b-0;
  }

  & &__toggle-button {
    @apply border-0;
    @apply py-1 px-3;
    @apply type-subtitle-xs;
    @apply flex items-center;
    @apply cursor-pointer;
  }

  & &__content {
    @apply flex-1;
    @apply border-solid border-0 border-t;
    @apply bg-white;
  }

  & &__dialog {
    @apply h-full;
    @apply transition-opacity;
    opacity: 0;

    &[data-enter] {
      opacity: 1;
    }
  }

  & &__grid {
    @apply list-none;
    @apply absolute inset-0;
    @apply p-4 lg:px-20 m-0;
    @apply grid grid-rows-2 sm:grid-rows-3 lg:grid-rows-2 auto-cols-max gap-4 grid-flow-col;
    @apply overflow-x-auto;

    scroll-snap-type: x mandatory;

    @screen lg {
      scroll-padding: 0 theme('spacing.20');

      /* Hide the scrollbar on desktop, since we add the paging buttons */
      -ms-overflow-style: none; /* IE and early Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }
    }
  }

  & &__fund {
    @apply flex justify-between items-start;
    @apply w-72 lg:w-96 p-2;
    @apply rounded-big shadow-0;

    scroll-snap-align: start;
  }

  & &__page-control {
    @apply hidden lg:flex items-center;
    @apply absolute inset-y-0;
    @apply p-4;

    &--prev {
      @apply left-0;
      @apply bg-gradient-to-r from-white;
    }
    &--next {
      @apply right-0;
      @apply bg-gradient-to-l from-white;
    }

    button {
      @apply rounded-full bg-grey-100;
      @apply transition-colors;

      &[aria-disabled] {
        @apply bg-transparent;
      }

      &:not([aria-disabled]) {
        @apply hover:bg-grey-200 focus:bg-grey-200;
      }
    }
  }

  & &__empty {
    @apply w-full;
    @apply flex items-start justify-center;

    .ant-empty-image {
      margin: 0;
    }
  }
}
