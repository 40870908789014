@import '../../styles/colors';

.modalTable {
  font-size: 16px;
  padding: 10px;
  height: 100%;
  width: 100%;

  & &-row {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-top: 15px;
  }

  & &-avatar {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  & &-name {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  & &-icons {
    margin-top: 15px;
    margin-right: 20px;
    margin-bottom: 15px;
  }

  & &-job {
    font-size: 12px;
    color: #9e9e9e;
  }

  & &-empty {
    width: 35%;
  }
}

.reactionModal-top {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;

  .ant-divider-vertical {
    height: 24px;
  }

  p {
    font-size: 14px;
    margin-bottom: 2px;
    margin-left: 5px;
  }

  svg {
    margin-right: 5px;
  }
}

.reactionButton {
  &:hover {
    color: #0072a3;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;