.scrollDiv::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar {
  width: 7px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c2c6cc;
}

.SummitAlert {
  background: #fff5e6;
  border: 1px solid #f19f00;
  box-sizing: border-box;
  border-radius: 8px;
  height: 68px;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  align-items: center;

  &-icon {
    margin: 0 18px 0 18px;
  }

  &-link {
    padding: 0px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;