@import '../../styles/colors.less';

.NotificationList {
  background: @cc-white;
  border: 1px solid @cc-light-grey;
  border-radius: 8px;
  padding: 12px 18px;
  width: 430px;

  &-header {
    border-bottom: 1px solid @cc-light-grey;
    padding-bottom: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    & > h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      margin-top: 9px;
      margin-bottom: 0;
    }

    & > &-actions {
      text-align: right;
    }

    &-actions-btn-text {
      display: inline-block;
      margin-left: 8px;
    }

    &-actions-btn-settings {
      color: @cc-grey;
    }
  }

  &-entries {
    max-height: 420px;

    & .NotificationEntry:last-child > .NotificationEntry-description {
      border-bottom: 0;
    }
  }

  &-footer {
    border-top: 1px solid @cc-light-grey;
    text-align: center;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;