.UpdateReturnsModal {
  & .ant-col {
    padding-left: 0px;
  }

  & .form-box {
    border: 1px solid #cbcbcb;
    box-sizing: border-box;
    box-shadow: 0px 7px 20px rgba(141, 136, 172, 0.15);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 12px;
  }

  & .header-row {
    margin-bottom: 12px;
    font-weight: bold;
  }

  & .full-width {
    width: 100%;
  }

  & .right-align {
    text-align: right;
  }

  & .ant-tabs-nav {
    width: 100% !important;
    padding-left: 0;
    margin-bottom: 0;
  }

  & .ant-tabs-content-holder {
    border: 1px #f0f0f0 solid;
    border-top: 0;
    padding: 8px;
  }

  & .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  & .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }

  &-header {
    font-size: 16px;
    color: #92949c;
    margin-bottom: 10px;
  }

  &-updateline {
    font-size: 12px;
    color: #92949c;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 12px;
  }

  &-preview {
    background-color: #eff1f4;
    color: #333333;

    &-row {
      padding: 10px 12px;
      border-bottom: 1px solid #e5e5e5;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;