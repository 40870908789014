@import '../../styles/colors.less';

.discover-card-stat {
  &-container {
    @apply grid grid-flow-col gap-x-10 pt-5;
    grid-template-rows: auto auto;
    justify-content: start;
  }

  &-title,
  &-details {
    font-size: 12px;
    line-height: 16px;
  }

  &-title {
    color: @primary-color;
  }

  &-details {
    font-weight: 600;
    color: #3d4858;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;