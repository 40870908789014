@import '../../styles/colors.less';

.FeaturedEvent {
  background-color: @cc-white;
  border-radius: 8px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

  &-header {
    padding: 12px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
    color: @cc-black;
  }

  &-poster {
    width: 100%;
    height: 100%;
    min-height: 280px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0 0 0 8px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
  }

  &-info {
    &-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: @cc-extras-secondary;
    }

    &-description {
      color: @cc-extras-grey;
      font-size: 12px;
      line-height: 20px;
    }

    &-location,
    &-date,
    &-booths {
      color: @cc-extras-lightgrey;
      padding-bottom: 8px;
    }

    &-location > span,
    &-date > span,
    &-booths > span {
      font-size: 12px;
      line-height: 20px;
      color: @cc-extras-secondary;
      margin-left: 5px;
    }

    &-booths > span.FeaturedEvent-booth-selection {
      color: #f19f00;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &-registration-btn {
    border: none;
    border-radius: 4px;
    & > svg {
      margin-right: 8px;
    }
  }

  &-registration-text {
    color: @cc-success;
    display: flex;
    align-items: center;
    margin-right: 15px;
    & > svg {
      margin-right: 8px;
    }
  }

  &-actions {
    display: flex;
    & > button,
    &-action > a {
      margin-right: 8px;
    }
  }

  &-booth-selection {
    background-color: #fffcf7;
    border: 1px solid #f19f00;
    border-radius: 8px;
    padding: 8px 8px 8px 16px;

    & > span {
      margin-left: 8px;
      color: #616161;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;