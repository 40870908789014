@import '../../styles/colors.less';

.Profile {
  &-Picture {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    object-fit: cover;
  }
  &-chapter-label {
    margin-top: 4px;
    background-color: rgba(187, 47, 210, 0.1);
    border-radius: 100px;
    color: #bb2fd2;
    width: fit-content;
    padding: 2px 16px;
  }
  &-title {
    padding: 30px 30px 15px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: @cc-lightest-grey;
  }
  &-container {
    padding: 24px 46px 16px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: @cc-lightest-grey;
  }
  &-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & .ant-btn-background-ghost {
      border-style: none;
      background-color: rgba(47, 197, 210, 0.1) !important;
      margin-right: 20px;
      & > svg {
        margin-right: 5px;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;