@import '../../../styles/colors';

.fifty-fifty-width .ant-radio-button-wrapper {
  width: 50%;
}

.one-third-width .ant-radio-button-wrapper {
  width: 33%;
}

.switch-conversation-radio {
  &-group label {
    width: 143px;
    text-align: center;
  }

  &-group {
    width: 100%;
  }

  &-group .ant-radio-button-wrapper {
    padding: 0px;
    min-height: 50px;
    padding-top: 15px;

    &-checked svg {
      color: @cc-white !important;
    }
    &:hover svg {
      color: @cc-primary !important;
    }
  }

  &-icon {
    display: block;
    font-size: large;
    width: 100% !important;
    color: @cc-grey;
  }
  &-badge {
    position: absolute;
    top: 50%;
  }
}

.badge-head {
  width: 42px;
  height: 42px;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;