@import '../../styles/colors.less';

.NoData {
  text-align: center;
  margin-bottom: 12px;

  & > &-icon {
    color: @cc-grey;
    margin-bottom: 8px;
  }

  & > &-title {
    color: @cc-greyscale-lightblack;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  & > &-message {
    color: @cc-grey;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &-action {
    & > .ant-btn {
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
      padding: 8px 24px;
      height: 40px;
    }

    &-label {
      display: inline-block;
      margin-left: 6px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;