@import '~antd/dist/antd.less';
@tailwind base;
@tailwind components;
@import './colors.less';

.ant-tabs {
  overflow: unset;
}

@media screen and (max-width: 1025px) {
  .cc-content {
    margin: 84px 24px 0px 24px;
  }
}

@media screen and (max-width: 414px) {
  .cc-content {
    margin: 72px 16px 0px 16px;
  }
}

hr.cc-line {
  border: 0;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 16px;
  margin-bottom: 15px;
}

hr.cc-space {
  border-bottom-color: transparent;
}

hr.cc-space {
  border: 0;
  background: transparent;
  margin: 1.5rem 0;
}

.cc-sidebar-logo {
  width: 100%;
  margin: 1rem 0;
  padding-left: 14px;
  display: flex;
}

.cc-menu-item-collapsed {
  background: rgb(36, 38, 43);
  display: grid;
  padding: 0px !important;
  text-align: center;
  line-height: 20px !important;
  height: 60px !important;
  border-radius: 11px;
  &-selected {
    background: rgba(47, 197, 210, 0.1) !important;
  }
}

.cc-menu-item-collapsed > a {
  padding-left: 0px !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: rgba(47, 197, 210, 0.1) !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px !important;
}

.ant-menu-inline-collapsed {
  width: 60px;
}

.ant-menu-item > a,
.ant-menu-submenu > a,
.ant-menu-submenu > div {
  padding-left: 24px;
  margin: 0px !important;
}

.cc-sidebar:hover > div > div > .cc-sidebar-logo > div > .trigger {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.cc-sidebar > div > div > .cc-sidebar-logo > div > .trigger {
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.trigger {
  background: #24262b;
  border-radius: 4px;
  color: #fff;
  margin-left: 22px;
  margin-top: 6px;
  position: fixed;
  padding: 5px;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  cursor: pointer;
  &-collapsed {
    margin-left: 5px !important;
    opacity: 1 !important;
  }
}

.cc-sidebar {
  z-index: 1000;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  height: 100vh;
  position: fixed !important;
  left: 0;
  & > .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    position: absolute;
  }
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background: transparent;
}
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
  background: linear-gradient(
    90deg,
    rgba(47, 197, 210, 0.1) 0%,
    rgba(47, 197, 210, 0) 100%
  );
}

.ant-menu-item > a:hover,
.ant-menu-submenu > div:hover {
  background: linear-gradient(
    90deg,
    rgba(47, 197, 210, 0.1) 0%,
    rgba(47, 197, 210, 0) 100%
  );
}

.cc-free {
  background: linear-gradient(92.88deg, #2cbc63 2.61%, #44d77c 100.42%);
}

.cc-starter {
  background: linear-gradient(92.88deg, #774ee0 2.61%, #9a7fdf 100.42%);
}

.cc-pro {
  background: linear-gradient(92.88deg, #bc9c67 2.61%, #ddbf8e 100.42%);
}

.cc-company-switch > .ant-select-selection {
  background: #f7f8fa;
  border: 0;
  border-radius: 4px;
}

.cc-company-switch .ant-select-selection-selected-value {
  font-size: 16px;
  line-height: 38px;
  color: #24262b;
  vertical-align: middle;
}

.cc-notification-count > sup {
  height: 15px;
  min-width: 15px;
  padding: 0 2px;
  font-size: 11px;
  line-height: 15px;
  top: 5px;
  right: 2px;
}

.cc-table-th .ant-table-column-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7f848a;
  text-transform: uppercase;
}

.cc-table-row > td {
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.ant-tabs-nav {
  padding-left: 24px;
}

.TopBar-tabs {
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }
}

.tab-pane {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  /* identical to box height, or 112% */
}

.tab-icon {
  margin-right: 8px;
  font-size: 20px;
}

.ant-modal-header {
  background: @cc-lightest-grey !important;

  & > .ant-modal-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: @cc-greyscale-darkestblack;
  }
}

/*ERVIN*/

.company-colleagues-table {
  background-color: white;
}

.ant-table-thead {
  background-color: white;
}

.ant-table-thead > tr {
  background-color: white !important;
}

.ant-table-tbody > tr > td {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #1b1a1f;
}

.ant-table-header-column {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #7f848a;
}

.ant-table-column-sorter {
  margin-top: 0px !important;
}

.company-colleagues-add {
  width: 198px;
  height: 40px !important;
  margin-top: 10px;
  border-radius: 8px !important;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #ffffff;
}

.button-icon {
  size: 13.5px;
  margin-right: 12px;
}

button[type='button'].cc-btn {
  border: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: none;
}

.cc-btn > span {
  position: relative;
  z-index: 555;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.cc-btn > .cc-btn-bg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0.1;
  z-index: 900;
}

.cc-btn-danger > span {
  color: @cc-danger;
}

.cc-btn-danger > .cc-btn-bg {
  background-color: @cc-danger;
}

.cc-btn-disabled > span {
  color: @cc-dark-grey;
}

.cc-btn-disabled > .cc-btn-bg {
  background-color: @cc-dark-grey;
}

.cc-btn-success > span {
  color: @cc-success;
}

.ant-btn-primary.cc-btn-success > span {
  color: @cc-white;
}

.ant-btn-primary.cc-btn-success,
.cc-btn-success > .cc-btn-bg {
  background-color: @cc-success;
}

.ant-btn-primary.cc-btn-notice > span {
  color: @cc-white;
}

.cc-btn-notice > span {
  color: @cc-notice;
}

.ant-btn-primary.cc-btn-notice,
.cc-btn-notice > .cc-btn-bg {
  background-color: @cc-notice;
}

.cc-btn-reschedule {
  background-color: @cc-notice;
  opacity: 1 !important;
}

.cc-btn-reschedule:hover {
  background-color: @cc-notice;
  opacity: 0.8 !important;
}

.cc-btn-reschedule:focus {
  background-color: @cc-notice;
  opacity: 1;
}

.cc-btn-red {
  background-color: @cc-danger;
  opacity: 1 !important;
  width: 44px;
}

.cc-btn-red:hover {
  background-color: @cc-danger;
  opacity: 0.8 !important;
  width: 44px;
}
.cc-btn-red:focus {
  background-color: @cc-danger;
  opacity: 1;
  width: 44px;
}

//TYPOGRAPHY

.cc-heading1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}
.cc-heading-custom {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
}

.cc-heading2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.cc-heading3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.cc-heading4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.cc-heading5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.cc-heading6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
}

.cc-tabletitle-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.cc-body-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.cc-small-font {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px !important;
}

.cc-xsmall-font {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
}

.cc-grey-text {
  color: @cc-grey;
}

.cc-lightest-grey-text {
  color: @cc-lightest-grey;
}
.cc-black-text {
  color: @cc-black;
}

.cc-white-text {
  color: @cc-white !important;
}

.cc-darkest-grey-text {
  color: @cc-darkest-grey;
}
.cc-dark-grey-text {
  color: @cc-dark-grey;
}
.cc-lightblack-text {
  color: @cc-light-black;
}

.cc-text-color-summit {
  color: @cc-primary;
}

.cc-text-color-roadshow {
  color: @cc-dark-pink-darker;
}

.cc-text-color-online {
  color: @cc-purple;
}

.cc-text-color-secondary {
  color: @cc-secondary;
}

.cc-text-color-danger {
  color: @cc-danger;
}

.cc-text-color-success {
  color: @cc-success;
}
//SHADOWS

.cc-shadow1 {
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
}

.cc-shadow2 {
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
}

.cc-shadow3 {
  box-shadow: 0px 16px 28px rgba(47, 56, 62, 0.08);
}

.cc-shadow-box {
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
}

.cc-shadow-buttons-inner {
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
}
.cc-shadow-on-hover {
  transition: 200ms;
  &:hover {
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
    transition: 200ms;
  }
}
.ant-btn-primary {
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
}

[ant-click-animating-without-extra-node]:after {
  // duhet per te hequr animation kur klikon butonat
  animation: 0s !important;
}

.cc-meeting-actions {
  display: inline;
  margin-top: 8px; //it may say that property is ignored due To display, but it turns out that's not true
}

.cc-text-with-icon-left {
  margin-left: 10px;
  display: inline-block;
}

.cc-profile-info {
  margin-top: 8px;
  display: flex;
  word-break: break-word;
}

.cc-light-grey-border {
  border: 1px solid @cc-light-grey;
}

.TopBar {
  &-header {
    &-discover {
      height: 90px !important;
      & > .ant-row {
        height: 49px;
      }
    }
    position: fixed;

    z-index: 998;
    background: @cc-white;
    padding: 4px 0px;
    height: 64px;
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
    &-collapsed {
      width: calc(100% - 70px);
      margin-left: 70px;
    }
    &-wide {
      width: calc(100% - 224px);
      margin-left: 224px;
    }
  }

  &-tabs {
    & > .ant-tabs {
      & > .ant-tabs-nav {
        border-bottom: none;
      }
      & > .ant-tabs-nav::before {
        border-bottom: none;
      }
    }
  }

  &-dropdown-notifications {
    cursor: pointer;
  }

  &-icons {
    padding: 0 24px;
  }

  &-icons-credit {
    margin: 0 12px;
  }

  &-icons:last-child {
    padding-right: 0px;
  }

  &-icons > span > svg,
  &-icons > a > svg,
  &-icons > a > span > svg {
    color: @cc-grey;
    width: 24px !important;
    height: 24px;
  }

  &-icons-credit > svg {
    color: @cc-notice;
    width: 16px !important;
    height: auto;
  }

  &-icons-points > img {
    width: 16px !important;
    height: auto;
  }
  &-icons-credit {
    background-color: rgba(241, 159, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 70px;
    height: 40px;
  }

  &-icons-points {
    background-color: rgba(0, 141, 202, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 40px;
    padding: 0px 16px;
  }
  &-secondary-color-button {
    margin-right: 24px;
    margin-left: -155px;
    margin-top: 8px;
    width: 140px;
    height: 40px;
    padding: 8px 16px;
    background-color: @cc-secondary;
    border-color: @cc-secondary;
    align-self: flex-start;
    &:hover {
      background-color: @cc-secondary-lighter;
      border-color: @cc-secondary-lighter;
    }
    &:active,
    &:focus {
      background-color: @cc-secondary-darker;
      border-color: @cc-secondary-darker;
    }
  }
}

.ant-col {
  padding-right: 10px;
  padding-left: 10px;
}

.ant-table {
  background: white !important;
}
@media screen and (max-width: 1025px) {
  .TopBar-header {
    margin-left: 92px;
    width: 88%;
    z-index: 999;
  }
}

@media screen and (max-width: 414px) {
  .TopBar-icons {
    padding: 0px 4px;
  }
  .TopBar-icons-credit {
    margin: 0px 4px;
    padding: 8px;
  }
  .TopBar-secondary-color-button {
    width: 100px;
    padding: 8px 2px;
    margin-right: 4px;
    height: 40px;
  }
}

.cc-table-header {
  background-color: @cc-white;
  padding: 32px 24px;
  line-height: 22px;
  border-bottom: 1px solid @cc-light-grey;
}

.ant-tag-purple {
  color: #6a48c6;
  background: rgba(106, 72, 198, 0.1);
  border-color: rgba(106, 72, 198, 0.1);
}
.ant-tag-blue {
  color: #2d81a8;
  background: rgba(45, 129, 168, 0.1);
  border-color: rgba(45, 129, 168, 0.1);
}
.FooterBar {
  position: fixed;
  width: 100%;
  z-index: 1002;
  background: @cc-light-black;
  padding: 0px 0px;
  left: 0px;
  bottom: 0px;
  height: 2.5vh;
  color: @cc-dark-grey;
  font-size: 12px;
  a {
    color: @cc-dark-grey;
    margin-right: 32px;
  }
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 300px) !important;
}

.DealMeetingLabel {
  margin: auto;
  border-radius: 24px;
  color: white;
  background-color: @cc-primary;
  width: fit-content;
  padding: 2px 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;

  &-smaller {
    margin: auto;
    border-radius: 24px;
    color: white;
    background-color: @cc-primary;
    width: max-content;
    padding: 0px 4px;
    line-height: 14px;
  }
}

.scrollDiv::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar {
  width: 7px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c2c6cc;
}

.scrollDiv {
  scrollbar-color: #c2c6cc #f0f2f5;
  scrollbar-width: thin;
}

@tailwind utilities;

@layer base {
  [class*='border'],
  [class*='divide-'] > :not([hidden]) ~ :not([hidden]) {
    border-width: 0;
    border-style: solid;
    border-color: theme('borderColor.DEFAULT');
  }
}

@layer components {
  .input-group .form-input input {
    border: none;
  }

  .input-group button:not([disabled], [aria-disabled='true']),
  .input-group [role='button']:not([disabled], [aria-disabled='true']) {
    cursor: pointer;
  }

  .avatar .avatar__profile-image {
    width: 100%;
    height: 100%;
  }

  .alert .alert__icon {
    flex-shrink: 0;
  }

  .step-list .step-list__list {
    list-style: none;
  }
}

@layer components {
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    margin: 0;
    transform: translate(-50%, -50%);
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;