@import '../../styles/colors';

.title {
  &-span {
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    margin: 8px 0;
  }
  &-small {
    font-size: 18px;
  }
}
.desc-span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 8px 0;
  color: #92949c;
  width: 70%;
}
.profile-small {
  min-height: 220px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;