@import '../../styles/colors.less';

.MeetingCard {
  background: @cc-white;
  border: 1px solid @cc-white;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &-center {
    text-align: center;
    @media only screen and (max-width: 992px) {
      &-actions {
        text-align: left;
      }
    }
    @media only screen and (min-width: 992px) {
      &-actions {
        text-align: center;
      }
    }
  }
  &:hover {
    border: 1px solid theme('colors.secondary.100');
    box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.51);
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;