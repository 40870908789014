@import '../../styles/colors.less';

.Search {
  height: 48px;
  width: 305px !important;
  line-height: 2.2;
  border: none;
}

.Search {
  & .Search {
    border-right: 1px solid @cc-light-grey !important;
  }

  & .ant-input-group-addon {
    display: none;
  }

  &-fund {
    margin-left: 15px;
  }

  & > .ant-input-group > .ant-input-affix-wrapper {
    height: 48px;
  }
}

.search-input.ant-input-affix-wrapper-input-with-clear-btn > input {
  padding-left: 0px !important;
}

.search-input {
  height: 48px;
  width: 305px;
  line-height: 2.2;
  border: none;
}

.search-input > .ant-input-suffix {
  display: none;
}

.search-input span {
  left: 17.67px;
  width: 16.67px;
  height: 16.67px;
  font-size: 16.67px;
  color: @cc-grey !important;
  margin: auto;
  margin-right: 10px;
}

.search-input input {
  padding-left: 44px;
  border: none;
  background: @cc-lightest-grey;
}

.search-input.ant-input-affix-wrapper-input-with-clear-btn > input {
  padding-left: 54px;
}

.search-input input::placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: @cc-dark-grey;
}

.search-input span i svg {
  color: @cc-grey;
}

.investors-header-col {
  padding-left: 8px;
  padding-right: 8px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;