@import '../../styles/colors.less';

.discover-service-provider {
  margin-bottom: 20px;

  &-text-image {
    display: flex;
    justify-content: flex-end;

    & > .ant-image > .ant-image-img {
      width: auto;
      max-height: 150px;
      max-width: 200px;
    }
  }

  &-description {
    display: block;
  }

  &-result {
    min-height: 240px;
    background: @cc-white;
    border: 1px solid #eff1f4;

    &-subtitle {
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-top: 5px;
      color: @cc-dark-grey;
    }
  }

  &-hidden {
    opacity: 0.7;
    border-color: #696969 !important;
  }

  &-favicon {
    color: @cc-notice;
    font-size: 21px !important;
    border: 0;
    box-shadow: 0 0;
    background: transparent !important;
    line-height: 0;
  }
}

.table-action-button {
  background: @cc-white;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;