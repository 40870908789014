@import '../../styles/colors.less';

.discoverSearch > .ant-input-suffix {
  display: none;
}

.discoverSearch {
  border: 1px solid @cc-light-grey;

  &-fund {
    margin-left: 15px;
  }
}

.discover-load-more {
  text-align: center;

  &-button {
    background: #ffffff;
    border: 1px solid #eff1f4;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.DiscoverContainer {
  &-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 2px 0 0;
  }

  &-filterLabel {
    margin-left: -10px;
    padding: 0 0 10px;
  }

  &-switch {
    position: absolute;
    text-align: center;
  }

  &-icon {
    margin-right: 8px;
  }

  &-col {
    margin-right: 8px;

    &-filters {
      .reset-filters {
        color: @cc-danger;
      }

      &-search {
        border-radius: 4px;
      }

      .ant-divider {
        height: 1em;
        border-left-width: 2px;

        &-horizontal {
          margin: 24px 0 0 0;
        }
      }
    }
  }

  &-alert {
  }

  &-fund-search {
    margin-right: 8px;
    width: 45%;
  }

  &-select-fund {
    margin-right: 8px;
    width: 20%;
  }

  &-alert-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: @cc-dark-grey;
  }

  &-alert-textbold {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    color: @cc-black;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;