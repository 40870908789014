@import '../../styles/colors.less';

.FundOnlineMR {
  & > .ant-modal-header {
    padding: 24px 32px;
    & > .ant-modal-title {
      color: @cc-darkest-grey;
    }
  }
  & .ant-modal-content > .ant-modal-footer {
    padding: 0px;
  }
  &-participants {
    background-color: @cc-white;
    padding: 16px 32px;
  }
}

.steps-icons {
  display: none;
}
.footer {
  padding: 16px 32px;
  background: @cc-lightest-grey;
}
.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: @cc-lightest-grey !important;
  border-style: none !important;
  border-radius: 4px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;