.content-contact-avatar {
  display: flex;
  flex-direction: row;

  &__image {
    flex-shrink: 0;
    margin-right: 12px;
  }

  &__name,
  &__job-title {
    color: rgba(0, 0, 0, 60%);
    font-size: 12px;
    line-height: 17px;
  }

  &__name {
    font-weight: 600;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;