@import '../../styles/colors.less';

.schedule-day-switch {
  width: 100px;
}

.schedule-day-switch.ant-switch-checked {
  background-color: @cc-success;
}

.schedule-day-row-event {
  height: 72px;
}

.schedule-day-row-grouping {
  background: @cc-light-grey;
  height: 32px;
}

.schedule-day-row-even {
  background: fade(@cc-lightest-grey, 50);
}

.schedule-day-row-grouping > td {
  padding: 0px !important;
  text-align: center;
  color: @cc-light-black;
}

.schedule-day-meeting div {
  display: inline-block;
}

.schedule-day-meeting-title {
  margin-right: 5px;
  color: @cc-primary;
  vertical-align: middle;
}

.schedule-day-meeting-image {
  vertical-align: middle;
}

.schedule-day-meeting-card {
  margin-left: 12px;
  vertical-align: middle;
}

.schedule-day-meeting-card span {
  display: block;
}

.schedule-day-meeting-name {
  font-size: 14px;
  color: @cc-light-black;
  font-weight: bold;
  vertical-align: middle;
}

.schedule-day-meeting-company {
  color: @cc-dark-grey;
  line-height: 16px;
}

.schedule-day-reschedule {
  color: @cc-primary;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.schedule-day-cancel {
  color: @cc-danger;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;