@import '../../../styles/colors.less';

.cc-xsmall-font-white {
  color: @cc-white;
}

.messaging-chat-document-filename-white button,
.messaging-chat-document-filename-white a {
  color: @cc-white;
  max-width: 180px;
  width: 180px;
  overflow-x: hidden;
  //word-wrap: break-word;
  overflow-wrap: break-word;
}

.messaging-chat-document-filename-white button {
  padding-left: 0px;
}

.messaging-chat-no-documents {
  color: @cc-grey;
  font-size: 12px;
  margin-left: 20px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;