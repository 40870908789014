@import '../../styles/colors.less';

.FundWizardModal.ant-modal {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  top: 0;
  padding: 0;

  .ant-modal-content {
    height: 100vh;
  }

  .ant-modal-body {
    padding: 0;
  }
}

.FundWizard {
  background: @cc-white;
  box-shadow: 0 16px 28px rgba(47, 56, 62, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &-form {
    padding: 20px;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }

  &-row {
    width: 100%;
  }

  &-steps {
    background: @cc-light-grey;

    &
      > .StepList
      > .ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-title {
      color: @cc-greyscale-darkestblack;
      font-weight: 600;
    }

    &
      > .StepList
      > .ant-steps-vertical
      > .ant-steps-item
      .ant-steps-item-description {
      display: none;
    }

    & > .StepList {
      display: flex;

      & > .ant-steps-vertical > .ant-steps-item {
        max-height: 80px;
        min-height: 80px;

        & > .ant-steps-item-container > .ant-steps-item-tail {
          background: #e0e0e0;
          margin-top: 34px;
          height: 35px;
        }
      }
    }
  }

  & &-steps {
    padding: 40px;
    min-height: calc(~'100vh - 136px');

    &
      > .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description {
      visibility: hidden;
    }
  }

  & > &-form {
    flex-grow: 1;
    padding: 0 84px;
    min-height: 100vh;

    &-actions {
      width: 95%;
      border-top: 1px solid @cc-light-grey;
      padding: 48px;
    }
  }

  & &-field {
    margin-bottom: 16px;

    &-label {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      color: @cc-greyscale-lightblack;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
    }

    // & .cc-floating-input {
    & .ant-input {
      height: 48px;
      border: none;
      border-radius: 4px;
      background-color: @cc-lightest-grey;
      font-size: 14px;
      line-height: 24px;
      color: @cc-greyscale-darkestblack;
      margin-bottom: 8px;
    }

    & .ant-input-number {
      border: none;
      height: 48px;
      width: 100%;
      margin-bottom: 8px;

      & .ant-input-number-input {
        height: 46px;
      }
    }

    & .ant-calendar-picker {
      display: inline-block;
      width: 100%;

      & .ant-calendar-picker-input {
        height: 48px;
      }
    }

    & .ant-select {
      & .ant-select-selection {
        height: 48px;
        border: none;
        background-color: @cc-lightest-grey;

        & .ant-select-selection__rendered {
          line-height: 46px;

          & .ant-select-selection__placeholder {
            height: 30px;
          }
        }
      }
    }

    &-checkbox > .ant-checkbox-group-item {
      display: block;
      margin-bottom: 7px;
    }
  }

  & &-field-boolean {
    &-options > .ant-radio-wrapper:first-child {
      margin-right: 48px;
    }
  }

  & &-field-footer {
    margin: 16px 0;
    padding-left: 24px;
  }

  & &-Step > &-actions {
    display: flex;
    border-top: 1px solid #e0e0e0;
    justify-content: space-between;
    padding: 10px 0;

    & > .ant-btn-lg {
      min-width: 50px;
    }
  }

  &.FundWizard-initial {
    justify-content: center;
    align-items: center;
    align-content: stretch;
  }

  .FundWizard-step-title {
    text-align: center;
    margin-bottom: 40px;
  }

  &-initial-form {
    & > .FundWizard-step-title {
      text-align: center;
      color: @cc-greyscale-lightblack;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      margin-bottom: 64px;
    }

    &-actions {
      margin-top: 64px;
    }
  }
}

.FundStepButton {
  margin-top: 10px;

  &-back {
    margin-top: 15px;
    color: @cc-dark-grey;
    cursor: pointer;
  }

  &-save {
    margin-top: 10px;
    color: @cc-dark-grey !important;
    border-color: @input-border-color !important;
  }

  &-save:hover {
    margin-top: 10px;
    color: #54d7de !important;
    border-color: #54d7de !important;
  }
}

.FundStepPreviewButton {
  margin-top: 10px;
  background: @cc-secondary;
  margin-right: 10px;
  color: #54d7de !important;
  border-color: #e0e0e0 !important;
}

.LinkField {
  &-container {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid @cc-light-grey;
    text-align: center;
  }

  &-fields-column {
    display: inline-block;
    width: 100%;
  }

  &-title,
  &-url {
    vertical-align: top;
    display: inline-block;
    width: 50%;
    padding: 0px 8px 0px 8px;
  }
}

.FundTypeStep {
  background: @cc-light-grey;
  padding: 10px 20px 20px;

  &-RadioGroup {
    padding-top: 10px;
    display: grid;
    grid-column-gap: 5px;
    grid-template-columns: 1fr 1fr;
  }

  &-RadioContainer {
    padding: 10px 0;
  }

  &-Radio {
    background: @cc-white;
    border-radius: 8px;
    border: 1px solid transparent;
    margin: 20px;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column-reverse;

    &:hover {
      border-color: @cc-primary;
      box-shadow: 0 2px 10px rgba(186, 186, 201, 0.51);
    }

    h3 {
      margin-top: 10px;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
    }

    p {
      font-size: 19px;
      line-height: 27px;
      margin: 40px 0;
    }

    .ant-radio {
      align-self: center;
    }
  }

  &-Next {
    padding: 0 60px;
    display: block;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
  }

  &-BulletGroup {
    margin-bottom: 10px;
  }

  &-Bullet {
    display: flex;
    margin: 10px;

    & svg {
      margin-right: 5px;
    }

    & span {
      font-weight: bold;
    }
  }

  &-BulletHeadline {
    font-size: 16px;
    line-height: 30px;
    color: #828282;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;