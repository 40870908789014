@import '../../styles/colors.less';
@import '../../styles/constants.less';

@transition: 120ms;
@transition-empty: 0ms;

.floating-textarea-row {
  position: relative;
  margin-bottom: 12px;
}

.placeholder-movement-textarea-notext {
  position: absolute;
  animation-name: bring-placeholder-down;
  animation-duration: @transition;
  animation-fill-mode: forwards;
}

.placeholder-movement-textarea-text,
.cc-floating-textarea:focus + .placeholder-movement-textarea-notext {
  position: absolute;
  animation-name: shift-placeholder-up;
  animation-duration: @transition;
  animation-fill-mode: forwards;
  color: @cc-dark-grey;
}

.cc-floating-textarea-text,
.cc-floating-textarea:focus {
  transition: @transition;
  padding-top: 24px !important;
  box-shadow: none;
  box-sizing: border-box;
}

.cc-floating-textarea-long-text,
.cc-floating-textarea-long-text:focus {
  transition: @transition;
  padding-top: 40px !important;
  box-shadow: none;
  box-sizing: border-box;
}
.cc-floating-textarea-notext-long:focus {
  padding-top: 40px !important;
}
.cc-floating-textarea {
  transition: @transition;
  padding-top: 8px;
  border: none;
  resize: vertical;
  color: @cc-darkest-grey;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.cc-floating-textarea::placeholder {
  transition: @transition cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: @cc-dark-grey;
}

.cc-floating-textarea:focus::placeholder {
  transition: @transition-empty;
  border-top: 1px solid @cc-lightest-grey;
  opacity: 0;
}

.floating-textarea-row .cc-validation-message {
  color: @cc-danger;
  text-align: left;
  padding-left: 6px;
}

.cc-floating-textarea:hover,
.cc-floating-textarea:focus {
  border-top: 1px solid @cc-lightest-grey;
}

.cc-floating-textarea-placeholder {
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  //height: 20px;
  overflow-y: hidden;
  overflow-wrap: unset;
  //white-space: nowrap;
  //width: 95%;
  right: 16px;
  //height: 40px;
  margin-top: -3.5px;
  padding-top: 3.5px;
  margin-bottom: 24px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;