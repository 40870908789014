@import '../../styles/colors.less';

.charts-frame {
  width: 100%;
  min-height: 750px;
  border: none;
}

.fund-details {
  &-location {
    color: @cc-grey;
  }
  &-actions-direct button {
    background: rgba(177, 52, 125, 0.1);
    font-size: 16px;
  }

  &-favicon {
    color: @cc-notice;
  }

  &-document {
    background: #2d81a8;
  }

  &-reschedule {
    margin-left: 32px !important;
    color: #6a48c6;
  }

  &-attachment {
    color: #b1347d;
  }

  &-tabs {
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-footer {
    height: 64px;
    width: 100%;
    background: @cc-lightest-grey;
    display: block;
    &-buttons {
      float: right;
      padding-right: 24px;
      padding-top: 16px;
    }

    &-buttons button {
      margin-right: 16px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }

    &-buttons button svg {
      margin-right: 10px;
    }
  }

  &-send-message {
    background: @cc-secondary !important;
  }

  &-menu-button {
    padding: 6px 12px;
    color: @cc-dark-grey;
    background: @cc-white;
    border: 1px solid @cc-grey;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &-menu-button svg {
    margin-right: 8px;
  }

  &-menu-divider {
    border-top: 1px solid @cc-light-grey;
    height: 1px;
    margin: 0px 0px 0px 0px;
  }

  &-disclaimer {
    margin-top: 36px;
  }
}

.menu-item-menu {
  //padding: 24px;
  padding-left: 8px;
  padding-right: 8px;
}
.menu-item {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 48px;
}
.menu-item-icon {
  display: inline-block;
  width: 32px;
  height: 48px;
  font-size: 20px;
  padding-top: 2px;
  vertical-align: top;
  text-align: center;
  color: @cc-primary;
}

.menu-item-text {
  display: inline-block;
  margin-left: 6px;
  color: @cc-black;
  padding-top: 8px;
}

.action-menu-divider {
  margin: 0px 0px 0px 0px !important;
}

.discover {
  &-fund-details-header {
    height: auto;
    padding: 24px 32px;
    background-color: @cc-lightest-grey;
  }
  &-fund-details-title {
    display: flex;
    flex-direction: column;
  }
  &-details-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &-details-send-message {
    background: rgba(47, 197, 210, 0.1) !important;
    margin-right: 20px;
    & > svg {
      margin-right: 5px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;