@import '../../styles/colors.less';

.company-dropdown {
  &.ant-select-dropdown-placement-bottomLeft {
    width: 100% !important;
    left: 0 !important;
  }
  & .ant-select-item.ant-select-item-option {
    padding: 8px 16px !important;
    border-bottom: 1px solid @cc-light-grey;
  }
}

.select-container {
  & > .ant-select-selector {
    height: 100% !important;
  }

  & > .ant-select-selector > .ant-select-selection-item {
    height: 50px;
    line-height: 25px !important;
    display: flex;
    align-items: center;
  }
  & > .ant-select-selector {
    display: flex;
    align-items: center;

    & > .ant-select-selection-placeholder {
      color: @cc-dark-grey;
      font-size: 14px;
    }
  }
}

.select-company {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &-text {
    &-contacts {
      color: @cc-dark-grey;
      display: flex;
    }
  }
  &-category {
    display: flex;
    align-items: center;
    color: @cc-dark-grey;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;