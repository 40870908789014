.content-media-viewer {
  background-color: rgba(0, 0, 0, 75%);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity 150ms ease-in-out;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;