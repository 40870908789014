@import '../../../styles/colors.less';

.VideoButton {
  margin: 10px;
}

.SettingsButton {
  margin: 10px 85px 10px 10px;
  color: #eff1f4;
  display: flex;
  font-size: 14px;
  align-items: center;
  height: 40px;
  cursor: pointer;

  &-icon {
    margin-left: 5px;
  }

  &.ant-btn-primary {
    background: rgba(239, 241, 244, 0.1);
    border-color: rgba(239, 241, 244, 0.1);
    color: @cc-grey;
  }
  &.ant-btn-background-ghost.ant-btn-primary {
    color: @cc-light-grey;
    background-color: transparent;
    border-color: @cc-light-grey;
  }
}

.RedVideoButton {
  margin: 10px;
  &.ant-btn-primary {
    background: @cc-danger;
    border-color: @cc-danger;
  }
  &.ant-btn-background-ghost.ant-btn-primary {
    color: @cc-danger;
    background-color: transparent;
    border-color: @cc-danger;
  }
}

.cc-meeting {
  padding: 0;
  margin: 0;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  text-align: end;
  &-name {
    border-radius: 24px;
    font-size: 10px;
    line-height: 14px;
    height: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    font-style: normal;
    color: @cc-white;
    min-height: 16px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    &-virtual {
      background: @cc-secondary;
    }
    &-online {
      background: @cc-purple;
    }
    &-name {
      background: @cc-notice;
      margin-right: 5px;
    }
  }
}

.cc-member-card {
  flex: 0 0 auto;
}

.VideoRoom {
  height: 100%;
  width: 100%;
  &-title {
    display: block;
    margin-top: 60px;
    float: right;
    margin-left: 75px;
    position: absolute;
    z-index: 1;
  }
  &-shadow {
    &-up {
      &-narrow {
        width: 100%;
        margin-top: bottom;
        height: 20%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        position: absolute;
        z-index: 180;
      }
      &-wide {
        width: 100%;
        margin-top: bottom;
        height: 20%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        position: absolute;
        z-index: 180;
      }
    }

    &-down {
      &-narrow {
        width: 75vw;
        margin-top: auto;
        justify-content: space-between;
        display: flex;
        height: 20%;
        padding-top: 6%;
        background: linear-gradient(
          360deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 20;
      }
      &-wide {
        width: 100vw;
        margin-top: auto;
        justify-content: space-between;
        display: flex;
        height: 20%;
        padding-top: 6%;
        background: linear-gradient(
          360deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 20;
      }
    }
  }
  &-narrow {
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    width: 75%;
    &-name {
      position: absolute;
      margin-top: 100px;
      z-index: 300;
      padding-right: 75px;
      width: 100%;
      color: @cc-white;
    }
  }
  &-wide {
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    width: 100%;
    &-name {
      position: absolute;
      margin-top: 100px;
      z-index: 300;
      padding-right: 75px;
      width: 100%;
      color: @cc-white;
    }
  }

  &-settings {
    max-width: 350px !important;
    & > .ant-tooltip-content > .ant-tooltip-inner {
      border-radius: 10px;
      padding: 20px;
      width: 350px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;