.content-contact-info {
  align-self: flex-start;
  text-align: left;

  &__title-card,
  &__contacts-card {
    width: 256px;
    background-color: #fafcfc;
    border-radius: 4px;
    padding: 16px;
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    color: #606062;
    margin-bottom: 8px;
  }

  &__contacts-card {
    margin-top: 12px;

    .content-contact-avatar:not(:first-child) {
      margin-top: 16px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;