@import '../../styles/colors';

.cc-reschedule-datepicker-container {
  padding: 10px;
  flex-wrap: wrap;
}

.cc-datepicker .ant-calendar-date-input-wrap,
.cc-datepicker .ant-calendar-input-wrap,
.cc-datepicker .ant-calendar-footer {
  display: none;
}

.cc-datepicker .ant-calendar,
.cc-datepicker .ant-calendar-header {
  width: 370px;
  border: 0;
  box-shadow: none;
}

.cc-datepicker.ant-calendar-picker input,
.cc-datepicker.ant-calendar-picker i {
  display: none;
}

.ant-calendar-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: @cc-light-black;
}

.cc-datepicker > div {
  position: relative !important;
}

.ant-calendar-date {
  width: 34px;
  height: 34px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  color: @cc-light-black;
}

.cc-datepicker .ant-calendar-date {
  background-color: @cc-white;
}

.ant-calendar-cell {
  width: 50px;
  height: 50px;
  padding: 0px;
  border: 1px solid #f7f8fa !important;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  color: @cc-dark-grey;
  background-color: @cc-white;
}

.ant-calendar-column-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7f848a;
  opacity: 0.5;
}

.ant-calendar-today .ant-calendar-date {
  background-color: rgba(37, 159, 169, 0.2);
  border-radius: 8px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  color: @cc-light-black;
  border: none;
  font-weight: normal;
}

.ant-calendar-selected-day .ant-calendar-date {
  background-color: @cc-primary;
  border-radius: 8px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  color: @cc-white;
  border: none;
  font-weight: normal;
}

div.react-select-timezone {
  border-style: none !important;
}

.cc-AMPM-radio-buttons {
  width: 50%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;
}

.cc-meeting-hours-radiogroup {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cc-meeting-hours-buttons {
  margin-bottom: 16px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;