@import '../../styles/colors.less';
@import './RatingMixins.less';

.MeetingWith {
  margin-bottom: 16px;

  &-header {
    .rating-form-header();
  }

  &-details {
    .meeting-info();
    margin-bottom: 8px;

    &-at {
      display: inline-block;
      color: @cc-dark-grey;
      margin: 0 0.25em;
    }
  }

  &-contacts {
    display: block;

    .contact-list-contact {
      display: inline-block;
      margin: 0 0.25em 0 0;
    }

    .contact-list-contact-name {
      .rating-form-header();
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;