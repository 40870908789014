@import '../../styles/colors.less';

.company-domicile-section {
  margin-top: 16px;
  margin-bottom: 16px;
  display: block;
}

.company-domicile-section > * {
  display: block;
}

.company-domicile-section > label {
  margin-top: 5px;
}

.cc-settings-investor-status {
  width: 558px;
  height: 110px;
  border: 1px solid #eff1f4;
  border-radius: 3px;
  width: 658px;
  text-align: center;
  margin-bottom: 24px;
}

.cc-settings-investor-label {
  display: block;
  margin-top: 16px;
}

.cc-settings-investor-label-value {
  display: block;
}

.cc-settings-status-container {
  padding-left: 200px;
  width: 100%;
  margin-bottom: 24px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;