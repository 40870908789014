@import '../../styles/colors.less';

.DiscoverFilters {
  width: 95%;

  .ant-checkbox-group-item {
    margin-bottom: auto; //to override behavior from CompanyContactAllocator.less
  }

  &-CollapseHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: #3d4858;

    & .ant-collapse-arrow {
      padding-top: 7px !important;
      & > svg {
        height: 1.2em;
        width: 1.2em;
      }
    }

    & svg {
      height: 1.4em;
      width: 1.4em;
    }

    &.ant-collapse-borderless {
      background: transparent;
    }

    &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: #3d4858;
    }

    &.ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header {
      padding: 8px 40px 8px 0;
    }
  }

  &-SoloFilter {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 0;

    .ant-btn-sm > span {
      font-size: 12px;
    }
  }

  &-CollapsePanel {
    padding: 5px 0;

    > .ant-collapse-content > .ant-collapse-content-box,
    &.ant-collapse-item-active {
      padding: 0 0 5px 0;
    }
  }

  &-Radio {
    margin-top: 15px;
    width: 100%;

    &.ant-radio-button-wrapper-checked {
      background-color: @cc-light-grey !important;
      color: @cc-white !important;
    }
  }

  &-Radio-body {
    &.ant-radio-button-wrapper {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      width: 50%;
      height: 25px;
      color: #3d4858;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: #3d4858 !important;
      border-color: #3d4858 !important;
    }

    &.ant-radio-button-wrapper:hover {
      background-color: #4a535f !important;
      border-color: #4a535f !important;
    }
  }

  &-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #8492a6;
  }

  &-MultiSelect {
    width: 100%;
    margin-bottom: 8px;

    &.ant-select,
    &.ant-select-dropdown-menu-item {
      font-weight: normal;
      font-size: 12px;
    }

    &.ant-select-multiple .ant-select-selection-item {
      height: auto;
    }

    &.ant-select-multiple .ant-select-selection-item-content,
    > .ant-select-item-option-content {
      white-space: break-spaces;
    }

    &.ant-select-multiple .ant-select-selector {
      padding: 2px 4px;
      border: 0;
    }

    &.ant-select-multiple .ant-select-clear {
      position: relative;
      width: auto;
      height: auto;
      right: 0;
      top: 0;
    }
  }

  &-select-container {
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      border: 1px solid @cc-primary;
    }
  }

  &-Line {
    width: 10px;
    height: 0.5px;
    display: inline-block;
    margin: 5px 3px;
    background-color: #d9dbdf;
  }

  &-NumberInput {
    margin-bottom: 8px;

    &.ant-input-number {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 24px;
      width: 40%;
    }
  }

  &-Checkbox {
    margin-top: 15px;
    padding-left: 16px;
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    color: #3d4858;

    > .ant-checkbox-group-item {
      margin-bottom: 12px;
      font-size: 13px;
    }
  }

  &-FilterCheckbox {
    padding-left: 16px;
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #3d4858;

    > .ant-checkbox-group-item,
    > .ant-checkbox-wrapper {
      font-size: 12px;
    }

    &&--stacked {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 4px;
    }
  }

  &-Checkbox {
    &-icon {
      margin-left: 8px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  &-switch {
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
  }

  &-header {
    margin: 5px 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: #3d4858;
  }

  &-switch-item {
    margin-left: auto;
    margin-top: 7px;
  }

  &-date {
    max-width: 40%;
    margin-bottom: 8px;

    .ant-input {
      font-size: 12px;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;