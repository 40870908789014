@import '../../styles/colors.less';

.banner {
  margin: 0;

  & .space-around.ant-btn-primary {
    background-color: #24c477;
    border-color: transparent;
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
  & .blue {
    background-color: @cc-primary !important;
  }

  & .ant-btn-background-ghost {
    background: rgba(255, 255, 255, 0.1) !important;
    border-radius: 4px;
    border-color: transparent;
  }

  & h4,
  h5 {
    color: @cc-white;
    margin-right: 8px;
    margin-bottom: 0 !important;
  }

  & h4 {
    font-size: 16px;
    line-height: 28px;
  }

  & h5 {
    font-size: 14px;
    line-height: 24px;
  }

  & .flex-childs {
    display: flex;
    align-items: center;
  }

  & .space-around {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 4px 16px;
  }

  & .button-icon-space {
    margin-right: 9.33px;
  }

  & .logo-icon-space {
    margin-right: 20px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;