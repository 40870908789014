@import '../../styles/colors.less';

.cc-credit {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    background: rgba(241, 159, 0, 0.1);
    width: 100%;
    padding: 24px;
  }
  &-cost,
  &-left {
    color: @cc-notice;
  }
}

body
  > div:nth-child(9)
  > div
  > div.ant-modal-wrap
  > div
  > div.ant-modal-content
  > div.ant-modal-body
  > div
  > div:nth-child(4)
  > div.ant-select.ant-select-enabled
  > div {
  background: #f7f8fa;
  border-radius: 4px;
  border-style: none;
  height: 40px;
  margin-top: 16px;
}

body
  > div:nth-child(8)
  > div
  > div.ant-modal-wrap
  > div
  > div.ant-modal-content
  > div.ant-modal-body
  > div
  > div:nth-child(4)
  > div.ant-select.ant-select-enabled
  > div {
  background: #f7f8fa;
  border-radius: 4px;
  border-style: none;
  height: 40px;
  margin-top: 16px;
}

body
  > div:nth-child(9)
  > div
  > div.ant-modal-wrap
  > div
  > div.ant-modal-content
  > div.ant-modal-body
  > div
  > div:nth-child(4)
  > div.ant-select.ant-select-enabled
  > div
  > div
  > div {
  line-height: 3;
  vertical-align: middle;
}

.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: @cc-lightest-grey !important;
  border-style: none !important;
  border-radius: 4px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;