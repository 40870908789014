@import '../../../styles/colors.less';

.messages-chat-colleagues-to-add {
  margin-bottom: 16px;

  & div {
    display: inline-block;
  }

  &-messagebox {
    width: 100%;

    & textarea {
      width: 100%;
      min-height: 80px;
    }
  }
}

.chat-conversation-preference-label {
  display: block;
  margin-top: 24px;
}

.chat-conversation-no-colleagues {
  margin-top: 16px;
  margin-bottom: 16px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;