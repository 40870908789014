@cc-floating-transition: 120ms;

@keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}
