@import '../../styles/colors.less';

.UpdateReturnsExcel {
  &-step {
    font-size: 16px;
    color: #92949c;
    margin-bottom: 10px;
  }

  &-error {
    color: @cc-danger;

    &.container {
      margin-bottom: 32px;
    }

    & .header {
      display: flex;
      justify-content: left;
      align-content: center;
      flex-direction: row;
      color: @cc-danger;
    }

    & .row {
      padding-left: 34px;
    }
  }

  &-preview {
    background-color: #eff1f4;
    color: #333333;

    &-data {
      max-height: 30vh;
      overflow-y: scroll;
    }

    &-row {
      padding: 10px 12px;
      border-bottom: 1px solid #e5e5e5;
    }
  }

  &-footer {
    margin-top: 12px;
    display: flex;
    justify-content: right;
    gap: 10px;
  }
}

.EditExcelForm {
  & .Field-error {
    border: 1px solid red !important;
  }

  & .full-width {
    width: 100%;
  }

  & .right-align {
    text-align: right;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;