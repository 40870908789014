@import '../../styles/colors.less';

.MeetingAnswers {
  &-Container {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  &-CollapseHeader {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    &.ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      color: @cc-darkest-grey;
    }
    &.ant-collapse-borderless > .ant-collapse-item {
      background: @cc-lightest-grey;
      border: 0px;
    }
  }
  &-AnswerBody {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }
  &-QuestionHeader {
    margin-bottom: 15px;
  }
  &-ExpandIcon {
    font-size: 25px !important;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;