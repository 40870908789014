@import '../../styles/colors.less';

.table-header-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-left: 14px !important;
  color: @cc-primary !important;
}

.table-header {
  height: 80px;
}

.investors-header-col {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  line-height: normal;
}

.investors-columns {
  height: 48px !important;
  width: 52px;
  top: 2px;
  margin-left: 16px;
}

.clear-filters {
  height: 48px;
  margin-right: 16px;
}

@keyframes rotating {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.rotating {
  animation: rotating 1.5s linear infinite;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;