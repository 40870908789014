@import '../../styles/colors.less';

.title() {
  color: @cc-black;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}

.title-header() {
  font-size: 12px;
  line-height: 16px;
  color: @cc-dark-grey;
}

.title-header-alt() {
  .title-header();
  font-weight: 600;
  text-transform: uppercase;
}

.description() {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}

@media screen and (max-width: 1025px) {
  .ant-col-10 {
    margin-left: 48px;
  }
}

.cc-contact {
  &-header {
    &-title {
      .title();
    }
  }

  &-content {
    padding: 64px 120px;

    @media (max-width: 1250px) {
      padding: 64px 30px;
    }

    &-fullname {
      .title();
    }

    &-jobtitle {
      .description();
      color: @cc-dark-grey;
    }

    &-qualification-title {
      .title-header-alt();
    }

    &-qualification {
      .description();
      color: @cc-greyscale-lightblack;
    }

    &-pic {
      height: 96px;
      width: 96px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-pic-empty {
      display: block;
      height: 96px;
      width: 96px;
      background-color: @cc-primary;
      color: @cc-white;
      padding: 20px;
      border-radius: 100%;
    }

    &-info {
      background: @cc-white;
      margin: 38px auto;
      padding: 49px 97px;
      width: 100%;

      @media (max-width: 1250px) {
        padding: 49px 40px;
      }

      &-header {
        .title-header-alt();
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid @cc-light-grey;

        &-last {
          margin-top: 64px;
        }
      }

      &-wrapper {
        position: relative;
        height: 36px;
        margin-bottom: 36px;
      }

      &-icon {
        color: @cc-light-grey;
      }

      &-title {
        margin: 0;
      }

      &-detail {
        font-size: 14px;
        line-height: 15px;
        font-weight: normal;
      }

      &-title {
        .title-header();
      }

      &-address > h6 {
        .title-header();
        margin-bottom: 0;
      }

      &-address > span {
        .description();
        color: @cc-greyscale-lightblack;
      }

      &-mailupdates {
        .title-header();
        color: @cc-greyscale-darkestblack;
        &-icon {
          margin-right: 10px;
          color: @cc-grey;
        }
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;