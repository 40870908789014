@import '../../styles/colors.less';

.FollowUpMessages {
  .message {
    padding: 8px 16px;
    &.sent-message {
      margin-right: 32px;
      background-color: @cc-primary;
      border-radius: 8px 0px 8px 8px;
      span {
        color: @cc-white;
      }
    }
    &.received-message {
      margin-left: 32px;
      margin-top: -4px;
      background-color: @cc-lightest-grey;
      border-radius: 0px 8px 8px 8px;
      span {
        color: @cc-greyscale-lightblack;
      }
    }
  }
  .contact {
    display: flex;
    &.sender {
      justify-content: flex-end;
    }
    &.receiver {
      justify-content: flex-start;
    }
  }
  .message-date {
    color: @cc-grey;
    &-sender {
      margin-left: 0px;
    }
    &-receiver {
      margin-left: 32px;
    }
  }
}
.ContactFont {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.ShowMoreButton {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
}

.FollowUpAlert {
  background-color: rgba(241, 159, 0, 0.1);
  border-radius: 4px;
  margin: 16px 0px;
  border: none;
}
.GoToConversationAlert {
  background-color: rgba(0, 141, 202, 0.1);
  border-radius: 4px;
  margin: 16px 0px;
  border: none;
}

.cc-show-more {
  color: rgba(0, 0, 0, 0.65);
}

.cc-show-more:hover {
  color: white;
  text-decoration: underline;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;