@import '../../styles/colors.less';

.empty-div {
  background-color: @cc-white;
  border-radius: 8px;
  padding: 24px;
  width: 500px;
  height: 300px;
  margin: auto;
  & > .ant-empty {
    padding-top: 24px;
  }
}

@media screen and (max-width: 414px) {
  .empty-div {
    padding: 0px;
    width: 285px;
    height: 250px;
  }
  & > .ant-empty {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;