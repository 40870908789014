.FundWizard {
  &-form {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
    padding: 20px;

    &-container {
      height: 75vh;
      overflow-y: auto;
      margin-bottom: 24px;
    }

    &-footer {
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 12px;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &-steps {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
}

.Question {
  &-label {
    font-weight: 400;
    font-size: 14px;
    color: #3d4858;
    display: block;
  }

  &-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    &-half {
      width: 50%;
    }
  }

  &-col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  &-array {
    &-field {
      margin-top: 12px;
    }
  }
}

.Nested-field > .ant-form-item > .ant-form-item-control {
  padding: 0px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;