.IlliquidOverview {
  .ant-tabs {
    &-nav {
      padding-left: 0px;
      border-bottom: none;
    }
    &-tab {
      padding-left: 0px;
    }
  }

  &-header {
    font-weight: 600;
    font-size: 14px;
    color: #4d4f56;
    margin-bottom: 10px;
  }

  &-stat {
    font-size: 24px;
    font-weight: 600;

    &-header {
      color: #92949c;
      font-size: 12px;
    }
  }

  &-property {
    &-row {
      display: flex;
      justify-content: space-between;
      border: 1px solid #f2f3f3;
      border-bottom: none;
      padding: 12px 24px;
      font-size: 13px;
      color: #5e656c;

      &:nth-child(even) {
        background-color: #fdfdfd;
      }

      &:last-child {
        border-bottom: 1px solid #f2f3f3;
        margin-bottom: 12px;
      }
    }

    &-value {
      font-weight: 600;
    }
  }

  &-ChartsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 18px;
  }

  &-Chart {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
}

.Positions-table {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #fdfdfd;
  }

  tr {
    border: 1px solid #f2f3f3;
  }

  th {
    background-color: #f7f8fa;
    color: #5e656c;
    font-size: 13px;
    font-weight: 600;
    padding: 8px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;