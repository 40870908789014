@import '../../styles/colors.less';

.cc-discover-fund-select {
  text-align: center;
  padding: 100px 0px;
  & > &-icon {
    color: @cc-grey;
  }

  & > &-name {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 18px;
    color: @cc-dark-black;
    margin-top: 20px;
  }

  & > &-description {
    color: @cc-dark-grey;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    width: 100%;
  }
  &-group {
    width: 30%;
    margin: auto;
  }
  &-checkbox {
    width: 100%;
    & > .ant-checkbox-group-item {
      display: flex;
      margin-right: 0;
      text-align: left;
      padding: 15px 20px;
      background: @cc-white;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 0;
      border-bottom: 1px solid @cc-light-grey;
      > span {
        font-size: 14px;
        color: @cc-greyscale-lightblack;
      }
    }
    & > .ant-checkbox-group-item:hover {
      display: flex;
      margin-right: 0;
      text-align: left;
      padding: 15px 20px;
      background: #f0fffe;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 0;
      border-bottom: 1px solid @cc-light-grey;
      > span {
        font-size: 14px;
        color: @cc-greyscale-lightblack;
      }
    }
  }
  &-new {
    color: @cc-primary;
    display: block;
    width: 100%;
    margin-right: 0;
    text-align: left;
    padding: 15px 20px;
    background: #ffffff;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 0;
    border-bottom: 1px solid #eff1f4;
    cursor: pointer;
  }
  &-new:hover {
    color: @cc-primary;
    display: block;
    width: 100%;
    margin-right: 0;
    text-align: left;
    padding: 15px 20px;
    background: #f0fffe;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 0;
    border-bottom: 1px solid #eff1f4;
    cursor: pointer;
  }
  &-button {
    background: @cc-primary;
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    height: 44px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;