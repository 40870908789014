@import '../../styles/colors.less';

.ant-table {
  background: transparent;
}

.listTable {
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: transparent !important;
  }
  .ant-table-content {
    padding-top: 0px !important ;
    background: transparent;
  }
  .ant-table-thead {
    background: transparent;
    tr {
      height: 0px;
      background-color: transparent !important;
      th {
        background: transparent;
        border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1;
      }
    }
  }
  .ant-table-body tr td {
    border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1;
  }
  .ant-table-small {
    background: transparent !important;
    border: none;
  }

  .ant-table-tbody tr.ant-table-row-level-0:hover > td {
    background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
  }
  .ant-table-tbody tr.ant-table-row-level-1:hover > td {
    background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
  }
  .ant-table-tbody tr.ant-table-row-level-2:hover > td {
    background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
  }
  .ant-table-tbody tr.ant-table-row-level-3:hover > td {
    background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
  }
  .ant-table-tbody tr.ant-table-row-level-4:hover > td {
    background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
  }
  .ant-table-placeholder {
    background: transparent !important;
  }
  .ant-table-placeholder > td {
    border-bottom: none;
  }
  .ant-table-placeholder:hover {
    background: transparent !important;
  }
  .ant-empty-description {
    color: #fff;
  }
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td:hover {
  background: transparent;
}

.cc-table-title {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: @cc-grey;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-left: 8px;
}

.cc-button-div {
  display: flex;
  visibility: hidden;
}
.cc-main-div {
  border-bottom: none !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1 !important;
}

.cc-main-div:hover {
  .cc-button-div {
    visibility: visible;
  }
}

.cc-main-container {
  height: calc(100vh - 480px);
}

.simplebar-scrollbar::before {
  background-color: @cc-dark-grey;
}

.cc-main-title {
  font-size: 14px;
  color: @cc-grey;
  margin-left: 5px;
}

.cc-main-panel {
  & > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }

  & > .ant-collapse-header {
    padding-left: 0px !important;
    display: inline-flex;
    padding: 0px !important;
    align-items: center;
    & > .anticon {
      position: unset !important;
      color: @cc-grey !important;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;