@import '../../../styles/colors.less';

.messaging-message-divider {
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;

  &-time {
    z-index: 201;
    margin-top: -8px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    width: 15%;
    color: @cc-grey;
    margin-bottom: 32px;
  }

  &-line {
    content: '';
    margin-top: 8px;
    border-top: 1px solid @cc-light-grey;
    background: @cc-light-grey;
    z-index: 200;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;