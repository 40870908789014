@import '../../styles/colors.less';

.rating-form-header {
  color: @cc-greyscale-lightblack;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.rating-card {
  background-color: @cc-white;
  border: 1px solid @cc-light-grey;
  border-radius: 4px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  padding: 16px;
  margin-bottom: 11px;
}

.meeting-info {
  font-size: 14px;
  line-height: 24px;
  color: @cc-greyscale-lightblack;
}
