@import '../../styles/colors.less';
@import '../../styles/constants.less';

@transition: 120ms;
@transition-empty: 0ms;

.floating-input-row {
  position: relative;
  margin-bottom: 16px;
}

.placeholder-movement-notext {
  position: absolute;
  animation-name: bring-placeholder-down;
  animation-duration: @transition;
  animation-fill-mode: forwards;
}

.cc-floating-placeholder {
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 20px;
  overflow-y: hidden;
  overflow-wrap: unset;
  white-space: nowrap;
  width: 95%;
}

.placeholder-movement-text,
.cc-floating-input:focus + .placeholder-movement-notext {
  position: absolute;
  animation-name: shift-placeholder-up;
  animation-duration: @transition;
  animation-fill-mode: forwards;
  color: @cc-dark-grey;
}

.cc-floating-input-text,
.cc-floating-input:focus {
  transition: @transition;
  height: 48px;
  padding-top: 24px;
  box-shadow: none;
}

.cc-floating-input-row-text {
  transition: @transition;
  margin-bottom: 8px;
}

.cc-floating-input {
  transition: @transition;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  color: @cc-darkest-grey;
}

.cc-floating-input-invalid {
  border: 1px solid @cc-danger !important;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation: normal;
  transition: @transition !important;
}

.cc-floating-input::placeholder {
  transition: @transition cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: @cc-dark-grey;
}

.cc-floating-input:focus::placeholder {
  transition: 0ms;
  opacity: 0;
}

.floating-input-row .cc-validation-message {
  color: @cc-danger;
  text-align: left;
  padding-left: 6px;
}

.cc-floating-search span input {
  color: @cc-darkest-grey;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.cc-floating-search span input::placeholder {
  color: @cc-darkest-grey;
}

.cc-floating-search span input:focus {
  border: none;
  box-shadow: none;
}

#zipCode::placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: @cc-dark-grey;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;