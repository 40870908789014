@import '../../styles/colors.less';
.cardSearchAlert {
  width: 507px;
  min-height: 160px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 24px;

  /* Greyscale/White */
  background: #ffffff;

  /* Greyscale/Light Grey */
  border: 1px solid #eff1f4;
  box-sizing: border-box;

  /* Shadow Level 1 */
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  border-radius: 4px;

  &-mainHeading {
    /* H5 Heading */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #3d4858;
    padding-top: 23px;
    padding-bottom: 23px;
  }

  &-filters {
    /* Greyscale/Lightest Grey */
    background: #f7f8fa;

    /* Greyscale/Light Grey */
    border: 1px solid #eff1f4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 4px;
    margin-right: 8px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    align-items: center;
    color: #000000;
  }

  & > .ant-tag,
  & > div > .ant-tag {
    border-color: @cc-light-grey;
    & > .ant-tag-close-icon {
      color: @cc-danger;
    }
  }
}

.delete-button {
  color: #d62b34;
  border: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 0;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
}

.inputSearchAlert {
  background: #f7f8fa;
  border-radius: 4px;
  border: none;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  margin-top: 32px;
  color: #4d4f56;
  margin-bottom: 16px;
}

.remove-button {
  border: 0;
  background: transparent;
  height: 0;
  padding: 0 6px 0 6px;
  color: red;
  font-weight: 600;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;