@import '../../styles/colors.less';

.DateButtons {
  &-group {
    width: 400px;
  }
}
.cc-eventdays-buttons {
  border: 1px solid @cc-primary !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  width: 103px;
  height: 125px;
  padding: 16px 30px 0px;
  text-align: center;
  margin-bottom: 24px;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &.ant-radio-button-wrapper-checked:focus-within {
    outline-style: none;
  }

  &.ant-radio-button-wrapper:not(:first-child)::before {
    background: transparent;
  }

  &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: @cc-white;
  }
}

.WeekDropdown {
  text-align: center;
  margin-bottom: 10px;
  &-week {
    color: @primary-color;
    font-weight: bold;
  }
  &-text {
    color: @primary-color;
    margin-right: 115px;
  }
}
.WeekDropdown > button {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #eff1f4;
  width: 100%;
  color: @primary-color;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;