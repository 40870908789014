@import '../../../styles/colors.less';

.message-board-loading-older .ant-spin-dot {
  max-height: 20px;
  max-width: 20px;
}

.messaging-arrow-new-message {
  position: sticky;
  bottom: 20px;
  left: auto;
  right: auto;
  text-align: center;
  width: 100%;
  height: 42px;

  &-icon {
    background-color: theme('colors.primary.2');
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    padding-top: 8px;
    border: 1px solid @cc-primary;
    border-radius: 8px 8px;
    cursor: pointer;

    color: @cc-primary;

    & svg {
      margin-right: 8px;
    }
  }
}

.message-board-last-message-notification {
  //width: 100%;
  text-align: center;
  color: @cc-darkest-grey;
  background: rgba(241, 159, 0, 0.1);
  border-radius: 4px;
  min-height: 80px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 64px;
  margin-right: 64px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;