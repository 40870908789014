.ant-picker-calendar {
  div.ant-picker-panel {
    border: none;
  }
  table.ant-picker-content thead tr {
    border-bottom: 2px solid #d3dae3;
    th {
      padding-bottom: 0.5em;
    }
  }
  table.ant-picker-content tbody {
    tr:first-child td {
      padding-top: 1em;
    }
    .ant-picker-calendar-date-content {
      line-height: 12px;
    }
    .ant-picker-calendar-date-today,
    .ant-picker-cell-selected .ant-picker-calendar-date {
      border-radius: 0.5rem;
      &:before {
        border-radius: 0.5rem;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;