.pop-confirm {
  @apply bg-white;
  @apply border border-solid border-grey-300;
  @apply rounded;
  @apply max-w-sm;
  @apply z-popover;

  &__arrow {
    svg {
      @apply block;
    }

    .stroke {
      @apply fill-current text-grey-300;
    }
    .fill {
      @apply fill-current text-white;
    }
  }

  &__message {
    @apply p-4 pb-0;
  }

  &__actions {
    @apply p-2;
    @apply flex justify-end gap-2;
  }
}
