@import '../../styles/colors.less';

.ConfirmedMeetingsTable {
  background-color: @cc-white;
  padding: 12px;

  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: @cc-primary;
    padding: 12px;
  }
}

.ConfirmedMeetingsTableCfn {
  padding: 12px;

  &-title {
    color: @cc-greyscale-lightblack;
    padding: 12px;
  }
}

.Ratings {
  &-popover {
    & .ant-popover-inner {
      background-color: rgba(0, 0, 0, 0.75);
      color: @cc-white;
    }
    &.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
      border-bottom-color: rgba(0, 0, 0, 0.75);
      border-right-color: rgba(0, 0, 0, 0.75);
    }
    & .ant-popover-inner-content {
      & .ant-list-item {
        color: @cc-white;
        border: none;
        padding: 4px 2px;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;