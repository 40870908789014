@import '../../styles/colors.less';
.cc-matchmaking-settings {
  text-align: center;

  & > &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: @cc-dark-black;
    padding-top: 40px;
  }
  & > &-description {
    color: @cc-dark-grey;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    width: 100%;
  }
  & > &-slider {
    background-color: @cc-white;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-top: 80px;
    margin-left: 10%;
    margin-right: 10%;
  }
  & > &-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-top: 50px;

    color: @cc-darkest-grey;
  }

  & > &-button {
    background-color: @cc-primary;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 50px;
    color: @cc-white;
    &.ant-btn[disabled] {
      color: #ffffff;
      background-color: #92949c;
      border-color: #92949c;
    }
  }

  .ant-tooltip-inner {
    background-color: @cc-white;
  }

  .ant-slider-handle {
    border-color: @cc-primary;
    background: @cc-primary;
  }
  .ant-slider-mark-text-active {
    color: @cc-primary;
    font-weight: 600;
    font-size: 18px;
  }
  &-Radio-body {
    &.ant-radio-button-wrapper {
      text-align: center;
      width: 50%;
    }
  }
  &-Radio {
    width: 750px;
    display: flex;
    margin: auto;
  }
  &-radio-text {
    margin-top: 20px;
    padding-left: 5%;
  }
  &-radio-visibility {
    padding-left: 15%;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;