.ant-upload-list-picture {
  display: grid;
  gap: 0.5rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.ant-upload-list-picture-container {
  order: 1;
}

.settings-tabs .tab-group.tab-group--vertical.tab-group--medium .tab {
  height: 48px;
}
