@import '../../../styles/colors.less';

.cc-participant {
  display: flex;

  &-info {
    display: inline-grid;
  }

  &-big {
    &-narrow {
      width: 75%;
      flex: 0 0 auto;
      position: fixed;
    }

    &-wide {
      width: 100%;
      flex: 0 0 auto;
      position: fixed;
    }
  }

  &-video {
    &-big {
      height: 100vh;
      width: 100%;

      &-web {
        object-fit: cover;
      }

      &-mobile {
        object-fit: contain;
      }

      &-on {
        color: @cc-white;
        text-align: center;
        position: absolute;
        z-index: 160;
      }

      &-off {
        color: @cc-white;
        background: @cc-dark-black;
        padding: 30% 0;
        text-align: center;
      }

      &-none {
        display: none;
      }
    }
  }
}

.dominant-network-wrapper {
  margin: 20px 0px 0px 85px;
  z-index: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: @cc-light-grey;
}

.dominant-network {
  width: 6px;
  display: inline-block;
  margin-right: 1px;
  margin-left: 1px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;