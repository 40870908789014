@import '../../styles/colors.less';

.RadioButtonItem {
  background: @cc-white;
  border: 1px solid @cc-light-grey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 47.5%;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: @cc-light-grey;
    border-color: @cc-grey;
  }

  &-label {
    font-size: 14px;
    line-height: 24px;
    color: @cc-black;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
  }

  &:hover &-label {
    font-weight: 600;
  }

  &-icon {
    color: @cc-grey;
  }

  &:hover &-icon {
    color: @cc-primary;
  }

  &-selected,
  &-selected:hover {
    background-color: @cc-primary;
    border-color: @cc-primary;
  }

  &-selected > &-label,
  &-selected:hover > &-label,
  &-selected > &-icon,
  &-selected:hover > &-icon {
    color: @cc-white;
  }

  &-selected-check {
    display: inline-block;
    color: @cc-white;
    position: absolute;
    top: 8px;
    right: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
    text-align: center;
  }

  &-selected-check:hover {
    cursor: pointer;
    color: @cc-light-grey;
  }
}

.RadioButtonList {
  margin-bottom: 16px;

  &-label {
    display: block;
    margin-bottom: 8px;
    color: @cc-darkest-grey;
  }

  &-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;