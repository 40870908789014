@import '../../styles/colors.less';
@import './RatingMixins.less';

.MeetingRatings-notice {
  margin-bottom: 1.5rem;

  & > .ant-alert-message {
    color: @cc-greyscale-lightblack;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
  }

  & > .ant-alert-description {
    color: @cc-greyscale-darkestblack;
    font-size: 14px;
    line-height: 24px;
  }
}

.MeetingRatings {
  max-height: 580px;

  &-session {
    &-title {
      color: @cc-black;
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
}

.ManagerToAllocatorRatingForm {
  .rating-card();

  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;

  & > .MeetingWith {
    width: 60%;
  }

  &-rating {
    width: 40%;
    text-align: right;

    &-header {
      .rating-form-header();
    }
  }

  &-comment {
    width: 100%;
    margin-top: 12px;

    & > textarea.ant-input {
      border: 0;
      border-top: 1px solid @cc-light-grey;
      background: transparent;
      border-radius: 0;
    }

    & > textarea.ant-input:focus {
      outline: 0;
      box-shadow: none !important;
    }

    &-help {
      font-size: 12px;
      line-height: 16px;
      color: @cc-danger;

      & > span {
        margin-left: 5px;
      }
    }
  }
}

.AllocatorToManagerRatingForm {
  .rating-card();

  &-details {
    width: 100%;

    &-header {
      .rating-form-header();
    }

    &-meeting {
      .meeting-info();
    }
  }

  &-rating {
    &-label {
      margin-bottom: 0;
    }

    & > .ant-radio-group {
      margin: 8px 0;
    }

    &-indication > .ant-checkbox-group {
      margin-top: 8px;
      & > .ant-checkbox-group-item {
        display: flex;
        margin-bottom: 8px;
      }
    }

    &-comment {
      margin: 9px 0 18px;
      & > textarea.ant-input {
        background-color: @cc-lightest-grey;
        border: none;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;