@import '../../styles/colors.less';

.discover-allocator {
  margin-bottom: 16px;
}

.discover-allocator-result-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #3d4858;
  & > span > .ant-badge-status-dot {
    width: 13px;
    height: 13px;
    margin-left: 5px;
  }

  & .round-profile-imagegroup-name {
    max-width: none;
  }
}

.discover-allocator-result-subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  color: #92949c;
}

.discover-allocator-hidden {
  opacity: 0.7;
  border-color: #696969 !important;
}

.discover-allocator-favicon {
  color: @cc-notice;
  width: 24px !important;
  height: auto;
  line-height: 0;
}

.discover-allocator-circle > div {
  font-size: 10px !important;
  & > .ant-progress-text {
    color: @cc-success;
  }
}

.discover-allocator-circle-unmatched > div {
  font-size: 10px !important;
  & > .ant-progress-text {
    color: #f19f00;
  }
}

.discover-allocator-menu {
  display: flex;
  align-items: center;
}

.discover-allocator-text-description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #3d4858;
}

.discover-allocator-percentage {
  width: 65px;
  height: 35px;
  background: rgba(20, 202, 156, 0.1) !important;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-right: 12px;
  cursor: default !important;
  /* or 200% */
  align-items: center;
  text-align: center;
  border: transparent;
  color: #14ca9c !important;
}

.discover-allocator-details-menu-button {
  padding: 6px 12px;
  color: @cc-dark-grey;
  background: white;
  border: 1px solid @cc-grey;
  box-sizing: border-box;
  border-radius: 4px;
}

.discover-allocator-details-menu-button svg {
  margin-right: 8px;
}

.ant-col-4 {
  padding-left: 0;
}

.table-action-button {
  background: white;
}

.discover-allocator-result-footer-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.discover-fund-result-footer {
  padding: 8px;
  display: flex;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;