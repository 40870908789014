/* purgecss start ignore */

.ContactAvatar-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
}

.ContactAvatar-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.ContactAvatar-popover .ant-popover-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.ContactAvatar-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.ContactAvatar-popover-no-image .ant-popover-inner-content {
  padding: 0px;
}

.ContactAvatar-popover-no-image .ant-popover-title {
  border-bottom: none;
  color: #ffffff;
}

/* purgecss end ignore */

/* purgecss start ignore */

.round-profile-thumbnail-group {
  border: 2px solid #ffffff;
  margin-left: -11px !important;
  margin-top: -11px !important;
}

.round-profile-imagegroup {
  padding-left: 11px;
  padding-top: 11px;
  vertical-align: bottom;
}

.round-profile-ellipsis {
  margin-left: -11px;
  margin-top: -11px;
  margin-top: -2px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff;
  object-fit: cover;
}

.round-profile-ellipsis span {
  vertical-align: middle;
}

.profile-ellipsis-online {
  background: #6a48c6;
}

.profile-ellipsis-roadshow {
  background: #962c6a;
}

.profile-ellipsis-summit {
  background: #052449;
}

.ant-avatar-string {
  margin-top: -2px;
}

.ProfilePictureGroup-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  text-align: center;
}

.ProfilePictureGroup-popover .ant-popover-inner-content img {
  object-fit: cover;
}

.ProfilePictureGroup-popover .ant-popover-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.ProfilePictureGroup-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.ProfilePictureGroup-popover.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: rgba(0, 0, 0, 0.75);
  border-left-color: rgba(0, 0, 0, 0.75);
}

.ProfilePictureGroup-popover-no-image .ant-popover-inner-content {
  padding: 0px;
}

.ProfilePictureGroup-popover-no-image .ant-popover-title {
  border-bottom: none;
  color: #ffffff;
}

.ProfilePictureGroup-dropdown-popover-no-image.ant-popover {
  display: none;
  z-index: 2000;
}

.ProfilePictureGroup-dropdown-popover.ant-popover {
  z-index: 2000;
}

.dropdown-avatar {
  margin-top: -2px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff;
  margin-right: 16px;
  object-fit: cover;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messages-compose-container {
  min-width: 600px;
  margin-bottom: 24px;
}

.messages-compose-title {
  color: #2d3239;
  margin-top: 24px;
}

.messages-compose-contacts {
  margin-top: 24px;
}

.messages-compose-searchselect {
  width: 300px;
  border: none;
}

.messages-compose-searchselect > div {
  height: 100% !important;
}

.messages-compose-searchselect .ant-select-selection {
  background-color: #f7f8fa;
  height: 48px;
  border: none;
}

.messages-compose-searchselect .ant-select-selection__placeholder {
  margin-top: 0px;
}

.messages-compose-searchselect .ant-select-selection-selected-value {
  min-width: 260px;
  margin-top: 4px;
}

.messages-compose-searchselect .ant-select-selection-selected-value > div {
  display: inline-block;
}

.messages-compose-searchselect .ant-select-search__field {
  height: 48px;
}

.messages-compose-more-contacts {
  margin-top: 16px;
}

.messages-compose-more-contacts-checks {
  margin-top: 16px;
  display: inline-block;
}

.messages-compose-textbox {
  margin-top: 18px;
}

.messages-compose-contact-option {
  min-width: 300px;
  box-shadow: inset 0px -1px 0px #eff1f4;
  display: inline-block;
}

.messages-compose-contact-option-text {
  margin-left: 8px;
  width: 65%;
}

.messages-compose-contact-option > div {
  display: flex;
}

.messages-compose-contact-checkbox {
  margin-top: 10px;
  margin-left: 8px;
}

.messages-compose-contact-checkbox-card {
  min-width: 183px;
  max-width: 183px;
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 49px;
  max-height: 49px;
  display: inline-block;
  margin-right: 13px;
  margin-bottom: 8px;
}

.messages-compose-contact-checkbox-card > div {
  display: inline-block;
  vertical-align: top;
}

.messages-compose-contact-checkbox .ant-checkbox-inner {
  min-width: 20px;
  min-height: 20px;
}

.messages-compose-contact-checkbox .ant-checkbox-inner::after {
  left: 28%;
}

.messages-compose-contact-checkbox-image {
  margin-left: 8px;
  margin-top: 2px;
}

.messages-compose-contact-checkbox-text {
  margin-left: 8px;
  margin-top: 8px;
}

.messages-compose-contact-checkbox-name {
  max-width: 90px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-height: 20px;
  white-space: nowrap;
  color: #2d3239;
}

.messages-compose-contact-checkbox-title {
  max-width: 90px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-height: 20px;
  white-space: nowrap;
  color: #c2c6cc;
}

.messages-compose-contact-checkbox-summit-presence {
  float: right;
  margin-right: 4px;
  color: #92949c;
}

.messages-compose-option-company-category {
  float: right;
  vertical-align: top;
  padding-top: 12px;
  color: #92949c;
}

.messages-compose-option-company-name {
  margin-top: -4px;
  color: #92949c;
}

.messages-compose-option-contact-image {
  vertical-align: top;
}

/* purgecss end ignore */

/* purgecss start ignore */

.company-dropdown.ant-select-dropdown-placement-bottomLeft {
  width: 100% !important;
  left: 0 !important;
}

.company-dropdown .ant-select-item.ant-select-item-option {
  padding: 8px 16px !important;
  border-bottom: 1px solid #eff1f4;
}

.select-container > .ant-select-selector {
  height: 100% !important;
}

.select-container > .ant-select-selector > .ant-select-selection-item {
  height: 50px;
  line-height: 25px !important;
  display: flex;
  align-items: center;
}

.select-container > .ant-select-selector {
  display: flex;
  align-items: center;
}

.select-container > .ant-select-selector > .ant-select-selection-placeholder {
  color: #92949c;
  font-size: 14px;
}

.select-company {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select-company-text-contacts {
  color: #92949c;
  display: flex;
}

.select-company-category {
  display: flex;
  align-items: center;
  color: #92949c;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-check-participants {
  display: inline-flex;
  width: 100%;
}

.cc-check-participants > .ant-checkbox-disabled + span {
  color: #4d4f56;
}

.cc-check-participants > .ant-checkbox,
.cc-check-participants > .ant-checkbox.ant-checkbox-checked {
  margin: 11px 0px 10px 10px;
  height: 100%;
}

.cc-check-participants > .ant-checkbox + span,
.cc-check-participants > .ant-checkbox.ant-checkbox-checked + span {
  margin-top: -5px;
  width: calc(100% - 20px);
}

.cc-check-participants > .ant-checkbox.ant-checkbox-checked > .ant-checkbox-input {
  height: auto;
}

.cc-check-participants-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.cc-check-participants-box {
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  border-radius: 4px;
  width: calc(33% - 16px);
  margin-right: 16px;
  margin-top: 16px;
}

.cc-check-participants-box-checked {
  border-color: #052449;
}

.cc-check-participants > .ant-checkbox > span {
  width: 20px;
  height: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AllocatorOnlineMR > .ant-modal-header {
  padding: 24px 32px;
}

.AllocatorOnlineMR > .ant-modal-header > .ant-modal-title {
  color: #2d3239;
}

.AllocatorOnlineMR .ant-modal-content > .ant-modal-footer {
  padding: 0px;
}

.AllocatorOnlineMR-participants {
  background-color: #f7f8fa;
  padding: 16px 32px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.contact-list {
  display: flex;
  flex-wrap: wrap;
}

.contact-list-contact {
  display: flex;
  align-items: center;
  margin: 3px 12px 3px 0;
}

.contact-list-contact-name {
  margin-left: 0.25em;
}

/* purgecss end ignore */

/* purgecss start ignore */

.pop-confirm {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
  border-radius: 4px;
  max-width: 24rem;
  z-index: 1050;
}

.pop-confirm__arrow svg {
  display: block;
}

.pop-confirm__arrow .stroke {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity));
}

.pop-confirm__arrow .fill {
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.pop-confirm__message {
  padding: 1rem;
  padding-bottom: 0px;
}

.pop-confirm__actions {
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.scrollDiv::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar {
  width: 7px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c2c6cc;
}

.SummitAlert {
  background: #fff5e6;
  border: 1px solid #f19f00;
  box-sizing: border-box;
  border-radius: 8px;
  height: 68px;
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.SummitAlert-icon {
  margin: 0 18px 0 18px;
}

.SummitAlert-link {
  padding: 0px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Sorter-menu {
  max-height: 70vh;
  position: absolute;
  right: 0;
  overflow-y: scroll;
}

.Sorter-menu-item {
  display: flex;
  justify-content: space-between;
}

.Sorter-menu-item-text {
  font-size: 14px;
  line-height: 24px;
  padding: 6px;
  margin-right: 8px;
  color: #4d4f56;
  flex-grow: 1;
}

/* purgecss end ignore */

/* purgecss start ignore */

.mainHeading {
  /* H4 Heading */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  align-items: center;
  text-align: center;
  /* Greyscale/Light Black */
  color: #24262b;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: center;
  margin: 0px 16px;
}

.subHeading {
  /* H5 Heading */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* Greyscale/Dark Grey */
  color: #92949c;
  /* Inside Auto Layout */
  padding-top: 16px;
  order: 2;
  align-self: center;
  margin: 0px 16px;
}

.subInfo {
  /* Body */
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-top: 16px;
  align-items: center;
  text-align: center;
  /* Greyscale/Dark Grey */
  color: #92949c;
}

.inputSearchAlert {
  background: #f7f8fa;
  border-radius: 4px;
  border: none;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  margin-top: 32px;
  color: #4d4f56;
}

.createAlertBody > .ant-tag,
.createAlertBody > div > .ant-tag {
  border-color: #eff1f4;
}

.createAlertBody > .ant-tag > .ant-tag-close-icon,
.createAlertBody > div > .ant-tag > .ant-tag-close-icon {
  color: #d62b34;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cardSearchAlert {
  width: 507px;
  min-height: 160px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 24px;
  /* Greyscale/White */
  background: #ffffff;
  /* Greyscale/Light Grey */
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  /* Shadow Level 1 */
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  border-radius: 4px;
}

.cardSearchAlert-mainHeading {
  /* H5 Heading */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #3d4858;
  padding-top: 23px;
  padding-bottom: 23px;
}

.cardSearchAlert-filters {
  /* Greyscale/Lightest Grey */
  background: #f7f8fa;
  /* Greyscale/Light Grey */
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
  margin-right: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  align-items: center;
  color: #000000;
}

.cardSearchAlert > .ant-tag,
.cardSearchAlert > div > .ant-tag {
  border-color: #eff1f4;
}

.cardSearchAlert > .ant-tag > .ant-tag-close-icon,
.cardSearchAlert > div > .ant-tag > .ant-tag-close-icon {
  color: #d62b34;
}

.delete-button {
  color: #d62b34;
  border: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 0;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
}

.inputSearchAlert {
  background: #f7f8fa;
  border-radius: 4px;
  border: none;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  margin-top: 32px;
  color: #4d4f56;
  margin-bottom: 16px;
}

.remove-button {
  border: 0;
  background: transparent;
  height: 0;
  padding: 0 6px 0 6px;
  color: red;
  font-weight: 600;
}

/* purgecss end ignore */

/* purgecss start ignore */

.DiscoverFilters {
  width: 95%;
}

.DiscoverFilters .ant-checkbox-group-item {
  margin-bottom: auto;
}

.DiscoverFilters-CollapseHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: #3d4858;
}

.DiscoverFilters-CollapseHeader .ant-collapse-arrow {
  padding-top: 7px !important;
}

.DiscoverFilters-CollapseHeader .ant-collapse-arrow > svg {
  height: 1.2em;
  width: 1.2em;
}

.DiscoverFilters-CollapseHeader svg {
  height: 1.4em;
  width: 1.4em;
}

.DiscoverFilters-CollapseHeader.ant-collapse-borderless {
  background: transparent;
}

.DiscoverFilters-CollapseHeader.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #3d4858;
}

.DiscoverFilters-CollapseHeader.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 8px 40px 8px 0;
}

.DiscoverFilters-SoloFilter {
  border-bottom: 1px solid #d9d9d9;
  padding: 5px 0;
}

.DiscoverFilters-SoloFilter .ant-btn-sm > span {
  font-size: 12px;
}

.DiscoverFilters-CollapsePanel {
  padding: 5px 0;
}

.DiscoverFilters-CollapsePanel > .ant-collapse-content > .ant-collapse-content-box,
.DiscoverFilters-CollapsePanel.ant-collapse-item-active {
  padding: 0 0 5px 0;
}

.DiscoverFilters-Radio {
  margin-top: 15px;
  width: 100%;
}

.DiscoverFilters-Radio.ant-radio-button-wrapper-checked {
  background-color: #eff1f4 !important;
  color: #ffffff !important;
}

.DiscoverFilters-Radio-body.ant-radio-button-wrapper {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 50%;
  height: 25px;
  color: #3d4858;
}

.DiscoverFilters-Radio-body.ant-radio-button-wrapper-checked {
  background-color: #3d4858 !important;
  border-color: #3d4858 !important;
}

.DiscoverFilters-Radio-body.ant-radio-button-wrapper:hover {
  background-color: #4a535f !important;
  border-color: #4a535f !important;
}

.DiscoverFilters-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #8492a6;
}

.DiscoverFilters-MultiSelect {
  width: 100%;
  margin-bottom: 8px;
}

.DiscoverFilters-MultiSelect.ant-select,
.DiscoverFilters-MultiSelect.ant-select-dropdown-menu-item {
  font-weight: normal;
  font-size: 12px;
}

.DiscoverFilters-MultiSelect.ant-select-multiple .ant-select-selection-item {
  height: auto;
}

.DiscoverFilters-MultiSelect.ant-select-multiple .ant-select-selection-item-content,
.DiscoverFilters-MultiSelect > .ant-select-item-option-content {
  white-space: break-spaces;
}

.DiscoverFilters-MultiSelect.ant-select-multiple .ant-select-selector {
  padding: 2px 4px;
  border: 0;
}

.DiscoverFilters-MultiSelect.ant-select-multiple .ant-select-clear {
  position: relative;
  width: auto;
  height: auto;
  right: 0;
  top: 0;
}

.DiscoverFilters-select-container {
  border: 1px solid #d9d9d9;
  background: #fff;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.DiscoverFilters-select-container:hover {
  border: 1px solid #052449;
}

.DiscoverFilters-Line {
  width: 10px;
  height: 0.5px;
  display: inline-block;
  margin: 5px 3px;
  background-color: #d9dbdf;
}

.DiscoverFilters-NumberInput {
  margin-bottom: 8px;
}

.DiscoverFilters-NumberInput.ant-input-number {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  width: 40%;
}

.DiscoverFilters-Checkbox {
  margin-top: 15px;
  padding-left: 16px;
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  color: #3d4858;
}

.DiscoverFilters-Checkbox > .ant-checkbox-group-item {
  margin-bottom: 12px;
  font-size: 13px;
}

.DiscoverFilters-FilterCheckbox {
  padding-left: 16px;
  width: 100%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3d4858;
}

.DiscoverFilters-FilterCheckbox > .ant-checkbox-group-item,
.DiscoverFilters-FilterCheckbox > .ant-checkbox-wrapper {
  font-size: 12px;
}

.DiscoverFilters-FilterCheckbox.DiscoverFilters-FilterCheckbox--stacked {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 4px;
  gap: 4px;
}

.DiscoverFilters-Checkbox-icon {
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: middle;
}

.DiscoverFilters-switch {
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
}

.DiscoverFilters-header {
  margin: 5px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: #3d4858;
}

.DiscoverFilters-switch-item {
  margin-left: auto;
  margin-top: 7px;
}

.DiscoverFilters-date {
  max-width: 40%;
  margin-bottom: 8px;
}

.DiscoverFilters-date .ant-input {
  font-size: 12px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-result-card {
  display: block;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
  border-radius: 4px;
  --tw-shadow: 0 2px 6px -2px rgba(5,36,73,0.16), 0 2px 3px -1px rgba(114,0,168,0.16), 0 14px 45px -9px rgba(5,36,73,0.03);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition: border-color 300ms, background-color 300ms, box-shadow 300ms;
}

.discover-result-card:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 112, 184, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(241, 244, 249, var(--tw-bg-opacity));
  --tw-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.discover-result-card__header {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 1rem;
}

.discover-result-card__body {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-bottom: 0.75rem;
}

.discover-result-card__footer {
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-style: solid;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.title-span {
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  margin: 8px 0;
}

.title-small {
  font-size: 18px;
}

.desc-span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 8px 0;
  color: #92949c;
  width: 70%;
}

.profile-small {
  min-height: 220px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-card-stat-container {
  display: grid;
  grid-auto-flow: column;
  -webkit-column-gap: 2.5rem;
          column-gap: 2.5rem;
  padding-top: 1.25rem;
  grid-template-rows: auto auto;
  justify-content: start;
}

.discover-card-stat-title,
.discover-card-stat-details {
  font-size: 12px;
  line-height: 16px;
}

.discover-card-stat-title {
  color: #052449;
}

.discover-card-stat-details {
  font-weight: 600;
  color: #3d4858;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-chart-table .ant-table-row-cell-break-word {
  font-size: 12px;
  padding: 10px;
}

.ChartLabel {
  font-size: 16px;
  color: #4d4f56;
  height: 28px;
  left: 43px;
  top: 134px;
  padding-bottom: 10px;
}

.cc-table-row > td {
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.cc-table-th .ant-table-column-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7f848a;
  text-transform: uppercase;
}

.cc-table-th .ant-table-tbody > tr > td {
  padding: 12px 2px;
}

.cc-table-th .ant-table-thead > tr > th {
  padding: 12px 2px;
}

.discover-fund-chart {
  overflow-x: auto;
  flex-shrink: 0;
}

.discover-fund-chart-container {
  position: relative;
}

.discover-fund-chart-tooltip {
  background-color: white;
  box-shadow: #aeaeae 0 0 10px;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.9;
  overflow: hidden;
  padding: 8px 12px;
  pointer-events: none;
  position: absolute;
}

.discover-fund-chart-tooltip-title {
  text-align: center;
  margin-bottom: 4px;
}

.discover-fund-chart-tooltip-legend {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin-right: 8px;
}

.discover-fund-chart-tooltip-value {
  text-align: right;
  padding-left: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ContextSignalContainer-icon {
  margin-left: 6px;
  margin-right: 6px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.matchmaking-score .ant-progress-text {
  color: #f19f00;
  font-size: 10px;
}

.matchmaking-score--match .ant-progress-text {
  color: #000000;
}

/* purgecss end ignore */

/* purgecss start ignore */

.IlliquidChart {
  border: 1px solid #f2f3f3;
  padding: 14px;
}

.IlliquidChart-tooltip {
  text-transform: uppercase;
  text-align: center;
  padding-top: 8px;
}

.IlliquidChart-tooltip-bullet {
  margin-top: 16px;
  margin-left: 0;
  padding-left: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.DiscoverIlliquidCharts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-fund {
  margin-bottom: 20px;
  color: #3d4858;
}

.discover-fund-result {
  position: relative;
  text-align: left;
}

.discover-fund-result.discover-fund-result--hidden {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity));
  opacity: 0.7;
}

.meeting-status-badge .ant-badge-status-dot {
  margin-left: 0.25rem;
  height: 0.75rem;
  width: 0.75rem;
}

.discover-fund-favicon {
  border-style: none;
  padding-top: 0px;
  padding-bottom: 0px;
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.discover-fund-favicon, .discover-fund-favicon:hover, .discover-fund-favicon:focus {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(241, 159, 0, var(--tw-text-opacity));
}

.discover-fund-result-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discover-fund-result-info > * {
  max-width: 50%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ChartLabel {
  font-size: 16px;
  color: #4d4f56;
  height: 28px;
  left: 43px;
  top: 134px;
  padding-bottom: 10px;
  text-align: center;
}

.cc-chart-table .ant-table-row-cell-break-word {
  font-size: 12px;
  padding: 10px;
}

.cc-table-row > td {
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.cc-table-th .ant-table-column-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7f848a;
  text-transform: uppercase;
}

.cc-table-th .ant-table-tbody > tr > td {
  padding: 8px;
  font-size: 12px;
}

.cc-table-th .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #f9f9f9;
}

.cc-table-th .ant-table-tbody > tr > td:nth-child(1) {
  color: #7a7a7d;
  padding: 8px;
  font-weight: 800;
  font-size: 14px;
}

.cc-table-th .ant-table-thead > tr > th {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
  padding: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.charts-container {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  overflow-x: auto;
  padding-left: 24px;
  padding-right: 24px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fund-details-managers {
  max-height: 580px;
  overflow-y: auto;
  padding-bottom: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fund-details-profile-container {
  padding-top: 24px;
}

.fund-details-profile-section {
  padding-top: 8px;
}

.fund-details-section-heading {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #c2c6cc;
}

.fund-details-section-content {
  padding-top: 8px;
}

.fundAUMandPerformance {
  display: flex;
  margin-top: 32px;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.fundAUMandPerformance-numbers {
  min-width: 100px;
}

.fundAUMandPerformance-numbers-container {
  width: 50%;
  min-width: 320px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-fund-details-profile-container {
  padding-top: 0px;
}

.discover-fund-details-profile-section {
  padding-top: 8px;
}

.discover-fund-details-profile-divider {
  margin: 16px 0px;
}

.discover-fund-details-profile-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f7f8fa;
  padding: 24px 16px 24px 24px;
  border-radius: 8px;
}

.fund-details-section-heading {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #c2c6cc;
}

.fund-details-section-content {
  padding: 8px 0px;
}

.fundAUMandPerformance {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.fundAUMandPerformance-numbers {
  min-width: 100px;
}

.fundAUMandPerformance-numbers-container {
  width: 45%;
  min-width: 320px;
}

.custom-col {
  flex-wrap: unset;
  padding: 0;
  margin-right: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.profile-details-values-container {
  vertical-align: top;
  display: inline-block;
  width: 100%;
}

.profile-details-details-container {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
  width: 50%;
}

.profile-details-details-container-full {
  width: 100%;
}

.profile-details-details-section {
  display: inline-block;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 16px;
}

.profile-details-details-section-heading {
  border-bottom: solid 1px #e2e8ee;
  padding-bottom: 8px;
}

.profile-details-details-data-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.profile-details-detail-column {
  justify-self: left;
}

.profile-details-detail {
  padding-top: 16px;
  padding-bottom: 8px;
  padding-right: 16px;
}

.profile-details-detail-content {
  display: flex;
  justify-content: left;
}

.profile-details-detail-piece {
  justify-self: left;
  flex: 3 1;
}

.profile-details-detail-piece-value {
  color: #2d3239;
  font-weight: bolder;
  font-family: Helvetica;
}

.profile-details-detail-piece-footer {
  color: #92949c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.profile-details-detail-type-Bold,
.profile-details-detail-type-BoldCurrency,
.profile-details-detail-type-BoldNumeric,
.profile-details-detail-type-BoldDateTime {
  font-weight: bold;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fund-details-values-container {
  vertical-align: top;
  display: inline-block;
  width: 100%;
}

.fund-details-details-container {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
}

.fund-details-details-section {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}

.fund-details-details-section-heading {
  border-bottom: solid 1px #e2e8ee;
  padding-bottom: 8px;
}

.fund-details-details-data-section {
  display: flex;
  width: 100%;
}

.fund-details-detail-column {
  justify-self: left;
}

.fund-details-detail {
  padding-top: 16px;
  padding-bottom: 8px;
  padding-right: 16px;
}

.fund-details-detail-content {
  display: flex;
  justify-content: left;
}

.fund-details-detail-piece {
  justify-self: left;
  flex: 3 1;
}

.fund-details-detail-piece-value {
  color: #2d3239;
  font-weight: bolder;
  font-family: Helvetica;
}

.fund-details-detail-piece-footer {
  color: #92949c;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.fund-details-detail-type-Bold,
.fund-details-detail-type-BoldCurrency,
.fund-details-detail-type-BoldNumeric,
.fund-details-detail-type-BoldDateTime {
  font-weight: bold;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fund-image {
  border-radius: 8px;
  cursor: pointer;
  height: 100% !important;
  object-fit: cover;
  width: 100% !important;
}

.fund-image-col {
  padding: 8px;
}

.fund-image-col > .ant-image {
  width: 100%;
  height: 100%;
}

.media-row {
  margin: 20px 0px;
}

.FundMedia-link {
  font-weight: 600;
  font-size: 24px;
  color: #052449;
  margin-bottom: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ContactShortProfile {
  display: flex;
  align-items: center;
}

.ContactShortProfile .ContactShortProfile-picture {
  flex: 0 0 auto;
  margin-right: 8px;
}

.ContactShortProfile .ContactShortProfile-info {
  flex: 1 0 auto;
}

.ContactShortProfile .ContactShortProfile-info-name {
  font-size: 16px;
  line-height: 28px;
  font-weight: normal;
  color: #4d4f56;
  margin-bottom: 2px;
}

.ContactShortProfile .ContactShortProfile-info-position {
  font-size: 12px;
  line-height: 16px;
  color: #92949c;
}

.ContactShortProfile-small .ContactShortProfile-info-name {
  font-size: 12px;
  line-height: 16px;
  color: #1b1a1f;
}

.ContactShortProfile-small .ContactShortProfile-info-position {
  font-size: 10px;
}

.ContactShortProfile-bio {
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  width: 100%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.IlliquidFirm-header {
  font-weight: 600;
  font-size: 14px;
  color: #4d4f56;
  margin-bottom: 10px;
  margin-top: 10px;
}

.IlliquidFirm-stat {
  font-size: 24px;
  font-weight: 600;
}

.IlliquidFirm-stat-header {
  color: #92949c;
  font-size: 12px;
}

.IlliquidFirm .ContactWrapper {
  border: 1px solid #f2f3f3;
  padding: 24px;
  margin-bottom: 12px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.IlliquidOverview .ant-tabs-nav {
  padding-left: 0px;
  border-bottom: none;
}

.IlliquidOverview .ant-tabs-tab {
  padding-left: 0px;
}

.IlliquidOverview-header {
  font-weight: 600;
  font-size: 14px;
  color: #4d4f56;
  margin-bottom: 10px;
}

.IlliquidOverview-stat {
  font-size: 24px;
  font-weight: 600;
}

.IlliquidOverview-stat-header {
  color: #92949c;
  font-size: 12px;
}

.IlliquidOverview-property-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f2f3f3;
  border-bottom: none;
  padding: 12px 24px;
  font-size: 13px;
  color: #5e656c;
}

.IlliquidOverview-property-row:nth-child(even) {
  background-color: #fdfdfd;
}

.IlliquidOverview-property-row:last-child {
  border-bottom: 1px solid #f2f3f3;
  margin-bottom: 12px;
}

.IlliquidOverview-property-value {
  font-weight: 600;
}

.IlliquidOverview-ChartsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 18px;
}

.IlliquidOverview-Chart {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}

.Positions-table {
  width: 100%;
  border-collapse: collapse;
}

.Positions-table td {
  padding: 8px;
}

.Positions-table tr:nth-child(even) {
  background-color: #fdfdfd;
}

.Positions-table tr {
  border: 1px solid #f2f3f3;
}

.Positions-table th {
  background-color: #f7f8fa;
  color: #5e656c;
  font-size: 13px;
  font-weight: 600;
  padding: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Tab {
  width: 100%;
}

.Tab > .ant-tabs-content-holder {
  width: 100%;
  padding: 0px 20px;
}

.Tab > .ant-tabs-nav {
  background-color: #f7f8fa;
}

/* purgecss end ignore */

/* purgecss start ignore */

.action-menu {
  background: #ffffff;
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04) !important;
  border-radius: 8px !important;
  padding: 0px !important;
}

.action-menu-item {
  padding: 12px 12px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.action-menu-item-disabled {
  background: #f7f8fa;
}

.action-menu-item-disabled > span > .action-menu-text {
  color: rgba(0, 0, 0, 0.25);
}

.action-menu-item-disabled:hover {
  background: #f7f8fa;
}

.action-menu-icon {
  display: inline-block;
  margin-left: 9px;
  margin-right: 9px;
  width: 20px;
  height: 16px;
  line-height: 22px !important;
  text-align: center;
}

.action-menu-icon > svg {
  font-size: 16px;
}

.action-menu-text {
  display: inline-block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #2d3039;
  line-height: 22px !important;
  margin-right: 16px;
}

.action-menu-divider {
  margin: 0px 8px 0px 8px !important;
}

.table-action-button {
  border: none;
  background: #eff1f4;
  min-width: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px;
  box-shadow: none;
}

.table-action-button span {
  width: 32px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Profile-Picture {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  object-fit: cover;
}

.Profile-chapter-label {
  margin-top: 4px;
  background-color: rgba(187, 47, 210, 0.1);
  border-radius: 100px;
  color: #bb2fd2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 16px;
}

.Profile-title {
  padding: 30px 30px 15px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f7f8fa;
}

.Profile-container {
  padding: 24px 46px 16px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f7f8fa;
}

.Profile-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Profile-buttons-container .ant-btn-background-ghost {
  border-style: none;
  background-color: rgba(47, 197, 210, 0.1) !important;
  margin-right: 20px;
}

.Profile-buttons-container .ant-btn-background-ghost > svg {
  margin-right: 5px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AvatarGroup-badge {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvatarGroup-badge-avatar {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.AvatarGroup-badge-green {
  background-color: #24c477;
}

.AvatarGroup-badge-pending {
  background-color: #f19f00;
}

.AvatarGroup-badge-red {
  background-color: #d62b34;
}

.AvatarGroup-badge-expired {
  background-color: #92949c;
}

.AvatarGroup {
  display: flex;
  justify-content: flex-end;
}

.AvatarGroup .AvatarGroup-avatar {
  border: 2px solid #ffffff;
}

.AvatarGroup .AvatarGroup-avatar-plus {
  font-size: 12px;
  font-weight: bolder;
}

.AvatarGroup-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.AvatarGroup-popover .ant-popover-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.AvatarGroup-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.AvatarGroup-popover-no-image .ant-popover-inner-content {
  padding: 0px;
}

.AvatarGroup-popover-no-image .ant-popover-title {
  border-bottom: none;
  color: #ffffff;
}

.AvatarGroup-dropdown-popover-no-image.ant-popover {
  display: none;
  z-index: 2000;
}

.AvatarGroup-dropdown-popover.ant-popover {
  z-index: 2000;
}

/* purgecss end ignore */

/* purgecss start ignore */

.NoData {
  text-align: center;
  margin-bottom: 12px;
}

.NoData > .NoData-icon {
  color: #c2c6cc;
  margin-bottom: 8px;
}

.NoData > .NoData-title {
  color: #24262b;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.NoData > .NoData-message {
  color: #c2c6cc;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

.NoData-action > .ant-btn {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  padding: 8px 24px;
  height: 40px;
}

.NoData-action-label {
  display: inline-block;
  margin-left: 6px;
}

/* purgecss end ignore */

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable */

/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}

[class^=ant-],
[class*= ant-],
[class^=ant-] *,
[class*= ant-] *,
[class^=ant-] *::before,
[class*= ant-] *::before,
[class^=ant-] *::after,
[class*= ant-] *::after {
  box-sizing: border-box;
}

/* stylelint-disable at-rule-no-unknown */

html,
body {
  width: 100%;
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

[tabindex='-1']:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #052449;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #143457;
}

a:active {
  color: #010f24;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::selection {
  color: #fff;
  background: #052449;
}

.clearfix::before {
  display: table;
  content: '';
}

.clearfix::after {
  display: table;
  clear: both;
  content: '';
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anticon > * {
  line-height: 1;
}

.anticon svg {
  display: inline-block;
}

.anticon::before {
  display: none;
}

.anticon .anticon-icon {
  display: block;
}

.anticon[tabindex] {
  cursor: pointer;
}

.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.ant-fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ant-move-up-enter,
.ant-move-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
  animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
  animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.ant-move-down-enter,
.ant-move-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
  animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-move-down-leave.ant-move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
  animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.ant-move-left-enter,
.ant-move-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
  animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-move-left-leave.ant-move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
  animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.ant-move-right-enter,
.ant-move-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
  animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-move-right-leave.ant-move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
  animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}

html {
  --antd-wave-shadow-color: #052449;
  --scroll-bar: 0;
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #052449;
  box-shadow: 0 0 0 0 #052449;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}

@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #052449;
    box-shadow: 0 0 0 6px #052449;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #052449;
    box-shadow: 0 0 0 6px #052449;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}

.ant-slide-up-enter,
.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-down-enter,
.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-left-enter,
.ant-slide-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-left-leave.ant-slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-right-enter,
.ant-slide-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-slide-right-leave.ant-slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.ant-slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.ant-zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}

@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-affix {
  position: fixed;
  z-index: 10;
}

.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}

.ant-alert-content {
  flex: 1 1;
  min-width: 0;
}

.ant-alert-icon {
  margin-right: 8px;
}

.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}

.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}

.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}

.ant-alert-info {
  background-color: #f1f4f9;
  border: 1px solid #3e5970;
}

.ant-alert-info .ant-alert-icon {
  color: #052449;
}

.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}

.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}

.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}

.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}

.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}

.ant-alert-action {
  margin-left: 8px;
}

.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}

.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}

.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}

.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}

.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}

.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}

.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}

.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}

.ant-alert-with-description .ant-alert-description {
  display: block;
}

.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}

.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}

.ant-alert.ant-alert-rtl {
  direction: rtl;
}

.ant-alert-rtl.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}

.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}

.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}

.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}

.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}

.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}

.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}

.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}

.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #052449;
  border-radius: 8px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}

.ant-anchor-ink-ball.visible {
  display: inline-block;
}

.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}

.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}

.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}

.ant-anchor-link-active > .ant-anchor-link-title {
  color: #052449;
}

.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-anchor-rtl {
  direction: rtl;
}

.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}

.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}

.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.ant-anchor-rtl .ant-anchor-link {
  padding: 7px 16px 7px 0;
}

.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}

.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}

.ant-avatar-image {
  background: transparent;
}

.ant-avatar .ant-image-img {
  display: block;
}

.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center;
}

.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}

.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center;
}

.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}

.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}

.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
  transform-origin: 0 center;
}

.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}

.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}

.ant-avatar-square {
  border-radius: 2px;
}

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ant-avatar-group {
  display: inline-flex;
}

.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}

.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}

.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.ant-back-top:empty {
  display: none;
}

.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}

.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}

.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}

.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}

@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}

@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}

.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}

.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}

.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}

.ant-badge-multiple-words {
  padding: 0 8px;
}

.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
}

.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}

.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}

.ant-badge-status-success {
  background-color: #52c41a;
}

.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}

.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}

.ant-badge-status-default {
  background-color: #d9d9d9;
}

.ant-badge-status-error {
  background-color: #ff4d4f;
}

.ant-badge-status-warning {
  background-color: #faad14;
}

.ant-badge-status-pink {
  background: #eb2f96;
}

.ant-badge-status-magenta {
  background: #eb2f96;
}

.ant-badge-status-red {
  background: #f5222d;
}

.ant-badge-status-volcano {
  background: #fa541c;
}

.ant-badge-status-orange {
  background: #fa8c16;
}

.ant-badge-status-yellow {
  background: #fadb14;
}

.ant-badge-status-gold {
  background: #faad14;
}

.ant-badge-status-cyan {
  background: #13c2c2;
}

.ant-badge-status-lime {
  background: #a0d911;
}

.ant-badge-status-green {
  background: #52c41a;
}

.ant-badge-status-blue {
  background: #1890ff;
}

.ant-badge-status-geekblue {
  background: #2f54eb;
}

.ant-badge-status-purple {
  background: #722ed1;
}

.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  -webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  -webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
  -webkit-transform: none;
  transform: none;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
  transform: none;
}

@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}

@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}

.ant-scroll-number {
  overflow: hidden;
}

.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.ant-scroll-number-symbol {
  vertical-align: top;
}

@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }
}

@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }
}

@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }

  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%);
  }

  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}

@-webkit-keyframes antNoWrapperZoomBadgeIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes antNoWrapperZoomBadgeIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes antNoWrapperZoomBadgeOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes antNoWrapperZoomBadgeOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

.ant-ribbon-wrapper {
  position: relative;
}

.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #052449;
  border-radius: 2px;
}

.ant-ribbon-text {
  color: #fff;
}

.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  -webkit-transform: scaleY(0.75);
  transform: scaleY(0.75);
  -webkit-transform-origin: top;
  transform-origin: top;
}

.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}

.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}

.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}

.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}

.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}

.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}

.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}

.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}

.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}

.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}

.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}

.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}

.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}

.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}

.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}

.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}

.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}

.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}

.ant-badge-rtl {
  direction: rtl;
}

.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}

.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}

.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
  -webkit-animation-name: antZoomBadgeInRtl;
  animation-name: antZoomBadgeInRtl;
}

.ant-badge-rtl .ant-badge-zoom-leave {
  -webkit-animation-name: antZoomBadgeOutRtl;
  animation-name: antZoomBadgeOutRtl;
}

.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
  transform: none;
}

.ant-ribbon-rtl {
  direction: rtl;
}

.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}

.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}

@-webkit-keyframes antZoomBadgeInRtl {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes antZoomBadgeInRtl {
  0% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
  }
}

@-webkit-keyframes antZoomBadgeOutRtl {
  0% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
  }

  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes antZoomBadgeOutRtl {
  0% {
    -webkit-transform: scale(1) translate(-50%, -50%);
    transform: scale(1) translate(-50%, -50%);
  }

  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}

.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-breadcrumb .anticon {
  font-size: 14px;
}

.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}

.ant-breadcrumb a:hover {
  color: #143457;
}

.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.85);
}

.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}

.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}

.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}

.ant-breadcrumb-rtl {
  direction: rtl;
}

.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}

.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}

.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}

.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}

.ant-breadcrumb-rtl > span {
  float: right;
}

.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}

.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
}

.ant-btn > .anticon {
  line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}

.ant-btn:not([disabled]):hover {
  text-decoration: none;
}

.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}

.ant-btn[disabled] {
  cursor: not-allowed;
}

.ant-btn[disabled] > * {
  pointer-events: none;
}

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}

.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}

.ant-btn > a:only-child {
  color: currentColor;
}

.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:hover,
.ant-btn:focus {
  color: #143457;
  background: #fff;
  border-color: #143457;
}

.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}

.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:active {
  color: #010f24;
  background: #fff;
  border-color: #010f24;
}

.ant-btn:active > a:only-child {
  color: currentColor;
}

.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}

.ant-btn > span {
  display: inline-block;
}

.ant-btn-primary {
  color: #fff;
  background: #052449;
  border-color: #052449;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #143457;
  border-color: #143457;
}

.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary:active {
  color: #fff;
  background: #010f24;
  border-color: #010f24;
}

.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #143457;
  border-left-color: #143457;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #143457;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #143457;
}

.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}

.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: #d9d9d9;
}

.ant-btn-ghost > a:only-child {
  color: currentColor;
}

.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #143457;
  background: transparent;
  border-color: #143457;
}

.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost:active {
  color: #010f24;
  background: transparent;
  border-color: #010f24;
}

.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}

.ant-btn-dashed > a:only-child {
  color: currentColor;
}

.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #143457;
  background: #fff;
  border-color: #143457;
}

.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed:active {
  color: #010f24;
  background: #fff;
  border-color: #010f24;
}

.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link {
  color: #052449;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #143457;
  background: transparent;
  border-color: #143457;
}

.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:active {
  color: #010f24;
  background: transparent;
  border-color: #010f24;
}

.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-link:hover {
  background: transparent;
}

.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #143457;
  background: transparent;
  border-color: #143457;
}

.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:active {
  color: #010f24;
  background: transparent;
  border-color: #010f24;
}

.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}

.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}

.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  background: #fff;
  border-color: #ff7875;
}

.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous:active {
  color: #d9363e;
  background: #fff;
  border-color: #d9363e;
}

.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #ff7875;
  border-color: #ff7875;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #d9363e;
  border-color: #d9363e;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #143457;
  background: transparent;
  border-color: #143457;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #010f24;
  background: transparent;
  border-color: #010f24;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #143457;
  background: transparent;
  border-color: #143457;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #010f24;
  background: transparent;
  border-color: #010f24;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -1px;
}

.ant-btn-icon-only > * {
  font-size: 16px;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}

.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}

.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}

.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}

.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}

.ant-btn-round.ant-btn-icon-only {
  width: auto;
}

.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}

.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}

.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}

.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}

.ant-btn.ant-btn-loading {
  position: relative;
}

.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}

.ant-btn.ant-btn-loading::before {
  display: block;
}

.ant-btn > .ant-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  -webkit-animation: none;
  animation: none;
}

.ant-btn > .ant-btn-loading-icon .anticon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
}

.ant-btn-group {
  position: relative;
  display: inline-flex;
}

.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}

.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}

.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}

.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0;
}

.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 0;
}

.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}

.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}

.ant-btn-group .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}

.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}

.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}

.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-group > .ant-btn-group {
  float: left;
}

.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}

.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}

.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}

.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}

.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #052449;
  background: transparent;
  border-color: #052449;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #143457;
  background: transparent;
  border-color: #143457;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary:active {
  color: #010f24;
  background: transparent;
  border-color: #010f24;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  background: transparent;
  border-color: #ff7875;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  background: transparent;
  border-color: #d9363e;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  background: transparent;
  border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  background: transparent;
  border-color: transparent;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}

.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}

.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}

.ant-btn-block {
  width: 100%;
}

.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}

a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}

a.ant-btn-lg {
  line-height: 38px;
}

a.ant-btn-sm {
  line-height: 22px;
}

.ant-btn-rtl {
  direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #143457;
  border-left-color: #d9d9d9;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #143457;
}

.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}

.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
}

.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}

.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}

.ant-picker-calendar-mini {
  border-radius: 2px;
}

.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}

.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}

.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}

.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #7c848a;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #052449;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #052449;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}

@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }

  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }

  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}

.ant-picker-calendar-rtl {
  direction: rtl;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}

.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}

.ant-card-rtl {
  direction: rtl;
}

.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.ant-card-bordered {
  border: 1px solid #f0f0f0;
}

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-card-head::before {
  display: table;
  content: '';
}

.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-head::before {
  display: table;
  content: '';
}

.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}

.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #f0f0f0;
}

.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}

.ant-card-body {
  padding: 24px;
}

.ant-card-body::before {
  display: table;
  content: '';
}

.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-body::before {
  display: table;
  content: '';
}

.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}

.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}

.ant-card-rtl .ant-card-grid {
  float: right;
}

.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}

.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}

.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}

.ant-card-cover > * {
  display: block;
  width: 100%;
}

.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}

.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

.ant-card-actions::before {
  display: table;
  content: '';
}

.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-actions::before {
  display: table;
  content: '';
}

.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}

.ant-card-rtl .ant-card-actions > li {
  float: right;
}

.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}

.ant-card-actions > li > span:hover {
  color: #052449;
  transition: color 0.3s;
}

.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}

.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #052449;
}

.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}

.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}

.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}

.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}

.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}

.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}

.ant-card-meta {
  margin: -4px 0;
}

.ant-card-meta::before {
  display: table;
  content: '';
}

.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-meta::before {
  display: table;
  content: '';
}

.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}

.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}

.ant-card-rtl .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}

.ant-card-meta-detail {
  overflow: hidden;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-card-loading {
  overflow: hidden;
}

.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-card-loading-content p {
  margin: 0;
}

.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  -webkit-animation: card-loading 1.4s ease infinite;
  animation: card-loading 1.4s ease infinite;
}

@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}

.ant-card-small > .ant-card-body {
  padding: 12px;
}

.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ant-carousel .slick-list:focus {
  outline: none;
}

.ant-carousel .slick-list.dragging {
  cursor: pointer;
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}

.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}

.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}

.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}

.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}

.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}

.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}

.ant-carousel .slick-track::after {
  clear: both;
}

.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}

.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.ant-carousel .slick-slide img {
  display: block;
}

.ant-carousel .slick-slide.slick-loading img {
  display: none;
}

.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}

.ant-carousel .slick-initialized .slick-slide {
  display: block;
}

.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}

.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}

.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}

.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}

.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}

.ant-carousel .slick-prev {
  left: -25px;
}

.ant-carousel .slick-prev::before {
  content: '←';
}

.ant-carousel .slick-next {
  right: -25px;
}

.ant-carousel .slick-next::before {
  content: '→';
}

.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}

.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}

.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}

.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}

.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}

.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}

.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}

.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}

.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}

.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}

.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}

.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}

.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}

.ant-carousel-rtl {
  direction: rtl;
}

.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}

.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}

.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}

.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}

.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}

.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}

.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}

.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}

.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}

.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}

.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-cascader-picker-borderless .ant-cascader-input {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}

.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
}

.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}

.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}

.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ant-cascader-menu:first-child {
  border-radius: 2px 0 0 2px;
}

.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 2px 2px 0;
}

.ant-cascader-menu:only-child {
  border-radius: 2px;
}

.ant-cascader-menu-item {
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}

.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}

.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #7c848a;
}

.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}

.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}

.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right;
}

.ant-cascader-picker-rtl {
  direction: rtl;
}

.ant-cascader-picker-rtl .ant-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right;
}

.ant-cascader-picker-rtl .ant-cascader-picker-clear {
  right: auto;
  left: 12px;
}

.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
  right: auto;
  left: 12px;
}

.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: auto;
  left: 8px;
}

.ant-cascader-menu-rtl .ant-cascader-menu {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #f0f0f0;
}

.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
  border-radius: 0 2px 2px 0;
}

.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 2px 0 0 2px;
}

.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
  border-radius: 2px;
}

.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px;
}

.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px;
}

.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #052449;
}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #052449;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: '';
}

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}

.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #052449;
  border-color: #052449;
}

.ant-checkbox-disabled {
  cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none;
}

.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}

.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}

.ant-checkbox-group-item {
  margin-right: 8px;
}

.ant-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #052449;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-rtl {
  direction: rtl;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}

.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: '';
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: '';
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}

.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}

.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}

.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}

.ant-collapse-content-hidden {
  display: none;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}

.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}

.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}

.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-collapse-rtl {
  direction: rtl;
}

.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: left;
}

.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}

.ant-comment {
  position: relative;
  background-color: inherit;
}

.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}

.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}

.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}

.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}

.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}

.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}

.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}

.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}

.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}

.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}

.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}

.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-comment-actions > li > span:hover {
  color: #595959;
}

.ant-comment-nested {
  margin-left: 44px;
}

.ant-comment-rtl {
  direction: rtl;
}

.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}

.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}

.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}

.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}

.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-picker-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}

.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}

.ant-picker-input > input::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-picker-input > input:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-picker-input > input::placeholder {
  color: #bfbfbf;
}

.ant-picker-input > input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-picker-input > input:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-picker-input > input-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-picker-input > input[disabled]:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-picker-input > input-sm {
  padding: 0px 7px;
}

.ant-picker-input > input-rtl {
  direction: rtl;
}

.ant-picker-input > input:focus {
  box-shadow: none;
}

.ant-picker-input > input[disabled] {
  background: transparent;
}

.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}

.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}

.ant-picker-small {
  padding: 0px 7px 0px;
}

.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}

.ant-picker-suffix > * {
  vertical-align: top;
}

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}

.ant-picker-clear > * {
  vertical-align: top;
}

.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}

.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}

.ant-picker-range {
  position: relative;
  display: inline-flex;
}

.ant-picker-range .ant-picker-clear {
  right: 11px;
}

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}

.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #052449;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}

.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}

.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}

.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
}

.ant-picker-dropdown-hidden {
  display: none;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-picker-dropdown-range {
  padding: 6.66666667px 0;
}

.ant-picker-dropdown-range-hidden {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}

.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}

.ant-picker-ranges > li {
  display: inline-block;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #052449;
  background: #7c848a;
  border-color: #3e5970;
  cursor: pointer;
}

.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}

.ant-picker-range-wrapper {
  display: flex;
}

.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out;
}

.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: '';
}

.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}

.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}

.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}

.ant-picker-panel-focused {
  border-color: #052449;
}

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}

.ant-picker-header > * {
  flex: none;
}

.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}

.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}

.ant-picker-header-view button:hover {
  color: #052449;
}

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}

.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ant-picker-next-icon,
.ant-picker-super-next-icon {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}

.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}

.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}

.ant-picker-cell-disabled {
  cursor: not-allowed;
}

.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #052449;
  border-radius: 2px;
  content: '';
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #7c848a;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #052449;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #7c848a;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #0c53a8;
  border-bottom: 1px dashed #0c53a8;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #1276ef;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #1276ef;
  content: '';
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #0c53a8;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #0c53a8;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-picker-cell-disabled {
  pointer-events: none;
}

.ant-picker-cell-disabled .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
}

.ant-picker-cell-disabled::before {
  background: #f5f5f5;
}

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}

.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}

.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
  background: #f5f5f5;
}

.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}

.ant-picker-footer {
  width: -webkit-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.ant-picker-now {
  text-align: left;
}

.ant-picker-today-btn {
  color: #052449;
}

.ant-picker-today-btn:hover {
  color: #143457;
}

.ant-picker-today-btn:active {
  color: #010f24;
}

.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}

.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}

.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}

.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #0c53a8;
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #0c53a8;
  border-radius: 0 2px 2px 0;
}

.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #0c53a8;
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #0c53a8;
  border-radius: 2px 0 0 2px;
}

.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}

.ant-picker-week-panel-row td {
  transition: background 0.3s;
}

.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #052449;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}

.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}

.ant-picker-datetime-panel {
  display: flex;
}

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}

.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}

.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}

.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}

.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}

.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}

.ant-picker-time-panel-column-active {
  background: rgba(124, 132, 138, 0.2);
}

.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #7c848a;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}

/* stylelint-disable-next-line */

_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}

.ant-picker-rtl {
  direction: rtl;
}

.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}

.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}

.ant-picker-rtl .ant-picker-separator {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}

.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}

.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}

.ant-picker-panel-rtl {
  direction: rtl;
}

.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #0c53a8;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #0c53a8;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #0c53a8;
  border-left: 1px dashed #0c53a8;
  border-radius: 2px;
}

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}

.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}

.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
}

.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}

.ant-descriptions-row:last-child {
  border-bottom: none;
}

.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}

.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}

.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}

.ant-descriptions-item-content {
  display: table-cell;
  flex: 1 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}

.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}

.ant-descriptions-item-container {
  display: flex;
}

.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}

.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}

.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}

.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}

.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}

.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}

.ant-descriptions-rtl {
  direction: rtl;
}

.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}

.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}

.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}

.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}

.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}

.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}

.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}

.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}

.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.ant-divider-rtl {
  direction: rtl;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}

.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer > * {
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}

.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-left {
  left: 0;
}

.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-right {
  right: 0;
}

.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
  transform: translateX(1px);
}

.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}

.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}

.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-top {
  top: 0;
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-bottom {
  bottom: 0;
}

.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}

.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}

.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: 0;
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - 0);
  padding-right: calc(20px - var(--scroll-bar));
}

.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}

.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}

.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-drawer .ant-picker-clear {
  background: #fff;
}

@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}

.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}

.ant-dropdown-wrap {
  position: relative;
}

.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}

.ant-dropdown-wrap .anticon-down::before {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}

.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}

.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}

.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}

.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}

.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #052449;
  background-color: #7c848a;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled > .anticon + span > a,
.ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
.ant-dropdown-menu-item-disabled > a,
.ant-dropdown-menu-submenu-title-disabled > a {
  position: relative;
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.ant-dropdown-menu-item-disabled > .anticon + span > a::after,
.ant-dropdown-menu-submenu-title-disabled > .anticon + span > a::after,
.ant-dropdown-menu-item-disabled > a::after,
.ant-dropdown-menu-submenu-title-disabled > a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  content: '';
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}

.ant-dropdown-menu-submenu-vertical {
  position: relative;
}

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #052449;
}

.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}

.ant-dropdown-button {
  white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #000000;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #052449;
}

.ant-dropdown-rtl {
  direction: rtl;
}

.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}

.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}

.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}

.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}

.ant-empty-image img {
  height: 100%;
}

.ant-empty-image svg {
  height: 100%;
  margin: auto;
}

.ant-empty-footer {
  margin-top: 16px;
}

.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}

.ant-empty-normal .ant-empty-image {
  height: 40px;
}

.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}

.ant-empty-small .ant-empty-image {
  height: 35px;
}

.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}

.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}

.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}

.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}

.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}

.ant-empty-img-default-g {
  fill: #fff;
}

.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}

.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
  fill: #fafafa;
}

.ant-empty-rtl {
  direction: rtl;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: auto;
}

.ant-form-item .ant-upload {
  background: transparent;
}

.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}

.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}

.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}

.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0;
}

.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block;
}

.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}

.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}

.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
}

.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100%;
}

.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
  width: auto;
}

.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}

.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}

.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}

.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}

.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}

.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
}

.ant-form-vertical .ant-form-item {
  flex-direction: column;
}

.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: normal;
  white-space: initial;
  text-align: left;
}

.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}

.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}

.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }

  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: normal;
    white-space: initial;
    text-align: left;
  }

  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}

.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item-explain.ant-form-item-explain-warning {
  color: #faad14;
}

.ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}

.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}

.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}

.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: 32px;
}

.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}

.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}

.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
}

.ant-form-item-has-feedback .ant-picker {
  padding-right: 29.2px;
}

.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px;
}

.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
  animation-name: diffZoomIn1 !important;
}

.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}

.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  background-color: #ffffff;
  border-color: #faad14;
}

.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #cbcbcb;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #cbcbcb;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}

.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-prefix {
  color: #faad14;
}

.ant-form-item-has-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}

.ant-form-item-has-warning .has-feedback {
  color: #faad14;
}

.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #faad14;
  -webkit-animation-name: diffZoomIn3 !important;
  animation-name: diffZoomIn3 !important;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #ffffff;
  border-color: #faad14 !important;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: #ffffff;
  border-color: #faad14;
}

.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: #ffffff;
  border-color: #faad14;
}

.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffc53d;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
}

.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #ffffff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #cbcbcb;
}

.ant-form-item-has-error .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #cbcbcb;
}

.ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}

.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-prefix {
  color: #ff4d4f;
}

.ant-form-item-has-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .has-feedback {
  color: #ff4d4f;
}

.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff4d4f;
  -webkit-animation-name: diffZoomIn2 !important;
  animation-name: diffZoomIn2 !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #ffffff;
  border-color: #ff4d4f !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}

.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #ffffff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: #ffffff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  background-color: #ffffff;
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: #ffffff;
  border-color: #ff7875;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}

.ant-form-item-has-error .ant-transfer-list {
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #cbcbcb;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: #ff4d4f !important;
}

.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #ff4d4f;
}

.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #052449;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ant-form label {
  font-size: 14px;
}

.ant-form input[type='search'] {
  box-sizing: border-box;
}

.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}

.ant-form input[type='file'] {
  display: block;
}

.ant-form input[type='range'] {
  display: block;
  width: 100%;
}

.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}

.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}

.ant-form-small .ant-form-item-label > label {
  height: 24px;
}

.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}

.ant-form-large .ant-form-item-label > label {
  height: 40px;
}

.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}

.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}

.ant-form-item-label-left {
  text-align: left;
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  -webkit-margin-start: 4px;
  margin-inline-start: 4px;
}

.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}

.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}

.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-form-item .ant-input-textarea-show-count::after {
  margin-bottom: -22px;
}

.ant-show-help-enter,
.ant-show-help-appear {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-show-help-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-show-help-enter.ant-show-help-enter-active,
.ant-show-help-appear.ant-show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
  animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-show-help-leave.ant-show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
  animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-show-help-enter,
.ant-show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }
}

@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    opacity: 0;
  }
}

@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ant-form-rtl {
  direction: rtl;
}

.ant-form-rtl .ant-form-item-label {
  text-align: left;
}

.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}

.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}

.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}

.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}

.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}

.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
}

.ant-row::before,
.ant-row::after {
  display: flex;
}

.ant-row-no-wrap {
  flex-wrap: nowrap;
}

.ant-row-start {
  justify-content: flex-start;
}

.ant-row-center {
  justify-content: center;
}

.ant-row-end {
  justify-content: flex-end;
}

.ant-row-space-between {
  justify-content: space-between;
}

.ant-row-space-around {
  justify-content: space-around;
}

.ant-row-top {
  align-items: flex-start;
}

.ant-row-middle {
  align-items: center;
}

.ant-row-bottom {
  align-items: flex-end;
}

.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}

.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.ant-col-push-24 {
  left: 100%;
}

.ant-col-pull-24 {
  right: 100%;
}

.ant-col-offset-24 {
  margin-left: 100%;
}

.ant-col-order-24 {
  order: 24;
}

.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}

.ant-col-push-23 {
  left: 95.83333333%;
}

.ant-col-pull-23 {
  right: 95.83333333%;
}

.ant-col-offset-23 {
  margin-left: 95.83333333%;
}

.ant-col-order-23 {
  order: 23;
}

.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.ant-col-push-22 {
  left: 91.66666667%;
}

.ant-col-pull-22 {
  right: 91.66666667%;
}

.ant-col-offset-22 {
  margin-left: 91.66666667%;
}

.ant-col-order-22 {
  order: 22;
}

.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.ant-col-push-21 {
  left: 87.5%;
}

.ant-col-pull-21 {
  right: 87.5%;
}

.ant-col-offset-21 {
  margin-left: 87.5%;
}

.ant-col-order-21 {
  order: 21;
}

.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.ant-col-push-20 {
  left: 83.33333333%;
}

.ant-col-pull-20 {
  right: 83.33333333%;
}

.ant-col-offset-20 {
  margin-left: 83.33333333%;
}

.ant-col-order-20 {
  order: 20;
}

.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}

.ant-col-push-19 {
  left: 79.16666667%;
}

.ant-col-pull-19 {
  right: 79.16666667%;
}

.ant-col-offset-19 {
  margin-left: 79.16666667%;
}

.ant-col-order-19 {
  order: 19;
}

.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}

.ant-col-push-18 {
  left: 75%;
}

.ant-col-pull-18 {
  right: 75%;
}

.ant-col-offset-18 {
  margin-left: 75%;
}

.ant-col-order-18 {
  order: 18;
}

.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}

.ant-col-push-17 {
  left: 70.83333333%;
}

.ant-col-pull-17 {
  right: 70.83333333%;
}

.ant-col-offset-17 {
  margin-left: 70.83333333%;
}

.ant-col-order-17 {
  order: 17;
}

.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.ant-col-push-16 {
  left: 66.66666667%;
}

.ant-col-pull-16 {
  right: 66.66666667%;
}

.ant-col-offset-16 {
  margin-left: 66.66666667%;
}

.ant-col-order-16 {
  order: 16;
}

.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.ant-col-push-15 {
  left: 62.5%;
}

.ant-col-pull-15 {
  right: 62.5%;
}

.ant-col-offset-15 {
  margin-left: 62.5%;
}

.ant-col-order-15 {
  order: 15;
}

.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.ant-col-push-14 {
  left: 58.33333333%;
}

.ant-col-pull-14 {
  right: 58.33333333%;
}

.ant-col-offset-14 {
  margin-left: 58.33333333%;
}

.ant-col-order-14 {
  order: 14;
}

.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}

.ant-col-push-13 {
  left: 54.16666667%;
}

.ant-col-pull-13 {
  right: 54.16666667%;
}

.ant-col-offset-13 {
  margin-left: 54.16666667%;
}

.ant-col-order-13 {
  order: 13;
}

.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}

.ant-col-push-12 {
  left: 50%;
}

.ant-col-pull-12 {
  right: 50%;
}

.ant-col-offset-12 {
  margin-left: 50%;
}

.ant-col-order-12 {
  order: 12;
}

.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}

.ant-col-push-11 {
  left: 45.83333333%;
}

.ant-col-pull-11 {
  right: 45.83333333%;
}

.ant-col-offset-11 {
  margin-left: 45.83333333%;
}

.ant-col-order-11 {
  order: 11;
}

.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.ant-col-push-10 {
  left: 41.66666667%;
}

.ant-col-pull-10 {
  right: 41.66666667%;
}

.ant-col-offset-10 {
  margin-left: 41.66666667%;
}

.ant-col-order-10 {
  order: 10;
}

.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.ant-col-push-9 {
  left: 37.5%;
}

.ant-col-pull-9 {
  right: 37.5%;
}

.ant-col-offset-9 {
  margin-left: 37.5%;
}

.ant-col-order-9 {
  order: 9;
}

.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.ant-col-push-8 {
  left: 33.33333333%;
}

.ant-col-pull-8 {
  right: 33.33333333%;
}

.ant-col-offset-8 {
  margin-left: 33.33333333%;
}

.ant-col-order-8 {
  order: 8;
}

.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}

.ant-col-push-7 {
  left: 29.16666667%;
}

.ant-col-pull-7 {
  right: 29.16666667%;
}

.ant-col-offset-7 {
  margin-left: 29.16666667%;
}

.ant-col-order-7 {
  order: 7;
}

.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}

.ant-col-push-6 {
  left: 25%;
}

.ant-col-pull-6 {
  right: 25%;
}

.ant-col-offset-6 {
  margin-left: 25%;
}

.ant-col-order-6 {
  order: 6;
}

.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}

.ant-col-push-5 {
  left: 20.83333333%;
}

.ant-col-pull-5 {
  right: 20.83333333%;
}

.ant-col-offset-5 {
  margin-left: 20.83333333%;
}

.ant-col-order-5 {
  order: 5;
}

.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.ant-col-push-4 {
  left: 16.66666667%;
}

.ant-col-pull-4 {
  right: 16.66666667%;
}

.ant-col-offset-4 {
  margin-left: 16.66666667%;
}

.ant-col-order-4 {
  order: 4;
}

.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.ant-col-push-3 {
  left: 12.5%;
}

.ant-col-pull-3 {
  right: 12.5%;
}

.ant-col-offset-3 {
  margin-left: 12.5%;
}

.ant-col-order-3 {
  order: 3;
}

.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.ant-col-push-2 {
  left: 8.33333333%;
}

.ant-col-pull-2 {
  right: 8.33333333%;
}

.ant-col-offset-2 {
  margin-left: 8.33333333%;
}

.ant-col-order-2 {
  order: 2;
}

.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.ant-col-push-1 {
  left: 4.16666667%;
}

.ant-col-pull-1 {
  right: 4.16666667%;
}

.ant-col-offset-1 {
  margin-left: 4.16666667%;
}

.ant-col-order-1 {
  order: 1;
}

.ant-col-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-offset-0 {
  margin-left: 0;
}

.ant-col-order-0 {
  order: 0;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}

.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}

.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}

.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}

.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}

.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}

.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}

.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}

.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}

.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}

.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}

.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}

.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}

.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}

.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}

.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}

.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}

.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}

.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}

.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}

.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}

.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}

.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}

.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}

.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}

.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}

.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}

.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}

.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}

.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}

.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}

.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}

.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}

.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}

.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}

.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}

.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}

.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}

.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}

.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}

.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}

.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}

.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}

.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}

.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}

.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}

.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}

.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}

.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}

.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}

.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}

.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}

.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}

.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}

.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}

.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}

.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}

.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}

.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}

.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}

.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}

.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}

.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}

.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}

.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}

.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}

.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}

.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}

.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}

.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}

.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}

.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}

.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}

.ant-col-xs-push-24 {
  left: 100%;
}

.ant-col-xs-pull-24 {
  right: 100%;
}

.ant-col-xs-offset-24 {
  margin-left: 100%;
}

.ant-col-xs-order-24 {
  order: 24;
}

.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}

.ant-col-xs-push-23 {
  left: 95.83333333%;
}

.ant-col-xs-pull-23 {
  right: 95.83333333%;
}

.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}

.ant-col-xs-order-23 {
  order: 23;
}

.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.ant-col-xs-push-22 {
  left: 91.66666667%;
}

.ant-col-xs-pull-22 {
  right: 91.66666667%;
}

.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}

.ant-col-xs-order-22 {
  order: 22;
}

.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.ant-col-xs-push-21 {
  left: 87.5%;
}

.ant-col-xs-pull-21 {
  right: 87.5%;
}

.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}

.ant-col-xs-order-21 {
  order: 21;
}

.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.ant-col-xs-push-20 {
  left: 83.33333333%;
}

.ant-col-xs-pull-20 {
  right: 83.33333333%;
}

.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}

.ant-col-xs-order-20 {
  order: 20;
}

.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}

.ant-col-xs-push-19 {
  left: 79.16666667%;
}

.ant-col-xs-pull-19 {
  right: 79.16666667%;
}

.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}

.ant-col-xs-order-19 {
  order: 19;
}

.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}

.ant-col-xs-push-18 {
  left: 75%;
}

.ant-col-xs-pull-18 {
  right: 75%;
}

.ant-col-xs-offset-18 {
  margin-left: 75%;
}

.ant-col-xs-order-18 {
  order: 18;
}

.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}

.ant-col-xs-push-17 {
  left: 70.83333333%;
}

.ant-col-xs-pull-17 {
  right: 70.83333333%;
}

.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}

.ant-col-xs-order-17 {
  order: 17;
}

.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.ant-col-xs-push-16 {
  left: 66.66666667%;
}

.ant-col-xs-pull-16 {
  right: 66.66666667%;
}

.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}

.ant-col-xs-order-16 {
  order: 16;
}

.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.ant-col-xs-push-15 {
  left: 62.5%;
}

.ant-col-xs-pull-15 {
  right: 62.5%;
}

.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}

.ant-col-xs-order-15 {
  order: 15;
}

.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.ant-col-xs-push-14 {
  left: 58.33333333%;
}

.ant-col-xs-pull-14 {
  right: 58.33333333%;
}

.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}

.ant-col-xs-order-14 {
  order: 14;
}

.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}

.ant-col-xs-push-13 {
  left: 54.16666667%;
}

.ant-col-xs-pull-13 {
  right: 54.16666667%;
}

.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}

.ant-col-xs-order-13 {
  order: 13;
}

.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}

.ant-col-xs-push-12 {
  left: 50%;
}

.ant-col-xs-pull-12 {
  right: 50%;
}

.ant-col-xs-offset-12 {
  margin-left: 50%;
}

.ant-col-xs-order-12 {
  order: 12;
}

.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}

.ant-col-xs-push-11 {
  left: 45.83333333%;
}

.ant-col-xs-pull-11 {
  right: 45.83333333%;
}

.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}

.ant-col-xs-order-11 {
  order: 11;
}

.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.ant-col-xs-push-10 {
  left: 41.66666667%;
}

.ant-col-xs-pull-10 {
  right: 41.66666667%;
}

.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}

.ant-col-xs-order-10 {
  order: 10;
}

.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.ant-col-xs-push-9 {
  left: 37.5%;
}

.ant-col-xs-pull-9 {
  right: 37.5%;
}

.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}

.ant-col-xs-order-9 {
  order: 9;
}

.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.ant-col-xs-push-8 {
  left: 33.33333333%;
}

.ant-col-xs-pull-8 {
  right: 33.33333333%;
}

.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}

.ant-col-xs-order-8 {
  order: 8;
}

.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}

.ant-col-xs-push-7 {
  left: 29.16666667%;
}

.ant-col-xs-pull-7 {
  right: 29.16666667%;
}

.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}

.ant-col-xs-order-7 {
  order: 7;
}

.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}

.ant-col-xs-push-6 {
  left: 25%;
}

.ant-col-xs-pull-6 {
  right: 25%;
}

.ant-col-xs-offset-6 {
  margin-left: 25%;
}

.ant-col-xs-order-6 {
  order: 6;
}

.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}

.ant-col-xs-push-5 {
  left: 20.83333333%;
}

.ant-col-xs-pull-5 {
  right: 20.83333333%;
}

.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}

.ant-col-xs-order-5 {
  order: 5;
}

.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.ant-col-xs-push-4 {
  left: 16.66666667%;
}

.ant-col-xs-pull-4 {
  right: 16.66666667%;
}

.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}

.ant-col-xs-order-4 {
  order: 4;
}

.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.ant-col-xs-push-3 {
  left: 12.5%;
}

.ant-col-xs-pull-3 {
  right: 12.5%;
}

.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}

.ant-col-xs-order-3 {
  order: 3;
}

.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.ant-col-xs-push-2 {
  left: 8.33333333%;
}

.ant-col-xs-pull-2 {
  right: 8.33333333%;
}

.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}

.ant-col-xs-order-2 {
  order: 2;
}

.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}

.ant-col-xs-push-1 {
  left: 4.16666667%;
}

.ant-col-xs-pull-1 {
  right: 4.16666667%;
}

.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}

.ant-col-xs-order-1 {
  order: 1;
}

.ant-col-xs-0 {
  display: none;
}

.ant-col-push-0 {
  left: auto;
}

.ant-col-pull-0 {
  right: auto;
}

.ant-col-xs-push-0 {
  left: auto;
}

.ant-col-xs-pull-0 {
  right: auto;
}

.ant-col-xs-offset-0 {
  margin-left: 0;
}

.ant-col-xs-order-0 {
  order: 0;
}

.ant-col-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}

.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}

.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}

.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}

.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}

.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}

.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}

.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}

.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}

.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}

.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}

.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}

.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}

.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}

.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}

.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}

.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}

.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}

.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}

.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}

.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}

.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}

.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}

.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}

.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}

.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}

.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}

.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}

.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}

.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}

.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}

.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}

.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}

.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}

.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}

.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}

.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}

.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}

.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}

.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}

.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}

.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}

.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}

.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}

.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}

.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}

.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}

.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}

.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}

.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}

.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}

.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}

.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}

.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}

.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}

.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}

.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}

.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}

.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}

.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}

.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}

.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}

.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-sm-push-24 {
    left: 100%;
  }

  .ant-col-sm-pull-24 {
    right: 100%;
  }

  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }

  .ant-col-sm-order-24 {
    order: 24;
  }

  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }

  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-sm-order-23 {
    order: 23;
  }

  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }

  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-sm-order-22 {
    order: 22;
  }

  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-sm-push-21 {
    left: 87.5%;
  }

  .ant-col-sm-pull-21 {
    right: 87.5%;
  }

  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-sm-order-21 {
    order: 21;
  }

  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }

  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-sm-order-20 {
    order: 20;
  }

  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }

  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-sm-order-19 {
    order: 19;
  }

  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-sm-push-18 {
    left: 75%;
  }

  .ant-col-sm-pull-18 {
    right: 75%;
  }

  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }

  .ant-col-sm-order-18 {
    order: 18;
  }

  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }

  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-sm-order-17 {
    order: 17;
  }

  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }

  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-sm-order-16 {
    order: 16;
  }

  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-sm-push-15 {
    left: 62.5%;
  }

  .ant-col-sm-pull-15 {
    right: 62.5%;
  }

  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-sm-order-15 {
    order: 15;
  }

  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }

  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-sm-order-14 {
    order: 14;
  }

  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }

  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-sm-order-13 {
    order: 13;
  }

  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-sm-push-12 {
    left: 50%;
  }

  .ant-col-sm-pull-12 {
    right: 50%;
  }

  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }

  .ant-col-sm-order-12 {
    order: 12;
  }

  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }

  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-sm-order-11 {
    order: 11;
  }

  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }

  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-sm-order-10 {
    order: 10;
  }

  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-sm-push-9 {
    left: 37.5%;
  }

  .ant-col-sm-pull-9 {
    right: 37.5%;
  }

  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-sm-order-9 {
    order: 9;
  }

  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }

  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-sm-order-8 {
    order: 8;
  }

  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }

  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-sm-order-7 {
    order: 7;
  }

  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-sm-push-6 {
    left: 25%;
  }

  .ant-col-sm-pull-6 {
    right: 25%;
  }

  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }

  .ant-col-sm-order-6 {
    order: 6;
  }

  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }

  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-sm-order-5 {
    order: 5;
  }

  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }

  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-sm-order-4 {
    order: 4;
  }

  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-sm-push-3 {
    left: 12.5%;
  }

  .ant-col-sm-pull-3 {
    right: 12.5%;
  }

  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-sm-order-3 {
    order: 3;
  }

  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }

  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-sm-order-2 {
    order: 2;
  }

  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }

  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-sm-order-1 {
    order: 1;
  }

  .ant-col-sm-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-sm-push-0 {
    left: auto;
  }

  .ant-col-sm-pull-0 {
    right: auto;
  }

  .ant-col-sm-offset-0 {
    margin-left: 0;
  }

  .ant-col-sm-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-md-push-24 {
    left: 100%;
  }

  .ant-col-md-pull-24 {
    right: 100%;
  }

  .ant-col-md-offset-24 {
    margin-left: 100%;
  }

  .ant-col-md-order-24 {
    order: 24;
  }

  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-md-push-23 {
    left: 95.83333333%;
  }

  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-md-order-23 {
    order: 23;
  }

  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-md-push-22 {
    left: 91.66666667%;
  }

  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-md-order-22 {
    order: 22;
  }

  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-md-push-21 {
    left: 87.5%;
  }

  .ant-col-md-pull-21 {
    right: 87.5%;
  }

  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-md-order-21 {
    order: 21;
  }

  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-md-push-20 {
    left: 83.33333333%;
  }

  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-md-order-20 {
    order: 20;
  }

  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-md-push-19 {
    left: 79.16666667%;
  }

  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-md-order-19 {
    order: 19;
  }

  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-md-push-18 {
    left: 75%;
  }

  .ant-col-md-pull-18 {
    right: 75%;
  }

  .ant-col-md-offset-18 {
    margin-left: 75%;
  }

  .ant-col-md-order-18 {
    order: 18;
  }

  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-md-push-17 {
    left: 70.83333333%;
  }

  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-md-order-17 {
    order: 17;
  }

  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-md-push-16 {
    left: 66.66666667%;
  }

  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-md-order-16 {
    order: 16;
  }

  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-md-push-15 {
    left: 62.5%;
  }

  .ant-col-md-pull-15 {
    right: 62.5%;
  }

  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-md-order-15 {
    order: 15;
  }

  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-md-push-14 {
    left: 58.33333333%;
  }

  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-md-order-14 {
    order: 14;
  }

  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-md-push-13 {
    left: 54.16666667%;
  }

  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-md-order-13 {
    order: 13;
  }

  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-md-push-12 {
    left: 50%;
  }

  .ant-col-md-pull-12 {
    right: 50%;
  }

  .ant-col-md-offset-12 {
    margin-left: 50%;
  }

  .ant-col-md-order-12 {
    order: 12;
  }

  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-md-push-11 {
    left: 45.83333333%;
  }

  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-md-order-11 {
    order: 11;
  }

  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-md-push-10 {
    left: 41.66666667%;
  }

  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-md-order-10 {
    order: 10;
  }

  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-md-push-9 {
    left: 37.5%;
  }

  .ant-col-md-pull-9 {
    right: 37.5%;
  }

  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-md-order-9 {
    order: 9;
  }

  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-md-push-8 {
    left: 33.33333333%;
  }

  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-md-order-8 {
    order: 8;
  }

  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-md-push-7 {
    left: 29.16666667%;
  }

  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-md-order-7 {
    order: 7;
  }

  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-md-push-6 {
    left: 25%;
  }

  .ant-col-md-pull-6 {
    right: 25%;
  }

  .ant-col-md-offset-6 {
    margin-left: 25%;
  }

  .ant-col-md-order-6 {
    order: 6;
  }

  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-md-push-5 {
    left: 20.83333333%;
  }

  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-md-order-5 {
    order: 5;
  }

  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-md-push-4 {
    left: 16.66666667%;
  }

  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-md-order-4 {
    order: 4;
  }

  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-md-push-3 {
    left: 12.5%;
  }

  .ant-col-md-pull-3 {
    right: 12.5%;
  }

  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-md-order-3 {
    order: 3;
  }

  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-md-push-2 {
    left: 8.33333333%;
  }

  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-md-order-2 {
    order: 2;
  }

  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-md-push-1 {
    left: 4.16666667%;
  }

  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-md-order-1 {
    order: 1;
  }

  .ant-col-md-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-md-push-0 {
    left: auto;
  }

  .ant-col-md-pull-0 {
    right: auto;
  }

  .ant-col-md-offset-0 {
    margin-left: 0;
  }

  .ant-col-md-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-lg-push-24 {
    left: 100%;
  }

  .ant-col-lg-pull-24 {
    right: 100%;
  }

  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }

  .ant-col-lg-order-24 {
    order: 24;
  }

  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }

  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-lg-order-23 {
    order: 23;
  }

  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }

  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-lg-order-22 {
    order: 22;
  }

  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-lg-push-21 {
    left: 87.5%;
  }

  .ant-col-lg-pull-21 {
    right: 87.5%;
  }

  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-lg-order-21 {
    order: 21;
  }

  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }

  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-lg-order-20 {
    order: 20;
  }

  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }

  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-lg-order-19 {
    order: 19;
  }

  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-lg-push-18 {
    left: 75%;
  }

  .ant-col-lg-pull-18 {
    right: 75%;
  }

  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }

  .ant-col-lg-order-18 {
    order: 18;
  }

  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }

  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-lg-order-17 {
    order: 17;
  }

  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }

  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-lg-order-16 {
    order: 16;
  }

  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-lg-push-15 {
    left: 62.5%;
  }

  .ant-col-lg-pull-15 {
    right: 62.5%;
  }

  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-lg-order-15 {
    order: 15;
  }

  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }

  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-lg-order-14 {
    order: 14;
  }

  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }

  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-lg-order-13 {
    order: 13;
  }

  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-lg-push-12 {
    left: 50%;
  }

  .ant-col-lg-pull-12 {
    right: 50%;
  }

  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }

  .ant-col-lg-order-12 {
    order: 12;
  }

  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }

  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-lg-order-11 {
    order: 11;
  }

  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }

  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-lg-order-10 {
    order: 10;
  }

  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-lg-push-9 {
    left: 37.5%;
  }

  .ant-col-lg-pull-9 {
    right: 37.5%;
  }

  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-lg-order-9 {
    order: 9;
  }

  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }

  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-lg-order-8 {
    order: 8;
  }

  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }

  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-lg-order-7 {
    order: 7;
  }

  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-lg-push-6 {
    left: 25%;
  }

  .ant-col-lg-pull-6 {
    right: 25%;
  }

  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }

  .ant-col-lg-order-6 {
    order: 6;
  }

  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }

  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-lg-order-5 {
    order: 5;
  }

  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }

  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-lg-order-4 {
    order: 4;
  }

  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-lg-push-3 {
    left: 12.5%;
  }

  .ant-col-lg-pull-3 {
    right: 12.5%;
  }

  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-lg-order-3 {
    order: 3;
  }

  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }

  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-lg-order-2 {
    order: 2;
  }

  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }

  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-lg-order-1 {
    order: 1;
  }

  .ant-col-lg-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-lg-push-0 {
    left: auto;
  }

  .ant-col-lg-pull-0 {
    right: auto;
  }

  .ant-col-lg-offset-0 {
    margin-left: 0;
  }

  .ant-col-lg-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xl-push-24 {
    left: 100%;
  }

  .ant-col-xl-pull-24 {
    right: 100%;
  }

  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xl-order-24 {
    order: 24;
  }

  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }

  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-xl-order-23 {
    order: 23;
  }

  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }

  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-xl-order-22 {
    order: 22;
  }

  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-xl-push-21 {
    left: 87.5%;
  }

  .ant-col-xl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xl-order-21 {
    order: 21;
  }

  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }

  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-xl-order-20 {
    order: 20;
  }

  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }

  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-xl-order-19 {
    order: 19;
  }

  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-xl-push-18 {
    left: 75%;
  }

  .ant-col-xl-pull-18 {
    right: 75%;
  }

  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xl-order-18 {
    order: 18;
  }

  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }

  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-xl-order-17 {
    order: 17;
  }

  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }

  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-xl-order-16 {
    order: 16;
  }

  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-xl-push-15 {
    left: 62.5%;
  }

  .ant-col-xl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xl-order-15 {
    order: 15;
  }

  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }

  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-xl-order-14 {
    order: 14;
  }

  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }

  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-xl-order-13 {
    order: 13;
  }

  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-xl-push-12 {
    left: 50%;
  }

  .ant-col-xl-pull-12 {
    right: 50%;
  }

  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xl-order-12 {
    order: 12;
  }

  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }

  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-xl-order-11 {
    order: 11;
  }

  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }

  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-xl-order-10 {
    order: 10;
  }

  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-xl-push-9 {
    left: 37.5%;
  }

  .ant-col-xl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xl-order-9 {
    order: 9;
  }

  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }

  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-xl-order-8 {
    order: 8;
  }

  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }

  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-xl-order-7 {
    order: 7;
  }

  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-xl-push-6 {
    left: 25%;
  }

  .ant-col-xl-pull-6 {
    right: 25%;
  }

  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xl-order-6 {
    order: 6;
  }

  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }

  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-xl-order-5 {
    order: 5;
  }

  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }

  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-xl-order-4 {
    order: 4;
  }

  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-xl-push-3 {
    left: 12.5%;
  }

  .ant-col-xl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xl-order-3 {
    order: 3;
  }

  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }

  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-xl-order-2 {
    order: 2;
  }

  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }

  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-xl-order-1 {
    order: 1;
  }

  .ant-col-xl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xl-push-0 {
    left: auto;
  }

  .ant-col-xl-pull-0 {
    right: auto;
  }

  .ant-col-xl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xxl-push-24 {
    left: 100%;
  }

  .ant-col-xxl-pull-24 {
    right: 100%;
  }

  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }

  .ant-col-xxl-order-24 {
    order: 24;
  }

  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }

  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }

  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }

  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }

  .ant-col-xxl-order-23 {
    order: 23;
  }

  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }

  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }

  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }

  .ant-col-xxl-order-22 {
    order: 22;
  }

  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .ant-col-xxl-push-21 {
    left: 87.5%;
  }

  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }

  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }

  .ant-col-xxl-order-21 {
    order: 21;
  }

  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }

  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }

  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }

  .ant-col-xxl-order-20 {
    order: 20;
  }

  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }

  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }

  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }

  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }

  .ant-col-xxl-order-19 {
    order: 19;
  }

  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ant-col-xxl-push-18 {
    left: 75%;
  }

  .ant-col-xxl-pull-18 {
    right: 75%;
  }

  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }

  .ant-col-xxl-order-18 {
    order: 18;
  }

  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }

  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }

  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }

  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }

  .ant-col-xxl-order-17 {
    order: 17;
  }

  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }

  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }

  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }

  .ant-col-xxl-order-16 {
    order: 16;
  }

  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .ant-col-xxl-push-15 {
    left: 62.5%;
  }

  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }

  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }

  .ant-col-xxl-order-15 {
    order: 15;
  }

  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }

  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }

  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }

  .ant-col-xxl-order-14 {
    order: 14;
  }

  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }

  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }

  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }

  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }

  .ant-col-xxl-order-13 {
    order: 13;
  }

  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ant-col-xxl-push-12 {
    left: 50%;
  }

  .ant-col-xxl-pull-12 {
    right: 50%;
  }

  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }

  .ant-col-xxl-order-12 {
    order: 12;
  }

  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }

  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }

  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }

  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }

  .ant-col-xxl-order-11 {
    order: 11;
  }

  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }

  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }

  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }

  .ant-col-xxl-order-10 {
    order: 10;
  }

  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .ant-col-xxl-push-9 {
    left: 37.5%;
  }

  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }

  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }

  .ant-col-xxl-order-9 {
    order: 9;
  }

  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }

  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }

  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }

  .ant-col-xxl-order-8 {
    order: 8;
  }

  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }

  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }

  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }

  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }

  .ant-col-xxl-order-7 {
    order: 7;
  }

  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .ant-col-xxl-push-6 {
    left: 25%;
  }

  .ant-col-xxl-pull-6 {
    right: 25%;
  }

  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }

  .ant-col-xxl-order-6 {
    order: 6;
  }

  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }

  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }

  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }

  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }

  .ant-col-xxl-order-5 {
    order: 5;
  }

  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }

  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }

  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }

  .ant-col-xxl-order-4 {
    order: 4;
  }

  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .ant-col-xxl-push-3 {
    left: 12.5%;
  }

  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }

  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }

  .ant-col-xxl-order-3 {
    order: 3;
  }

  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }

  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }

  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }

  .ant-col-xxl-order-2 {
    order: 2;
  }

  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }

  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }

  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }

  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }

  .ant-col-xxl-order-1 {
    order: 1;
  }

  .ant-col-xxl-0 {
    display: none;
  }

  .ant-col-push-0 {
    left: auto;
  }

  .ant-col-pull-0 {
    right: auto;
  }

  .ant-col-xxl-push-0 {
    left: auto;
  }

  .ant-col-xxl-pull-0 {
    right: auto;
  }

  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }

  .ant-col-xxl-order-0 {
    order: 0;
  }

  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }

  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }

  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }

  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }

  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }

  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }

  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }

  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }

  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }

  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }

  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }

  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }

  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }

  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }

  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }

  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }

  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }

  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }

  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }

  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }

  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }

  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }

  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }

  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }

  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }

  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }

  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }

  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }

  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }

  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }

  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }

  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }

  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }

  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }

  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }

  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }

  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }

  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }

  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }

  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }

  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }

  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }

  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }

  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }

  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }

  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}

.ant-row-rtl {
  direction: rtl;
}

.ant-image {
  position: relative;
  display: inline-block;
}

.ant-image-img {
  display: block;
  width: 100%;
  height: auto;
}

.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}

.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.ant-image-mask-info .anticon {
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
}

.ant-image-mask:hover {
  opacity: 1;
}

.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}

.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-image-preview-mask-hidden {
  display: none;
}

.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;
}

.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}

.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}

.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}

.ant-image-preview-wrap {
  z-index: 1080;
}

.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}

.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
}

.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}

.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}

.ant-image-preview-operations-icon {
  font-size: 18px;
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}

.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}

.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
  cursor: not-allowed;
}

.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}

.ant-image-preview-switch-left {
  left: 10px;
}

.ant-image-preview-switch-right {
  right: 10px;
}

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}

.ant-input-affix-wrapper::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-affix-wrapper[disabled]:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}

.ant-input-affix-wrapper-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #143457;
  border-right-width: 1px !important;
  z-index: 1;
}

.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none;
}

.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}

.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}

.ant-input-prefix {
  margin-right: 4px;
}

.ant-input-suffix {
  margin-left: 4px;
}

.ant-input-clear-icon {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input-clear-icon-hidden {
  visibility: hidden;
}

.ant-input-clear-icon:last-child {
  margin-right: 0;
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}

.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  transition: all 0.3s;
}

.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-input::placeholder {
  color: #bfbfbf;
}

.ant-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input[disabled]:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-sm {
  padding: 0px 7px;
}

.ant-input-rtl {
  direction: rtl;
}

.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}

.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}

.ant-input-group-wrap > * {
  display: block !important;
}

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}

.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}

.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  transition: all 0.3s;
}

.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}

.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #052449;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group-addon:first-child {
  border-right: 0;
}

.ant-input-group-addon:last-child {
  border-left: 0;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-group.ant-input-group-compact {
  display: block;
}

.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}

.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}

.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}

.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}

.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #cbcbcb;
  border-left: 0;
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #cbcbcb;
}

.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}

.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input[type='color'] {
  height: 32px;
}

.ant-input[type='color'].ant-input-lg {
  height: 40px;
}

.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #143457;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #143457;
}

.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ant-input-search-button {
  height: 32px;
}

.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-group-wrapper-rtl {
  direction: rtl;
}

.ant-input-group-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}

.ant-input-textarea-rtl {
  direction: rtl;
}

.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}

.ant-input-search-rtl {
  direction: rtl;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #143457;
  border-left-color: #d9d9d9;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #143457;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}

.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }

  .ant-input-lg {
    height: 40px;
  }

  .ant-input-sm {
    height: 24px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}

.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cbcbcb;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-input-number::placeholder {
  color: #bfbfbf;
}

.ant-input-number:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number[disabled]:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-input-number-sm {
  padding: 0px 7px;
}

.ant-input-number-rtl {
  direction: rtl;
}

.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}

.ant-input-number-handler:active {
  background: #f4f4f4;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #143457;
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}

.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}

.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}

.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}

.ant-input-number:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}

.ant-input-number-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}

.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}

.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-input-number-input::placeholder {
  color: #bfbfbf;
}

.ant-input-number-input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}

.ant-input-number-lg input {
  height: 38px;
}

.ant-input-number-sm {
  padding: 0;
}

.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}

.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}

.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}

.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}

.ant-input-number-handler-up:hover {
  height: 60% !important;
}

.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}

.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-input-number-handler-down:hover {
  height: 60% !important;
}

.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}

.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}

.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-borderless {
  box-shadow: none;
}

.ant-input-number-out-of-range input {
  color: #ff4d4f;
}

.ant-input-number-rtl {
  direction: rtl;
}

.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 2px 0 0 2px;
}

.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}

.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}

.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}

.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}

.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #000000;
  transition: all 0.2s;
}

.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}

.ant-layout-sider-right {
  order: 1;
}

.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}

.ant-layout-sider-zero-width > * {
  overflow: hidden;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #000000;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}

.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}

.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}

.ant-layout-sider-light {
  background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}

.ant-layout-rtl {
  direction: rtl;
}

.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}

.ant-list * {
  outline: none;
}

.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}

.ant-list-pagination .ant-pagination-options {
  text-align: left;
}

.ant-list-more {
  margin-top: 12px;
  text-align: center;
}

.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}

.ant-list-spin {
  min-height: 40px;
  text-align: center;
}

.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}

.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}

.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}

.ant-list-item-meta-avatar {
  margin-right: 16px;
}

.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}

.ant-list-item-meta-title > a:hover {
  color: #052449;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}

.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}

.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}

.ant-list-item-action > li:first-child {
  padding-left: 0;
}

.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}

.ant-list-header {
  background: transparent;
}

.ant-list-footer {
  background: transparent;
}

.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}

.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}

.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}

.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}

.ant-list-vertical .ant-list-item {
  align-items: initial;
}

.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}

.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}

.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}

.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}

.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}

.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}

.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.ant-list-item-no-flex {
  display: block;
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}

.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}

.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}

.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}

.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}

.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}

.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}

.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}

.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}

@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }

  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}

@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }

  .ant-list-item-action {
    margin-left: 12px;
  }

  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }

  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }

  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

.ant-list-rtl {
  direction: rtl;
  text-align: right;
}

.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}

.ant-list-rtl .ant-list-pagination {
  text-align: left;
}

.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}

.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}

.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}

.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}

.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}

.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}

@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }

  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }

  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}

.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}

.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-mentions::placeholder {
  color: #bfbfbf;
}

.ant-mentions:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-mentions-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-mentions[disabled]:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-mentions-sm {
  padding: 0px 7px;
}

.ant-mentions-rtl {
  direction: rtl;
}

.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-mentions-disabled > textarea:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-mentions-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}

.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}

.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
}

.ant-mentions > textarea:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}

.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}

.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", ;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-mentions-dropdown-hidden {
  display: none;
}

.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}

.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}

.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}

.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}

.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}

.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}

.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}

.ant-mentions-rtl {
  direction: rtl;
}

.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}

.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}

.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}

.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-menu::before {
  display: table;
  content: '';
}

.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}

.ant-menu::before {
  display: table;
  content: '';
}

.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}

.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-menu-hidden {
  display: none;
}

.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}

.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-selected {
  color: #052449;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #7c848a;
}

.ant-menu-submenu .ant-menu-sub {
  cursor: auto;
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item a:hover {
  color: #052449;
}

.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}

.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item > .ant-badge a:hover {
  color: #052449;
}

.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #052449;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}

.ant-menu-item-selected {
  color: #052449;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #052449;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7c848a;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}

.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}

.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}

.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}

.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}

.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}

.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}

.ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2.5px);
  transform: rotate(45deg) translateY(-2.5px);
}

.ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2.5px);
  transform: rotate(-45deg) translateY(2.5px);
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #052449;
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2.5px);
  transform: rotate(-45deg) translateX(2.5px);
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2.5px);
  transform: rotate(45deg) translateX(-2.5px);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2.5px);
  transform: rotate(-45deg) translateX(-2.5px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2.5px);
  transform: rotate(45deg) translateX(2.5px);
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #052449;
}

.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #052449;
  border-bottom: 2px solid #052449;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #052449;
}

.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #052449;
}

.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #052449;
  -webkit-transform: scaleY(0.0001);
  transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 48px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 48px;
  text-overflow: ellipsis;
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}

.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline {
  width: 100%;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}

.ant-menu-inline-collapsed {
  width: 80px;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 48px;
}

.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}

.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}

.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}

.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
  list-style-position: inside;
  list-style-type: disc;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}

.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}

.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}

.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}

.ant-layout-header .ant-menu {
  line-height: inherit;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #000000;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}

.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #000000;
  border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}

.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}

.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}

.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}

.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}

.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}

.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}

.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}

.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateY(-2px);
  transform: rotate(-45deg) translateY(-2px);
}

.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateY(2px);
  transform: rotate(45deg) translateY(2px);
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}

.ant-menu-sub.ant-menu-inline {
  border: 0;
}

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #052449;
}

.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-message-rtl {
  direction: rtl;
}

.ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}

.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.ant-modal-footer .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #052449;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: auto;
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}

.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
  animation-name: NotificationLeftFadeIn;
}

.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}

.ant-notification-hook-holder,
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-notification-topLeft .ant-notification-hook-holder,
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-hook-holder,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}

.ant-notification-hook-holder > .ant-notification-notice {
  margin-bottom: 0;
  box-shadow: none;
}

.ant-notification-notice {
  padding: 16px 24px;
  line-height: 1.5715;
}

.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}

.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}

.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}

.ant-notification-notice-description {
  font-size: 14px;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}

.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}

.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}

.anticon.ant-notification-notice-icon-info {
  color: #052449;
}

.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}

.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}

.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}

.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}

.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}

.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ant-notification-fade-enter,
.ant-notification-fade-appear {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 0;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
  animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
  animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

.ant-notification-rtl {
  direction: rtl;
}

.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}

.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}

.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}

.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}

.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}

.ant-page-header-ghost {
  background-color: inherit;
}

.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}

.ant-page-header.has-footer {
  padding-bottom: 0;
}

.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}

.ant-page-header-back-button {
  color: #052449;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}

.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #143457;
}

.ant-page-header-back-button:active {
  color: #010f24;
}

.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}

.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}

.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}

.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}

.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}

.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}

.ant-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset;
}

.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}

.ant-page-header-content {
  padding-top: 12px;
}

.ant-page-header-footer {
  margin-top: 16px;
}

.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}

.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}

.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding: 8px 0;
  font-size: 16px;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}

.ant-page-header-rtl {
  direction: rtl;
}

.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}

.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}

.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}

.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}

.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}

.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}

.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}

.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}

.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: 'Open Sans', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}

.ant-pagination-item a:hover {
  text-decoration: none;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #052449;
  transition: all 0.3s;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #052449;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #052449;
}

.ant-pagination-item-active a {
  color: #052449;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #143457;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #143457;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}

.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #052449;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}

.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}

.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: 'Open Sans', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #143457;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #052449;
  border-color: #052449;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination-slash {
  margin: 0 10px 0 5px;
}

.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}

.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}

.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}

.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}

.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}

.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:hover {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #cbcbcb;
  border-right-width: 1px !important;
}

.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}

.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}

.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}

.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}

.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}

.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #052449;
}

.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}

.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}

.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0px;
}

.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}

.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
}

.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}

.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}

.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}

.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}

.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}

.ant-popconfirm {
  z-index: 1060;
}

.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}

.ant-popover-hidden {
  display: none;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}

.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}

.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }

  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}

.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}

.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px;
}

.ant-popover-message-title {
  padding-left: 22px;
}

.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}

.ant-popover-buttons button {
  margin-left: 8px;
}

.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
}

.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}

.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}

.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}

.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}

.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}

.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}

.ant-progress-steps {
  display: inline-block;
}

.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}

.ant-progress-steps-item-active {
  background: #1890ff;
}

.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}

.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}

.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}

.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}

.ant-progress-circle-trail {
  stroke: #f5f5f5;
}

.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
  animation: ant-progress-appear 0.3s;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}

.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}

.ant-progress-text .anticon {
  font-size: 14px;
}

.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}

.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}

.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}

.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}

.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}

.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}

.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}

@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }

  20% {
    width: 0;
    opacity: 0.5;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }

  20% {
    width: 0;
    opacity: 0.5;
  }

  100% {
    width: 100%;
    opacity: 0;
  }
}

.ant-progress-rtl {
  direction: rtl;
}

.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}

.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}

.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}

.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
  line-height: unset;
}

.ant-radio-group .ant-badge-count {
  z-index: 1;
}

.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}

.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}

.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #052449;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(5, 36, 73, 0.08);
}

.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #052449;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: '';
}

.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}

.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}

.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #052449;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #052449;
}

.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-radio-disabled {
  cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}

.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #052449;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(5, 36, 73, 0.08);
}

.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #052449;
  background: #fff;
  border-color: #052449;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #052449;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #052449;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #143457;
  border-color: #143457;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #143457;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #010f24;
  border-color: #010f24;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #010f24;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(5, 36, 73, 0.08);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #052449;
  border-color: #052449;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #143457;
  border-color: #143457;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #010f24;
  border-color: #010f24;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(5, 36, 73, 0.08);
}

.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}

@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0;
  }
}

.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}

.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #143457;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}

.ant-rate-disabled .ant-rate-star {
  cursor: default;
}

.ant-rate-disabled .ant-rate-star:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}

.ant-rate-star > div:focus {
  outline: 0;
}

.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}

.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}

.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}

.ant-rate-rtl {
  direction: rtl;
}

.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}

.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}

.ant-result {
  padding: 48px 32px;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}

.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}

.ant-result-info .ant-result-icon > .anticon {
  color: #052449;
}

.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}

.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}

.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}

.ant-result-icon > .anticon {
  font-size: 72px;
}

.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}

.ant-result-extra > * {
  margin-right: 8px;
}

.ant-result-extra > *:last-child {
  margin-right: 0;
}

.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}

.ant-result-rtl {
  direction: rtl;
}

.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}

.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}

.ant-select-single .ant-select-selector {
  display: flex;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}

@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
  }
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
}

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}

/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */

.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}

.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}

.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}

.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}

.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}

.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}

.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  -webkit-margin-start: 7px;
  margin-inline-start: 7px;
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: 'Open Sans', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  line-height: 24px;
  transition: all 0.3s;
}

.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}

.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}

.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.3s;
}

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}

.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  -webkit-margin-start: 3px;
  margin-inline-start: 3px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}

.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}

/* Reset search input style */

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #143457;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #143457;
  border-right-width: 1px !important;
}

.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}

.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}

.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}

.ant-select-arrow > * {
  line-height: 1;
}

.ant-select-arrow svg {
  display: inline-block;
}

.ant-select-arrow::before {
  display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}

.ant-select-arrow .anticon {
  vertical-align: top;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-select-arrow .anticon > svg {
  vertical-align: top;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}

.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}

.ant-select-clear::before {
  display: block;
}

.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-select:hover .ant-select-clear {
  opacity: 1;
}

.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", ;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
  display: none;
}

.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}

.ant-select-item-option {
  display: flex;
}

.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-select-item-option-state {
  flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #7c848a;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #052449;
}

.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-item-option-grouped {
  padding-left: 24px;
}

.ant-select-lg {
  font-size: 16px;
}

.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select-rtl {
  direction: rtl;
}

.ant-select-rtl .ant-select-arrow {
  right: auto;
  right: initial;
  left: 11px;
}

.ant-select-rtl .ant-select-clear {
  right: auto;
  right: initial;
  left: 11px;
}

.ant-select-dropdown-rtl {
  direction: rtl;
}

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}

.ant-skeleton {
  display: table;
  width: 100%;
}

.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}

.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}

.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}

.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}

.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}

.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}

.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

.ant-skeleton-element {
  display: inline-block;
  width: auto;
}

.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  height: 32px;
  line-height: 32px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}

.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}

.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 100%;
  height: 32px;
  line-height: 32px;
}

.ant-skeleton-element .ant-skeleton-input-lg {
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 24px;
  line-height: 24px;
}

.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}

.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}

.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}

.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}

@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.ant-skeleton-rtl {
  direction: rtl;
}

.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  -webkit-animation-name: ant-skeleton-loading-rtl;
  animation-name: ant-skeleton-loading-rtl;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  -webkit-animation-name: ant-skeleton-loading-rtl;
  animation-name: ant-skeleton-loading-rtl;
}

@-webkit-keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}

.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}

.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-track {
  width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}

.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}

.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}

.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}

.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}

.ant-slider-with-marks {
  margin-bottom: 28px;
}

.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #3e5970;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #3e5970;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #37506d;
  box-shadow: 0 0 0 5px rgba(5, 36, 73, 0.12);
}

.ant-slider-handle:focus {
  border-color: #37506d;
  outline: none;
  box-shadow: 0 0 0 5px rgba(5, 36, 73, 0.12);
}

.ant-slider-handle.ant-tooltip-open {
  border-color: #052449;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}

.ant-slider:hover .ant-slider-track {
  background-color: #274663;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #274663;
}

.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}

.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}

.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}

.ant-slider-dot:first-child {
  margin-left: -4px;
}

.ant-slider-dot:last-child {
  margin-left: -4px;
}

.ant-slider-dot-active {
  border-color: #8292a4;
}

.ant-slider-disabled {
  cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}

.ant-slider-rtl {
  direction: rtl;
}

.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}

.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}

.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}

.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}

.ant-space {
  display: inline-flex;
}

.ant-space-vertical {
  flex-direction: column;
}

.ant-space-align-center {
  align-items: center;
}

.ant-space-align-start {
  align-items: flex-start;
}

.ant-space-align-end {
  align-items: flex-end;
}

.ant-space-align-baseline {
  align-items: baseline;
}

.ant-space-item:empty {
  display: none;
}

.ant-space-rtl {
  direction: rtl;
}

.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #052449;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}

.ant-spin-nested-loading {
  position: relative;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}

.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}

.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}

.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -20px;
}

.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 15px;
}

.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -30px;
}

.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}

.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}

.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}

.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}

.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #052449;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
  animation: antSpinMove 1s infinite linear alternate;
}

.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}

.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
  animation: antRotate 1.2s infinite linear;
}

.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}

.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 40px;
}

.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */

  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

.ant-spin-rtl {
  direction: rtl;
}

.ant-spin-rtl .ant-spin-dot-spin {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation-name: antRotateRtl;
  animation-name: antRotateRtl;
}

@-webkit-keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes antRotateRtl {
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
}

.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: 'Open Sans', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
}

.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}

.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}

.ant-statistic-content-prefix {
  margin-right: 4px;
}

.ant-statistic-content-suffix {
  margin-left: 4px;
}

.ant-statistic-rtl {
  direction: rtl;
}

.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}

.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}

.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: left;
  text-align: initial;
}

.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}

.ant-steps-item-container {
  outline: none;
}

.ant-steps-item:last-child {
  flex: none;
}

.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}

.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}

.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: 'Open Sans', 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #052449;
  line-height: 1;
}

.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}

.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}

.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}

.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}

.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}

.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #052449;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #052449;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #052449;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #052449;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #052449;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #052449;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #052449;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #052449;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #052449;
}

.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}

.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}

.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}

.ant-steps-item-disabled {
  cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #052449;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #052449;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #052449;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}

.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}

.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #052449;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}

.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}

.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}

.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}

.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
  transform: none;
}

.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}

.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}

.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}

.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}

.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}

.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}

.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}

.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}

.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}

.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}

.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}

.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: none;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  left: -2px;
}

.ant-steps-navigation {
  padding-top: 12px;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}

.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}

.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}

.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}

.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}

.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
}

.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #052449;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}

.ant-steps-rtl {
  direction: rtl;
}

.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}

.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}

.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}

.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}

.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}

.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}

.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}

.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}

.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}

.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}

.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}

.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(5, 36, 73, 0.2);
}

.ant-switch:focus:hover {
  box-shadow: none;
}

.ant-switch-checked {
  background-color: #052449;
}

.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}

.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}

.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}

.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}

.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}

.ant-switch-loading-icon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}

.ant-switch-checked .ant-switch-loading-icon {
  color: #052449;
}

.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}

.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}

.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}

.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}

.ant-switch-rtl {
  direction: rtl;
}

.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}

.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}

.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}

.ant-table.ant-table-middle {
  font-size: 14px;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 9px 8px;
}

.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
  margin: -9px -8px;
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
  padding: 9px 2.3em 9px 8px;
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 9px 8px;
}

.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -9px -8px;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -9px -8px -9px 25px;
}

.ant-table.ant-table-small {
  font-size: 14px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 6px 8px;
}

.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: -6px -8px;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 6px 2.3em 6px 8px;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 6px 8px;
}

.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -6px -8px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -6px -8px -6px 25px;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #ffffff;
}

.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -17px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}

.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}

.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -9px -9px;
}

.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -6px -9px;
}

.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}

.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}

.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #ffffff;
}

.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}

.ant-table-wrapper::before {
  display: table;
  content: '';
}

.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-table-wrapper::before {
  display: table;
  content: '';
}

.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}

.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 12px 16px;
  overflow-wrap: break-word;
}

.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-table-title {
  padding: 12px 16px;
}

.ant-table-footer {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}

.ant-table-thead > tr > th {
  color: #7f848a;
  font-weight: 500;
  text-align: left;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}

.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -12px -16px -12px 33px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #7c848a;
  border-color: rgba(0, 0, 0, 0.03);
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #777f85;
}

.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}

.ant-table-pagination {
  display: flex;
}

.ant-table-pagination-left {
  justify-content: flex-start;
}

.ant-table-pagination-center {
  justify-content: center;
}

.ant-table-pagination-right {
  justify-content: flex-end;
}

.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f7f7f7;
}

.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #fcfcfc;
}

.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}

td.ant-table-column-sort {
  background: #fafafa;
}

.ant-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}

.ant-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
}

.ant-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}

.ant-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}

.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #052449;
}

.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}

.ant-table-filter-column {
  display: flex;
  align-items: center;
  margin: -12px -16px;
}

.ant-table-filter-column-title {
  flex: auto;
  padding: 12px 2.3em 12px 16px;
}

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
  margin: 0;
}

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 2.3em 0 0;
}

.ant-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #ebebeb;
}

.ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  transition: color 0.3s;
}

.ant-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-table-filter-trigger.active {
  color: #052449;
}

.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}

.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}

.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}

.ant-table .ant-table-selection-col {
  width: 32px;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}

table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}

.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}

.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-margin-start: 100%;
  margin-inline-start: 100%;
  -webkit-padding-start: 4px;
  padding-inline-start: 4px;
}

.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}

.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}

.ant-table-expand-icon-col {
  width: 48px;
}

.ant-table-row-expand-icon-cell {
  text-align: center;
}

.ant-table-row-indent {
  float: left;
  height: 1px;
}

.ant-table-row-expand-icon {
  color: #052449;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  -webkit-transform: scale(0.94117647);
  transform: scale(0.94117647);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  transition: all 0.3s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #143457;
}

.ant-table-row-expand-icon:active {
  color: #010f24;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}

.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  content: '';
}

.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}

.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}

.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}

tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}

tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}

.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -12px -16px;
  padding: 12px 16px;
}

.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}

.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}

.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}

.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}

.ant-table .ant-table-container::before {
  left: 0;
}

.ant-table .ant-table-container::after {
  right: 0;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.ant-table-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(2 + 1);
}

.ant-table-sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}

.ant-table-sticky-scroll:hover {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}

.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}

@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}

.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}

.ant-table-title {
  border-radius: 2px 2px 0 0;
}

.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}

.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}

.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}

.ant-table-footer {
  border-radius: 0 0 2px 2px;
}

.ant-table-wrapper-rtl {
  direction: rtl;
}

.ant-table-rtl {
  direction: rtl;
}

.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}

.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}

.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -12px 33px -12px -16px;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}

.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}

.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 12px 16px 12px 2.3em;
}

.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}

.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
  right: auto;
  left: 0;
}

.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}

.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}

.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}

.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}

.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}

.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}

.ant-tabs-rtl {
  direction: rtl;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}

.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 0;
  margin-left: 2px;
}

.ant-tabs-dropdown-rtl {
  direction: rtl;
}

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}

.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}

.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}

.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}

.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}

.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}

.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}

.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}

.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}

.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}

.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}

.ant-tabs-dropdown-hidden {
  display: none;
}

.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}

.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #052449;
  background: #fff;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}

.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}

.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}

.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}

.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  overflow: hidden;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  content: '';
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #143457;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #010f24;
}

.ant-tabs-extra-content {
  flex: none;
}

.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #052449;
  pointer-events: none;
}

.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #010f24;
}

.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}

.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-tabs-tab:hover {
  color: #143457;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #052449;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}

.ant-tabs-tab .anticon {
  margin-right: 12px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}

.ant-tabs-content {
  display: flex;
  width: 100%;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-content-animated {
  transition: margin 0.3s;
}

.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}

.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}

.ant-tag:hover {
  opacity: 0.85;
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}

.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-tag-has-color {
  border-color: transparent;
}

.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}

.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #052449;
}

.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}

.ant-tag-checkable-checked {
  background-color: #052449;
}

.ant-tag-checkable:active {
  background-color: #010f24;
}

.ant-tag-hidden {
  display: none;
}

.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}

.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}

.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}

.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}

.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}

.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}

.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}

.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}

.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}

.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}

.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}

.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}

.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}

.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}

.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}

.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}

.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}

.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}

.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.ant-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}

.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}

.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}

.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}

.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}

.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}

.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}

.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}

.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}

.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}

.ant-timeline-item-head-blue {
  color: #052449;
  border-color: #052449;
}

.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}

.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}

.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}

.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}

.ant-timeline-rtl {
  direction: rtl;
}

.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}

.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}

.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
  transform: translateY(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
  transform: translateX(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
  transform: translateX(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
  transform: translateY(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-pink .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}

.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}

.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}

.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}

.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}

.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}

.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1;
}

.ant-tooltip-rtl {
  direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}

.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #ffffff;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}

.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}

.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: stretch;
}

.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}

.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}

.ant-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}

.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}

.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}

span.ant-transfer-list-search-action {
  pointer-events: none;
}

.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}

.ant-transfer-list-header > * {
  flex: none;
}

.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}

.ant-transfer-list-header-dropdown {
  font-size: 10px;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  cursor: pointer;
}

.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}

.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}

.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}

.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}

.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}

.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}

.ant-transfer-list-content-item > * {
  flex: none;
}

.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-transfer-list-content-item-remove {
  color: #052449;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #d9d9d9;
}

.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #143457;
}

.ant-transfer-list-content-item-remove:active {
  color: #010f24;
}

.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}

.ant-transfer-list-content-item-remove:hover {
  color: #143457;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #777f85;
}

.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}

.ant-transfer-list-content-item-checked {
  background-color: #7c848a;
}

.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}

.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}

.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}

.ant-transfer-list-checkbox .ant-checkbox {
  top: 0;
}

.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}

.ant-transfer-operation .ant-btn {
  display: block;
}

.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}

.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}

.ant-transfer .ant-empty-image {
  max-height: -2px;
}

.ant-transfer-rtl {
  direction: rtl;
}

.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}

.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}

.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}

.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}

.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}

.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}

.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}

.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}

.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #052449;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}

.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #052449;
}

.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #052449;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: '';
}

.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}

.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}

.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #052449;
  border-color: #052449;
}

.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}

.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}

.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}

.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}

.ant-tree-checkbox-group-item {
  margin-right: 8px;
}

.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #052449;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-tree-checkbox-rtl {
  direction: rtl;
}

.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}

.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}

.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #7c848a;
}

.ant-tree-list-holder-inner {
  align-items: flex-start;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}

.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}

.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}

.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}

.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}

.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}

.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree-switcher-noop {
  cursor: default;
}

.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ant-tree-switcher-loading-icon {
  color: #052449;
}

.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}

.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}

.ant-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}

.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #596e7d;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}

.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}

.ant-tree-node-content-wrapper[draggable='true'] {
  line-height: 24px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #052449;
  border-radius: 1px;
  pointer-events: none;
}

.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #052449;
  border-radius: 50%;
  content: '';
}

.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #052449;
}

.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}

.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}

.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}

.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}

.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.225em;
}

.ant-tree-rtl {
  direction: rtl;
}

.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}

.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}

.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}

.ant-tree-rtl.ant-tree-checkbox {
  margin: 4px 0 0 8px;
}

.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}

.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #052449;
}

.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #052449;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
  animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  content: '';
}

.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}

.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}

.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}

.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #052449;
  border-color: #052449;
}

.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
  animation-name: none;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
  animation-name: none;
}

.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}

.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}

.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}

.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}

.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}

.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}

.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #052449;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}

.ant-select-tree-checkbox-rtl {
  direction: rtl;
}

.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}

.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}

.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}

.ant-tree-select-dropdown {
  padding: 8px 4px 0;
}

.ant-tree-select-dropdown-rtl {
  direction: rtl;
}

.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 8px;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}

.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #7c848a;
}

.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}

.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}

.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}

.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}

.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}

.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}

.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-select-tree-switcher-noop {
  cursor: default;
}

.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.ant-select-tree-switcher-loading-icon {
  color: #052449;
}

.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}

.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}

.ant-select-tree-checkbox {
  top: auto;
  top: initial;
  margin: 4px 8px 0 0;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #596e7d;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}

.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}

.ant-select-tree-node-content-wrapper[draggable='true'] {
  line-height: 24px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #052449;
  border-radius: 1px;
  pointer-events: none;
}

.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #052449;
  border-radius: 50%;
  content: '';
}

.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #052449;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}

.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}

.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}

.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.225em;
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
}

.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}

.ant-typography.ant-typography-success {
  color: #52c41a;
}

.ant-typography.ant-typography-warning {
  color: #faad14;
}

.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}

a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}

.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}

h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}

h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}

h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}

h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}

h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}

.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}

a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
}

a.ant-typography,
.ant-typography a {
  color: #052449;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}

a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #143457;
}

a.ant-typography:active,
.ant-typography a:active {
  color: #010f24;
}

a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}

a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}

a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}

.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}

.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}

.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}

.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}

.ant-typography strong {
  font-weight: 600;
}

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #052449;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}

.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #143457;
}

.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #010f24;
}

.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}

.ant-typography-edit-content {
  position: relative;
}

div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}

.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}

.ant-typography-edit-content textarea {
  -moz-transition: none;
}

.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}

.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}

.ant-typography ul {
  list-style-type: circle;
}

.ant-typography ul ul {
  list-style-type: disc;
}

.ant-typography ol {
  list-style-type: decimal;
}

.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}

.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}

.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}

.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}

.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}

.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.ant-typography-rtl {
  direction: rtl;
}

.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}

.ant-typography-rtl .ant-typography-expand {
  float: left;
}

div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}

.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}

.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}

.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}

.ant-upload p {
  margin: 0;
}

.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}

.ant-upload input[type='file'] {
  cursor: pointer;
}

.ant-upload.ant-upload-select {
  display: inline-block;
}

.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}

.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #052449;
}

.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}

.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #010f24;
}

.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #143457;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #143457;
  font-size: 48px;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}

.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}

.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}

.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}

.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}

.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}

.ant-upload-list::before {
  display: table;
  content: '';
}

.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}

.ant-upload-list::before {
  display: table;
  content: '';
}

.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}

.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}

.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}

.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}

.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
}

.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}

.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}

.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}

.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}

.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}

.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}

.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}

.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}

.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}

.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}

.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}

.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}

.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}

.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}

.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}

.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}

.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
}

.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}

.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}

.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
  animation-name: uploadAnimateInlineIn;
}

.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
  animation-name: uploadAnimateInlineOut;
}

@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-upload-rtl {
  direction: rtl;
}

.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}

.ant-upload-list-rtl {
  direction: rtl;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}

.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}

.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}

.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}

.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}

.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}

.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}

.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}

*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

html {
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, Helvetica, Arial, sans-serif;
  line-height: 150%;
}

body {
  color: #212121;
  font-family: inherit;
  font-size: 16px;
  line-height: inherit;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-transform: none;
  background-color: transparent;
  background-image: none;
  color: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

[class*='border'],
  [class*='divide-'] > :not([hidden]) ~ :not([hidden]) {
  border-width: 0;
  border-style: solid;
  border-color: #e0e0e0;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.avatar {
  color: #fff;
  border-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 9999px;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.avatar__profile-image {
  border-radius: 9999px;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.avatar--organizer {
  border-color: #2fc5d2;
}

.avatar--super-big {
  height: 64px;
  width: 64px;
}

.avatar--super-big.avatar--organizer {
  border-width: 3px;
}

.avatar--super-big .avatar__initials {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar--big {
  height: 48px;
  width: 48px;
}

.avatar--big.avatar--organizer {
  border-width: 3px;
}

.avatar--big .avatar__initials {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar--medium {
  height: 36px;
  width: 36px;
}

.avatar--medium.avatar--organizer {
  border-width: 2px;
}

.avatar--medium .avatar__initials {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar--small {
  height: 28px;
  width: 28px;
}

.avatar--small.avatar--organizer {
  border-width: 2px;
}

.avatar--small .avatar__initials {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar--no-image {
  background-color: #052449;
}

.avatar-group--small > :not([hidden]) ~ :not([hidden]) {
  margin-left: 4px;
}

.avatar-group--small.avatar-group--layered > :not([hidden]) ~ :not([hidden]) {
  margin-left: -8px;
}

.avatar-group--medium > :not([hidden]) ~ :not([hidden]) {
  margin-left: 4px;
}

.avatar-group--medium.avatar-group--layered > :not([hidden]) ~ :not([hidden]) {
  margin-left: -12px;
}

.avatar-group--big > :not([hidden]) ~ :not([hidden]) {
  margin-left: 4px;
}

.avatar-group--big.avatar-group--layered > :not([hidden]) ~ :not([hidden]) {
  margin-left: -16px;
}

.avatar-group--super-big > :not([hidden]) ~ :not([hidden]) {
  margin-left: 8px;
}

.avatar-group--super-big.avatar-group--layered > :not([hidden]) ~ :not([hidden]) {
  margin-left: -20px;
}

.avatar-detailed {
  display: inline-flex;
  align-items: center;
}

.avatar-detailed .avatar-detailed__name {
  color: #212121;
}

.avatar-detailed .avatar-detailed__company {
  color: #616161;
}

.avatar-detailed--super-big .avatar-detailed__details {
  margin-left: 8px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.avatar-detailed--super-big.avatar-detailed--name-and-company .avatar-detailed__details {
  margin-left: 8px;
}

.avatar-detailed--super-big.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.avatar-detailed--super-big.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__company {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar-detailed--big .avatar-detailed__details {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.avatar-detailed--big.avatar-detailed--name-and-company .avatar-detailed__details {
  margin-left: 8px;
}

.avatar-detailed--big.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.avatar-detailed--big.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__company {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar-detailed--medium .avatar-detailed__details {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.avatar-detailed--medium.avatar-detailed--name-and-company .avatar-detailed__details {
  margin-left: 8px;
}

.avatar-detailed--medium.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__name {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.avatar-detailed--medium.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__company {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.avatar-detailed--small .avatar-detailed__details {
  margin-left: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.avatar-detailed--small.avatar-detailed--name-and-company .avatar-detailed__details {
  margin-left: 8px;
}

.avatar-detailed--small.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__name {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.avatar-detailed--small.avatar-detailed--name-and-company .avatar-detailed__details .avatar-detailed__company {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.badge {
  display: inline-flex;
  cursor: default;
  white-space: nowrap;
}

.badge.badge--circular {
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  padding: 0 5px;
}

.badge.badge--circular svg {
  display: inline;
}

.badge.badge--rectangular {
  border-radius: 4px;
}

.badge--big {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.badge--big.badge--rectangular {
  padding: 4px 8px;
}

.badge--medium {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.badge--medium.badge--rectangular {
  padding: 4px 8px;
}

.badge--small {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.badge--small.badge--rectangular {
  padding: 3px 4px;
}

.badge--info {
  background-color: #008dca;
  color: #fff;
}

.badge--success {
  background-color: #1e814e;
  color: #fff;
}

.badge--error {
  background-color: #d62b34;
  color: #fff;
}

.badge--warning {
  background-color: #f19f00;
  color: #212121;
}

.badge--default {
  background-color: #eee;
  color: #052449;
}

.badge--primary {
  background-color: #052449;
  color: #fff;
}

.badge--big.badge--circular {
  height: 32px;
  min-width: 32px;
}

.badge--big.badge--circular svg {
  width: 24px;
  height: 24px;
}

.badge--medium.badge--circular {
  height: 24px;
  min-width: 24px;
}

.badge--medium.badge--circular svg {
  width: 20px;
  height: 20px;
}

.badge--small.badge--circular {
  height: 16px;
  min-width: 16px;
}

.badge--small.badge--circular svg {
  width: 12px;
  height: 12px;
}

.chart-tooltip {
  background-color: #fff;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(5,36,73,0.16), 0 2px 3px -1px rgba(114,0,168,0.16), 0 14px 45px -9px rgba(5,36,73,0.03);
  opacity: 0.9;
  overflow: hidden;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.chart-tooltip__arrow .fill {
  fill: #fff;
  fill-opacity: 0.9;
}

.chart-tooltip__arrow .stroke {
  fill: #9e9e9e;
}

.chart-tooltip__title {
  margin-bottom: 0.25rem;
  text-align: center;
}

.chart-tooltip__table {
  border-collapse: separate;
  border-spacing: 0.5rem 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.chart-tooltip__table-label {
  text-align: left;
}

.chart-tooltip__table-value {
  text-align: right;
}

.chart-tooltip__table-indicator {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 9999px;
}

.form-group input,
.form-group textarea {
  border: none;
}

.form-label {
  color: #212121;
}

.form-label__required {
  color: #d62b34;
}

.form-label {
  --tw-form-label-font-size: 14px;
  --tw-form-label-font-weight: 400px;
  --tw-form-label-letter-spacing: normal;
  --tw-form-label-line-height: 150%;
  --tw-form-label-required-font-size: 14px;
  --tw-form-label-required-font-weight: 600px;
  --tw-form-label-required-letter-spacing: normal;
  --tw-form-label-required-line-height: 150%;
  --tw-form-label-spacing: 4px;
  font-size: var(--tw-form-label-font-size);
  font-weight: var(--tw-form-label-font-weight);
  letter-spacing: var(--tw-form-label-letter-spacing);
  line-height: var(--tw-form-label-line-height);
  text-transform: var(--tw-form-label-text-transform);
  margin-bottom: var(--tw-form-label-spacing);
}

.form-label__required {
  font-size: var(--tw-form-label-required-font-size);
  font-weight: var(--tw-form-label-required-font-weight);
  letter-spacing: var(--tw-form-label-required-letter-spacing);
  line-height: var(--tw-form-label-required-line-height);
  text-transform: var(--tw-form-label-required-text-transform);
}

.form-group--big .form-label {
  --tw-form-label-font-size: 14px;
  --tw-form-label-font-weight: 400px;
  --tw-form-label-letter-spacing: normal;
  --tw-form-label-line-height: 150%;
  --tw-form-label-required-font-size: 14px;
  --tw-form-label-required-font-weight: 600px;
  --tw-form-label-required-letter-spacing: normal;
  --tw-form-label-required-line-height: 150%;
  --tw-form-label-spacing: 4px;
}

.form-group--medium .form-label {
  --tw-form-label-font-size: 14px;
  --tw-form-label-font-weight: 400px;
  --tw-form-label-letter-spacing: normal;
  --tw-form-label-line-height: 150%;
  --tw-form-label-required-font-size: 14px;
  --tw-form-label-required-font-weight: 600px;
  --tw-form-label-required-letter-spacing: normal;
  --tw-form-label-required-line-height: 150%;
  --tw-form-label-spacing: 4px;
}

.form-group--small .form-label {
  --tw-form-label-font-size: 12px;
  --tw-form-label-font-weight: 400px;
  --tw-form-label-letter-spacing: 0.01em;
  --tw-form-label-line-height: 150%;
  --tw-form-label-required-font-size: 12px;
  --tw-form-label-required-font-weight: 600px;
  --tw-form-label-required-letter-spacing: 0.01em;
  --tw-form-label-required-line-height: 150%;
  --tw-form-label-spacing: 4px;
}

.form-message {
  --tw-form-message-font-size: 12px;
  --tw-form-message-font-weight: 400px;
  --tw-form-message-letter-spacing: 0.01em;
  --tw-form-message-line-height: 150%;
  --tw-form-message-spacing: 4px;
  --tw-form-message-color: #757575;
  font-size: var(--tw-form-message-font-size);
  font-weight: var(--tw-form-message-font-weight);
  letter-spacing: var(--tw-form-message-letter-spacing);
  line-height: var(--tw-form-message-line-height);
  text-transform: var(--tw-form-message-text-transform);
  margin-top: var(--tw-form-message-spacing);
  color: var(--tw-form-message-color);
}

.form-group--big .form-message {
  --tw-form-message-font-size: 12px;
  --tw-form-message-font-weight: 400px;
  --tw-form-message-letter-spacing: 0.01em;
  --tw-form-message-line-height: 150%;
  --tw-form-message-spacing: 4px;
}

.form-group--medium .form-message {
  --tw-form-message-font-size: 12px;
  --tw-form-message-font-weight: 400px;
  --tw-form-message-letter-spacing: 0.01em;
  --tw-form-message-line-height: 150%;
  --tw-form-message-spacing: 4px;
}

.form-group--small .form-message {
  --tw-form-message-font-size: 12px;
  --tw-form-message-font-weight: 400px;
  --tw-form-message-letter-spacing: 0.01em;
  --tw-form-message-line-height: 150%;
  --tw-form-message-spacing: 4px;
}

.form-message {
  --tw-form-message-color: #757575;
}

.form-message.form-message--has-error {
  --tw-form-message-color: #d62b34;
}

.form-group .form-input {
  border-style: solid;
  display: flex;
  cursor: text;
  transition: border-color 200ms;
  align-items: center;
}

.form-group .form-input .form-input__input {
  flex-grow: 1;
}

.form-group .form-input .form-input__input:focus {
  outline: none;
}

.form-group .form-input textarea.form-input__input {
  height: auto;
}

.form-group .form-input .form-input__icon,
.form-group .form-input .form-input__prefix,
.form-group .form-input .form-input__suffix {
  flex-shrink: 0;
}

.form-group.form-group--disabled .form-input {
  cursor: default;
}

.form-group--big .form-input {
  border-radius: 4px;
  border-width: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.form-group--big .form-input .form-input__input,
.form-group--big .form-input .form-input__prefix,
.form-group--big .form-input .form-input__suffix {
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.form-group--big .form-input .form-input__icon {
  width: 24px;
  height: 24px;
}

.form-group--big .form-input > :not([hidden]) ~ :not([hidden]) {
  margin-left: 8px;
}

.form-group--medium .form-input {
  border-radius: 4px;
  border-width: 1px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-group--medium .form-input .form-input__input,
.form-group--medium .form-input .form-input__prefix,
.form-group--medium .form-input .form-input__suffix {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.form-group--medium .form-input .form-input__icon {
  width: 16px;
  height: 16px;
}

.form-group--medium .form-input > :not([hidden]) ~ :not([hidden]) {
  margin-left: 8px;
}

.form-group--small .form-input {
  border-radius: 4px;
  border-width: 1px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.form-group--small .form-input .form-input__input,
.form-group--small .form-input .form-input__prefix,
.form-group--small .form-input .form-input__suffix {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.form-group--small .form-input .form-input__icon {
  width: 14px;
  height: 14px;
}

.form-group--small .form-input > :not([hidden]) ~ :not([hidden]) {
  margin-left: 6px;
}

.form-group.form-group--disabled .form-input {
  background-color: #fff;
  border-color: #e0e0e0;
}

.form-group.form-group--disabled .form-input .form-input__input {
  color: #e0e0e0;
  background-color: transparent;
}

.form-group.form-group--disabled .form-input .form-input__prefix,
.form-group.form-group--disabled .form-input .form-input__suffix {
  color: #e0e0e0;
}

.form-group.form-group--disabled .form-input .form-input__icon {
  color: #e0e0e0;
}

.form-group .form-input {
  background-color: #fff;
  border-color: #757575;
}

.form-group .form-input .form-input__input {
  color: #212121;
  background-color: transparent;
}

.form-group .form-input .form-input__prefix,
.form-group .form-input .form-input__suffix {
  color: #9e9e9e;
}

.form-group .form-input .form-input__icon {
  color: #9e9e9e;
}

.form-group:focus-within .form-input {
  background-color: #fff;
  border-color: #052449;
}

.form-group:focus-within .form-input .form-input__input {
  color: #212121;
  background-color: transparent;
}

.form-group:focus-within .form-input .form-input__prefix,
.form-group:focus-within .form-input .form-input__suffix {
  color: #9e9e9e;
}

.form-group:focus-within .form-input .form-input__icon {
  color: #9e9e9e;
}

.form-group.form-group--has-error .form-input {
  background-color: #fff;
  border-color: #d62b34;
}

.form-group.form-group--has-error .form-input .form-input__input {
  color: #212121;
  background-color: transparent;
}

.form-group.form-group--has-error .form-input .form-input__prefix,
.form-group.form-group--has-error .form-input .form-input__suffix {
  color: #9e9e9e;
}

.form-group.form-group--has-error .form-input .form-input__icon {
  color: #9e9e9e;
}

.search-group.form-group {
  display: flex;
}

.search-group.form-group .form-input {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
}

.search-group.form-group .search-group__button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 0 0 auto;
}

.search-group.form-group.search-group.form-group--empty .search-group__clear-button {
  display: none;
}

.form-checkbox {
  cursor: pointer;
  display: inline-block;
  padding-left: var(--form-checkbox-hanging-indent);
  text-indent: calc(-1 * var(--form-checkbox-hanging-indent));
}

.form-checkbox .form-checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.form-checkbox .form-checkbox__icon {
  display: inline-block;
  vertical-align: middle;
}

.form-checkbox .form-checkbox__label {
  vertical-align: middle;
}

.form-checkbox.form-checkbox--disabled {
  cursor: default;
}

.form-checkbox--small {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  --form-checkbox-hanging-indent: 24px;
}

.form-checkbox--small .form-checkbox__icon {
  width: 16px;
  height: 16px;
}

.form-checkbox--small .form-checkbox__icon + .form-checkbox__label {
  margin-left: 8px;
}

.form-checkbox--medium {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  --form-checkbox-hanging-indent: 32px;
}

.form-checkbox--medium .form-checkbox__icon {
  width: 24px;
  height: 24px;
}

.form-checkbox--medium .form-checkbox__icon + .form-checkbox__label {
  margin-left: 8px;
}

.form-checkbox .form-checkbox__input[aria-checked="true"] ~ .form-checkbox__label,
.form-checkbox .form-checkbox__input[aria-checked="mixed"] ~ .form-checkbox__label {
  color: #212121;
}

.form-checkbox .form-checkbox__input[aria-checked="true"] ~ .form-checkbox__icon,
.form-checkbox .form-checkbox__input[aria-checked="mixed"] ~ .form-checkbox__icon {
  color: #052449;
}

.form-checkbox:hover .form-checkbox__label,
.form-checkbox .form-checkbox__input:focus-visible ~ .form-checkbox__label {
  color: #212121;
}

.form-checkbox:hover .form-checkbox__icon,
.form-checkbox .form-checkbox__input:focus-visible ~ .form-checkbox__icon {
  color: #37506d;
}

.form-checkbox .form-checkbox__label {
  color: #212121;
}

.form-checkbox .form-checkbox__icon {
  color: #757575;
}

.form-checkbox.form-checkbox--disabled .form-checkbox__label {
  color: #bdbdbd;
}

.form-checkbox.form-checkbox--disabled .form-checkbox__icon {
  color: #bdbdbd;
}

.form-toggle {
  cursor: pointer;
  display: inline-block;
}

.form-toggle .form-toggle__input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.form-toggle .form-toggle__toggle,
.form-toggle .form-toggle__toggle::after {
  border-radius: 9999px;
}

.form-toggle .form-toggle__toggle {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.form-toggle .form-toggle__toggle::after {
  background-color: white;
  content: "";
  position: absolute;
  transition: -webkit-transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.form-toggle .form-toggle__label {
  vertical-align: middle;
}

.form-toggle.form-toggle--disabled {
  cursor: default;
}

.form-toggle.form-toggle--small .form-toggle__label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.form-toggle.form-toggle--small .form-toggle__toggle {
  width: 32px;
  height: 16px;
}

.form-toggle.form-toggle--small .form-toggle__toggle::after {
  height: 10px;
  width: 10px;
  top: 3px;
  left: 0;
  -webkit-transform: translate(3px, 0);
          transform: translate(3px, 0);
}

.form-toggle.form-toggle--small .form-toggle__input:checked ~ .form-toggle__toggle::after {
  -webkit-transform: translate(19px, 0);
          transform: translate(19px, 0);
}

.form-toggle.form-toggle--small .form-toggle__toggle + .form-toggle__label {
  margin-left: 8px;
}

.form-toggle.form-toggle--medium .form-toggle__label {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.form-toggle.form-toggle--medium .form-toggle__toggle {
  width: 48px;
  height: 24px;
}

.form-toggle.form-toggle--medium .form-toggle__toggle::after {
  height: 16px;
  width: 16px;
  top: 4px;
  left: 0;
  -webkit-transform: translate(4px, 0);
          transform: translate(4px, 0);
}

.form-toggle.form-toggle--medium .form-toggle__input:checked ~ .form-toggle__toggle::after {
  -webkit-transform: translate(28px, 0);
          transform: translate(28px, 0);
}

.form-toggle.form-toggle--medium .form-toggle__toggle + .form-toggle__label {
  margin-left: 12px;
}

.form-toggle .form-toggle__toggle {
  background-color: #757575;
}

.form-toggle .form-toggle__label {
  color: #212121;
}

.form-toggle:hover .form-toggle__toggle,
.form-toggle .form-toggle__input:focus-visible ~ .form-toggle__toggle {
  background-color: #9e9e9e;
}

.form-toggle:hover .form-toggle__label,
.form-toggle .form-toggle__input:focus-visible ~ .form-toggle__label {
  color: #212121;
}

.form-toggle.form-toggle--disabled .form-toggle__toggle {
  background-color: #bdbdbd;
}

.form-toggle.form-toggle--disabled .form-toggle__label {
  color: #bdbdbd;
}

.form-toggle .form-toggle__input:checked ~ .form-toggle__toggle {
  background-color: #052449;
}

.form-toggle .form-toggle__input:checked ~ .form-toggle__label {
  color: #212121;
}

.form-toggle:hover .form-toggle__input:checked ~ .form-toggle__toggle,
.form-toggle .form-toggle__input:checked:focus-visible ~ .form-toggle__toggle {
  background-color: #37506d;
}

.form-toggle:hover .form-toggle__input:checked ~ .form-toggle__label,
.form-toggle .form-toggle__input:checked:focus-visible ~ .form-toggle__label {
  color: #212121;
}

.form-toggle.form-toggle--disabled .form-toggle__input:checked ~ .form-toggle__toggle {
  background-color: #bdbdbd;
}

.form-toggle.form-toggle--disabled .form-toggle__input:checked ~ .form-toggle__label {
  color: #bdbdbd;
}

.form-group .form-select {
  position: relative;
}

.form-group .form-select .form-select__dropdown {
  border-style: solid;
  display: flex;
  cursor: pointer;
  transition: border-color 200ms;
  align-items: center;
  width: 100%;
  text-align: left;
}

.form-group .form-select .form-select__dropdown:focus {
  outline: none;
}

.form-group .form-select .form-select__dropdown .form-select__label {
  flex-grow: 1;
}

.form-group .form-select .form-select__dropdown .form-select__clear-button {
  flex-shrink: 0;
}

.form-group .form-select .form-select__dropdown .form-select__icon {
  flex-shrink: 0;
}

.form-group .form-select [role="listbox"]:focus {
  outline: none;
}

.form-group .form-select .form-select__menu-outer {
  z-index: 1070;
}

.form-group .form-select .form-select__menu {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  border-style: solid;
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-height: 24rem;
  overflow: auto;
}

.form-group .form-select .form-select__menu .form-select__menu-item {
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
}

.form-group .form-select.form-select--auto-complete .form-select__label {
  display: grid;
  flex: 1 1 0%;
  overflow: hidden;
  position: relative;
}

.form-group .form-select.form-select--auto-complete .form-select__value,
.form-group .form-select.form-select--auto-complete .form-select__input {
  grid-area: 1 / 1 / 2 / 2;
}

.form-group .form-select.form-select--auto-complete .form-select__value {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-group .form-select.form-select--auto-complete .form-select__input {
  background-color: transparent;
  color: #000;
}

.form-group .form-select.form-select--auto-complete .form-select__input:focus {
  outline: none;
}

.form-group.form-group--disabled .form-select .form-select__dropdown {
  cursor: default;
}

.form-group--medium .form-select .form-select__dropdown {
  border-radius: 4px;
  border-width: 1px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}

.form-group--medium .form-select .form-select__label {
  height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.form-group--medium .form-select .form-select__icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.form-group--medium .form-select.form-select--auto-complete .form-select__value,
.form-group--medium .form-select.form-select--auto-complete .form-select__input {
  height: 21px;
}

.form-group--big .form-select .form-select__dropdown {
  border-radius: 4px;
  border-width: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.form-group--big .form-select .form-select__label {
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.form-group--big .form-select .form-select__icon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.form-group--big .form-select.form-select--auto-complete .form-select__value,
.form-group--big .form-select.form-select--auto-complete .form-select__input {
  height: 24px;
}

.form-group--small .form-select .form-select__dropdown {
  border-radius: 4px;
  border-width: 1px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.form-group--small .form-select .form-select__label {
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.form-group--small .form-select .form-select__icon {
  width: 14px;
  height: 14px;
  margin-left: 8px;
}

.form-group--small .form-select.form-select--auto-complete .form-select__value,
.form-group--small .form-select.form-select--auto-complete .form-select__input {
  height: 18px;
}

 .form-select .form-select__dropdown {
  background-color: #fff;
  border-color: #bdbdbd;
  color: #000;
}

.form-group:focus-within .form-select .form-select__dropdown {
  background-color: #fff;
  border-color: #052449;
  color: #000;
}

.form-group.form-group--has-error .form-select .form-select__dropdown {
  background-color: #fff;
  border-color: #d62b34;
  color: #000;
}

.form-group.form-group--empty .form-select .form-select__dropdown {
  color: #9e9e9e;
}

.form-group.form-group--empty .form-select .form-select__dropdown .form-select__clear-button {
  display: none;
}

.form-group.form-group--disabled .form-select .form-select__dropdown {
  background-color: #fff;
  border-color: #e0e0e0;
  color: #e0e0e0;
}

.form-group .form-select .form-select__menu--medium .form-select__menu-item {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  word-break: break-word;
}

.form-group .form-select .form-select__menu--small .form-select__menu-item {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  word-break: break-word;
}

.form-group .form-select .form-select__menu .form-select__menu-item {
  background-color: transparent;
  color: #424242;
  border-bottom-color: #e0e0e0;
}

.form-group .form-select .form-select__menu undefined {
  background-color: #f1f4f9;
  color: #052449;
  border-bottom-color: #e0e0e0;
}

.form-group .form-select .form-select__menu .form-select__menu-item.form-select__menu-item--highlighted {
  background-color: #e2e8f4;
  color: #424242;
  border-bottom-color: #e0e0e0;
}

.form-group .form-select .form-select__menu .form-select__menu-item.form-select__menu-item--disabled {
  text-color: #424242;
  background-color: transparent;
  divider-color: #e0e0e0;
  color: #e0e0e0;
}

.form-group.date-picker-group .react-datepicker {
  background-color: #fff;
  box-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  cursor: default;
  display: inline-block;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.form-group.date-picker-group .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.form-group.date-picker-group .react-datepicker-popper {
  z-index: 1;
}

.form-group.date-picker-group .react-datepicker__tab-loop {
  margin: 0 !important;
}

.form-group.date-picker-group .react-datepicker__input.form-input__input {
  width: 100%;
}

.form-group.date-picker-group .react-datepicker__input--clearable {
  padding-right: 16px;
}

.form-group.date-picker-group .react-datepicker__input-container {
  display: inline-block;
  position: relative;
  width: 100%;
}

.form-group.date-picker-group .react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.form-group.date-picker-group .react-datepicker__close-icon::after {
  cursor: pointer;
  color: #9e9e9e;
  height: 20px;
  width: 20px;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  content: "×";
  display: inline-block;
}

.form-group.date-picker-group .react-datepicker__header {
  border-bottom-color: #e2e8f4;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-bottom: 16px;
}

.form-group.date-picker-group .react-datepicker__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-group.date-picker-group .react-datepicker__current-month {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 150%;
  color: #000;
}

.form-group.date-picker-group .react-datepicker__day-names,
.form-group.date-picker-group .react-datepicker__week,
.form-group.date-picker-group .react-datepicker__month-wrapper,
.form-group.date-picker-group .react-datepicker__quarter-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.form-group.date-picker-group .react-datepicker__year-wrapper {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 12px 8px;
}

.form-group.date-picker-group .react-datepicker__day-names {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  color: #424242;
}

.form-group.date-picker-group .react-datepicker__month,
.form-group.date-picker-group .react-datepicker__year {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

.form-group.date-picker-group .react-datepicker__day-name,
.form-group.date-picker-group .react-datepicker__day,
.form-group.date-picker-group .react-datepicker__month-text,
.form-group.date-picker-group .react-datepicker__quarter-text,
.form-group.date-picker-group .react-datepicker__year-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group.date-picker-group .react-datepicker__day-name {
  width: 40px;
}

.form-group.date-picker-group .react-datepicker__day,
.form-group.date-picker-group .react-datepicker__month-text,
.form-group.date-picker-group .react-datepicker__quarter-text,
.form-group.date-picker-group .react-datepicker__year-text {
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  color: #424242;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
}

.form-group.date-picker-group .react-datepicker__day--outside-month, .form-group.date-picker-group .react-datepicker__month-text--outside-month, .form-group.date-picker-group .react-datepicker__quarter-text--outside-month, .form-group.date-picker-group .react-datepicker__year-text--outside-month {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  color: #9e9e9e;
}

.form-group.date-picker-group .react-datepicker__day--today, .form-group.date-picker-group .react-datepicker__month-text--today, .form-group.date-picker-group .react-datepicker__quarter-text--today, .form-group.date-picker-group .react-datepicker__year-text--today {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  color: #4b70b8;
}

.form-group.date-picker-group .react-datepicker__day:hover,
.form-group.date-picker-group .react-datepicker__day--keyboard-selected,
.form-group.date-picker-group .react-datepicker__month-text:hover,
.form-group.date-picker-group .react-datepicker__month-text--keyboard-selected,
.form-group.date-picker-group .react-datepicker__quarter-text:hover,
.form-group.date-picker-group .react-datepicker__quarter-text--keyboard-selected,
.form-group.date-picker-group .react-datepicker__year-text:hover,
.form-group.date-picker-group .react-datepicker__year-text--keyboard-selected {
  background-color: #f1f4f9;
  border-color: #f1f4f9;
}

.form-group.date-picker-group .react-datepicker__day--selected, .form-group.date-picker-group .react-datepicker__month--selected, .form-group.date-picker-group .react-datepicker__quarter--selected, .form-group.date-picker-group .react-datepicker__year-text--selected {
  background-color: #e2e8f4;
  border-color: #4b70b8;
}

.form-group.date-picker-group .react-datepicker__day--disabled,
.form-group.date-picker-group .react-datepicker__day--disabled:hover,
.form-group.date-picker-group .react-datepicker__day--keyboard-selected.react-datepicker__day--disabled,
.form-group.date-picker-group .react-datepicker__month--disabled,
.form-group.date-picker-group .react-datepicker__month--disabled:hover,
.form-group.date-picker-group .react-datepicker__month--keyboard-selected.react-datepicker__day--disabled,
.form-group.date-picker-group .react-datepicker__quarter--disabled,
.form-group.date-picker-group .react-datepicker__quarter--disabled:hover,
.form-group.date-picker-group .react-datepicker__quarter--keyboard-selected.react-datepicker__day--disabled,
.form-group.date-picker-group .react-datepicker__year-text--disabled,
.form-group.date-picker-group .react-datepicker__year-text--disabled:hover,
.form-group.date-picker-group .react-datepicker__year-text--keyboard-selected.react-datepicker__day--disabled {
  color: #bdbdbd;
  cursor: default;
  background-color: transparent;
  border-color: transparent;
}

.form-group.date-picker-group--date .react-datepicker__header {
  padding-bottom: 16px;
}

.tooltip {
  max-width: 16rem;
  text-align: center;
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

[data-enter] > .tooltip {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.tooltip--top {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.tooltip--top-start {
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.tooltip--top-end {
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.tooltip--right {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.tooltip--right-start {
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.tooltip--right-end {
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.tooltip--bottom {
  -webkit-transform-origin: top;
          transform-origin: top;
}

.tooltip--bottom-start {
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.tooltip--bottom-end {
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.tooltip--left {
  -webkit-transform-origin: right;
          transform-origin: right;
}

.tooltip--left-start {
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.tooltip--left-end {
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.tooltip {
  padding: 16px;
  border-radius: 4px;
  background-color: #212121;
}

.tooltip__body {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.tooltip__title + .tooltip__body {
  margin-top: 4px;
}

.tooltip__title {
  color: #2fc5d2;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tooltip__arrow svg {
  display: block;
}

.tooltip__outer {
  z-index: 2000;
}

.popover {
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

[data-enter] > .popover {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.popover--top {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.popover--top-start {
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.popover--top-end {
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.popover--right {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.popover--right-start {
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.popover--right-end {
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.popover--bottom {
  -webkit-transform-origin: top;
          transform-origin: top;
}

.popover--bottom-start {
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.popover--bottom-end {
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.popover--left {
  -webkit-transform-origin: right;
          transform-origin: right;
}

.popover--left-start {
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.popover--left-end {
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.popover__arrow svg {
  display: block;
}

.popover__outer {
  z-index: 1050;
}

.pop-confirm {
  max-width: 24rem;
  border-radius: 4px;
  background-color: #fff;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

[data-enter] > .pop-confirm {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.pop-confirm--top {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.pop-confirm--top-start {
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.pop-confirm--top-end {
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.pop-confirm--right {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.pop-confirm--right-start {
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.pop-confirm--right-end {
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}

.pop-confirm--bottom {
  -webkit-transform-origin: top;
          transform-origin: top;
}

.pop-confirm--bottom-start {
  -webkit-transform-origin: left top;
          transform-origin: left top;
}

.pop-confirm--bottom-end {
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.pop-confirm--left {
  -webkit-transform-origin: right;
          transform-origin: right;
}

.pop-confirm--left-start {
  -webkit-transform-origin: right top;
          transform-origin: right top;
}

.pop-confirm--left-end {
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
}

.pop-confirm__arrow svg {
  display: block;
}

.pop-confirm__outer {
  z-index: 1050;
}

.pop-confirm__message {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0;
  color: #212121;
}

.pop-confirm__actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}

.pop-confirm__actions > :not([hidden]) ~ :not([hidden]) {
  margin-left: 0.5rem;
}

.pop-confirm__arrow .stroke {
  fill: #e0e0e0;
}

.pop-confirm__arrow .fill {
  fill: #fff;
}

.button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
}

.button--icon-only {
  min-width: auto;
  flex-shrink: 0;
}

.button__icon {
  display: inline;
  flex-shrink: 0;
}

.button.button--big {
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 24px;
  min-width: 50px;
}

.button.button--big .button__icon {
  width: 24px;
  height: 24px;
}

.button.button--big .button__icon.button__icon--left {
  margin-right: 8px;
}

.button.button--big .button__icon.button__icon--right {
  margin-left: 8px;
}

.button.button--big.button--icon-only {
  padding: 8px;
}

.button.button--big.button--icon-only .button__icon {
  width: 32px;
  height: 32px;
}

.button.button--medium {
  padding: 5px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 22px;
  min-width: 34px;
}

.button.button--medium .button__icon {
  width: 16px;
  height: 16px;
}

.button.button--medium .button__icon.button__icon--left {
  margin-right: 8px;
}

.button.button--medium .button__icon.button__icon--right {
  margin-left: 8px;
}

.button.button--medium.button--icon-only {
  padding: 4px;
}

.button.button--medium.button--icon-only .button__icon {
  width: 24px;
  height: 24px;
}

.button.button--small {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 20px;
  min-width: 26px;
}

.button.button--small .button__icon {
  width: 14px;
  height: 14px;
}

.button.button--small .button__icon.button__icon--left {
  margin-right: 4px;
}

.button.button--small .button__icon.button__icon--right {
  margin-left: 4px;
}

.button.button--small.button--icon-only {
  padding: 4px;
}

.button.button--small.button--icon-only .button__icon {
  width: 16px;
  height: 16px;
}

.button.button--filled {
  background-color: #052449;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled:active,
.button.button--filled.button--active {
  background-color: #041d3a;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled:hover,
.button.button--filled:focus-visible {
  background-color: #37506d;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--secondary {
  background-color: #4b70b8;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--secondary:active,
.button.button--filled.button--secondary.button--active {
  background-color: #3c5a93;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--secondary:hover,
.button.button--filled.button--secondary:focus-visible {
  background-color: #6f8dc6;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--success {
  background-color: #1e814e;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--success:active,
.button.button--filled.button--success.button--active {
  background-color: #18673e;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--success:hover,
.button.button--filled.button--success:focus-visible {
  background-color: #4b9a71;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--error {
  background-color: #d62b34;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--error:active,
.button.button--filled.button--error.button--active {
  background-color: #b01a22;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled.button--error:hover,
.button.button--filled.button--error:focus-visible {
  background-color: #e04f56;
  color: #fff;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--filled[disabled],
.button.button--filled[aria-disabled="true"] {
  background-color: #f5f5f5;
  color: #bdbdbd;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined {
  background-color: #fff;
  color: #052449;
  border-color: #052449;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined:active,
.button.button--outlined.button--active {
  background-color: #fff;
  color: #041d3a;
  border-color: #041d3a;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined:hover,
.button.button--outlined:focus-visible {
  background-color: #fff;
  color: #37506d;
  border-color: #37506d;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--secondary {
  background-color: #fff;
  color: #4b70b8;
  border-color: #4b70b8;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--secondary:active,
.button.button--outlined.button--secondary.button--active {
  background-color: #fff;
  color: #3c5a93;
  border-color: #3c5a93;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--secondary:hover,
.button.button--outlined.button--secondary:focus-visible {
  background-color: #fff;
  color: #6f8dc6;
  border-color: #6f8dc6;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--success {
  background-color: #fff;
  color: #1e814e;
  border-color: #1e814e;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--success:active,
.button.button--outlined.button--success.button--active {
  background-color: #fff;
  color: #18673e;
  border-color: #18673e;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--success:hover,
.button.button--outlined.button--success:focus-visible {
  background-color: #fff;
  color: #4b9a71;
  border-color: #4b9a71;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--error {
  background-color: #fff;
  color: #d62b34;
  border-color: #d62b34;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--error:active,
.button.button--outlined.button--error.button--active {
  background-color: #fff;
  color: #b01a22;
  border-color: #b01a22;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined.button--error:hover,
.button.button--outlined.button--error:focus-visible {
  background-color: #fff;
  color: #e04f56;
  border-color: #e04f56;
  border-width: 1px;
  border-style: solid;
}

.button.button--outlined[disabled],
.button.button--outlined[aria-disabled="true"] {
  background-color: #f5f5f5;
  color: #bdbdbd;
  border-color: #bdbdbd;
  border-width: 1px;
  border-style: solid;
}

.button.button--link {
  background-color: transparent;
  color: #052449;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link:active,
.button.button--link.button--active {
  background-color: transparent;
  color: #041d3a;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link:hover,
.button.button--link:focus-visible {
  background-color: transparent;
  color: #37506d;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--secondary {
  background-color: transparent;
  color: #4b70b8;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--secondary:active,
.button.button--link.button--secondary.button--active {
  background-color: transparent;
  color: #3c5a93;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--secondary:hover,
.button.button--link.button--secondary:focus-visible {
  background-color: transparent;
  color: #6f8dc6;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--success {
  background-color: transparent;
  color: #1e814e;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--success:active,
.button.button--link.button--success.button--active {
  background-color: transparent;
  color: #18673e;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--success:hover,
.button.button--link.button--success:focus-visible {
  background-color: transparent;
  color: #4b9a71;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--error {
  background-color: transparent;
  color: #d62b34;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--error:active,
.button.button--link.button--error.button--active {
  background-color: transparent;
  color: #b01a22;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link.button--error:hover,
.button.button--link.button--error:focus-visible {
  background-color: transparent;
  color: #e04f56;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button.button--link[disabled],
.button.button--link[aria-disabled="true"] {
  background-color: transparent;
  color: #bdbdbd;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
}

.button[disabled],
.button[aria-disabled="true"] {
  cursor: auto;
  pointer-events: none;
}

.menu__list {
  z-index: 1070;
}

.menu__list-inner {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 12rem;
  border-radius: 4px;
  box-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  opacity: 0;
  -webkit-transform: scaleY(0.8);
          transform: scaleY(0.8);
}

[data-enter] > .menu__list-inner {
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.menu__list-inner--top,
.menu__list-inner--top-start,
.menu__list-inner--top-end,
.menu__list-inner--right-end,
.menu__list-inner--left-end {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.menu__list-inner--bottom,
.menu__list-inner--bottom-start,
.menu__list-inner--bottom-end,
.menu__list-inner--right,
.menu__list-inner--right-start,
.menu__list-inner--left,
.menu__list-inner--left-start {
  -webkit-transform-origin: top;
          transform-origin: top;
}

.menu__list-inner > :first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.menu__item {
  cursor: pointer;
  padding-right: 8px;
  border-left-color: transparent;
  border-left-width: 8px;
  border-left-style: solid;
  color: #424242;
  background-color: transparent;
}

.menu__item .menu__item-inner {
  font-weight: 400;
}

.menu__item:hover, .menu__item:focus {
  color: #424242;
  background-color: #e2e8f4;
}

.menu__item:hover .menu__item-inner, .menu__item:focus .menu__item-inner {
  font-weight: 400;
}

.menu__item--selected,
.menu__item--selected:hover,
.menu__item--selected:focus {
  color: #052449;
  background-color: #f1f4f9;
}

.menu__item--selected .menu__item-inner, .menu__item--selected:hover .menu__item-inner, .menu__item--selected:focus .menu__item-inner {
  font-weight: 600;
}

.menu__item ~ .menu__item {
  border-top-color: #e0e0e0;
  border-top-width: 1px;
  border-top-style: solid;
}

.menu__item[disabled] {
  cursor: default;
}

.menu__item-inner {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.menu__item[disabled] .menu__item-inner {
  opacity: 0.5;
}

.menu__item--selected {
  padding-left: 8px;
  border-left-color: #4b70b8;
}

.menu__item--selected:hover {
  cursor: default;
}

.menu__list--medium .menu__item {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.menu__list--medium .menu__item-inner {
  height: 48px;
}

.menu__list--small .menu__item {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.menu__list--small .menu__item-inner {
  height: 40px;
}

.menu-button-group__button.button.button--big .button__icon--right {
  margin-left: 24px;
  width: 32px;
  height: 32px;
}

.menu-button-group__button.button.button--medium .button__icon--right {
  margin-left: 16px;
  width: 24px;
  height: 24px;
}

.menu-button-group__button.button.button--small .button__icon--right {
  margin-left: 12px;
  width: 16px;
  height: 16px;
}

.menu-button-group {
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  border-radius: 4px;
  background-color: #fff;
}

.menu-button-group--filled button:not(:first-child) {
  margin-left: 1px;
}

.menu-button-group__primary-button.button {
  border-radius: 4px 0 0 4px;
}

.menu-button-group__primary-button.button.button--outlined {
  border-right-width: 0;
}

.menu-button-group__expand-button.button {
  border-radius: 0 4px 4px 0;
}

.tab-group .tab {
  border: none;
}

.tab-group .tab,
.tab-menu .tab {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tab-group .tab:focus, .tab-menu .tab:focus {
  outline: none;
}

.tab-group .tab .tab__indicator {
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
}

.tab-group.tab-group--vertical .tab .tab__indicator {
  height: auto;
  top: 0;
  right: auto;
  bottom: 0;
  left: auto;
}

.tab-group {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  max-width: -webkit-max-content;
  max-width: max-content;
}

.tab-group--vertical {
  flex-direction: column;
}

.tab-menu {
  z-index: 1070;
  width: 100%;
}

.tab-menu__container {
  position: relative;
}

.tab-menu__button {
  width: 100%;
  position: relative;
  transition: box-shadow 150ms ease-in-out;
}

.tab-menu__button::after {
  background-color: #fff;
  position: absolute;
  content: "";
  right: -1px;
  bottom: -1px;
  left: -1px;
  height: 50%;
  transition: opacity 150ms ease-in-out;
  opacity: 0;
  z-index: -1;
}

.tab-menu__button--open {
  box-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
}

.tab-menu__button--open::after {
  opacity: 1;
}

.tab-menu-inner {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
  min-width: 12rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  opacity: 0;
  -webkit-transform: scaleY(0.8);
          transform: scaleY(0.8);
}

[data-enter] > .tab-menu-inner {
  opacity: 1;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.tab-menu-inner--top,
.tab-menu-inner--top-start,
.tab-menu-inner--top-end,
.tab-menu-inner--right-end,
.tab-menu-inner--left-end {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}

.tab-menu-inner--bottom,
.tab-menu-inner--bottom-start,
.tab-menu-inner--bottom-end,
.tab-menu-inner--right,
.tab-menu-inner--right-start,
.tab-menu-inner--left,
.tab-menu-inner--left-start {
  -webkit-transform-origin: top;
          transform-origin: top;
}

.tab-menu .tab {
  padding-right: 8px;
  border-left-color: transparent;
  border-left-width: 8px;
  border-left-style: solid;
  justify-content: flex-start;
  color: #424242;
  background-color: transparent;
}

.tab-menu .tab:hover, .tab-menu .tab:focus {
  color: #424242;
  background-color: #e2e8f4;
}

.tab-menu .tab--selected,
.tab-menu .tab--selected:hover,
.tab-menu .tab--selected:focus {
  color: #052449;
  background-color: #f1f4f9;
}

.tab-menu .tab[disabled] {
  color: #bdbdbd;
  cursor: default;
}

.tab-menu .tab.tab--selected {
  padding-left: 8px;
  border-left-color: #4b70b8;
}

.tab-menu .tab.tab--selected:hover {
  cursor: default;
}

.tab-menu--medium .tab {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  height: 48px;
}

.tab-menu--medium .tab .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-menu--medium .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-menu--medium .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-menu--small .tab {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  height: 40px;
}

.tab-menu--small .tab .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-menu--small .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-menu--small .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group--filled {
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
}

.tab-group.tab-group--filled.tab-group--big {
  border-radius: 4px;
}

.tab-group.tab-group--filled.tab-group--big .tab {
  height: 48px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.tab-group.tab-group--filled.tab-group--big .tab .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--filled.tab-group--big .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--filled.tab-group--big .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--filled.tab-group--big .tab.tab--icon-only .tab__icon {
  width: 32px;
  height: 32px;
}

.tab-group.tab-group--filled.tab-group--medium {
  border-radius: 4px;
}

.tab-group.tab-group--filled.tab-group--medium .tab {
  height: 32px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.tab-group.tab-group--filled.tab-group--medium .tab .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--filled.tab-group--medium .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--filled.tab-group--medium .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--filled.tab-group--medium .tab.tab--icon-only .tab__icon {
  width: 20px;
  height: 20px;
}

.tab-group.tab-group--filled.tab-group--small {
  border-radius: 4px;
}

.tab-group.tab-group--filled.tab-group--small .tab {
  height: 24px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.tab-group.tab-group--filled.tab-group--small .tab .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group.tab-group--filled.tab-group--small .tab .tab__icon--left {
  margin-right: 4px;
}

.tab-group.tab-group--filled.tab-group--small .tab .tab__icon--right {
  margin-left: 4px;
}

.tab-group.tab-group--filled.tab-group--small .tab.tab--icon-only .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--filled .tab:not(:last-child) {
  border-right-color: #e0e0e0;
  border-right-width: 1px;
  border-right-style: solid;
}

.tab-group.tab-group--filled.tab-group--primary .tab {
  background-color: #fff;
  color: #052449;
}

.tab-group.tab-group--filled.tab-group--primary .tab:hover,
.tab-group.tab-group--filled.tab-group--primary .tab:focus-visible {
  background-color: #f5f5f5;
  color: #37506d;
}

.tab-group.tab-group--filled.tab-group--primary .tab[disabled],
.tab-group.tab-group--filled.tab-group--primary .tab[aria-disabled="true"] {
  background-color: #fff;
  color: #bdbdbd;
  cursor: default;
}

.tab-group.tab-group--filled.tab-group--primary .tab--selected {
  background-color: #052449;
  color: #fff;
}

.tab-group.tab-group--filled.tab-group--primary .tab--selected:hover,
.tab-group.tab-group--filled.tab-group--primary .tab--selected:focus-visible {
  background-color: #37506d;
  color: #f5f5f5;
}

.tab-group.tab-group--filled.tab-group--primary .tab--selected[disabled],
.tab-group.tab-group--filled.tab-group--primary .tab--selected[aria-disabled="true"] {
  background-color: #f5f5f5;
  color: #bdbdbd;
  cursor: default;
}

.tab-group.tab-group--filled.tab-group--secondary .tab {
  background-color: #fff;
  color: #4b70b8;
}

.tab-group.tab-group--filled.tab-group--secondary .tab:hover,
.tab-group.tab-group--filled.tab-group--secondary .tab:focus-visible {
  background-color: #f5f5f5;
  color: #6f8dc6;
}

.tab-group.tab-group--filled.tab-group--secondary .tab[disabled],
.tab-group.tab-group--filled.tab-group--secondary .tab[aria-disabled="true"] {
  background-color: #fff;
  color: #bdbdbd;
  cursor: default;
}

.tab-group.tab-group--filled.tab-group--secondary .tab--selected {
  background-color: #4b70b8;
  color: #fff;
}

.tab-group.tab-group--filled.tab-group--secondary .tab--selected:hover,
.tab-group.tab-group--filled.tab-group--secondary .tab--selected:focus-visible {
  background-color: #6f8dc6;
  color: #f5f5f5;
}

.tab-group.tab-group--filled.tab-group--secondary .tab--selected[disabled],
.tab-group.tab-group--filled.tab-group--secondary .tab--selected[aria-disabled="true"] {
  background-color: #f5f5f5;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--outlined .tab {
  background-color: transparent;
  color: #000;
}

.tab-group--outlined .tab:hover,
.tab-group--outlined .tab:focus-visible {
  background-color: transparent;
  color: #616161;
}

.tab-group--outlined .tab[disabled],
.tab-group--outlined .tab[aria-disabled="true"] {
  background-color: transparent;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--outlined .tab .tab__indicator {
  background-color: #bdbdbd;
}

.tab-group--outlined .tab--selected {
  background-color: #fff;
  color: #000;
  border-color: #bdbdbd;
  border-width: 1px;
  border-style: solid;
}

.tab-group--outlined .tab--selected:hover,
.tab-group--outlined .tab--selected:focus-visible {
  background-color: #f5f5f5;
  color: #616161;
}

.tab-group--outlined .tab--selected[disabled],
.tab-group--outlined .tab--selected[aria-disabled="true"] {
  background-color: #f5f5f5;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--outlined .tab--selected .tab__indicator {
  background-color: #fff;
}

.tab-group.tab-group--outlined.tab-group--big .tab {
  height: 48px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--outlined.tab-group--big .tab .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--outlined.tab-group--big .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--outlined.tab-group--big .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--outlined.tab-group--big .tab.tab--icon-only .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--outlined.tab-group--medium .tab {
  height: 32px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--outlined.tab-group--medium .tab .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--outlined.tab-group--medium .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--outlined.tab-group--medium .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--outlined.tab-group--medium .tab.tab--icon-only .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--outlined.tab-group--small .tab {
  height: 24px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--outlined.tab-group--small .tab .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group.tab-group--outlined.tab-group--small .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--outlined.tab-group--small .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--outlined.tab-group--small .tab.tab--icon-only .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group--outlined.tab-group--big .tab .tab__indicator {
  bottom: 0;
  height: 1px;
}

.tab-group--outlined.tab-group--big .tab.tab--selected .tab__indicator {
  bottom: -1px;
}

.tab-group--outlined.tab-group--medium .tab .tab__indicator {
  bottom: 0;
  height: 1px;
}

.tab-group--outlined.tab-group--medium .tab.tab--selected .tab__indicator {
  bottom: -1px;
}

.tab-group--outlined.tab-group--small .tab .tab__indicator {
  bottom: 0;
  height: 1px;
}

.tab-group--outlined.tab-group--small .tab.tab--selected .tab__indicator {
  bottom: -1px;
}

.tab-group--underlined .tab {
  background-color: transparent;
  color: #052449;
}

.tab-group--underlined .tab:hover,
.tab-group--underlined .tab:focus-visible {
  background-color: transparent;
  color: #37506d;
}

.tab-group--underlined .tab[disabled],
.tab-group--underlined .tab[aria-disabled="true"] {
  background-color: transparent;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--underlined .tab .tab__indicator {
  background-color: transparent;
}

.tab-group--underlined .tab--selected {
  background-color: transparent;
  color: #052449;
}

.tab-group--underlined .tab--selected:hover,
.tab-group--underlined .tab--selected:focus-visible {
  background-color: transparent;
  color: #37506d;
}

.tab-group--underlined .tab--selected[disabled],
.tab-group--underlined .tab--selected[aria-disabled="true"] {
  background-color: transparent;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--underlined .tab--selected .tab__indicator {
  background-color: #052449;
}

.tab-group.tab-group--underlined.tab-group--big .tab {
  height: 48px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--underlined.tab-group--big .tab .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--underlined.tab-group--big .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--underlined.tab-group--big .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--underlined.tab-group--big .tab.tab--icon-only .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--underlined.tab-group--medium .tab {
  height: 32px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--underlined.tab-group--medium .tab .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--underlined.tab-group--medium .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--underlined.tab-group--medium .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--underlined.tab-group--medium .tab.tab--icon-only .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--underlined.tab-group--small .tab {
  height: 24px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--underlined.tab-group--small .tab .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group.tab-group--underlined.tab-group--small .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--underlined.tab-group--small .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--underlined.tab-group--small .tab.tab--icon-only .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group--underlined.tab-group--big .tab .tab__indicator {
  bottom: 0;
  height: 3px;
}

.tab-group--underlined.tab-group--medium .tab .tab__indicator {
  bottom: 0;
  height: 2px;
}

.tab-group--underlined.tab-group--small .tab .tab__indicator {
  bottom: 0;
  height: 2px;
}

.tab-group--vertical .tab {
  background-color: transparent;
  color: #052449;
}

.tab-group--vertical .tab:hover,
.tab-group--vertical .tab:focus-visible {
  background-color: transparent;
  color: #37506d;
}

.tab-group--vertical .tab[disabled],
.tab-group--vertical .tab[aria-disabled="true"] {
  background-color: transparent;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--vertical .tab--selected {
  background-color: transparent;
  color: #052449;
}

.tab-group--vertical .tab--selected:hover,
.tab-group--vertical .tab--selected:focus-visible {
  background-color: transparent;
  color: #37506d;
}

.tab-group--vertical .tab--selected[disabled],
.tab-group--vertical .tab--selected[aria-disabled="true"] {
  background-color: transparent;
  color: #bdbdbd;
  cursor: default;
}

.tab-group--vertical .tab--selected .tab__indicator {
  background-color: #2fc5d2;
}

.tab-group--vertical .tab {
  justify-content: left;
}

.tab-group.tab-group--vertical.tab-group--big .tab {
  height: 36px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--vertical.tab-group--big .tab .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--vertical.tab-group--big .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--vertical.tab-group--big .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--vertical.tab-group--big .tab.tab--icon-only .tab__icon {
  width: 24px;
  height: 24px;
}

.tab-group.tab-group--vertical.tab-group--medium .tab {
  height: 32px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--vertical.tab-group--medium .tab .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--vertical.tab-group--medium .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--vertical.tab-group--medium .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--vertical.tab-group--medium .tab.tab--icon-only .tab__icon {
  width: 16px;
  height: 16px;
}

.tab-group.tab-group--vertical.tab-group--small .tab {
  height: 24px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tab-group.tab-group--vertical.tab-group--small .tab .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group.tab-group--vertical.tab-group--small .tab .tab__icon--left {
  margin-right: 8px;
}

.tab-group.tab-group--vertical.tab-group--small .tab .tab__icon--right {
  margin-left: 8px;
}

.tab-group.tab-group--vertical.tab-group--small .tab.tab--icon-only .tab__icon {
  width: 14px;
  height: 14px;
}

.tab-group--vertical.tab-group--big .tab .tab__indicator {
  left: 0;
  width: 6px;
}

.tab-group--vertical.tab-group--medium .tab .tab__indicator {
  left: 0;
  width: 4px;
}

.tab-group--vertical.tab-group--small .tab .tab__indicator {
  left: 0;
  width: 3px;
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.alert.alert--info {
  background-color: #f5fcff;
  border-color: #008dca;
  border-width: 1px;
  border-style: solid;
  color: #616161;
}

.alert.alert--info .alert__icon {
  color: #008dca;
}

.alert.alert--success {
  background-color: #f1f7f4;
  border-color: #1e814e;
  border-width: 1px;
  border-style: solid;
  color: #616161;
}

.alert.alert--success .alert__icon {
  color: #1e814e;
}

.alert.alert--warning {
  background-color: #fffcf7;
  border-color: #f19f00;
  border-width: 1px;
  border-style: solid;
  color: #616161;
}

.alert.alert--warning .alert__icon {
  color: #f19f00;
}

.alert.alert--error {
  background-color: #fdf6f7;
  border-color: #d62b34;
  border-width: 1px;
  border-style: solid;
  color: #616161;
}

.alert.alert--error .alert__icon {
  color: #d62b34;
}

.alert.alert--medium {
  padding: 12px 12px 12px 16px;
}

.alert.alert--medium .alert__icon {
  margin-right: 16px;
  width: 24px;
  height: 24px;
}

.alert.alert--small {
  padding: 8px;
}

.alert.alert--small .alert__icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.alert.alert--flat {
  border-radius: 0;
  border: none;
}

.tag {
  display: inline-flex;
  align-items: center;
}

.tag--big {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tag--big.tag--show-close .tag--close-icon {
  margin-left: 8px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.tag--medium {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tag--medium.tag--show-close .tag--close-icon {
  margin-left: 8px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.tag--small {
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.tag--small.tag--show-close .tag--close-icon {
  margin-left: 4px;
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.tag--blue {
  background-color: #e6f7ff;
}

.tag--green {
  background-color: #f6ffed;
}

.tag--purple {
  background-color: #f9f0ff;
}

.tag--yellow {
  background-color: #feffe6;
}

.tag--primary {
  background-color: #d7dce2;
}

.tag--secondary {
  background-color: #e2e8f4;
}

.modal-backdrop {
  background-color: rgba(0,0,0,0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.modal-backdrop[data-enter] {
  opacity: 1;
}

.modal {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 95vh;
  width: 95vw;
  opacity: 0;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: opacity 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

@media (min-width: 768px) {
  .modal {
    max-height: 90%;
    max-width: min(920px, 95vw, calc(1px * var(--modal-max-width, 920)));
    min-width: 300px;
    width: auto;
  }
}

.modal[data-enter] {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.modal__header {
  background-color: #f1f4f9;
  border-bottom-color: #e2e8f4;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-shrink: 0;
  padding: 18px 16px;
}

.modal__header button {
  border: none;
  cursor: pointer;
}

.modal__title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.modal__title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__close {
  color: #424242;
  width: 24px;
  height: 24px;
}

.modal__body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 18px 16px;
}

.modal__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
  border-top-color: #e0e0e0;
  border-top-width: 1px;
  border-top-style: solid;
  padding: 16px 20px;
}

.modal__footer-actions {
  display: flex;
  gap: 16px;
}

.confirmation-modal {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 95vh;
  min-height: 184px;
  width: min(95vw, 460px);
  opacity: 0;
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
  transition: opacity 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

.confirmation-modal[data-enter] {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.confirmation-modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
}

.confirmation-modal__header button {
  border: none;
  cursor: pointer;
}

.confirmation-modal__title {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}

.confirmation-modal__close {
  color: #424242;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 16px;
}

.confirmation-modal__message {
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 24px;
}

.confirmation-modal__actions {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 8px;
  margin-right: 20px;
  margin-bottom: 16px;
}

.takeover-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 999;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: opacity 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

.takeover-modal[data-enter] {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.takeover-modal .modal__header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.step-list {
  background-color: #fafafa;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
  padding: 0;
}

.step-list .step-list__list {
  margin-top: 24px;
}

@media (min-width: 640px) {
  .step-list {
    background-color: #fff;
    border-color: #e0e0e0;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 12px 12px 30px 12px;
  }

  .step-list .step-list__list {
    margin-top: 12px;
  }
}

.step-list .step-list__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.step-list .step-list__header .progress-circle {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  color: #212121;
  margin-right: 16px;
}

.step-list .step-list__title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
  color: #212121;
}

.step-list .step-list__subtitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  color: #616161;
}

.step-list .step-list__toggle {
  width: 40px;
  height: 40px;
  color: #052449;
}

@media (min-width: 640px) {
  .step-list .step-list__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .step-list .step-list__header .progress-circle {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 150%;
    margin-right: 12px;
  }

  .step-list .step-list__title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 150%;
  }

  .step-list .step-list__subtitle {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 150%;
  }

  .step-list .step-list__toggle {
  }
}

.step-list.step-list--big .step-list__list {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 640px) {
  .step-list.step-list--big .step-list__list {
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.step-list.step-list--big .step-list__list li:not(:first-child) {
  position: relative;
  margin-top: 36px;
}

.step-list.step-list--big .step-list__list li:not(:first-child)::before {
  content: '';
  position: absolute;
  width: 0;
  height: 32px;
  top: -34px;
  left: 16px;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
}

.step-list.step-list--medium .step-list__list {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 640px) {
  .step-list.step-list--medium .step-list__list {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.step-list.step-list--medium .step-list__list li:not(:first-child) {
  position: relative;
  margin-top: 32px;
}

.step-list.step-list--medium .step-list__list li:not(:first-child)::before {
  content: '';
  position: absolute;
  width: 0;
  height: 28px;
  top: -30px;
  left: 12px;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
}

.step-list.step-list--small .step-list__list {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 640px) {
  .step-list.step-list--small .step-list__list {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.step-list.step-list--small .step-list__list li:not(:first-child) {
  position: relative;
  margin-top: 28px;
}

.step-list.step-list--small .step-list__list li:not(:first-child)::before {
  content: '';
  position: absolute;
  width: 0;
  height: 24px;
  top: -26px;
  left: 8px;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
}

.step-list .step {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step-list .step__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
}

.step-list.step-list--big .step {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.step-list.step-list--big .step .step__badge {
  width: 32px;
  height: 32px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  margin-right: 16px;
}

.step-list.step-list--big .step .step__icon {
  width: 24px;
  height: 24px;
}

.step-list.step-list--medium .step {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.step-list.step-list--medium .step .step__badge {
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  margin-right: 8px;
}

.step-list.step-list--medium .step .step__icon {
  width: 20px;
  height: 20px;
}

.step-list.step-list--small .step {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.step-list.step-list--small .step .step__badge {
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  margin-right: 8px;
}

.step-list.step-list--small .step .step__icon {
  width: 12px;
  height: 12px;
}

.step-list .step.step--complete {
  color: #212121;
}

.step-list .step.step--complete .step__badge {
  background-color: transparent;
  border-color: #1e814e;
  border-width: 1px;
  border-style: solid;
  color: #1e814e;
}

.step-list .step.step--active {
  color: #1e814e;
}

.step-list .step.step--active .step__badge {
  background-color: #1e814e;
  border-color: #1e814e;
  border-width: 1px;
  border-style: solid;
  color: #fff;
}

.step-list .step {
  color: #212121;
}

.step-list .step .step__badge {
  background-color: #fff;
  border-color: #bdbdbd;
  border-width: 1px;
  border-style: solid;
  color: #bdbdbd;
}

.progress-circle {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.progress-circle .progress-circle__root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.progress-circle .progress-circle__background,
.progress-circle .progress-circle__filled {
  fill: none;
  stroke-width: 5;
}

.progress-circle .progress-circle__background {
  stroke: #f5f5f5;
}

.progress-circle .progress-circle__filled {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: stroke-dashoffset 200ms ease-in-out, stroke 150ms ease-in-out;
}

.progress-circle .progress-circle__label {
  position: relative;
}

.wait-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wait-loader__transform {
  -webkit-transform: translate(50%, 50%) rotate(45deg);
          transform: translate(50%, 50%) rotate(45deg);
}

.wait-loader__block {
  width: 75px;
  height: 75px;
  x: 0;
  y: 0;
  rx: 4px;
  ry: 4px;
  -webkit-animation: wait-loader 7s linear infinite;
          animation: wait-loader 7s linear infinite;
}

.prefers-reduced-motion .wait-loader__block {
  -webkit-animation-name: wait-loader-fade;
          animation-name: wait-loader-fade;
  opacity: 0.2;
}

.prefers-reduced-motion .wait-loader__block--1 {
  -webkit-transform: translate(-81px, -81px);
          transform: translate(-81px, -81px);
  -webkit-animation-delay: -1.75s;
          animation-delay: -1.75s;
}

.prefers-reduced-motion .wait-loader__block--2 {
  -webkit-transform: translate(6px, -81px);
          transform: translate(6px, -81px);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.prefers-reduced-motion .wait-loader__block--3 {
  -webkit-transform: translate(6px, 6px);
          transform: translate(6px, 6px);
  -webkit-animation-delay: 1.75s;
          animation-delay: 1.75s;
}

.prefers-reduced-motion .wait-loader__block--4 {
  -webkit-transform: translate(-81px, 6px);
          transform: translate(-81px, 6px);
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
}

@media (prefers-reduced-motion: reduce) {
  .wait-loader__block {
    -webkit-animation-name: wait-loader-fade;
            animation-name: wait-loader-fade;
    opacity: 0.2;
  }

  .wait-loader__block--1 {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
    -webkit-animation-delay: -1.75s;
            animation-delay: -1.75s;
  }

  .wait-loader__block--2 {
    -webkit-transform: translate(6px, -81px);
            transform: translate(6px, -81px);
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .wait-loader__block--3 {
    -webkit-transform: translate(6px, 6px);
            transform: translate(6px, 6px);
    -webkit-animation-delay: 1.75s;
            animation-delay: 1.75s;
  }

  .wait-loader__block--4 {
    -webkit-transform: translate(-81px, 6px);
            transform: translate(-81px, 6px);
    -webkit-animation-delay: 3.5s;
            animation-delay: 3.5s;
  }
}

.wait-loader__block--1 {
  fill: #052449;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.wait-loader__block--2 {
  fill: #4b70b8;
  -webkit-animation-delay: -1.75s;
          animation-delay: -1.75s;
}

.wait-loader__block--3 {
  fill: #d7dce2;
  -webkit-animation-delay: -3.5s;
          animation-delay: -3.5s;
}

.wait-loader__block--4 {
  fill: #2fc5d2;
  -webkit-animation-delay: -5.25s;
          animation-delay: -5.25s;
}

.spin-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-loader--inline {
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: middle;
}

.spin-loader__transform {
  -webkit-animation: spin-loader 1s linear infinite;
          animation: spin-loader 1s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.prefers-reduced-motion .spin-loader__transform {
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}

@media (prefers-reduced-motion: reduce) {
  .spin-loader__transform {
    -webkit-animation-duration: 6s;
            animation-duration: 6s;
  }
}

.spin-loader__background {
  stroke-opacity: 0.25;
}

.spin-loader__foreground {
  stroke-dasharray: 45.23893421169302, 75.39822368615503;
  stroke-linecap: round;
  stroke-opacity: 0.75;
}

@-webkit-keyframes wait-loader-fade {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes wait-loader-fade {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes wait-loader {
  0% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }

  4% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }

  6.833% {
    -webkit-transform: translate(-81px, -124.5px);
            transform: translate(-81px, -124.5px);
  }

  9.833% {
    -webkit-transform: translate(-81px, -124.5px);
            transform: translate(-81px, -124.5px);
  }

  15.167% {
    -webkit-transform: translate(6px, -124.5px);
            transform: translate(6px, -124.5px);
  }

  18.167% {
    -webkit-transform: translate(6px, -124.5px);
            transform: translate(6px, -124.5px);
  }

  21% {
    -webkit-transform: translate(6px, -81px);
            transform: translate(6px, -81px);
  }

  29% {
    -webkit-transform: translate(6px, -81px);
            transform: translate(6px, -81px);
  }

  31.833% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  34.833% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  40.167% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  43.167% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  46% {
    -webkit-transform: translate(6px, 6px);
            transform: translate(6px, 6px);
  }

  54% {
    -webkit-transform: translate(6px, 6px);
            transform: translate(6px, 6px);
  }

  56.833% {
    -webkit-transform: translate(6px, 49.5px);
            transform: translate(6px, 49.5px);
  }

  59.833% {
    -webkit-transform: translate(6px, 49.5px);
            transform: translate(6px, 49.5px);
  }

  65.167% {
    -webkit-transform: translate(-81px, 49.5px);
            transform: translate(-81px, 49.5px);
  }

  68.167% {
    -webkit-transform: translate(-81px, 49.5px);
            transform: translate(-81px, 49.5px);
  }

  71% {
    -webkit-transform: translate(-81px, 6px);
            transform: translate(-81px, 6px);
  }

  79% {
    -webkit-transform: translate(-81px, 6px);
            transform: translate(-81px, 6px);
  }

  81.833% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  84.833% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  90.167% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  93.167% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  96% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }

  100% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }
}

@keyframes wait-loader {
  0% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }

  4% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }

  6.833% {
    -webkit-transform: translate(-81px, -124.5px);
            transform: translate(-81px, -124.5px);
  }

  9.833% {
    -webkit-transform: translate(-81px, -124.5px);
            transform: translate(-81px, -124.5px);
  }

  15.167% {
    -webkit-transform: translate(6px, -124.5px);
            transform: translate(6px, -124.5px);
  }

  18.167% {
    -webkit-transform: translate(6px, -124.5px);
            transform: translate(6px, -124.5px);
  }

  21% {
    -webkit-transform: translate(6px, -81px);
            transform: translate(6px, -81px);
  }

  29% {
    -webkit-transform: translate(6px, -81px);
            transform: translate(6px, -81px);
  }

  31.833% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  34.833% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  40.167% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  43.167% {
    -webkit-transform: translate(6px, -37.5px);
            transform: translate(6px, -37.5px);
  }

  46% {
    -webkit-transform: translate(6px, 6px);
            transform: translate(6px, 6px);
  }

  54% {
    -webkit-transform: translate(6px, 6px);
            transform: translate(6px, 6px);
  }

  56.833% {
    -webkit-transform: translate(6px, 49.5px);
            transform: translate(6px, 49.5px);
  }

  59.833% {
    -webkit-transform: translate(6px, 49.5px);
            transform: translate(6px, 49.5px);
  }

  65.167% {
    -webkit-transform: translate(-81px, 49.5px);
            transform: translate(-81px, 49.5px);
  }

  68.167% {
    -webkit-transform: translate(-81px, 49.5px);
            transform: translate(-81px, 49.5px);
  }

  71% {
    -webkit-transform: translate(-81px, 6px);
            transform: translate(-81px, 6px);
  }

  79% {
    -webkit-transform: translate(-81px, 6px);
            transform: translate(-81px, 6px);
  }

  81.833% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  84.833% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  90.167% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  93.167% {
    -webkit-transform: translate(-81px, -37.5px);
            transform: translate(-81px, -37.5px);
  }

  96% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }

  100% {
    -webkit-transform: translate(-81px, -81px);
            transform: translate(-81px, -81px);
  }
}

@-webkit-keyframes spin-loader {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin-loader {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.input-group .form-input input {
  border: none;
}

.input-group button:not([disabled], [aria-disabled='true']),
  .input-group [role='button']:not([disabled], [aria-disabled='true']) {
  cursor: pointer;
}

.avatar .avatar__profile-image {
  width: 100%;
  height: 100%;
}

.alert .alert__icon {
  flex-shrink: 0;
}

.step-list .step-list__list {
  list-style: none;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-tabs {
  overflow: unset;
}

@media screen and (max-width: 1025px) {
  .cc-content {
    margin: 84px 24px 0px 24px;
  }
}

@media screen and (max-width: 414px) {
  .cc-content {
    margin: 72px 16px 0px 16px;
  }
}

hr.cc-line {
  border: 0;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 16px;
  margin-bottom: 15px;
}

hr.cc-space {
  border-bottom-color: transparent;
}

hr.cc-space {
  border: 0;
  background: transparent;
  margin: 1.5rem 0;
}

.cc-sidebar-logo {
  width: 100%;
  margin: 1rem 0;
  padding-left: 14px;
  display: flex;
}

.cc-menu-item-collapsed {
  background: #24262b;
  display: grid;
  padding: 0px !important;
  text-align: center;
  line-height: 20px !important;
  height: 60px !important;
  border-radius: 11px;
}

.cc-menu-item-collapsed-selected {
  background: rgba(47, 197, 210, 0.1) !important;
}

.cc-menu-item-collapsed > a {
  padding-left: 0px !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: rgba(47, 197, 210, 0.1) !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0px !important;
}

.ant-menu-inline-collapsed {
  width: 60px;
}

.ant-menu-item > a,
.ant-menu-submenu > a,
.ant-menu-submenu > div {
  padding-left: 24px;
  margin: 0px !important;
}

.cc-sidebar:hover > div > div > .cc-sidebar-logo > div > .trigger {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.cc-sidebar > div > div > .cc-sidebar-logo > div > .trigger {
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.trigger {
  background: #24262b;
  border-radius: 4px;
  color: #fff;
  margin-left: 22px;
  margin-top: 6px;
  position: fixed;
  padding: 5px;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  cursor: pointer;
}

.trigger-collapsed {
  margin-left: 5px !important;
  opacity: 1 !important;
}

.cc-sidebar {
  z-index: 1000;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  height: 100vh;
  position: fixed !important;
  left: 0;
}

.cc-sidebar > .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  position: absolute;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background: transparent;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
  background: linear-gradient(90deg, rgba(47, 197, 210, 0.1) 0%, rgba(47, 197, 210, 0) 100%);
}

.ant-menu-item > a:hover,
.ant-menu-submenu > div:hover {
  background: linear-gradient(90deg, rgba(47, 197, 210, 0.1) 0%, rgba(47, 197, 210, 0) 100%);
}

.cc-free {
  background: linear-gradient(92.88deg, #2cbc63 2.61%, #44d77c 100.42%);
}

.cc-starter {
  background: linear-gradient(92.88deg, #774ee0 2.61%, #9a7fdf 100.42%);
}

.cc-pro {
  background: linear-gradient(92.88deg, #bc9c67 2.61%, #ddbf8e 100.42%);
}

.cc-company-switch > .ant-select-selection {
  background: #f7f8fa;
  border: 0;
  border-radius: 4px;
}

.cc-company-switch .ant-select-selection-selected-value {
  font-size: 16px;
  line-height: 38px;
  color: #24262b;
  vertical-align: middle;
}

.cc-notification-count > sup {
  height: 15px;
  min-width: 15px;
  padding: 0 2px;
  font-size: 11px;
  line-height: 15px;
  top: 5px;
  right: 2px;
}

.cc-table-th .ant-table-column-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7f848a;
  text-transform: uppercase;
}

.cc-table-row > td {
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #000;
}

.ant-tabs-nav {
  padding-left: 24px;
}

.TopBar-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.tab-pane {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  /* identical to box height, or 112% */
}

.tab-icon {
  margin-right: 8px;
  font-size: 20px;
}

.ant-modal-header {
  background: #f7f8fa !important;
}

.ant-modal-header > .ant-modal-title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #4d4f56;
}

/*ERVIN*/

.company-colleagues-table {
  background-color: white;
}

.ant-table-thead {
  background-color: white;
}

.ant-table-thead > tr {
  background-color: white !important;
}

.ant-table-tbody > tr > td {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #1b1a1f;
}

.ant-table-header-column {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #7f848a;
}

.ant-table-column-sorter {
  margin-top: 0px !important;
}

.company-colleagues-add {
  width: 198px;
  height: 40px !important;
  margin-top: 10px;
  border-radius: 8px !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #ffffff;
}

.button-icon {
  size: 13.5px;
  margin-right: 12px;
}

button[type='button'].cc-btn {
  border: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: none;
}

.cc-btn > span {
  position: relative;
  z-index: 555;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.cc-btn > .cc-btn-bg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: 0.1;
  z-index: 900;
}

.cc-btn-danger > span {
  color: #d62b34;
}

.cc-btn-danger > .cc-btn-bg {
  background-color: #d62b34;
}

.cc-btn-disabled > span {
  color: #92949c;
}

.cc-btn-disabled > .cc-btn-bg {
  background-color: #92949c;
}

.cc-btn-success > span {
  color: #24c477;
}

.ant-btn-primary.cc-btn-success > span {
  color: #ffffff;
}

.ant-btn-primary.cc-btn-success,
.cc-btn-success > .cc-btn-bg {
  background-color: #24c477;
}

.ant-btn-primary.cc-btn-notice > span {
  color: #ffffff;
}

.cc-btn-notice > span {
  color: #f19f00;
}

.ant-btn-primary.cc-btn-notice,
.cc-btn-notice > .cc-btn-bg {
  background-color: #f19f00;
}

.cc-btn-reschedule {
  background-color: #f19f00;
  opacity: 1 !important;
}

.cc-btn-reschedule:hover {
  background-color: #f19f00;
  opacity: 0.8 !important;
}

.cc-btn-reschedule:focus {
  background-color: #f19f00;
  opacity: 1;
}

.cc-btn-red {
  background-color: #d62b34;
  opacity: 1 !important;
  width: 44px;
}

.cc-btn-red:hover {
  background-color: #d62b34;
  opacity: 0.8 !important;
  width: 44px;
}

.cc-btn-red:focus {
  background-color: #d62b34;
  opacity: 1;
  width: 44px;
}

.cc-heading1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}

.cc-heading-custom {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
}

.cc-heading2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
}

.cc-heading3 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.cc-heading4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.cc-heading5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.cc-heading6 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
}

.cc-tabletitle-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.cc-body-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.cc-small-font {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px !important;
}

.cc-xsmall-font {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
}

.cc-grey-text {
  color: #c2c6cc;
}

.cc-lightest-grey-text {
  color: #f7f8fa;
}

.cc-black-text {
  color: #000000;
}

.cc-white-text {
  color: #ffffff !important;
}

.cc-darkest-grey-text {
  color: #2d3239;
}

.cc-dark-grey-text {
  color: #92949c;
}

.cc-lightblack-text {
  color: #1b1a1f;
}

.cc-text-color-summit {
  color: #052449;
}

.cc-text-color-roadshow {
  color: #962c6a;
}

.cc-text-color-online {
  color: #6a48c6;
}

.cc-text-color-secondary {
  color: #2d81a8;
}

.cc-text-color-danger {
  color: #d62b34;
}

.cc-text-color-success {
  color: #24c477;
}

.cc-shadow1 {
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
}

.cc-shadow2 {
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
}

.cc-shadow3 {
  box-shadow: 0px 16px 28px rgba(47, 56, 62, 0.08);
}

.cc-shadow-box {
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
}

.cc-shadow-buttons-inner {
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
}

.cc-shadow-on-hover {
  transition: 200ms;
}

.cc-shadow-on-hover:hover {
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  transition: 200ms;
}

.ant-btn-primary {
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: 0s !important;
  animation: 0s !important;
}

.cc-meeting-actions {
  display: inline;
  margin-top: 8px;
}

.cc-text-with-icon-left {
  margin-left: 10px;
  display: inline-block;
}

.cc-profile-info {
  margin-top: 8px;
  display: flex;
  word-break: break-word;
}

.cc-light-grey-border {
  border: 1px solid #eff1f4;
}

.TopBar-header {
  position: fixed;
  z-index: 998;
  background: #ffffff;
  padding: 4px 0px;
  height: 64px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
}

.TopBar-header-discover {
  height: 90px !important;
}

.TopBar-header-discover > .ant-row {
  height: 49px;
}

.TopBar-header-collapsed {
  width: calc(100% - 70px);
  margin-left: 70px;
}

.TopBar-header-wide {
  width: calc(100% - 224px);
  margin-left: 224px;
}

.TopBar-tabs > .ant-tabs > .ant-tabs-nav {
  border-bottom: none;
}

.TopBar-tabs > .ant-tabs > .ant-tabs-nav::before {
  border-bottom: none;
}

.TopBar-dropdown-notifications {
  cursor: pointer;
}

.TopBar-icons {
  padding: 0 24px;
}

.TopBar-icons-credit {
  margin: 0 12px;
}

.TopBar-icons:last-child {
  padding-right: 0px;
}

.TopBar-icons > span > svg,
.TopBar-icons > a > svg,
.TopBar-icons > a > span > svg {
  color: #c2c6cc;
  width: 24px !important;
  height: 24px;
}

.TopBar-icons-credit > svg {
  color: #f19f00;
  width: 16px !important;
  height: auto;
}

.TopBar-icons-points > img {
  width: 16px !important;
  height: auto;
}

.TopBar-icons-credit {
  background-color: rgba(241, 159, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70px;
  height: 40px;
}

.TopBar-icons-points {
  background-color: rgba(0, 141, 202, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
}

.TopBar-secondary-color-button {
  margin-right: 24px;
  margin-left: -155px;
  margin-top: 8px;
  width: 140px;
  height: 40px;
  padding: 8px 16px;
  background-color: #2d81a8;
  border-color: #2d81a8;
  align-self: flex-start;
}

.TopBar-secondary-color-button:hover {
  background-color: #3495c2;
  border-color: #3495c2;
}

.TopBar-secondary-color-button:active,
.TopBar-secondary-color-button:focus {
  background-color: #266e8f;
  border-color: #266e8f;
}

.ant-col {
  padding-right: 10px;
  padding-left: 10px;
}

.ant-table {
  background: white !important;
}

@media screen and (max-width: 1025px) {
  .TopBar-header {
    margin-left: 92px;
    width: 88%;
    z-index: 999;
  }
}

@media screen and (max-width: 414px) {
  .TopBar-icons {
    padding: 0px 4px;
  }

  .TopBar-icons-credit {
    margin: 0px 4px;
    padding: 8px;
  }

  .TopBar-secondary-color-button {
    width: 100px;
    padding: 8px 2px;
    margin-right: 4px;
    height: 40px;
  }
}

.cc-table-header {
  background-color: #ffffff;
  padding: 32px 24px;
  line-height: 22px;
  border-bottom: 1px solid #eff1f4;
}

.ant-tag-purple {
  color: #6a48c6;
  background: rgba(106, 72, 198, 0.1);
  border-color: rgba(106, 72, 198, 0.1);
}

.ant-tag-blue {
  color: #2d81a8;
  background: rgba(45, 129, 168, 0.1);
  border-color: rgba(45, 129, 168, 0.1);
}

.FooterBar {
  position: fixed;
  width: 100%;
  z-index: 1002;
  background: #1b1a1f;
  padding: 0px 0px;
  left: 0px;
  bottom: 0px;
  height: 2.5vh;
  color: #92949c;
  font-size: 12px;
}

.FooterBar a {
  color: #92949c;
  margin-right: 32px;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 300px) !important;
}

.DealMeetingLabel {
  margin: auto;
  border-radius: 24px;
  color: white;
  background-color: #052449;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
}

.DealMeetingLabel-smaller {
  margin: auto;
  border-radius: 24px;
  color: white;
  background-color: #052449;
  width: -webkit-max-content;
  width: max-content;
  padding: 0px 4px;
  line-height: 14px;
}

.scrollDiv::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar {
  width: 7px;
  background-color: #f0f2f5;
}

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c2c6cc;
}

.scrollDiv {
  scrollbar-color: #c2c6cc #f0f2f5;
  scrollbar-width: thin;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.top-24 {
  top: 6rem;
}

.-top-0 {
  top: 0px;
}

.-top-2 {
  top: -0.5rem;
}

.-top-3 {
  top: -0.75rem;
}

.-top-6 {
  top: -1.5rem;
}

.-top-px {
  top: -1px;
}

.-top-0\.5 {
  top: -0.125rem;
}

.-top-2\.5 {
  top: -0.625rem;
}

.top-1\/2 {
  top: 50%;
}

.right-0 {
  right: 0px;
}

.right-1 {
  right: 0.25rem;
}

.right-4 {
  right: 1rem;
}

.right-16 {
  right: 4rem;
}

.-right-1 {
  right: -0.25rem;
}

.-right-3 {
  right: -0.75rem;
}

.-right-1\.5 {
  right: -0.375rem;
}

.bottom-0 {
  bottom: 0px;
}

.bottom-2 {
  bottom: 0.5rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-auto {
  bottom: auto;
}

.bottom-1\/4 {
  bottom: 25%;
}

.left-0 {
  left: 0px;
}

.left-4 {
  left: 1rem;
}

.-left-1 {
  left: -0.25rem;
}

.-left-2 {
  left: -0.5rem;
}

.isolate {
  isolation: isolate;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-4 {
  grid-column-start: 4;
}

.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-8 {
  margin: 2rem;
}

.-m-4 {
  margin: -1rem;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mt-3 {
  margin-top: -0.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.-mr-3 {
  margin-right: -0.75rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-1\.5 {
  margin-left: 0.375rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-auto {
  height: auto;
}

.h-1\/4 {
  height: 25%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0px;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-1500 {
  min-width: 1500px;
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-2 {
  flex: 2 1 0%;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.rotate-45 {
  --tw-rotate: 45deg;
}

.-rotate-12 {
  --tw-rotate: -12deg;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.select-none {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-x-2 {
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}

.divide-grey-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded-sm {
  border-radius: 2px;
}

.rounded {
  border-radius: 4px;
}

.rounded-big {
  border-radius: 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-t-big {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-b-big {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-l-big {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-tl-none {
  border-top-left-radius: 0px;
}

.rounded-br-none {
  border-bottom-right-radius: 0px;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-transparent {
  border-color: transparent;
}

.border-grey-100 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
}

.border-grey-200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity));
}

.border-grey-300 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.border-grey-400 {
  --tw-border-opacity: 1;
  border-color: rgba(189, 189, 189, var(--tw-border-opacity));
}

.border-grey-600 {
  --tw-border-opacity: 1;
  border-color: rgba(117, 117, 117, var(--tw-border-opacity));
}

.border-gold-90 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 177, 42, var(--tw-border-opacity));
}

.border-gold-100 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 159, 0, var(--tw-border-opacity));
}

.border-secondary-2 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 244, 249, var(--tw-border-opacity));
}

.border-secondary-5 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 244, var(--tw-border-opacity));
}

.border-secondary-90 {
  --tw-border-opacity: 1;
  border-color: rgba(111, 141, 198, var(--tw-border-opacity));
}

.border-secondary-100 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 112, 184, var(--tw-border-opacity));
}

.border-brand-90 {
  --tw-border-opacity: 1;
  border-color: rgba(89, 207, 217, var(--tw-border-opacity));
}

.border-brand-100 {
  --tw-border-opacity: 1;
  border-color: rgba(47, 197, 210, var(--tw-border-opacity));
}

.border-primary-5 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 220, 226, var(--tw-border-opacity));
}

.border-primary-90 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 80, 109, var(--tw-border-opacity));
}

.border-primary-100 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 36, 73, var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.hover\:border-secondary-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 112, 184, var(--tw-border-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.bg-grey-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}

.bg-grey-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.bg-grey-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.bg-grey-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 224, 224, var(--tw-bg-opacity));
}

.bg-grey-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity));
}

.bg-grey-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity));
}

.bg-grey-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 43, 52, var(--tw-bg-opacity));
}

.bg-gold-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 159, 0, var(--tw-bg-opacity));
}

.bg-gold-110 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 143, 0, var(--tw-bg-opacity));
}

.bg-secondary-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 244, 249, var(--tw-bg-opacity));
}

.bg-secondary-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 244, var(--tw-bg-opacity));
}

.bg-secondary-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 112, 184, var(--tw-bg-opacity));
}

.bg-brand-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 253, 253, var(--tw-bg-opacity));
}

.bg-brand-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 249, 250, var(--tw-bg-opacity));
}

.bg-brand-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(47, 197, 210, var(--tw-bg-opacity));
}

.bg-primary-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 238, 240, var(--tw-bg-opacity));
}

.bg-primary-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 220, 226, var(--tw-bg-opacity));
}

.bg-primary-90 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 80, 109, var(--tw-bg-opacity));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 36, 73, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-purple-5 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 229, 255, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 141, 202, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 129, 78, var(--tw-bg-opacity));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity));
}

.bg-info-action {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 141, 202, var(--tw-bg-opacity));
}

.bg-success-action {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 129, 78, var(--tw-bg-opacity));
}

.bg-error-action {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 43, 52, var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.even\:bg-grey-100:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.even\:bg-secondary-2:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 244, 249, var(--tw-bg-opacity));
}

.even\:bg-white:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-grey-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.hover\:bg-grey-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity));
}

.hover\:bg-secondary-2:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 244, 249, var(--tw-bg-opacity));
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-gold-90 {
  --tw-gradient-from: #f7b12a;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(247, 177, 42, 0));
}

.from-brand-100 {
  --tw-gradient-from: #2fc5d2;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(47, 197, 210, 0));
}

.via-secondary-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #4b70b8, var(--tw-gradient-to, rgba(75, 112, 184, 0));
}

.to-primary-100 {
  --tw-gradient-to: #052449;
}

.to-white {
  --tw-gradient-to: #fff;
}

.bg-cover {
  background-size: cover;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-center {
  object-position: center;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-0\.5 {
  padding: 0.125rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pt-0 {
  padding-top: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.pr-0 {
  padding-right: 0px;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-0\.5 {
  padding-right: 0.125rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.text-md {
  font-size: 16px;
  line-height: 150%;
}

.text-sm {
  font-size: 14px;
  line-height: 150%;
}

.text-xs {
  font-size: 12px;
  line-height: 150%;
}

.text-lg {
  font-size: 18px;
  line-height: 150%;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.ordinal, .tabular-nums {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.ordinal {
  --tw-ordinal: ordinal;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-snug {
  line-height: 1.375;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-transparent {
  color: transparent;
}

.text-grey-100 {
  --tw-text-opacity: 1;
  color: rgba(245, 245, 245, var(--tw-text-opacity));
}

.text-grey-300 {
  --tw-text-opacity: 1;
  color: rgba(224, 224, 224, var(--tw-text-opacity));
}

.text-grey-400 {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity));
}

.text-grey-500 {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity));
}

.text-grey-600 {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity));
}

.text-grey-700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity));
}

.text-grey-800 {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity));
}

.text-grey-900 {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
}

.text-red-90 {
  --tw-text-opacity: 1;
  color: rgba(224, 79, 86, var(--tw-text-opacity));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(214, 43, 52, var(--tw-text-opacity));
}

.text-gold-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 159, 0, var(--tw-text-opacity));
}

.text-gold-110 {
  --tw-text-opacity: 1;
  color: rgba(217, 143, 0, var(--tw-text-opacity));
}

.text-secondary-90 {
  --tw-text-opacity: 1;
  color: rgba(111, 141, 198, var(--tw-text-opacity));
}

.text-secondary-100 {
  --tw-text-opacity: 1;
  color: rgba(75, 112, 184, var(--tw-text-opacity));
}

.text-secondary-110 {
  --tw-text-opacity: 1;
  color: rgba(60, 90, 147, var(--tw-text-opacity));
}

.text-brand-100 {
  --tw-text-opacity: 1;
  color: rgba(47, 197, 210, var(--tw-text-opacity));
}

.text-primary-2 {
  --tw-text-opacity: 1;
  color: rgba(235, 238, 240, var(--tw-text-opacity));
}

.text-primary-5 {
  --tw-text-opacity: 1;
  color: rgba(215, 220, 226, var(--tw-text-opacity));
}

.text-primary-90 {
  --tw-text-opacity: 1;
  color: rgba(55, 80, 109, var(--tw-text-opacity));
}

.text-primary-100 {
  --tw-text-opacity: 1;
  color: rgba(5, 36, 73, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(0, 141, 202, var(--tw-text-opacity));
}

.text-green-90 {
  --tw-text-opacity: 1;
  color: rgba(75, 154, 113, var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(30, 129, 78, var(--tw-text-opacity));
}

.text-green-110 {
  --tw-text-opacity: 1;
  color: rgba(24, 103, 62, var(--tw-text-opacity));
}

.text-header {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
}

.text-body {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
}

.hover\:text-brand-100:hover {
  --tw-text-opacity: 1;
  color: rgba(47, 197, 210, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.underline {
  text-decoration: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-0 {
  --tw-shadow: 0 2px 6px -2px rgba(5,36,73,0.16), 0 2px 3px -1px rgba(114,0,168,0.16), 0 14px 45px -9px rgba(5,36,73,0.03);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-6 {
  --tw-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-12 {
  --tw-shadow: -1px 7px 20px -8px rgba(114,0,168,0.11), 0 18px 37px -22px rgba(5,36,73,0.29), 0 24px 72px -8px rgba(5,36,73,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-0:hover {
  --tw-shadow: 0 2px 6px -2px rgba(5,36,73,0.16), 0 2px 3px -1px rgba(114,0,168,0.16), 0 14px 45px -9px rgba(5,36,73,0.03);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-6:hover {
  --tw-shadow: 0 4px 32px -10px rgba(5,36,73,0.08), 0 5px 5px -2px rgba(114,0,168,0.12), 0 20px 18px -12px rgba(5,36,73,0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
}

.blur {
  --tw-blur: blur(8px);
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-in-out-back {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.type-body-regular-md {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.type-body-regular-sm {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.type-body-regular-xs {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.type-body-regular-lg {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.type-body-regular-xl {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 150%;
}

.type-body-semibold-2xl {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.type-body-semibold-xl {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 150%;
}

.type-body-semibold-lg {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.type-body-semibold-md {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.type-body-semibold-sm {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 150%;
}

.type-body-semibold-xs {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
}

.type-subtitle-xxs {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.type-subtitle-lg {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.type-subtitle-md {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.type-subtitle-sm {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.type-subtitle-xs {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

.type-header-xxs {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 120%;
}

.type-header-lg {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 120%;
}

.type-header-md {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 120%;
}

.type-header-sm {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 120%;
}

.type-header-xs {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 120%;
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }

  .sm\:m-0 {
    margin: 0px;
  }

  .sm\:m-5 {
    margin: 1.25rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:ml-16 {
    margin-left: 4rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-1\/3 {
    width: 33.333333%;
  }

  .sm\:w-2\/3 {
    width: 66.666667%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0px;
  }

  .sm\:type-header-2xl {
    font-size: 52px;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 120%;
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .md\:container {
      max-width: 1536px;
    }
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:-m-8 {
    margin: -2rem;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:-mt-4 {
    margin-top: -1rem;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .md\:flex-1 {
    flex: 1 1 0%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:place-content-end {
    place-content: end;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-2 {
    gap: 0.5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .md\:place-self-end {
    place-self: end;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:justify-self-center {
    justify-self: center;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:border-t {
    border-top-width: 1px;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:bg-transparent {
    background-color: transparent;
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .md\:even\:bg-grey-100:nth-child(even) {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  }

  .md\:p-0 {
    padding: 0px;
  }

  .md\:p-3 {
    padding: 0.75rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:type-body-regular-md {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 150%;
  }

  .md\:type-header-lg {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 120%;
  }

  .md\:type-header-md {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 120%;
  }
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }

  .lg\:-top-1 {
    top: -0.25rem;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-2\/3 {
    width: 66.666667%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-1\/5 {
    width: 20%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:flex-1 {
    flex: 1 1 0%;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-0 {
    gap: 0px;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:border-l-2 {
    border-left-width: 2px;
  }

  .lg\:bg-grey-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  }

  .lg\:bg-grey-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }
}

@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:mt-48 {
    margin-top: 12rem;
  }

  .xl\:block {
    display: block;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-60 {
    width: 15rem;
  }

  .xl\:w-72 {
    width: 18rem;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:gap-4 {
    gap: 1rem;
  }

  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media (min-width: 1536px) {
  .\32xl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .\32xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .\32xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .\32xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .\32xl\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .\32xl\:container {
      max-width: 1536px;
    }
  }

  .\32xl\:block {
    display: block;
  }

  .\32xl\:hidden {
    display: none;
  }

  .\32xl\:w-1\/5 {
    width: 20%;
  }

  .\32xl\:max-w-md {
    max-width: 28rem;
  }

  .\32xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .\32xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .\32xl\:gap-9 {
    gap: 2.25rem;
  }

  .\32xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .\32xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

/* purgecss start ignore */

.TimeSlots {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.cc-online-meeting-hours-buttons {
  margin-bottom: 16px;
  width: 24%;
  margin-right: 1%;
}

.cc-online-meeting-hours-buttons .ant-radio-button-wrapper {
  padding: 0px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.online-meeting-date-picker .ant-calendar-picker-input {
  min-width: 340px;
}

.cc-online-meeting-hours-badge.ant-badge {
  padding-right: 5px;
}

.online-meeting-date-time-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-bottom: 8px;
  border-bottom: 1px solid #eff1f4;
}

.online-meeting-date-time-datePicer {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.online-meeting-date-time-timezone {
  width: 50%;
  margin-top: 16px;
  padding-left: 10px;
}

.online-meeting-date-time-radio-button-wrapper {
  width: 45%;
  border: 1px solid #e0e4e8;
  line-height: 16px;
  box-sizing: border-box;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
}

.online-meeting-date-time-radio-group {
  margin-top: 10px;
}

.online-meeting-date-time-radio-group > .ant-radio-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* purgecss end ignore */

/* purgecss start ignore */

.meeting-invite-colleagues-to-add {
  margin-bottom: 16px;
}

.meeting-invite-colleagues-to-add div {
  display: inline-block;
}

.meeting-invite-no-colleagues {
  margin-top: 16px;
  margin-bottom: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.MeetingAnswers-Container {
  margin-top: 15px;
  margin-bottom: 25px;
}

.MeetingAnswers-CollapseHeader {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.MeetingAnswers-CollapseHeader.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: #2d3239;
}

.MeetingAnswers-CollapseHeader.ant-collapse-borderless > .ant-collapse-item {
  background: #f7f8fa;
  border: 0px;
}

.MeetingAnswers-AnswerBody {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.MeetingAnswers-QuestionHeader {
  margin-bottom: 15px;
}

.MeetingAnswers-ExpandIcon {
  font-size: 25px !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

@-webkit-keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@-webkit-keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}

@keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}

.floating-textarea-row {
  position: relative;
  margin-bottom: 12px;
}

.placeholder-movement-textarea-notext {
  position: absolute;
  -webkit-animation-name: bring-placeholder-down;
  animation-name: bring-placeholder-down;
  -webkit-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.placeholder-movement-textarea-text,
.cc-floating-textarea:focus + .placeholder-movement-textarea-notext {
  position: absolute;
  -webkit-animation-name: shift-placeholder-up;
  animation-name: shift-placeholder-up;
  -webkit-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  color: #92949c;
}

.cc-floating-textarea-text,
.cc-floating-textarea:focus {
  transition: 120ms;
  padding-top: 24px !important;
  box-shadow: none;
  box-sizing: border-box;
}

.cc-floating-textarea-long-text,
.cc-floating-textarea-long-text:focus {
  transition: 120ms;
  padding-top: 40px !important;
  box-shadow: none;
  box-sizing: border-box;
}

.cc-floating-textarea-notext-long:focus {
  padding-top: 40px !important;
}

.cc-floating-textarea {
  transition: 120ms;
  padding-top: 8px;
  border: none;
  resize: vertical;
  color: #2d3239;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.cc-floating-textarea::-webkit-input-placeholder {
  -webkit-transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-textarea:-ms-input-placeholder {
  -ms-transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-textarea::placeholder {
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-textarea:focus::-webkit-input-placeholder {
  -webkit-transition: 0ms;
  transition: 0ms;
  border-top: 1px solid #f7f8fa;
  opacity: 0;
}

.cc-floating-textarea:focus:-ms-input-placeholder {
  -ms-transition: 0ms;
  transition: 0ms;
  border-top: 1px solid #f7f8fa;
  opacity: 0;
}

.cc-floating-textarea:focus::placeholder {
  transition: 0ms;
  border-top: 1px solid #f7f8fa;
  opacity: 0;
}

.floating-textarea-row .cc-validation-message {
  color: #d62b34;
  text-align: left;
  padding-left: 6px;
}

.cc-floating-textarea:hover,
.cc-floating-textarea:focus {
  border-top: 1px solid #f7f8fa;
}

.cc-floating-textarea-placeholder {
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-y: hidden;
  overflow-wrap: unset;
  right: 16px;
  margin-top: -3.5px;
  padding-top: 3.5px;
  margin-bottom: 24px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cancellingReasons > .ant-radio-wrapper > span {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #24262b;
}

/* purgecss end ignore */

/* purgecss start ignore */

.message-modal-heads-up {
  color: #92949c;
  font-size: small;
  text-align: center;
  margin-bottom: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.DateButtons-group {
  width: 400px;
}

.cc-eventdays-buttons {
  border: 1px solid #052449 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  width: 103px;
  height: 125px;
  padding: 16px 30px 0px;
  text-align: center;
  margin-bottom: 24px;
}

.cc-eventdays-buttons:not(:last-child) {
  margin-right: 24px;
}

.cc-eventdays-buttons.ant-radio-button-wrapper-checked:focus-within {
  outline-style: none;
}

.cc-eventdays-buttons.ant-radio-button-wrapper:not(:first-child)::before {
  background: transparent;
}

.cc-eventdays-buttons.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #ffffff;
}

.WeekDropdown {
  text-align: center;
  margin-bottom: 10px;
}

.WeekDropdown-week {
  color: #052449;
  font-weight: bold;
}

.WeekDropdown-text {
  color: #052449;
  margin-right: 115px;
}

.WeekDropdown > button {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #eff1f4;
  width: 100%;
  color: #052449;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-reschedule-datepicker-container {
  padding: 10px;
  flex-wrap: wrap;
}

.cc-datepicker .ant-calendar-date-input-wrap,
.cc-datepicker .ant-calendar-input-wrap,
.cc-datepicker .ant-calendar-footer {
  display: none;
}

.cc-datepicker .ant-calendar,
.cc-datepicker .ant-calendar-header {
  width: 370px;
  border: 0;
  box-shadow: none;
}

.cc-datepicker.ant-calendar-picker input,
.cc-datepicker.ant-calendar-picker i {
  display: none;
}

.ant-calendar-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #1b1a1f;
}

.cc-datepicker > div {
  position: relative !important;
}

.ant-calendar-date {
  width: 34px;
  height: 34px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 35px;
  color: #1b1a1f;
}

.cc-datepicker .ant-calendar-date {
  background-color: #ffffff;
}

.ant-calendar-cell {
  width: 50px;
  height: 50px;
  padding: 0px;
  border: 1px solid #f7f8fa !important;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  color: #92949c;
  background-color: #ffffff;
}

.ant-calendar-column-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7f848a;
  opacity: 0.5;
}

.ant-calendar-today .ant-calendar-date {
  background-color: rgba(37, 159, 169, 0.2);
  border-radius: 8px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  color: #1b1a1f;
  border: none;
  font-weight: normal;
}

.ant-calendar-selected-day .ant-calendar-date {
  background-color: #052449;
  border-radius: 8px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  color: #ffffff;
  border: none;
  font-weight: normal;
}

div.react-select-timezone {
  border-style: none !important;
}

.cc-AMPM-radio-buttons {
  width: 50%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 16px;
}

.cc-meeting-hours-radiogroup {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cc-meeting-hours-buttons {
  margin-bottom: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.UpcomingMeetingRequests-info {
  display: flex;
  align-items: center;
}

.UpcomingMeetingRequests-meeting-actions {
  display: inline-flex;
  margin-top: 8px;
}

.UpcomingMeetingRequests-date {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #052449;
}

.UpcomingMeetingRequests-tbd {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  color: #052449;
}

.UpcomingMeetingRequests-month {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #4d4f56;
  text-transform: uppercase;
}

.UpcomingMeetingRequests-name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
}

.UpcomingMeetingRequests-hour {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #4d4f56;
}

.UpcomingMeetingRequests-participants {
  display: flex;
  flex-wrap: wrap;
}

.UpcomingMeetingRequests-participants-icons {
  margin-right: 4px;
}

.UpcomingMeetingRequests-participants-confirmed {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #24262b;
}

.UpcomingMeetingRequests-participants-pending {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #92949c;
}

/* purgecss end ignore */

/* purgecss start ignore */

.charts-frame {
  width: 100%;
  min-height: 750px;
  border: none;
}

.fund-details-location {
  color: #c2c6cc;
}

.fund-details-actions-direct button {
  background: rgba(177, 52, 125, 0.1);
  font-size: 16px;
}

.fund-details-favicon {
  color: #f19f00;
}

.fund-details-document {
  background: #2d81a8;
}

.fund-details-reschedule {
  margin-left: 32px !important;
  color: #6a48c6;
}

.fund-details-attachment {
  color: #b1347d;
}

.fund-details-tabs {
  margin-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.fund-details-footer {
  height: 64px;
  width: 100%;
  background: #f7f8fa;
  display: block;
}

.fund-details-footer-buttons {
  float: right;
  padding-right: 24px;
  padding-top: 16px;
}

.fund-details-footer-buttons button {
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.fund-details-footer-buttons button svg {
  margin-right: 10px;
}

.fund-details-send-message {
  background: #2d81a8 !important;
}

.fund-details-menu-button {
  padding: 6px 12px;
  color: #92949c;
  background: #ffffff;
  border: 1px solid #c2c6cc;
  box-sizing: border-box;
  border-radius: 4px;
}

.fund-details-menu-button svg {
  margin-right: 8px;
}

.fund-details-menu-divider {
  border-top: 1px solid #eff1f4;
  height: 1px;
  margin: 0px 0px 0px 0px;
}

.fund-details-disclaimer {
  margin-top: 36px;
}

.menu-item-menu {
  padding-left: 8px;
  padding-right: 8px;
}

.menu-item {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 48px;
}

.menu-item-icon {
  display: inline-block;
  width: 32px;
  height: 48px;
  font-size: 20px;
  padding-top: 2px;
  vertical-align: top;
  text-align: center;
  color: #052449;
}

.menu-item-text {
  display: inline-block;
  margin-left: 6px;
  color: #000000;
  padding-top: 8px;
}

.action-menu-divider {
  margin: 0px 0px 0px 0px !important;
}

.discover-fund-details-header {
  height: auto;
  padding: 24px 32px;
  background-color: #f7f8fa;
}

.discover-fund-details-title {
  display: flex;
  flex-direction: column;
}

.discover-details-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.discover-details-send-message {
  background: rgba(47, 197, 210, 0.1) !important;
  margin-right: 20px;
}

.discover-details-send-message > svg {
  margin-right: 5px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.MeetingTypeSelector {
  width: 100%;
  display: flex;
  height: 200px;
  padding: 32px;
}

.MeetingTypeSelector-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  border-radius: 4px;
  flex: 1 1;
  padding: 16px;
}

.MeetingTypeSelector-button span {
  height: 100%;
  width: 100%;
}

.MeetingTypeSelector-button-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.MeetingTypeSelector-button-icon {
  color: #c2c6cc;
}

.ant-radio-button-wrapper-checked .MeetingTypeSelector-button-icon,
.ant-radio-button-wrapper:hover .MeetingTypeSelector-button-icon {
  color: #ffffff;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FundWizard-form {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2 1;
  padding: 20px;
}

.FundWizard-form-container {
  height: 75vh;
  overflow-y: auto;
  margin-bottom: 24px;
}

.FundWizard-form-footer {
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
}

.FundWizard-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FundWizard-steps {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}

.Question-label {
  font-weight: 400;
  font-size: 14px;
  color: #3d4858;
  display: block;
}

.Question-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.Question-row-half {
  width: 50%;
}

.Question-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}

.Question-array-field {
  margin-top: 12px;
}

.Nested-field > .ant-form-item > .ant-form-item-control {
  padding: 0px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.UpdateReturnsExcel-step {
  font-size: 16px;
  color: #92949c;
  margin-bottom: 10px;
}

.UpdateReturnsExcel-error {
  color: #d62b34;
}

.UpdateReturnsExcel-error.container {
  margin-bottom: 32px;
}

.UpdateReturnsExcel-error .header {
  display: flex;
  justify-content: left;
  align-content: center;
  flex-direction: row;
  color: #d62b34;
}

.UpdateReturnsExcel-error .row {
  padding-left: 34px;
}

.UpdateReturnsExcel-preview {
  background-color: #eff1f4;
  color: #333333;
}

.UpdateReturnsExcel-preview-data {
  max-height: 30vh;
  overflow-y: scroll;
}

.UpdateReturnsExcel-preview-row {
  padding: 10px 12px;
  border-bottom: 1px solid #e5e5e5;
}

.UpdateReturnsExcel-footer {
  margin-top: 12px;
  display: flex;
  justify-content: right;
  grid-gap: 10px;
  gap: 10px;
}

.EditExcelForm .Field-error {
  border: 1px solid red !important;
}

.EditExcelForm .full-width {
  width: 100%;
}

.EditExcelForm .right-align {
  text-align: right;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FundWizardModal.ant-modal {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  top: 0;
  padding: 0;
}

.FundWizardModal.ant-modal .ant-modal-content {
  height: 100vh;
}

.FundWizardModal.ant-modal .ant-modal-body {
  padding: 0;
}

.FundWizard {
  background: #ffffff;
  box-shadow: 0 16px 28px rgba(47, 56, 62, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.FundWizard-form {
  padding: 20px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.FundWizard-row {
  width: 100%;
}

.FundWizard-steps {
  background: #eff1f4;
}

.FundWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  color: #4d4f56;
  font-weight: 600;
}

.FundWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  display: none;
}

.FundWizard-steps > .StepList {
  display: flex;
}

.FundWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item {
  max-height: 80px;
  min-height: 80px;
}

.FundWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  background: #e0e0e0;
  margin-top: 34px;
  height: 35px;
}

.FundWizard .FundWizard-steps {
  padding: 40px;
  min-height: calc(100vh - 136px);
}

.FundWizard .FundWizard-steps > .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  visibility: hidden;
}

.FundWizard > .FundWizard-form {
  flex-grow: 1;
  padding: 0 84px;
  min-height: 100vh;
}

.FundWizard > .FundWizard-form-actions {
  width: 95%;
  border-top: 1px solid #eff1f4;
  padding: 48px;
}

.FundWizard .FundWizard-field {
  margin-bottom: 16px;
}

.FundWizard .FundWizard-field-label {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  color: #24262b;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.FundWizard .FundWizard-field .ant-input {
  height: 48px;
  border: none;
  border-radius: 4px;
  background-color: #f7f8fa;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  margin-bottom: 8px;
}

.FundWizard .FundWizard-field .ant-input-number {
  border: none;
  height: 48px;
  width: 100%;
  margin-bottom: 8px;
}

.FundWizard .FundWizard-field .ant-input-number .ant-input-number-input {
  height: 46px;
}

.FundWizard .FundWizard-field .ant-calendar-picker {
  display: inline-block;
  width: 100%;
}

.FundWizard .FundWizard-field .ant-calendar-picker .ant-calendar-picker-input {
  height: 48px;
}

.FundWizard .FundWizard-field .ant-select .ant-select-selection {
  height: 48px;
  border: none;
  background-color: #f7f8fa;
}

.FundWizard .FundWizard-field .ant-select .ant-select-selection .ant-select-selection__rendered {
  line-height: 46px;
}

.FundWizard .FundWizard-field .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
  height: 30px;
}

.FundWizard .FundWizard-field-checkbox > .ant-checkbox-group-item {
  display: block;
  margin-bottom: 7px;
}

.FundWizard .FundWizard-field-boolean-options > .ant-radio-wrapper:first-child {
  margin-right: 48px;
}

.FundWizard .FundWizard-field-footer {
  margin: 16px 0;
  padding-left: 24px;
}

.FundWizard .FundWizard-Step > .FundWizard-actions {
  display: flex;
  border-top: 1px solid #e0e0e0;
  justify-content: space-between;
  padding: 10px 0;
}

.FundWizard .FundWizard-Step > .FundWizard-actions > .ant-btn-lg {
  min-width: 50px;
}

.FundWizard.FundWizard-initial {
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.FundWizard .FundWizard-step-title {
  text-align: center;
  margin-bottom: 40px;
}

.FundWizard-initial-form > .FundWizard-step-title {
  text-align: center;
  color: #24262b;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 64px;
}

.FundWizard-initial-form-actions {
  margin-top: 64px;
}

.FundStepButton {
  margin-top: 10px;
}

.FundStepButton-back {
  margin-top: 15px;
  color: #92949c;
  cursor: pointer;
}

.FundStepButton-save {
  margin-top: 10px;
  color: #92949c !important;
  border-color: #cbcbcb !important;
}

.FundStepButton-save:hover {
  margin-top: 10px;
  color: #54d7de !important;
  border-color: #54d7de !important;
}

.FundStepPreviewButton {
  margin-top: 10px;
  background: #2d81a8;
  margin-right: 10px;
  color: #54d7de !important;
  border-color: #e0e0e0 !important;
}

.LinkField-container {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eff1f4;
  text-align: center;
}

.LinkField-fields-column {
  display: inline-block;
  width: 100%;
}

.LinkField-title,
.LinkField-url {
  vertical-align: top;
  display: inline-block;
  width: 50%;
  padding: 0px 8px 0px 8px;
}

.FundTypeStep {
  background: #eff1f4;
  padding: 10px 20px 20px;
}

.FundTypeStep-RadioGroup {
  padding-top: 10px;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 1fr 1fr;
}

.FundTypeStep-RadioContainer {
  padding: 10px 0;
}

.FundTypeStep-Radio {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid transparent;
  margin: 20px;
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column-reverse;
}

.FundTypeStep-Radio:hover {
  border-color: #052449;
  box-shadow: 0 2px 10px rgba(186, 186, 201, 0.51);
}

.FundTypeStep-Radio h3 {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.FundTypeStep-Radio p {
  font-size: 19px;
  line-height: 27px;
  margin: 40px 0;
}

.FundTypeStep-Radio .ant-radio {
  align-self: center;
}

.FundTypeStep-Next {
  padding: 0 60px;
  display: block;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
}

.FundTypeStep-BulletGroup {
  margin-bottom: 10px;
}

.FundTypeStep-Bullet {
  display: flex;
  margin: 10px;
}

.FundTypeStep-Bullet svg {
  margin-right: 5px;
}

.FundTypeStep-Bullet span {
  font-weight: bold;
}

.FundTypeStep-BulletHeadline {
  font-size: 16px;
  line-height: 30px;
  color: #828282;
}

/* purgecss end ignore */

/* purgecss start ignore */

.returns-update-form table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.returns-update-form table thead :is(th, td) > div {
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
  margin-left: -1px;
  margin-right: -1px;
}

.returns-update-form table tbody :is(th, td) {
  padding: 0.125rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.UpdateReturnsModal .ant-col {
  padding-left: 0px;
}

.UpdateReturnsModal .form-box {
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  box-shadow: 0px 7px 20px rgba(141, 136, 172, 0.15);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 12px;
}

.UpdateReturnsModal .header-row {
  margin-bottom: 12px;
  font-weight: bold;
}

.UpdateReturnsModal .full-width {
  width: 100%;
}

.UpdateReturnsModal .right-align {
  text-align: right;
}

.UpdateReturnsModal .ant-tabs-nav {
  width: 100% !important;
  padding-left: 0;
  margin-bottom: 0;
}

.UpdateReturnsModal .ant-tabs-content-holder {
  border: 1px #f0f0f0 solid;
  border-top: 0;
  padding: 8px;
}

.UpdateReturnsModal .ant-tabs-tab {
  display: block;
  flex: 1 1;
  text-align: center;
}

.UpdateReturnsModal .ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}

.UpdateReturnsModal-header {
  font-size: 16px;
  color: #92949c;
  margin-bottom: 10px;
}

.UpdateReturnsModal-updateline {
  font-size: 12px;
  color: #92949c;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 12px;
}

.UpdateReturnsModal-preview {
  background-color: #eff1f4;
  color: #333333;
}

.UpdateReturnsModal-preview-row {
  padding: 10px 12px;
  border-bottom: 1px solid #e5e5e5;
}

/* purgecss end ignore */

/* purgecss start ignore */

@-webkit-keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@-webkit-keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}

@keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}

.floating-input-row {
  position: relative;
  margin-bottom: 16px;
}

.placeholder-movement-notext {
  position: absolute;
  -webkit-animation-name: bring-placeholder-down;
  animation-name: bring-placeholder-down;
  -webkit-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.cc-floating-placeholder {
  text-align: left;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 20px;
  overflow-y: hidden;
  overflow-wrap: unset;
  white-space: nowrap;
  width: 95%;
}

.placeholder-movement-text,
.cc-floating-input:focus + .placeholder-movement-notext {
  position: absolute;
  -webkit-animation-name: shift-placeholder-up;
  animation-name: shift-placeholder-up;
  -webkit-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  color: #92949c;
}

.cc-floating-input-text,
.cc-floating-input:focus {
  transition: 120ms;
  height: 48px;
  padding-top: 24px;
  box-shadow: none;
}

.cc-floating-input-row-text {
  transition: 120ms;
  margin-bottom: 8px;
}

.cc-floating-input {
  transition: 120ms;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  color: #2d3239;
}

.cc-floating-input-invalid {
  border: 1px solid #d62b34 !important;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation: normal;
  animation: normal;
  transition: 120ms !important;
}

.cc-floating-input::-webkit-input-placeholder {
  -webkit-transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-input:-ms-input-placeholder {
  -ms-transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-input::placeholder {
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-input:focus::-webkit-input-placeholder {
  -webkit-transition: 0ms;
  transition: 0ms;
  opacity: 0;
}

.cc-floating-input:focus:-ms-input-placeholder {
  -ms-transition: 0ms;
  transition: 0ms;
  opacity: 0;
}

.cc-floating-input:focus::placeholder {
  transition: 0ms;
  opacity: 0;
}

.floating-input-row .cc-validation-message {
  color: #d62b34;
  text-align: left;
  padding-left: 6px;
}

.cc-floating-search span input {
  color: #2d3239;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.cc-floating-search span input::-webkit-input-placeholder {
  color: #2d3239;
}

.cc-floating-search span input:-ms-input-placeholder {
  color: #2d3239;
}

.cc-floating-search span input::placeholder {
  color: #2d3239;
}

.cc-floating-search span input:focus {
  border: none;
  box-shadow: none;
}

#zipCode::-webkit-input-placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

#zipCode:-ms-input-placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

#zipCode::placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

/* purgecss end ignore */

/* purgecss start ignore */

.upload-icon-upload {
  position: absolute;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 137px;
  border-radius: 64px;
}

.upload-icon-empty {
  border: dashed 1px #c2c6cc;
  width: 137px;
  height: 137px;
  border-radius: 69px;
  background: #f7f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.upload-icon-empty .ant-upload-select-picture-card {
  border: none;
  background: rgba(0, 0, 0, 0);
  padding-right: 0px;
  padding-bottom: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.upload-icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
}

.upload-icon-text svg {
  font-size: 26px;
  margin-bottom: 10px;
  color: #c2c6cc;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FormField {
  margin-bottom: 16px;
}

.FormField .Field-error {
  font-size: 12px;
  line-height: 16px;
  color: #d62b34;
  border: none;
}

.FormField .Field-error .Field-error-message {
  margin-left: 6px;
}

.FormField .Field-deals > .ant-picker > .ant-picker-input > input {
  margin: 0px !important;
}

.FormField .Field {
  position: relative;
  margin-bottom: 8px;
}

.FormField .Field > .ant-picker {
  width: 100%;
  height: 48px;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.FormField .Field > .ant-picker > .ant-picker-input > input {
  margin: 15px 0px 0px 5px;
}

.FormField .Field > .ant-select-selector {
  transition: 0.12s;
  position: relative;
  height: 32px;
  cursor: pointer;
}

.FormField .Field > .ant-input-search:not(.ant-input-search-enter-button) {
  padding-right: 0;
}

.FormField .Field > .ant-input-search:not(.ant-input-search-enter-button) > .ant-input-wrapper > .ant-input-group-addon > .ant-input-search-button {
  height: 48px;
}

.FormField .Field > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 60px;
  margin-left: 5px;
}

.FormField .Field .ant-input-affix-wrapper {
  border: none;
  webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', 'tnum';
  font-feature-settings: 'tnum', 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  width: 100%;
  text-align: start;
}

.FormField .Field > .ant-input,
.FormField .Field > .ant-input-number .ant-input-number-input,
.FormField .Field > .ant-input-password > .ant-input,
.FormField .Field > .ant-input-search > .ant-input,
.FormField .Field > .ant-calendar-picker .ant-calendar-picker-input,
.FormField .Field > .ant-select > .ant-select-selection--single {
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  height: 48px;
}

.FormField .Field > span > .ant-upload {
  background: #ffffff;
}

.FormField .Field > .ant-input-search {
  background: #f7f8fa;
  border-radius: 3px;
}

.FormField .Field > .ant-input-search > .ant-input {
  border: none;
}

.FormField .Field > .ant-input-search > .ant-input-suffix {
  margin-left: 0px;
}

.FormField .Field > .ant-input-search > .ant-input-suffix > .ant-input-search-icon {
  margin-left: 0px;
}

.FormField .Field > .ant-select > .ant-select-selector {
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  height: 48px;
}

.FormField .Field > .ant-select > .ant-select-selector {
  border: none;
  background: #f7f8fa;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  height: 48px;
}

.FormField .Field > .ant-input-number {
  border: none;
  height: 48px;
}

.FormField .Field > .ant-input-number .ant-input-number-input {
  height: 48px;
}

.FormField .Field > .ant-select-auto-complete.ant-select .ant-input {
  border-width: 0;
  padding-left: 0;
  background-color: transparent;
}

.FormField .Field > .ant-input-number,
.FormField .Field > .ant-calendar-picker,
.FormField .Field > .ant-select {
  width: 100%;
}

.FormField .Field .ant-input:focus,
.FormField .Field > .ant-input-number .ant-input-number-input:focus,
.FormField .Field > .ant-input-search > .ant-input:focus,
.FormField .Field > .ant-calendar-picker .ant-calendar-picker-input:focus {
  outline: 0;
  background: #ffffff;
  border: 1px solid #052449;
  box-shadow: none;
}

.FormField .Field .ant-input.Field-has-value,
.FormField .Field .ant-input-search.Field-has-value .ant-input,
.FormField .Field .ant-input-number.Field-has-value .ant-input-number-input,
.FormField .Field .ant-calendar-picker.Field-has-value .ant-calendar-picker-input {
  padding-top: 20px;
  padding-bottom: 4px;
}

.FormField .Field > .ant-select > .ant-select-selection--single > .ant-select-selection__rendered {
  line-height: 36px;
  margin-right: 0px;
  margin-left: 0;
}

.FormField .Field > .ant-checkbox-group > .ant-checkbox-wrapper {
  display: flex;
  margin-bottom: 4px;
}

.FormField .Field > label.Field-label {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #24262b;
}

.FormField .Field > label.Field-floating-label {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 12px;
  font-size: 14px;
  line-height: 24px;
  transition: 0.2s ease all;
  color: #4d4f56;
}

.FormField .Field-required > label.Field-floating-label {
  font-weight: 600;
}

.FormField .Field-required > label.Field-floating-label > b {
  color: #4d4f56;
  padding-left: 4px;
}

.FormField .Field > .Field-has-focus ~ label.Field-floating-label,
.FormField .Field > .Field-has-value ~ label.Field-floating-label {
  color: #7f848a;
  top: 6px;
  left: 16px;
  font-size: 12px;
  line-height: 16px;
}

.FormField .Field-info {
  margin: 8px 0 16px;
  padding-left: 24px;
}

.FormField-helpText {
  color: rgba(130, 130, 130, 0.75);
  margin-top: 5px;
  margin-left: 10px;
}

#OpportunityDetails {
  min-height: 120px;
}

#DistributorEmail {
  padding-top: 0px;
  padding-bottom: 0px;
}

#ManagerZipCode {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 48px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.interested-button .button.button--filled, .interested-button.button.button--filled {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 129, 78, var(--tw-bg-opacity))
}

.interested-button .button.button--filled:hover, .interested-button .button.button--filled:focus-visible, .interested-button .button.button--filled:active, .interested-button.button.button--filled:hover, .interested-button.button.button--filled:focus-visible, .interested-button.button.button--filled:active {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 154, 113, var(--tw-bg-opacity))
}

.interested-button .button.button--outlined, .interested-button.button.button--outlined {
  --tw-border-opacity: 1;
  border-color: rgba(30, 129, 78, var(--tw-border-opacity))
}

.interested-button .button.button--outlined, .interested-button.button.button--outlined {
  --tw-text-opacity: 1;
  color: rgba(30, 129, 78, var(--tw-text-opacity))
}

.interested-button .button.button--outlined:hover, .interested-button .button.button--outlined:focus-visible, .interested-button .button.button--outlined:active, .interested-button.button.button--outlined:hover, .interested-button.button.button--outlined:focus-visible, .interested-button.button.button--outlined:active {
  --tw-border-opacity: 1;
  border-color: rgba(75, 154, 113, var(--tw-border-opacity))
}

.interested-button .button.button--outlined:hover, .interested-button .button.button--outlined:focus-visible, .interested-button .button.button--outlined:active, .interested-button.button.button--outlined:hover, .interested-button.button.button--outlined:focus-visible, .interested-button.button.button--outlined:active {
  --tw-text-opacity: 1;
  color: rgba(75, 154, 113, var(--tw-text-opacity))
}

/* purgecss end ignore */

/* purgecss start ignore */

.profile-link {
  color: #052449;
  margin-bottom: 20px;
  display: flex;
  overflow-wrap: anywhere;
  align-items: baseline;
}

.profile-link > svg {
  margin-right: 5px;
}

.profile-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  justify-content: center;
  line-height: 40px;
}

.deal-info-text {
  color: #4d4f56;
  line-height: 21px;
  font-weight: 600;
  margin: 8px 0px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.allocator-managers .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.NotificationEntry > .NotificationEntry-icon {
  padding: 16px;
  display: flex;
}

.NotificationEntry > .NotificationEntry-icon > .ant-btn {
  color: #052449;
  border: none;
  padding: 0;
  margin: 0;
  height: auto;
}

.NotificationEntry-read > .NotificationEntry-icon {
  color: #c2c6cc;
}

.NotificationEntry .NotificationEntry-description {
  flex-grow: 1;
  padding-bottom: 16px;
  border-bottom: 1px solid #eff1f4;
  font-size: 14px;
  line-height: 24px;
  color: #1b1a1f;
}

.NotificationEntry .NotificationEntry-description > .NotificationEntry-description-wrapper {
  color: #1b1a1f;
  cursor: pointer;
}

.NotificationEntry .NotificationEntry-description > .NotificationEntry-description-wrapper:hover {
  color: #4b70b8;
}

.NotificationEntry > .NotificationEntry-description .NotificationEntry-subject {
  font-weight: 600;
}

.NotificationEntry > .NotificationEntry-description span {
  margin-right: 0.25rem;
}

.NotificationEntry > .NotificationEntry-description span:last-child {
  margin-right: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.NotificationList {
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  padding: 12px 18px;
  width: 430px;
}

.NotificationList-header {
  border-bottom: 1px solid #eff1f4;
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.NotificationList-header > h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-top: 9px;
  margin-bottom: 0;
}

.NotificationList-header > .NotificationList-header-actions {
  text-align: right;
}

.NotificationList-header-actions-btn-text {
  display: inline-block;
  margin-left: 8px;
}

.NotificationList-header-actions-btn-settings {
  color: #c2c6cc;
}

.NotificationList-entries {
  max-height: 420px;
}

.NotificationList-entries .NotificationEntry:last-child > .NotificationEntry-description {
  border-bottom: 0;
}

.NotificationList-footer {
  border-top: 1px solid #eff1f4;
  text-align: center;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-meeting-status-notification {
  width: 100%;
  height: 64px;
  text-align: center;
  padding-top: 18px;
  color: #ffffff;
}

.cc-meeting-status-notification svg {
  margin-right: 10px;
  font-size: 24px;
  padding-top: 4px;
}

.cc-meeting-status-notification-upcoming {
  background: #0072dc;
}

.cc-meeting-status-notification-ongoing {
  background: #6a48c6;
}

.cc-meeting-status-contact {
  font-weight: 900;
}

.cc-meeting-status-button {
  margin-left: 16px;
  width: 160px;
  height: 32px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.rating-form-header {
  color: #24262b;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.rating-card {
  background-color: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 4px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  padding: 16px;
  margin-bottom: 11px;
}

.meeting-info {
  font-size: 14px;
  line-height: 24px;
  color: #24262b;
}

.MeetingWith {
  margin-bottom: 16px;
}

.MeetingWith-header {
  color: #24262b;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.MeetingWith-details {
  font-size: 14px;
  line-height: 24px;
  color: #24262b;
  margin-bottom: 8px;
}

.MeetingWith-details-at {
  display: inline-block;
  color: #92949c;
  margin: 0 0.25em;
}

.MeetingWith-contacts {
  display: block;
}

.MeetingWith-contacts .contact-list-contact {
  display: inline-block;
  margin: 0 0.25em 0 0;
}

.MeetingWith-contacts .contact-list-contact-name {
  color: #24262b;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.rating-form-header {
  color: #24262b;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.rating-card {
  background-color: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 4px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  padding: 16px;
  margin-bottom: 11px;
}

.meeting-info {
  font-size: 14px;
  line-height: 24px;
  color: #24262b;
}

.MeetingRatings-notice {
  margin-bottom: 1.5rem;
}

.MeetingRatings-notice > .ant-alert-message {
  color: #24262b;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.MeetingRatings-notice > .ant-alert-description {
  color: #4d4f56;
  font-size: 14px;
  line-height: 24px;
}

.MeetingRatings {
  max-height: 580px;
}

.MeetingRatings-session-title {
  color: #000000;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}

.ManagerToAllocatorRatingForm {
  background-color: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 4px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  padding: 16px;
  margin-bottom: 11px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.ManagerToAllocatorRatingForm > .MeetingWith {
  width: 60%;
}

.ManagerToAllocatorRatingForm-rating {
  width: 40%;
  text-align: right;
}

.ManagerToAllocatorRatingForm-rating-header {
  color: #24262b;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.ManagerToAllocatorRatingForm-comment {
  width: 100%;
  margin-top: 12px;
}

.ManagerToAllocatorRatingForm-comment > textarea.ant-input {
  border: 0;
  border-top: 1px solid #eff1f4;
  background: transparent;
  border-radius: 0;
}

.ManagerToAllocatorRatingForm-comment > textarea.ant-input:focus {
  outline: 0;
  box-shadow: none !important;
}

.ManagerToAllocatorRatingForm-comment-help {
  font-size: 12px;
  line-height: 16px;
  color: #d62b34;
}

.ManagerToAllocatorRatingForm-comment-help > span {
  margin-left: 5px;
}

.AllocatorToManagerRatingForm {
  background-color: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 4px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.04);
  padding: 16px;
  margin-bottom: 11px;
}

.AllocatorToManagerRatingForm-details {
  width: 100%;
}

.AllocatorToManagerRatingForm-details-header {
  color: #24262b;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.AllocatorToManagerRatingForm-details-meeting {
  font-size: 14px;
  line-height: 24px;
  color: #24262b;
}

.AllocatorToManagerRatingForm-rating-label {
  margin-bottom: 0;
}

.AllocatorToManagerRatingForm-rating > .ant-radio-group {
  margin: 8px 0;
}

.AllocatorToManagerRatingForm-rating-indication > .ant-checkbox-group {
  margin-top: 8px;
}

.AllocatorToManagerRatingForm-rating-indication > .ant-checkbox-group > .ant-checkbox-group-item {
  display: flex;
  margin-bottom: 8px;
}

.AllocatorToManagerRatingForm-rating-comment {
  margin: 9px 0 18px;
}

.AllocatorToManagerRatingForm-rating-comment > textarea.ant-input {
  background-color: #f7f8fa;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 24px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ant-col {
  padding-left: 0px;
}

.form-box {
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  box-shadow: 0px 7px 20px rgba(141, 136, 172, 0.15);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 12px;
}

.header-row {
  margin-bottom: 12px;
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.right-align {
  text-align: right;
}

.ApproveReturns {
  text-align: center;
  padding-bottom: 24px;
}

.ApproveReturns-title {
  font-size: 16px;
  color: gray;
}

.ApproveReturns-name {
  font-size: 20px;
  font-weight: bold;
  color: black;
  padding-bottom: 16px;
}

.ApproveReturns-light-text {
  color: #92949c;
}

.ApproveReturns-publish {
  margin-top: 24px;
  padding-bottom: 16px;
}

.ApproveReturns-edit {
  margin-top: 8px;
}

.Disclaimer {
  color: #052449;
}

.ApproveReturns-Disclaimer-Tooltip > .ant-tooltip-content > .ant-tooltip-inner {
  width: 25vw;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FundMiniProfile {
  background-color: #151517;
  width: 250px;
  margin-top: 10px;
  border-radius: 10px;
}

.FundMiniProfile-Row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.FundMiniProfile-Title {
  padding-top: 12px;
  color: #ffffff;
}

.FundMiniProfile-SubTitle {
  color: #ffffff;
  opacity: 0.75;
  padding-top: 5px;
}

.FundMiniProfile-Button {
  width: 185px;
  margin: 10px auto;
}

/* purgecss end ignore */

/* purgecss start ignore */

@-webkit-keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@keyframes shift-placeholder-up {
  0% {
    opacity: 1;
    top: 11px;
    left: 11px;
  }

  100% {
    top: 4px;
    left: 11px;
    opacity: 1;
    font-size: 11px;
    font-weight: 900;
  }
}

@-webkit-keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}

@keyframes bring-placeholder-down {
  0% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  1% {
    top: 4px;
    left: 11px;
    font-size: 11px;
    font-weight: 900;
  }

  99% {
    top: 11px;
    left: 11px;
    opacity: 1;
    font-size: 14px;
    z-index: 1049;
  }

  100% {
    top: 11px;
    left: 11px;
    opacity: 0;
    font-size: 14px;
    z-index: -9999;
  }
}

.floating-select-row {
  position: relative;
  margin-bottom: 16px;
}

.cc-floating-select {
  width: 100%;
  color: #2d3239;
  text-align: left;
}

.cc-floating-select div {
  border: 1px solid #cbcbcb;
  border-radius: 3px;
}

.cc-floating-placeholder-select {
  position: absolute;
  -webkit-animation-name: bring-placeholder-down;
  animation-name: bring-placeholder-down;
  -webkit-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.placeholder-select-focused,
.placeholder-select-movement-text {
  position: absolute;
  -webkit-animation-name: shift-placeholder-up;
  animation-name: shift-placeholder-up;
  -webkit-animation-duration: 120ms;
  animation-duration: 120ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  color: #92949c;
}

.cc-floating-select-text {
  height: 48px;
  text-align: left;
}

.cc-floating-select div:focus {
  transition: 120ms;
  box-shadow: none;
  height: 48px;
  padding-top: 14px;
}

.cc-floating-select-text > div {
  box-shadow: none;
  height: 48px !important;
  padding-top: 8px !important;
}

.cc-floating-select div:focus div {
  height: 30px;
}

.ant-select-selection {
  transition: 120ms;
}

.cc-floating-select div div {
  height: 30px;
}

.cc-floating-select-text div div {
  height: 30px;
}

.cc-floating-select-focused .ant-select-selection__placeholder {
  transition: 0ms;
  opacity: 0;
}

.cc-floating-select > .ant-select-selector > .ant-select-selection-search {
  top: 9px;
}

.cc-floating-select-nonfocused .ant-select-selection__placeholder {
  transition: 120ms cubic-bezier(1, 0.01, 1, 0.01);
  padding-top: 6px;
  opacity: 1;
}

.cc-floating-select .ant-select-selection__placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #92949c;
}

.cc-floating-select .ant-select-selection-selected-value {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.cc-floating-select-invalid {
  border: 1px solid #d62b34 !important;
  border-radius: 4px 4px;
}

.floating-select-row .cc-validation-message {
  color: #d62b34;
  text-align: left;
  padding-left: 6px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 44px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.settings-investor-preferences-board {
  background-color: #ffffff;
  margin-bottom: 32px !important;
  padding-bottom: 32px;
  text-align: center;
  width: 55%;
  margin: auto;
}

.settings-investor-preferences-board .cc-settings-investor-status {
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #eff1f4;
  border-radius: 3px;
  width: 80%;
}

.settings-investor-preferences-board .cc-generic-form-container {
  margin-left: 64px;
  width: 90%;
}

.settings-investor-preferences-board button {
  width: 150px;
}

.settings-investor-preferences-board .cc-onboard-section-row-control {
  width: 50%;
}

.settings-investor-preferences-board .cc-strategies-checkbox .ant-checkbox-wrapper {
  width: 50%;
}

.settings-investor-preferences-board .ant-checkbox-group {
  width: 100%;
}

.settings-investor-preferences-board .form-section-submit {
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-left: 0px;
  padding-right: 0px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-matchmaking-settings {
  text-align: center;
}

.cc-matchmaking-settings > .cc-matchmaking-settings-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #151517;
  padding-top: 40px;
}

.cc-matchmaking-settings > .cc-matchmaking-settings-description {
  color: #92949c;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.cc-matchmaking-settings > .cc-matchmaking-settings-slider {
  background-color: #ffffff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-top: 80px;
  margin-left: 10%;
  margin-right: 10%;
}

.cc-matchmaking-settings > .cc-matchmaking-settings-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 50px;
  color: #2d3239;
}

.cc-matchmaking-settings > .cc-matchmaking-settings-button {
  background-color: #052449;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-top: 50px;
  color: #ffffff;
}

.cc-matchmaking-settings > .cc-matchmaking-settings-button.ant-btn[disabled] {
  color: #ffffff;
  background-color: #92949c;
  border-color: #92949c;
}

.cc-matchmaking-settings .ant-tooltip-inner {
  background-color: #ffffff;
}

.cc-matchmaking-settings .ant-slider-handle {
  border-color: #052449;
  background: #052449;
}

.cc-matchmaking-settings .ant-slider-mark-text-active {
  color: #052449;
  font-weight: 600;
  font-size: 18px;
}

.cc-matchmaking-settings-Radio-body.ant-radio-button-wrapper {
  text-align: center;
  width: 50%;
}

.cc-matchmaking-settings-Radio {
  width: 750px;
  display: flex;
  margin: auto;
}

.cc-matchmaking-settings-radio-text {
  margin-top: 20px;
  padding-left: 5%;
}

.cc-matchmaking-settings-radio-visibility {
  padding-left: 15%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.company-domicile-section {
  margin-top: 16px;
  margin-bottom: 16px;
  display: block;
}

.company-domicile-section > * {
  display: block;
}

.company-domicile-section > label {
  margin-top: 5px;
}

.cc-settings-investor-status {
  width: 558px;
  height: 110px;
  border: 1px solid #eff1f4;
  border-radius: 3px;
  width: 658px;
  text-align: center;
  margin-bottom: 24px;
}

.cc-settings-investor-label {
  display: block;
  margin-top: 16px;
}

.cc-settings-investor-label-value {
  display: block;
}

.cc-settings-status-container {
  padding-left: 200px;
  width: 100%;
  margin-bottom: 24px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AllocatorDetailsModal-tabs > .ant-tabs-nav {
  margin: 0 !important;
  max-height: 35px;
}

.allocator-disclaimer {
  margin-top: 18px;
  padding-bottom: 18px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FundOnlineMR > .ant-modal-header {
  padding: 24px 32px;
}

.FundOnlineMR > .ant-modal-header > .ant-modal-title {
  color: #2d3239;
}

.FundOnlineMR .ant-modal-content > .ant-modal-footer {
  padding: 0px;
}

.FundOnlineMR-participants {
  background-color: #ffffff;
  padding: 16px 32px;
}

.steps-icons {
  display: none;
}

.footer {
  padding: 16px 32px;
  background: #f7f8fa;
}

.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: #f7f8fa !important;
  border-style: none !important;
  border-radius: 4px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ServiceProviderClients {
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
}

.ServiceProviderClients-text {
  margin: 10px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: #4d4f56;
}

.ServiceProviderClients-client {
  float: left;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ServiceProviderContent {
  margin: 30px;
}

.ServiceProviderContent-title {
  font-size: 12px;
  color: #92949c;
}

.ServiceProviderContent-link {
  font-weight: 600;
  font-size: 24px;
  color: #052449;
  margin-bottom: 20px;
}

.ServiceProviderContent-content {
  margin-bottom: 10px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ServiceProviderContact-description {
  color: #4d4f56;
  font-size: 14px;
}

.ServiceProviderContact-link {
  color: #052449;
  cursor: pointer;
}

.ServiceProviderContact-icon {
  margin-right: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ServiceProviderPeopleCard {
  margin: 8px 8px 24px 8px;
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  padding: 32px 16px;
  box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.15);
  transition: border 300ms;
}

.ServiceProviderPeopleCard-click {
  cursor: pointer;
  border: 1px solid transparent;
}

.ServiceProviderPeopleCard-click:hover {
  box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.51);
  background: #f1f4f9;
  border-color: #4b70b8;
}

.ServiceProviderPeopleCard-title {
  color: #3d4858;
  font-size: 18px;
  font-weight: 600;
}

.ServiceProviderPeopleCard-subtitle {
  color: #92949c;
  font-size: 14px;
}

.ServiceProviderPeopleCard-description {
  color: #4d4f56;
  font-size: 14px;
}

.ServiceProviderPeopleCard-row {
  display: flex;
  align-items: center;
}

.ServiceProviderPeopleCard-text {
  margin: 16px 16px 0px 0px;
}

.ServiceProviderPeopleCard-avatar {
  height: 70px;
  display: flex;
  align-items: flex-start;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-categories-div {
  display: grid;
  grid-gap: 16px;
  margin-bottom: 32px;
  margin-top: 8px;
}

.service-image {
  border-radius: 8px;
  cursor: pointer;
  height: 100% !important;
  object-fit: cover;
  width: 100% !important;
}

.service-image-col {
  padding: 8px;
}

.service-image-col > .ant-image {
  width: 100%;
  height: 100%;
}

.video-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.media-row {
  margin: 20px 0px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-service-provider-details-header {
  display: block;
  align-items: center;
}

.discover-service-provider-details-title {
  padding: 30px 30px 15px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f7f8fa;
}

.discover-service-provider-details-tabSection {
  width: 100%;
}

.discover-service-provider-details-tabSection > .ant-tabs-content-holder {
  width: 100%;
  padding: 0px 20px;
}

.discover-service-provider-details-tabSection > .ant-tabs-nav {
  background-color: #f7f8fa;
}

.discover-service-provider-details-location {
  color: #c2c6cc;
}

.discover-service-provider-details-buttons {
  display: flex;
  justify-content: flex-end;
}

.discover-service-provider-details-actions {
  vertical-align: top;
  display: inline-block;
  margin-left: 105px;
}

.discover-service-provider-details-actions div {
  display: inline-block;
}

.discover-service-provider-details-actions button {
  vertical-align: top;
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-left: 16px;
  font-size: 14px;
  padding: 0px;
}

.discover-service-provider-details-actions-direct button {
  background: rgba(177, 52, 125, 0.1);
  font-size: 16px;
}

.discover-service-provider-details-favicon {
  color: #f19f00;
  font-size: 21px !important;
}

.discover-service-provider-details-document {
  background: #2d81a8;
}

.discover-service-provider-details-reschedule {
  margin-left: 32px !important;
  color: #6a48c6;
}

.discover-service-provider-details-attachment {
  color: #b1347d;
}

.discover-service-provider-details-tabs {
  margin-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.discover-service-provider-details-footer {
  height: 64px;
  width: 100%;
  background: #f7f8fa;
  display: block;
}

.discover-service-provider-details-footer-buttons {
  float: right;
  padding-right: 24px;
  padding-top: 16px;
}

.discover-service-provider-details-footer-buttons button {
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.discover-service-provider-details-footer-buttons button svg {
  margin-right: 10px;
}

.discover-service-provider-details-send-message {
  background: #2d81a8 !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AllocatorOnlineMR > .ant-modal-header {
  padding: 24px 32px;
}

.AllocatorOnlineMR > .ant-modal-header > .ant-modal-title {
  color: #2d3239;
}

.AllocatorOnlineMR .ant-modal-content > .ant-modal-footer {
  padding: 0px;
}

.AllocatorOnlineMR-participants {
  background-color: #f7f8fa;
  padding: 16px 32px;
}

.Invite-company-button > span {
  vertical-align: top;
}

.Invite-company-button-popover .ant-popover-inner-content {
  padding: 8px;
}

.remove-invited-button:hover {
  color: #d62b34;
  opacity: 0.7;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ReceivedRequestsTable {
  background-color: #ffffff;
  padding: 12px;
}

.ReceivedRequestsTable-category {
  color: #92949c;
}

.ReceivedRequestsTable-tag {
  background: rgba(77, 79, 86, 0.1);
  border-radius: 48px;
  margin-left: 8px;
  color: #4d4f56;
  padding: 0px 10px;
}

.ReceivedRequestsTable-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052449;
  padding: 12px;
  display: inline-block;
}

.ReceivedRequestsTable-DeclineAll {
  margin-left: 16px;
}

.MessageTooltip .ant-tooltip-inner {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.PresentationRoleLabel {
  visibility: visible;
  position: relative;
}

/* purgecss end ignore */

/* purgecss start ignore */

.PresentationWizard {
  margin: auto;
  width: calc(100vw - 548px);
  max-width: 1372px;
  min-width: 768px;
  background: #ffffff;
  min-height: calc(100vh - 340px);
  max-height: calc(100vh - 340px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 60px;
  border-radius: 8px 8px 0px 0px;
  overflow-y: auto;
}

.PresentationWizard .PresentationWizard-steps {
  min-width: 390px;
  min-height: calc(100vh - 136px);
}

.PresentationWizard .PresentationWizard-steps > .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  visibility: hidden;
}

.PresentationWizard-footer {
  width: calc(100vw - 548px);
  background: #ffffff;
  box-shadow: 0 16px 28px rgba(47, 56, 62, 0.08);
  max-width: 1372px;
  min-width: 768px;
  margin: auto;
  border-radius: 0px 0px 8px 8px;
}

.PresentationWizard-footer-button {
  padding: 12px 60px;
  display: flex;
}

.timepicker .ant-picker-footer {
  display: none;
}

.select .ant-select-selector {
  height: 48px !important;
  align-items: center;
}

.cc-timezone__indicator {
  padding-right: 8px;
}

.learnMoreBody {
  line-height: 2;
}

/* purgecss end ignore */

/* purgecss start ignore */

.SelectCompanyUsers > .ant-select-selector {
  height: 3rem !important;
  display: flex;
  align-items: center;
}

.SelectCompanyUsers > .ant-select-selector > .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.SelectCompanyUsers > .ant-select-selector > .ant-select-selection-item {
  display: flex;
}

/* purgecss end ignore */

/* purgecss start ignore */

@media screen and (max-width: 1025px) {
  .ant-col-10 {
    margin-left: 48px;
  }
}

.cc-contact-header-title {
  color: #000000;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}

.cc-contact-content {
  padding: 64px 120px;
}

@media (max-width: 1250px) {
  .cc-contact-content {
    padding: 64px 30px;
  }
}

.cc-contact-content-fullname {
  color: #000000;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}

.cc-contact-content-jobtitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #92949c;
}

.cc-contact-content-qualification-title {
  font-size: 12px;
  line-height: 16px;
  color: #92949c;
  font-weight: 600;
  text-transform: uppercase;
}

.cc-contact-content-qualification {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #24262b;
}

.cc-contact-content-pic {
  height: 96px;
  width: 96px;
  background-repeat: no-repeat;
  background-size: cover;
}

.cc-contact-content-pic-empty {
  display: block;
  height: 96px;
  width: 96px;
  background-color: #052449;
  color: #ffffff;
  padding: 20px;
  border-radius: 100%;
}

.cc-contact-content-info {
  background: #ffffff;
  margin: 38px auto;
  padding: 49px 97px;
  width: 100%;
}

@media (max-width: 1250px) {
  .cc-contact-content-info {
    padding: 49px 40px;
  }
}

.cc-contact-content-info-header {
  font-size: 12px;
  line-height: 16px;
  color: #92949c;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #eff1f4;
}

.cc-contact-content-info-header-last {
  margin-top: 64px;
}

.cc-contact-content-info-wrapper {
  position: relative;
  height: 36px;
  margin-bottom: 36px;
}

.cc-contact-content-info-icon {
  color: #eff1f4;
}

.cc-contact-content-info-title {
  margin: 0;
}

.cc-contact-content-info-detail {
  font-size: 14px;
  line-height: 15px;
  font-weight: normal;
}

.cc-contact-content-info-title {
  font-size: 12px;
  line-height: 16px;
  color: #92949c;
}

.cc-contact-content-info-address > h6 {
  font-size: 12px;
  line-height: 16px;
  color: #92949c;
  margin-bottom: 0;
}

.cc-contact-content-info-address > span {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #24262b;
}

.cc-contact-content-info-mailupdates {
  font-size: 12px;
  line-height: 16px;
  color: #92949c;
  color: #4d4f56;
}

.cc-contact-content-info-mailupdates-icon {
  margin-right: 10px;
  color: #c2c6cc;
}

/* purgecss end ignore */

/* purgecss start ignore */

.content-contact-avatar {
  display: flex;
  flex-direction: row;
}

.content-contact-avatar__image {
  flex-shrink: 0;
  margin-right: 12px;
}

.content-contact-avatar__name,
.content-contact-avatar__job-title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 17px;
}

.content-contact-avatar__name {
  font-weight: 600;
}

/* purgecss end ignore */

/* purgecss start ignore */

.content-skeleton {
  pointer-events: none;
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@-webkit-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.content-skeleton * {
  background-color: #ddd;
}

.content-skeleton .content-skeleton-container {
  background-color: transparent;
}

/* purgecss end ignore */

/* purgecss start ignore */

.content-contact-info {
  align-self: flex-start;
  text-align: left;
}

.content-contact-info__title-card,
.content-contact-info__contacts-card {
  width: 256px;
  background-color: #fafcfc;
  border-radius: 4px;
  padding: 16px;
}

.content-contact-info__title {
  font-size: 16px;
  line-height: 22px;
  color: #606062;
  margin-bottom: 8px;
}

.content-contact-info__contacts-card {
  margin-top: 12px;
}

.content-contact-info__contacts-card .content-contact-avatar:not(:first-child) {
  margin-top: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.content-video-player {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-video-player__contacts-info {
  margin-left: 12px;
}

.content-video-player__container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.content-video-player .content-video-player__close {
  color: white;
  cursor: pointer;
  position: absolute;
  width: 28px;
  left: -32px;
  top: -32px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.content-media-viewer {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity 150ms ease-in-out;
}

.content-media-viewer-enter {
  opacity: 0;
}

.content-media-viewer-enter-active {
  opacity: 1;
}

.content-media-viewer-exit {
  opacity: 1;
}

.content-media-viewer-exit-active {
  opacity: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.modalTable {
  font-size: 16px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.modalTable .modalTable-row {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-top: 15px;
}

.modalTable .modalTable-avatar {
  margin-top: 15px;
  margin-bottom: 15px;
}

.modalTable .modalTable-name {
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.modalTable .modalTable-icons {
  margin-top: 15px;
  margin-right: 20px;
  margin-bottom: 15px;
}

.modalTable .modalTable-job {
  font-size: 12px;
  color: #9e9e9e;
}

.modalTable .modalTable-empty {
  width: 35%;
}

.reactionModal-top {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
}

.reactionModal-top .ant-divider-vertical {
  height: 24px;
}

.reactionModal-top p {
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 5px;
}

.reactionModal-top svg {
  margin-right: 5px;
}

.reactionButton:hover {
  color: #0072a3;
}

/* purgecss end ignore */

/* purgecss start ignore */

.content__categories {
  margin: 0 -8px;
  display: flex;
  justify-content: space-between;
}

.content__categories-right {
  white-space: nowrap;
}

.content__categories .content__category {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: #6b6b60;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin: 0 8px;
  padding: 12px 24px;
  text-transform: uppercase;
  transition: background-color 200ms, color 200ms, border-color 200ms;
}

.content__categories .content__category:hover {
  background-color: #d9d9dd;
  color: #6b6b60;
}

.content__categories .content__category--active,
.content__categories .content__category--active:hover {
  border-color: #e4e7eb;
  background-color: white;
  color: black;
  cursor: default;
}

.content__categories .search-box {
  width: 200px;
  margin: 8px 10px;
}

.content-featured-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content-featured-entry__body {
  color: black;
  padding: 64px 20px 36px 0;
  width: 50%;
}

.content-featured-entry .content-featured-entry__category {
  text-transform: uppercase;
  color: #868688;
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 8px;
}

.content-featured-entry__title {
  font-size: 24px;
  line-height: 32px;
  padding-top: 8px;
  padding-bottom: 20px;
}

.content-featured-entry__cover-image {
  width: 50%;
}

.content-featured-entry:hover .content-featured-entry__title {
  color: #052449;
}

.content-entry__reactionCount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 14px;
}

.content-entry__reactionCount svg {
  margin-right: 4px;
  font-size: 8px;
}

.content-entry__reactions {
  justify-content: center;
  align-items: inherit;
}

.content-entry__reactions > button {
  margin: 0 3px 5px;
  vertical-align: middle;
}

.aspect-ratio-16-9 {
  position: relative;
}

.aspect-ratio-16-9::after {
  display: block;
  content: '';
  padding-top: 56.25%;
}

.aspect-ratio-16-9 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.content-entry {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-entry .content-entry__body {
  padding: 12px;
}

.content-entry .content-entry__category {
  text-transform: uppercase;
  color: #868688;
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 8px;
}

.content-entry .content-entry__title {
  color: #606062;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.content-entry .content-entry__subtitle {
  color: #606062;
  font-size: 14px;
  line-height: 22px;
}

.content-entry .content-entry__description {
  color: #606062;
  font-size: 12px;
  line-height: 22px;
}

.content-entry .content-entry__publishDate {
  padding-top: 12px;
  color: #606062;
  font-size: 12px;
  line-height: 22px;
}

.content-entry .content-contact-avatar {
  padding-top: 12px;
}

.content-author-avatar {
  display: flex;
  flex-direction: row;
}

.content-author-avatar .content-author-avatar__image {
  flex-shrink: 0;
  margin-right: 12px;
}

.content-author-avatar .content-author-avatar__name {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.content-author-avatar .content-author-avatar__job-title {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 17px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.info-popover .popover__arrow {
  fill: #2fc5d2;
}

.info-popover .popover-body {
  line-height: 150%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fund-campaign-card .campaign-stats {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 56rem;
  display: grid;
  grid-auto-flow: column;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  grid-template: repeat(4, auto) / repeat(2, 1fr);
}

.fund-campaign-card .campaign-stats > :nth-child(3), .fund-campaign-card .campaign-stats > :nth-child(7) {
  margin-top: 1rem;
}

@media (min-width: 640px) {
  .fund-campaign-card .campaign-stats {
    grid-template: repeat(2, auto) / repeat(4, 1fr)
  }

  .fund-campaign-card .campaign-stats > :nth-child(3), .fund-campaign-card .campaign-stats > :nth-child(7) {
    margin-top: 0px;
  }
}

@media (min-width: 1024px) {
  .fund-campaign-card .campaign-stats {
    grid-template: repeat(4, auto) / repeat(2, 1fr)
  }

  .fund-campaign-card .campaign-stats > :nth-child(3), .fund-campaign-card .campaign-stats > :nth-child(7) {
    margin-top: 1rem;
  }
}

@media (min-width: 1280px) {
  .fund-campaign-card .campaign-stats {
    grid-template: repeat(2, auto) / repeat(4, 1fr)
  }

  .fund-campaign-card .campaign-stats > :nth-child(3), .fund-campaign-card .campaign-stats > :nth-child(7) {
    margin-top: 0px;
  }
}

.fund-campaign-card .campaign-stats .campaign-stats__data {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
  align-self: flex-end;
  justify-self: center;
}

.fund-campaign-card .campaign-stats .campaign-stats__label {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity));
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 150%;
  align-self: flex-start;
  justify-self: center;
  text-align: center;
}

.fund-campaign-card a.update-button, .fund-campaign-card button.update-button, .fund-campaign-card .menu-button-group.update-button .button {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 143, 0, var(--tw-bg-opacity));
}

.fund-campaign-card a.update-button:hover, .fund-campaign-card button.update-button:hover, .fund-campaign-card .menu-button-group.update-button .button:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 159, 0, var(--tw-bg-opacity));
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-allocator {
  margin-bottom: 16px;
}

.discover-allocator-result-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #3d4858;
}

.discover-allocator-result-title > span > .ant-badge-status-dot {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}

.discover-allocator-result-title .round-profile-imagegroup-name {
  max-width: none;
}

.discover-allocator-result-subtitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  color: #92949c;
}

.discover-allocator-hidden {
  opacity: 0.7;
  border-color: #696969 !important;
}

.discover-allocator-favicon {
  color: #f19f00;
  width: 24px !important;
  height: auto;
  line-height: 0;
}

.discover-allocator-circle > div {
  font-size: 10px !important;
}

.discover-allocator-circle > div > .ant-progress-text {
  color: #24c477;
}

.discover-allocator-circle-unmatched > div {
  font-size: 10px !important;
}

.discover-allocator-circle-unmatched > div > .ant-progress-text {
  color: #f19f00;
}

.discover-allocator-menu {
  display: flex;
  align-items: center;
}

.discover-allocator-text-description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #3d4858;
}

.discover-allocator-percentage {
  width: 65px;
  height: 35px;
  background: rgba(20, 202, 156, 0.1) !important;
  border-radius: 4px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  margin-right: 12px;
  cursor: default !important;
  /* or 200% */
  align-items: center;
  text-align: center;
  border: transparent;
  color: #14ca9c !important;
}

.discover-allocator-details-menu-button {
  padding: 6px 12px;
  color: #92949c;
  background: white;
  border: 1px solid #c2c6cc;
  box-sizing: border-box;
  border-radius: 4px;
}

.discover-allocator-details-menu-button svg {
  margin-right: 8px;
}

.ant-col-4 {
  padding-left: 0;
}

.table-action-button {
  background: white;
}

.discover-allocator-result-footer-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.discover-fund-result-footer {
  padding: 8px;
  display: flex;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discoverSearch > .ant-input-suffix {
  display: none;
}

.discoverSearch {
  border: 1px solid #eff1f4;
}

.discoverSearch-fund {
  margin-left: 15px;
}

.discover-load-more {
  text-align: center;
}

.discover-load-more-button {
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 5px;
}

.DiscoverContainer-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 2px 0 0;
}

.DiscoverContainer-filterLabel {
  margin-left: -10px;
  padding: 0 0 10px;
}

.DiscoverContainer-switch {
  position: absolute;
  text-align: center;
}

.DiscoverContainer-icon {
  margin-right: 8px;
}

.DiscoverContainer-col {
  margin-right: 8px;
}

.DiscoverContainer-col-filters .reset-filters {
  color: #d62b34;
}

.DiscoverContainer-col-filters-search {
  border-radius: 4px;
}

.DiscoverContainer-col-filters .ant-divider {
  height: 1em;
  border-left-width: 2px;
}

.DiscoverContainer-col-filters .ant-divider-horizontal {
  margin: 24px 0 0 0;
}

.DiscoverContainer-fund-search {
  margin-right: 8px;
  width: 45%;
}

.DiscoverContainer-select-fund {
  margin-right: 8px;
  width: 20%;
}

.DiscoverContainer-alert-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #92949c;
}

.DiscoverContainer-alert-textbold {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-columns-preferences-info {
  margin-left: 6px;
}

.cc-columns-preferences-info span {
  display: block;
}

.cc-columns-preferences-info-header {
  color: #1b1a1f;
}

.cc-columns-preferences-info-aux {
  color: #92949c;
  margin-top: 8px;
}

.cc-columns-preferences-column-list {
  margin-top: 16px;
  margin-bottom: 60px;
}

.cc-columns-preferences-item {
  margin-bottom: 16px;
}

.cc-columns-preferences-item-icon {
  color: #c2c6cc;
  margin: 0px 8px;
}

.cc-columns-preferences-item-checkbox {
  color: #1b1a1f;
}

.cc-columns-preferences-item-checkbox > .ant-checkbox {
  margin-right: 8px;
}

.cc-columns-preferences-bottom {
  position: fixed;
  bottom: 0;
  height: 60px;
  padding-bottom: 24px;
  padding-top: 16px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: white;
  width: 390px;
}

.cc-columns-preferences-cancel-button {
  position: absolute;
  right: 32px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.DiscoverTable button.action-menu-button {
  --tw-border-opacity: 1;
  border-color: rgba(137, 0, 202, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(137, 0, 202, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.DiscoverTable button.action-menu-button:hover, .DiscoverTable button.action-menu-button:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 9, 246, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(170, 9, 246, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

/* purgecss end ignore */

/* purgecss start ignore */

.AvatarGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AvatarGroup-start {
  justify-content: start;
}

.AvatarGroup .AvatarGroup-avatar {
  border: 2px solid #ffffff;
}

.AvatarGroup .AvatarGroup-avatar-plus {
  font-size: 12px;
  font-weight: bolder;
}

.AvatarGroup-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.AvatarGroup-popover .ant-popover-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.AvatarGroup-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.AvatarGroup-popover-no-image .ant-popover-inner-content {
  padding: 0px;
}

.AvatarGroup-popover-no-image .ant-popover-title {
  border-bottom: none;
  color: #ffffff;
}

.AvatarGroup-dropdown-popover-no-image.ant-popover {
  display: none;
  z-index: 2000;
}

.AvatarGroup-dropdown-popover.ant-popover {
  z-index: 2000;
}

/* purgecss end ignore */

/* purgecss start ignore */

.compare-funds-drawer {
  position: fixed;
  z-index: 1000;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

@media (min-width: 768px) {
  .compare-funds-drawer {
    left: 6rem;
  }
}

.compare-funds-drawer {
  height: 20rem;
}

@media (min-width: 640px) {
  .compare-funds-drawer {
    height: 24rem;
  }
}

@media (min-width: 1024px) {
  .compare-funds-drawer {
    height: 18rem;
  }
}

.compare-funds-drawer {
  display: flex;
  flex-direction: column;
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  /* This container should be invisible to the mouse, but let everything inside
     it behave like normal. */
  pointer-events: none;
}

.compare-funds-drawer * {
  pointer-events: auto;
}

.compare-funds-drawer {
  -webkit-transform: translateY(85%);
  transform: translateY(85%);
}

.compare-funds-drawer.compare-funds-drawer--open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.compare-funds-drawer .compare-funds-drawer__tab {
  flex-shrink: 0;
  position: relative;
  bottom: -1px;
  z-index: 10;
  align-self: center;
  border-width: 1px;
  border-bottom-width: 0px;
  border-style: solid;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.compare-funds-drawer .compare-funds-drawer__toggle-button {
  border-width: 0px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.compare-funds-drawer .compare-funds-drawer__content {
  flex: 1 1 0%;
  border-width: 0px;
  border-top-width: 1px;
  border-style: solid;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.compare-funds-drawer .compare-funds-drawer__dialog {
  height: 100%;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  opacity: 0;
}

.compare-funds-drawer .compare-funds-drawer__dialog[data-enter] {
  opacity: 1;
}

.compare-funds-drawer .compare-funds-drawer__grid {
  list-style-type: none;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: 0px;
  padding: 1rem;
}

@media (min-width: 1024px) {
  .compare-funds-drawer .compare-funds-drawer__grid {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.compare-funds-drawer .compare-funds-drawer__grid {
  display: grid;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 640px) {
  .compare-funds-drawer .compare-funds-drawer__grid {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .compare-funds-drawer .compare-funds-drawer__grid {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
}

.compare-funds-drawer .compare-funds-drawer__grid {
  overflow-x: auto;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

@media (min-width: 1024px) {
  .compare-funds-drawer .compare-funds-drawer__grid {
    scroll-padding: 0 5rem;
    /* Hide the scrollbar on desktop, since we add the paging buttons */
    -ms-overflow-style: none; /* IE and early Edge */
    scrollbar-width: none /* Firefox */
  }

  .compare-funds-drawer .compare-funds-drawer__grid::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
}

.compare-funds-drawer .compare-funds-drawer__fund {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 18rem;
  padding: 0.5rem;
}

@media (min-width: 1024px) {
  .compare-funds-drawer .compare-funds-drawer__fund {
    width: 24rem;
  }
}

.compare-funds-drawer .compare-funds-drawer__fund {
  border-radius: 8px;
  --tw-shadow: 0 2px 6px -2px rgba(5,36,73,0.16), 0 2px 3px -1px rgba(114,0,168,0.16), 0 14px 45px -9px rgba(5,36,73,0.03);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  scroll-snap-align: start;
}

.compare-funds-drawer .compare-funds-drawer__page-control {
  display: none;
  align-items: center;
}

@media (min-width: 1024px) {
  .compare-funds-drawer .compare-funds-drawer__page-control {
    display: flex;
  }
}

.compare-funds-drawer .compare-funds-drawer__page-control {
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 1rem;
}

.compare-funds-drawer .compare-funds-drawer__page-control--prev {
  left: 0px;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.compare-funds-drawer .compare-funds-drawer__page-control--next {
  right: 0px;
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.compare-funds-drawer .compare-funds-drawer__page-control button {
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.compare-funds-drawer .compare-funds-drawer__page-control button[aria-disabled] {
  background-color: transparent;
}

.compare-funds-drawer .compare-funds-drawer__page-control button:not([aria-disabled]):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.compare-funds-drawer .compare-funds-drawer__page-control button:not([aria-disabled]):focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.compare-funds-drawer .compare-funds-drawer__empty {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.compare-funds-drawer .compare-funds-drawer__empty .ant-empty-image {
  margin: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.discover-service-provider {
  margin-bottom: 20px;
}

.discover-service-provider-text-image {
  display: flex;
  justify-content: flex-end;
}

.discover-service-provider-text-image > .ant-image > .ant-image-img {
  width: auto;
  max-height: 150px;
  max-width: 200px;
}

.discover-service-provider-description {
  display: block;
}

.discover-service-provider-result {
  min-height: 240px;
  background: #ffffff;
  border: 1px solid #eff1f4;
}

.discover-service-provider-result-subtitle {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-top: 5px;
  color: #92949c;
}

.discover-service-provider-hidden {
  opacity: 0.7;
  border-color: #696969 !important;
}

.discover-service-provider-favicon {
  color: #f19f00;
  font-size: 21px !important;
  border: 0;
  box-shadow: 0 0;
  background: transparent !important;
  line-height: 0;
}

.table-action-button {
  background: #ffffff;
}

/* purgecss end ignore */

/* purgecss start ignore */

.modalHeader {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  color: #4d4f56;
}

.modalSearch {
  background: #ffffff;
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  border-radius: 4px;
  width: 90%;
  align-items: center;
  margin-left: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.marker-list {
  overflow-y: auto;
  max-height: 600px;
  min-height: 350px;
}

.marker-item {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  align-items: center;
  padding-left: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: inset 0px -1px 0px #eff1f4;
}

.marker-item:hover {
  background: #f7f8fa;
}

.marker-item:active {
  background: #eff1f4;
  box-shadow: inset 0px -1px 0px #eff1f4;
}

.marker-item-name {
  color: #24262b;
  font-size: 14px;
  line-height: 24px;
}

.marker-item-companyname {
  color: #92949c;
  font-size: 12px;
  line-height: 16px;
}

.marker-item-roadshowDates {
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  color: #962c6a;
}

.marker-item-footer {
  display: flex;
  margin-top: 5px;
}

.marker-item-footer-title {
  font-size: 9px;
  line-height: 12px;
  color: #052449;
}

.marker-item-footer-title-roadshow {
  font-size: 9px;
  line-height: 12px;
  color: #962c6a;
}

.marker-item-footer-broad {
  width: 85%;
}

.marker-item-footer-match {
  width: 15%;
}

.marker-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #4d4f56;
  padding-left: 34px;
  border-bottom: 1px solid #e2e8ee;
  padding-bottom: 20px;
}

.marker-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
}

/* purgecss end ignore */

/* purgecss start ignore */

.map-container {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  overflow-x: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.mapMarker {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  /* Greyscale/White */
  color: #ffffff !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-discover-fund-select {
  text-align: center;
  padding: 100px 0px;
}

.cc-discover-fund-select > .cc-discover-fund-select-icon {
  color: #c2c6cc;
}

.cc-discover-fund-select > .cc-discover-fund-select-name {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 18px;
  color: #151517;
  margin-top: 20px;
}

.cc-discover-fund-select > .cc-discover-fund-select-description {
  color: #92949c;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.cc-discover-fund-select-group {
  width: 30%;
  margin: auto;
}

.cc-discover-fund-select-checkbox {
  width: 100%;
}

.cc-discover-fund-select-checkbox > .ant-checkbox-group-item {
  display: flex;
  margin-right: 0;
  text-align: left;
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0;
  border-bottom: 1px solid #eff1f4;
}

.cc-discover-fund-select-checkbox > .ant-checkbox-group-item > span {
  font-size: 14px;
  color: #24262b;
}

.cc-discover-fund-select-checkbox > .ant-checkbox-group-item:hover {
  display: flex;
  margin-right: 0;
  text-align: left;
  padding: 15px 20px;
  background: #f0fffe;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0;
  border-bottom: 1px solid #eff1f4;
}

.cc-discover-fund-select-checkbox > .ant-checkbox-group-item:hover > span {
  font-size: 14px;
  color: #24262b;
}

.cc-discover-fund-select-new {
  color: #052449;
  display: block;
  width: 100%;
  margin-right: 0;
  text-align: left;
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0;
  border-bottom: 1px solid #eff1f4;
  cursor: pointer;
}

.cc-discover-fund-select-new:hover {
  color: #052449;
  display: block;
  width: 100%;
  margin-right: 0;
  text-align: left;
  padding: 15px 20px;
  background: #f0fffe;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0;
  border-bottom: 1px solid #eff1f4;
  cursor: pointer;
}

.cc-discover-fund-select-button {
  background: #052449;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
  margin-top: 20px;
  height: 44px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.round-profile-imagegroup-name {
  display: inline-block;
  max-height: 20px;
  max-width: 112px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  overflow-wrap: unset;
  white-space: nowrap;
}

.dropdown-avatar-named {
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff;
  object-fit: cover;
}

.named-avatar-ellipsis {
  border-radius: 48px;
  width: 72px !important;
  background-color: #ebeef0;
  color: #052449;
  cursor: pointer;
  margin-left: 10px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.category-tag {
  border: none;
}

.tag-clip-button {
  background: #ebeef0 !important;
  width: 20px;
  height: 20px !important;
  border: none !important;
  max-width: 20px;
  padding: 0px !important;
  box-shadow: none !important;
  top: 2px;
}

.tag-clip-button:hover {
  background: #d7dce2;
}

.tag-clip-button span {
  color: #6a48c6;
  height: 20px;
  text-shadow: none;
}

.tag-overflow-popover .popover__arrow {
  fill: white;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Search {
  height: 48px;
  width: 305px !important;
  line-height: 2.2;
  border: none;
}

.Search .Search {
  border-right: 1px solid #eff1f4 !important;
}

.Search .ant-input-group-addon {
  display: none;
}

.Search-fund {
  margin-left: 15px;
}

.Search > .ant-input-group > .ant-input-affix-wrapper {
  height: 48px;
}

.search-input.ant-input-affix-wrapper-input-with-clear-btn > input {
  padding-left: 0px !important;
}

.search-input {
  height: 48px;
  width: 305px;
  line-height: 2.2;
  border: none;
}

.search-input > .ant-input-suffix {
  display: none;
}

.search-input span {
  left: 17.67px;
  width: 16.67px;
  height: 16.67px;
  font-size: 16.67px;
  color: #c2c6cc !important;
  margin: auto;
  margin-right: 10px;
}

.search-input input {
  padding-left: 44px;
  border: none;
  background: #f7f8fa;
}

.search-input.ant-input-affix-wrapper-input-with-clear-btn > input {
  padding-left: 54px;
}

.search-input input::-webkit-input-placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #92949c;
}

.search-input input:-ms-input-placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #92949c;
}

.search-input input::placeholder {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #92949c;
}

.search-input span i svg {
  color: #c2c6cc;
}

.investors-header-col {
  padding-left: 8px;
  padding-right: 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.table-header-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-left: 14px !important;
  color: #052449 !important;
}

.table-header {
  height: 80px;
}

.investors-header-col {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  line-height: normal;
}

.investors-columns {
  height: 48px !important;
  width: 52px;
  top: 2px;
  margin-left: 16px;
}

.clear-filters {
  height: 48px;
  margin-right: 16px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1.5s linear infinite;
  animation: rotating 1.5s linear infinite;
}

/* purgecss end ignore */

/* purgecss start ignore */

.meeting-avatar {
  height: 100%;
}

.meeting-avatar-pending {
  background-color: #008dca !important;
}

.meeting-avatar-confirmed {
  background-color: #24c477 !important;
}

.meeting-avatar-declined {
  background-color: #d62b34 !important;
}

.meeting-avatar-cancelled {
  background-color: #f19f00 !important;
}

.cc-name-column {
  max-width: 250px;
  padding: 5px !important;
}

.cc-column-linkbutton-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
}

.cc-column-linkbutton-div .SummitAttendees-btn {
  max-width: 180px;
  width: 180px;
  text-align: left;
  overflow-x: hidden;
  word-wrap: break-word;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.cc-column-linkbutton-div .SummitAttendees-btn span {
  overflow-wrap: break-word;
  max-width: 180px;
  white-space: pre-wrap;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.cc-contacts-cell,
.cc-contacts-column {
  padding: 8px 12px 5px !important;
}

.cc-actions-cell,
.cc-actions-column {
  padding: 5px !important;
}

.ant-table-fixed-left {
  z-index: 200;
}

.cc-context-table .ant-table-placeholder {
  min-height: 500px;
  vertical-align: middle;
}

.cc-context-table {
  min-height: 500px;
}

.cc-context-table .ant-table-header table {
  min-width: -webkit-max-content;
  min-width: max-content;
}

@supports (-moz-appearance: none) {
  .cc-context-table .ant-table-header table {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@supports (-moz-appearance: none) {
  .cc-context-table .ant-table-empty .ant-table-header table {
    table-layout: auto !important;
  }
}

.cc-context-table .ant-table-tbody:empty::after {
  content: '-';
  font-size: 0px;
}

.cc-name-cell,
.cc-name-cell button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cc-table-filters {
  background-color: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-credit-wrapper {
  display: flex;
  justify-content: space-between;
  background: rgba(241, 159, 0, 0.1);
  width: 100%;
  padding: 24px;
}

.cc-credit-cost,
.cc-credit-left {
  color: #f19f00;
}

body > div:nth-child(9) > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div:nth-child(4) > div.ant-select.ant-select-enabled > div {
  background: #f7f8fa;
  border-radius: 4px;
  border-style: none;
  height: 40px;
  margin-top: 16px;
}

body > div:nth-child(8) > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div:nth-child(4) > div.ant-select.ant-select-enabled > div {
  background: #f7f8fa;
  border-radius: 4px;
  border-style: none;
  height: 40px;
  margin-top: 16px;
}

body > div:nth-child(9) > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div:nth-child(4) > div.ant-select.ant-select-enabled > div > div > div {
  line-height: 3;
  vertical-align: middle;
}

.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: #f7f8fa !important;
  border-style: none !important;
  border-radius: 4px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.form-container {
  padding-bottom: 24px;
  background: white;
}

.form-container-header {
  text-align: center;
}

.form-container-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #151517;
  padding-top: 40px;
}

.form-container-description {
  color: #92949c;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.form-section {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 31px;
  padding-right: 31px;
  padding-bottom: 32px;
}

.form-column-grouping {
  color: #2d3239;
  padding-top: 10px;
  flex: 1 1;
}

.form-column-inputs {
  flex: 2 1;
  border-left: solid 1px #eff1f4;
  padding-left: 25px;
}

.form-column-upload {
  flex: 1.5 1;
}

.upload-container {
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.form-section-agreements {
  padding-top: 50px;
  padding-left: 97px;
  padding-right: 31px;
  padding-top: 20px;
}

.checkbox-agreement {
  display: block;
  margin-top: 26px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.form-section-submit {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 97px;
  padding-right: 31px;
}

.form-section-submit-buttons {
  flex: 4 1;
  display: inline-flex;
  justify-content: center;
}

.form-section-submit button {
  margin-left: 24px;
}

.form-section-btn-save {
  width: 200px;
}

.form-section-btn-cancel {
  width: 150px;
}

.empty-column-left,
.empty-column-right {
  flex: 1 1;
}

/* purgecss end ignore */

/* purgecss start ignore */

.StatBox {
  padding: 15px;
  border-radius: 8px;
  background-color: #f2fbfc;
}

.StatValue {
  text-align: right;
}

.StatRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.TextSection {
  margin-bottom: 16px;
}

@media print {
  .HidePrint {
    display: none;
  }

  .TearSheetContainer {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .StatBox-Print {
    padding-right: 100px;
  }

  .NoBreaks {
    page-break-inside: avoid;
  }
}

@media screen {
  .TearSheetContainer {
    padding: 15px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .StatBox-Print {
    display: none;
  }

  .ShowPrint {
    display: none;
  }
}

h1 {
  margin-top: 12px;
  font-weight: bold;
}

/* purgecss end ignore */

/* purgecss start ignore */

.RadioButtonItem {
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 47.5%;
  cursor: pointer;
  position: relative;
}

.RadioButtonItem:hover {
  background-color: #eff1f4;
  border-color: #c2c6cc;
}

.RadioButtonItem-label {
  color: #000000;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
}

.RadioButtonItem:hover .RadioButtonItem-label {
  font-weight: 600;
}

.RadioButtonItem-icon {
  color: #c2c6cc;
}

.RadioButtonItem:hover .RadioButtonItem-icon {
  color: #052449;
}

.RadioButtonItem-selected,
.RadioButtonItem-selected:hover {
  background-color: #052449;
  border-color: #052449;
}

.RadioButtonItem-selected > .RadioButtonItem-label,
.RadioButtonItem-selected:hover > .RadioButtonItem-label,
.RadioButtonItem-selected > .RadioButtonItem-icon,
.RadioButtonItem-selected:hover > .RadioButtonItem-icon {
  color: #ffffff;
}

.RadioButtonItem-selected-check {
  display: inline-block;
  color: #ffffff;
  position: absolute;
  top: 8px;
  right: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  text-align: center;
}

.RadioButtonItem-selected-check:hover {
  cursor: pointer;
  color: #eff1f4;
}

.RadioButtonList {
  margin-bottom: 16px;
}

.RadioButtonList-label {
  display: block;
  margin-bottom: 8px;
  color: #2d3239;
}

.RadioButtonList-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Login {
  flex-direction: row;
  height: 100vh;
}

.Login-field-email {
  background-color: #f7f8fa;
  border: 0;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  padding-left: 16px;
  padding-right: 16px;
}

.Login-field-methods {
  background-color: #f7f8fa;
  border: 0;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  padding-left: 16px;
  padding-right: 16px;
}

.Login-sidebar {
  background-color: #151517;
  flex: 1 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login-sidebar-info {
  margin-top: auto;
}

.Login-sidebar-info-header {
  font-weight: 300;
  font-size: 36px;
}

.Login-sidebar-description {
  color: #ffffff;
  text-align: left;
  margin-top: auto;
  margin-bottom: 32px;
}

.Login-main {
  flex: 2 1;
}

.Login-main-content {
  max-width: 364px;
}

.Login-main-content-logo {
  max-width: 364px;
  height: 64px;
  background-image: url(/static/media/new-logo.750f2cc6.svg);
  background-repeat: no-repeat;
}

.Login-main-content-form-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #2d3239;
  margin-bottom: 36px;
}

.Login-main-content-form-field {
  margin-top: 12px;
  margin-bottom: 24px;
}

.Login-main-content-form-field > .ant-alert {
  margin-top: 8px;
  margin-bottom: 8px;
}

.Login-main-content-form-field-otp input {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  background-color: #ffffff;
  border: 1px solid #c2c6cc;
  border-radius: 4px;
  text-align: center;
  margin-right: 22px;
  outline: none;
}

.Login-main-content-form-contact-column {
  margin-top: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ContextForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: wrap;
}

.Text {
  color: rgba(130, 130, 130, 0.75);
  margin-bottom: 10px;
}

.Label {
  width: 100%;
}

.Label > .ant-col {
  padding-left: 0px;
  padding-right: 0px;
}

.Label > .ant-col > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.Label > .ant-col > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  margin-left: 4px;
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  content: '*';
}

.Label > .ant-col > label {
  color: #828282;
}

.InputField {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px;
  height: 48px;
  width: 340px !important;
  max-width: 95%;
}

.InputField-number {
  display: flex;
  align-items: center;
}

.InputField-number > .ant-input-number-input-wrap {
  width: 100%;
}

.TextArea {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px;
}

.BooleanField-radio {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.BooleanField-radio > div > label {
  color: #828282;
  margin-bottom: 10px;
}

.BooleanField-radio > div > .ant-row {
  margin-bottom: 20px;
  margin-top: 0px;
}

.BooleanField-radio > .ant-radio-wrapper {
  color: #828282;
  margin-bottom: 10px;
}

.BooleanField-radio-conditional {
  margin: 10px 0px 0px 20px;
}

.CheckboxField-box {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 340px !important;
}

.CheckboxField-box > .ant-checkbox-wrapper {
  color: #828282;
  margin-bottom: 10px;
}

.CheckboxField-box > div > label {
  color: #828282;
  margin-bottom: 10px;
}

.CheckboxField-box-conditional {
  margin: 10px 0px 20px 20px;
}

.DropdownField {
  width: 340px !important;
}

.DropdownField > .ant-select-selector {
  border: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px !important;
  height: 48px !important;
}

.DropdownField > .ant-select-selector > .ant-select-selection-search > input {
  height: 48px !important;
}

.DropdownField > .ant-select-selector > .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.DateField {
  width: 340px !important;
  border: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.1);
  border-radius: 3px !important;
  height: 48px !important;
}

.LinkList-title {
  padding: 20px 0px 10px 0px;
}

.LinkList-title > .ant-btn {
  padding: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.MandateWizard {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  --tw-shadow: -1px 7px 20px -8px rgba(114,0,168,0.11), 0 18px 37px -22px rgba(5,36,73,0.29), 0 24px 72px -8px rgba(5,36,73,0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  max-width: calc(100vw - 48px);
  min-height: calc(100vh - 108px);
}

.MandateWizard .MandateWizard-steps {
  min-width: 390px;
  min-height: calc(100vh - 136px);
}

.MandateWizard .Step {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MandateWizard .Step-title {
  margin-bottom: 1.25rem;
}

.MandateWizard .Step-form {
  max-width: 1000px;
}

.MandateWizard .StepList .ant-steps-item-description {
  visibility: hidden;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: #f7f8fa !important;
  border-style: none !important;
  border-radius: 4px;
}

.cc-videoSettings-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #24262b;
}

.cc-videoSettings-body {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #24262b;
}

.cc-videoSettings-title {
  display: flex;
  justify-content: space-between;
}

.cc-videoSettings-icon {
  margin-right: 5px;
}

.pids-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pid {
  width: calc(8%);
  height: 4px;
  display: inline-block;
  border-radius: 2px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-meeting-lobby-body {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #c2c6cc;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cc-meeting-lobby-header {
  margin-top: 30px;
}

.cc-meeting-lobby-body-white {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.cc-meeting-lobby-body-white > .ant-checkbox-checked {
  background-color: #052449;
}

.cc-meeting-lobby-body-white > .ant-checkbox > .ant-checkbox-inner {
  background-color: transparent;
}

.cc-meeting-lobby-body-2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #c2c6cc;
}

.cc-meeting-lobby-tag {
  font-size: 14px;
  line-height: 24px;
  color: #c2c6cc;
  padding: 12px 30px 12px 30px;
  border: 1px solid #008dca;
}

.cc-meeting-lobby-container .ant-spin-nested-loading .ant-spin-lg .ant-spin-dot {
  margin: 18% 0 !important;
}

.sponsors {
  height: calc(100vh - 190px);
  margin: 16px 0px;
}

.listTable .ant-table-tbody tr.ant-table-row-level-0:hover > td {
  background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%) !important;
}

.listTableRow:hover {
  background: transparent !important;
}

.cc-meeting-lobby-scrollbar {
  max-height: calc(100vh - 190px);
}

/* purgecss end ignore */

/* purgecss start ignore */

.ant-table {
  background: transparent;
}

.listTable .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: transparent !important;
}

.listTable .ant-table-content {
  padding-top: 0px !important ;
  background: transparent;
}

.listTable .ant-table-thead {
  background: transparent;
}

.listTable .ant-table-thead tr {
  height: 0px;
  background-color: transparent !important;
}

.listTable .ant-table-thead tr th {
  background: transparent;
  border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1;
}

.listTable .ant-table-body tr td {
  border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1;
}

.listTable .ant-table-small {
  background: transparent !important;
  border: none;
}

.listTable .ant-table-tbody tr.ant-table-row-level-0:hover > td {
  background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
}

.listTable .ant-table-tbody tr.ant-table-row-level-1:hover > td {
  background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
}

.listTable .ant-table-tbody tr.ant-table-row-level-2:hover > td {
  background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
}

.listTable .ant-table-tbody tr.ant-table-row-level-3:hover > td {
  background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
}

.listTable .ant-table-tbody tr.ant-table-row-level-4:hover > td {
  background: linear-gradient(90deg, #24262b 0%, rgba(36, 38, 43, 0) 100%);
}

.listTable .ant-table-placeholder {
  background: transparent !important;
}

.listTable .ant-table-placeholder > td {
  border-bottom: none;
}

.listTable .ant-table-placeholder:hover {
  background: transparent !important;
}

.listTable .ant-empty-description {
  color: #fff;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td:hover {
  background: transparent;
}

.cc-table-title {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c2c6cc;
  padding-bottom: 8px;
  padding-left: 8px;
  margin-left: 8px;
}

.cc-button-div {
  display: flex;
  visibility: hidden;
}

.cc-main-div {
  border-bottom: none !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-image: linear-gradient(90deg, #c2c6cc, rgba(36, 38, 43, 0)) 1 !important;
}

.cc-main-div:hover .cc-button-div {
  visibility: visible;
}

.cc-main-container {
  height: calc(100vh - 480px);
}

.simplebar-scrollbar::before {
  background-color: #92949c;
}

.cc-main-title {
  font-size: 14px;
  color: #c2c6cc;
  margin-left: 5px;
}

.cc-main-panel > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}

.cc-main-panel > .ant-collapse-header {
  padding-left: 0px !important;
  display: inline-flex;
  padding: 0px !important;
  align-items: center;
}

.cc-main-panel > .ant-collapse-header > .anticon {
  position: unset !important;
  color: #c2c6cc !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-meeting-lobby-container {
  background-color: #151517;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
}

.cc-meeting-lobby-container-divider {
  background-color: #8492a6;
  height: 35px;
}

.cc-meeting-lobby-container-settings {
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: flex-end;
}

.cc-meeting-lobby-container-logo {
  display: flex;
  margin-top: 60px;
  align-items: center;
}

.cc-white-heading1 {
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}

.cc-grey-heading3 {
  color: #c4c4c4;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FollowUpMessages .message {
  padding: 8px 16px;
}

.FollowUpMessages .message.sent-message {
  margin-right: 32px;
  background-color: #052449;
  border-radius: 8px 0px 8px 8px;
}

.FollowUpMessages .message.sent-message span {
  color: #ffffff;
}

.FollowUpMessages .message.received-message {
  margin-left: 32px;
  margin-top: -4px;
  background-color: #f7f8fa;
  border-radius: 0px 8px 8px 8px;
}

.FollowUpMessages .message.received-message span {
  color: #24262b;
}

.FollowUpMessages .contact {
  display: flex;
}

.FollowUpMessages .contact.sender {
  justify-content: flex-end;
}

.FollowUpMessages .contact.receiver {
  justify-content: flex-start;
}

.FollowUpMessages .message-date {
  color: #c2c6cc;
}

.FollowUpMessages .message-date-sender {
  margin-left: 0px;
}

.FollowUpMessages .message-date-receiver {
  margin-left: 32px;
}

.ContactFont {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.ShowMoreButton {
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
}

.FollowUpAlert {
  background-color: rgba(241, 159, 0, 0.1);
  border-radius: 4px;
  margin: 16px 0px;
  border: none;
}

.GoToConversationAlert {
  background-color: rgba(0, 141, 202, 0.1);
  border-radius: 4px;
  margin: 16px 0px;
  border: none;
}

.cc-show-more {
  color: rgba(0, 0, 0, 0.65);
}

.cc-show-more:hover {
  color: white;
  text-decoration: underline;
}

/* purgecss end ignore */

/* purgecss start ignore */

.invite-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-divider-companydetails {
  min-width: '80%';
  margin-top: 10px;
}

.cc-ul-comp-details {
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.cc-ul-comp-details-items {
  width: 50%;
  min-width: 120px;
  margin-bottom: 15px;
}

.cc-container-compdetails-list {
  margin-bottom: 40px;
  max-width: 300px;
}

.cc-company-desc {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}

.cc-company-desc-numbers {
  margin-top: 7px;
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.cc-company-desc-numbers-containers {
  width: 50%;
  min-width: 300px;
}

@media screen and (max-width: 1024px) {
  .cc-company-desc {
    width: 100%;
  }

  .cc-company-desc-numbers {
    width: 80%;
  }

  .cc-company-desc-numbers-containers {
    width: 100%;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-categories-div {
  display: grid;
  grid-gap: 16px;
  margin-bottom: 32px;
  margin-top: 8px;
}

.service-image {
  cursor: pointer;
  width: 100%;
  height: 90%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-meeting-details-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px;
}

.cc-meeting-details-tabs-radio-buttons {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  padding: 2px 2px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.proposed-slots {
  flex: row 1;
  background: #f7f8fa;
  text-align: left;
  border-radius: 4px;
  padding: 10px;
}

.proposed-slots-text {
  color: #24262b;
  padding-top: 5px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ant-picker-calendar div.ant-picker-panel {
  border: none;
}

.ant-picker-calendar table.ant-picker-content thead tr {
  border-bottom: 2px solid #d3dae3;
}

.ant-picker-calendar table.ant-picker-content thead tr th {
  padding-bottom: 0.5em;
}

.ant-picker-calendar table.ant-picker-content tbody tr:first-child td {
  padding-top: 1em;
}

.ant-picker-calendar table.ant-picker-content tbody .ant-picker-calendar-date-content {
  line-height: 12px;
}

.ant-picker-calendar table.ant-picker-content tbody .ant-picker-calendar-date-today,
.ant-picker-calendar table.ant-picker-content tbody .ant-picker-cell-selected .ant-picker-calendar-date {
  border-radius: 0.5rem;
}

.ant-picker-calendar table.ant-picker-content tbody .ant-picker-calendar-date-today:before,
.ant-picker-calendar table.ant-picker-content tbody .ant-picker-cell-selected .ant-picker-calendar-date:before {
  border-radius: 0.5rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.message-day-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity));
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .message-day-header {
    padding-left: 2rem;
    padding-right: 2rem
  }
}

.message-day-header::before,
  .message-day-header::after {
  content: '';
}

.message-day-header::before, .message-day-header::after {
  flex: 1 1 0%;
}

.message-day-header::before, .message-day-header::after {
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.conversation-header .search-group.input-group .form-input,
  .conversation-header .search-group.input-group .form-input .form-input__input {
  min-width: 0;
}

/* purgecss end ignore */

/* purgecss start ignore */

.flexcontainer {
  display: flex;
  flex-wrap: wrap;
}

.ant-checkbox-group-item {
  margin-bottom: 17px;
  flex-basis: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-initialstep {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
  justify-content: center;
  align-content: center;
}

.cc-initialstep .cc-allocator-header {
  padding: 50px 0;
  margin-right: 30px;
}

.cc-initialstep .cc-allocator-input {
  width: 364px;
  height: 48px;
  margin: auto;
  margin-bottom: 16px;
}

.cc-initialstep .cc-floating-select {
  position: relative;
  margin-bottom: 16px;
}

.cc-initialstep .floating-select-row {
  position: relative;
  margin-bottom: 16px;
  width: 364px;
  margin: auto;
}

.cc-initialstep .cc-allocator-p {
  margin: 24px 34px 8px 34px;
  text-align: left;
}

.cc-initialstep .cc-allocator-radio {
  margin: 0 34px 32px 32px;
  text-align: left;
}

.cc-initialstep .btn-back {
  border: 0;
  box-shadow: 0 0 0 0;
  color: #052449;
  margin: auto;
  width: 364px;
}

.FormHeader {
  font-size: 30px;
  font-weight: 600;
}

.cc-initialstep .cc-floating-select {
  position: relative;
  margin-bottom: 16px;
  width: 90%;
  float: left;
}

/* purgecss end ignore */

/* purgecss start ignore */

.btn-container {
  margin-left: -120px;
}

.btn-container .btn-back {
  width: 137px;
  height: 40px;
  margin-right: 64px;
}

.btn-container .btn-submit {
  width: 200px;
  height: 40px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-step1 {
  width: 30vw;
}

.cc-step1 .cc-step1-section1 {
  width: 558px;
  margin-bottom: 24px;
}

.cc-step1 .cc-step1-section1 .cc-heading3 {
  margin-bottom: 32px;
}

.cc-step1 .cc-step1-section2 {
  width: 558px;
  margin-bottom: 15px;
}

.cc-step1 .cc-step1-section2 .cc-step1-section2-header {
  margin-bottom: 16px;
}

.cc-step1 .cc-step1-section2 .cc-step1-section2-footer span {
  display: block;
}

.cc-step1 .cc-step1-section3 {
  width: 558px;
  margin-bottom: 15px;
}

.cc-step1 .cc-step1-section3 .cc-step1-section3-header {
  margin-bottom: 16px;
}

.cc-step1 .cc-step1-section3 .cc-step1-section2-footer span {
  display: block;
}

.FormHeader {
  font-size: 30px;
  font-weight: 600;
}

/* purgecss end ignore */

/* purgecss start ignore */

.colleagues-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.colleagues-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.colleagues-popover .ant-popover-inner-content .ant-list-item {
  color: #ffffff;
  border: none;
  padding: 4px 2px;
}

.schedule:hover {
  color: #052449;
}

.schedule:hover span {
  color: #052449;
  font-style: italic;
}

.schedule span {
  color: #7f848a;
  font-style: italic;
}

/* purgecss end ignore */

/* purgecss start ignore */

.settings-funds-managers-header {
  color: #2d3239;
  padding-bottom: 24px;
  border-bottom: 1px solid #f7f8fa;
}

.settings-funds-managers-contacts {
  padding-bottom: 48px;
  padding-top: 16px;
}

.settings-funds-managers-contacts-title {
  color: #2d3239;
}

.settings-funds-managers-contacts-title-fund {
  color: #000000;
  font-weight: bolder;
}

.settings-funds-managers-contacts-list {
  margin-top: 18px;
}

.settings-funds-managers-contacts-list .messages-compose-contact-checkbox-card {
  min-width: 263px;
}

.settings-funds-managers-contacts-list .messages-compose-contact-checkbox-card .messages-compose-contact-checkbox-name {
  min-width: 186px;
}

.settings-funds-managers-contacts-list .messages-compose-contact-checkbox-card .messages-compose-contact-checkbox-title {
  min-width: 186px;
}

.settings-funds-managers-footer {
  border-top: 1px solid #f7f8fa;
  padding-top: 16px;
  margin-bottom: -16px;
  text-align: right;
}

.settings-funds-managers-footer button {
  margin-left: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.colleagues-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.colleagues-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.colleagues-popover .ant-popover-inner-content .ant-list-item {
  color: #ffffff;
  border: none;
  padding: 4px 2px;
}

.schedule:hover {
  color: #052449;
}

.schedule:hover span {
  color: #052449;
  font-style: italic;
}

.schedule span {
  color: #7f848a;
  font-style: italic;
}

/* purgecss end ignore */

/* purgecss start ignore */

.GenericForm-form {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2 1;
  padding: 20px;
}

.GenericForm-form-header {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
}

.GenericForm-form-header {
  font-weight: 600;
  font-size: 24px;
  padding: 10px;
}

.GenericForm-form-container {
  height: 80vh;
  overflow-y: auto;
  margin-bottom: 24px;
}

.GenericForm-form-footer {
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.GenericForm-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.GenericForm-steps {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
}

.Question-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.Question-row-half {
  width: 50%;
}

.Question-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  margin-bottom: 0px;
}

.Question-array-field {
  margin-top: 12px;
}

.Nested-field > .ant-form-item > .ant-form-item-control {
  padding: 0px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ant-upload-list-picture {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .ant-upload-list-picture {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}

.ant-upload-list-picture-container {
  order: 1;
}

.settings-tabs .tab-group.tab-group--vertical.tab-group--medium .tab {
  height: 48px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.NotificationEntries {
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  padding: 12px 18px;
}

.NotificationEntries-header {
  border-bottom: 1px solid #eff1f4;
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.NotificationEntries-header > .NotificationEntries-header-actions {
  text-align: right;
}

.NotificationEntries-header-actions-btn-text {
  display: inline-block;
  margin-left: 8px;
}

.NotificationEntries-header-actions-btn-settings {
  color: #c2c6cc;
}

.NotificationEntries-header-back {
  display: flex;
}

.NotificationEntries-header-back-title {
  margin: 0 5px;
  align-self: center;
  font-weight: 600;
  font-size: 16px;
}

.NotificationEntries-entries {
  max-height: 420px;
}

.NotificationEntries-entries .NotificationEntry:last-child > .NotificationEntry-description {
  border-bottom: 0;
}

.NotificationEntries-footer {
  border-top: 1px solid #eff1f4;
  text-align: center;
}

.Notification-pagination {
  display: flex;
  width: 388px;
  max-width: 388px;
  min-width: 388px;
  margin: 6px auto;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cfnVisibilityEditor-divider {
  margin: 20px 0px;
}

.cfnVisibilityEditor-header {
  display: flex;
  width: 468px;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.cfnVisibilityEditor-body {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.cfnVisibilityEditor-info-icon {
  color: #92949c;
  margin-left: 18px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-layout-default-header {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.cc-layout-default {
  width: 100%;
  text-align: center;
  margin: auto;
}

.cc-layout-default-alert {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  background: #d3f3e4;
  text-align: left;
}

.cc-layout-default-back {
  position: absolute;
  top: 40px;
  left: 150px;
  background-color: #d7dce2;
  border: none;
  width: 127px;
  height: 40px;
  color: #052449;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.cc-layout-default-back:hover {
  background: #ebeef0;
}

.cc-layout-default-logout {
  position: absolute;
  top: 40px;
  right: 150px;
  background-color: rgba(214, 43, 52, 0.1);
  border: none;
  width: 127px;
  height: 40px;
  color: #d62b34;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.cc-layout-default-context {
  display: block;
}

.cc-layout-default-welcome {
  display: block;
  margin-top: 40px;
  color: black;
}

.cc-layout-default-form-space {
  display: block;
  margin-top: 27px;
  width: 364px;
  margin-left: auto;
  margin-right: auto;
}

.cc-layout-default-footer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  margin-top: 48px;
  margin-bottom: 120px;
}

.cc-layout-footer-back-button {
  background-color: #d7dce2;
  border: none;
  height: 48px;
  color: #052449;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-right: 16px;
}

.cc-layout-footer-back-button:hover {
  background: #ebeef0;
}

.cc-layout-default-title {
  margin-top: 108px;
  color: #000000;
}

.cc-company-selection-item {
  height: 70px;
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  border-radius: 4px;
  width: 364px;
  display: inline-block;
  max-width: 364px;
  margin: 0px;
  text-align: left;
  padding-left: 17px;
  display: block;
  margin-bottom: 24px;
}

.cc-company-selection-item .ant-radio {
  position: absolute;
  top: 27px;
}

.cc-company-selection-item-text {
  display: inline-block;
  margin-left: 25px;
  height: 100%;
  padding-top: 13px;
}

.cc-company-selection-name {
  color: #1b1a1f;
}

.cc-company-selection-type {
  color: #92949c;
}

.cc-generic-form-control-container {
  display: inline-block;
}

.cc-generic-form-checkbox-group {
  text-align: left;
  margin-top: 8px;
  color: #1b1a1f;
}

.cc-generic-form-checkbox-group .ant-checkbox-group {
  margin-top: 16px;
  width: 100%;
}

.cc-generic-form-checkbox-header {
  width: 100%;
  color: #1b1a1f;
  display: block;
}

.cc-generic-form-date-header {
  width: 100%;
  color: #92949c;
  display: block;
  font-size: 11px;
  background: #f7f8fa;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 600;
}

.cc-generic-form-checkbox-group .ant-checkbox-group-item {
  width: 48%;
  color: #1b1a1f;
}

.cc-investor-status-form-subselections .ant-checkbox-group-item {
  width: 100%;
}

.cc-generic-form-radio-group {
  text-align: left;
  margin-bottom: 16px;
}

.cc-generic-form-radio-header {
  display: block;
  color: #1b1a1f;
  margin-bottom: 16px;
}

.cc-generic-form-radio-header-capital {
  color: #1b1a1f;
  margin-bottom: 18px;
}

.cc-generic-form-date-picker {
  margin-bottom: 16px;
  width: 100%;
}

.cc-generic-form-date-picker .ant-input {
  border: none;
}

.year-picker-dropdown .ant-calendar-header .ant-calendar-year-select {
  visibility: hidden;
}

.year-picker-dropdown .ant-calendar-header .ant-calendar-next-year-btn {
  visibility: hidden;
}

.year-picker-dropdown .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select {
  visibility: hidden;
}

.year-picker-dropdown .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select {
  visibility: hidden;
}

.year-picker-dropdown .ant-calendar-header .ant-calendar-prev-year-btn {
  visibility: hidden;
}

.year-picker-dropdown .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  visibility: hidden;
}

.year-picker-dropdown .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  visibility: hidden;
}

.year-picker-dropdown-disable-prev .ant-calendar-header .ant-calendar-prev-month-btn {
  visibility: hidden;
}

.cc-layout-confirm {
  text-align: center;
  width: 100%;
}

.cc-layout-confirm-form-space {
  display: block;
  margin-top: 27px;
  text-align: left;
  width: 556px;
  margin-left: auto;
  margin-right: auto;
}

.cc-layout-confirm-footer {
  margin-left: auto;
  margin-right: auto;
  width: 364px;
  margin-top: 48px;
  margin-bottom: 120px;
}

.cc-layout-confirm-question-answer {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 36px;
}

.cc-layout-confirm-question {
  color: #92949c;
}

.cc-layout-confirm-answer {
  color: #1b1a1f;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.cc-layout-wizard-steps {
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  width: 1000px;
  text-align: left;
}

.cc-layout-wizard-steps-big {
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  width: 1300px;
  text-align: left;
}

.cc-horizontal-spawning-radio {
  display: inline-block;
  text-align: left;
  vertical-align: top;
}

.cc-investor-status-form {
  width: 800px;
  margin-left: -200px;
}

.cc-investor-status-form-separator {
  margin: 24px;
  border-bottom: 1px solid #eff1f4;
  height: 1px;
}

.cc-investor-details-form-separator {
  border-bottom: 1px solid #eff1f4;
  height: 1px;
  margin-bottom: 24px;
}

.cc-investor-status-form-subselections {
  text-align: left;
}

.cc-investor-status-item {
  display: inline-block;
  width: unset;
}

.cc-investor-status-item-checkbox {
  text-align: left;
}

.cc-investor-status-item-checkbox {
  margin-top: 16px;
}

.cc-investor-status-item-checkbox .ant-checkbox + span {
  display: inline-block;
  width: unset;
}

.cc-investor-status-item-details {
  margin-left: 24px;
  color: #2d3239;
}

.cc-investor-status-item-label {
  color: #1b1a1f;
}

.cc-info-icon {
  color: #92949c;
}

.cc-generic-form-checkbox-group-grid {
  text-align: left;
}

.cc-generic-form-checkbox-group-grid .ant-checkbox-group {
  margin-top: 16px;
  vertical-align: top;
}

.cc-generic-form-checkbox-group-grid .ant-checkbox-group-item {
  width: 48%;
  color: #1b1a1f;
  vertical-align: top;
}

.cc-onboard-section {
  text-align: left;
}

.cc-onboard-section-title {
  color: #052449;
}

.cc-onboard-section-row-title {
  color: #000000;
  margin-top: 16px;
}

.cc-onboard-section-row-controls {
  margin-top: 16px;
}

.cc-onboard-section-row-control {
  width: 45%;
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
}

.cc-onboarding-anything-else-textarea {
  border: none;
  margin-top: 16px;
  height: 148px !important;
}

.cc-onboarding-percentage {
  border: none;
  margin-top: 16px;
  width: 126px;
}

.cc-onboarding-percentage .ant-input {
  border: none;
}

.cc-onboarding-percentage .ant-input-group-addon {
  border: none;
  background: #eff1f4;
}

.cc-onboarding-invalid .ant-input {
  border: 1px solid red;
}

.cc-substrategy-group {
  padding-left: 20px;
}

.cc-strategies-checkbox .ant-checkbox-wrapper {
  color: #1b1a1f;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  width: 45%;
}

.cc-strategies-checkbox {
  margin-bottom: 32px;
  width: 100%;
}

.cc-strategies-header {
  margin-top: 16px;
}

.cc-broadstrategy-title {
  margin-bottom: 24px;
}

.cc-broad-strategy {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1b1a1f;
}

.cc-substrategy-group .ant-checkbox-wrapper {
  display: flex;
  margin-top: 16px;
}

.cc-substrategy-group .ant-checkbox-group {
  margin-top: 0px;
}

.cc-login-logo-onboard {
  text-align: center;
  font-size: 40px;
  width: 600px;
  height: 40px;
  background-repeat: no-repeat;
  margin: 0 0 50px 0;
  background-size: contain;
  margin-left: auto;
  width: 100%;
  background-position: center;
}

.cc-domicile-countries {
  margin-left: 24px;
  margin-top: -16px;
  margin-bottom: 16px;
}

.cc-additional-info {
  display: block;
  font-size: 14px;
  color: #1b1a1f;
  margin-left: 8px;
  margin-top: -4px;
}

.cc-domicile-all-countries-checkbox {
  color: #1b1a1f;
  margin-top: 16px;
}

.cc-address-zip-code-message {
  margin-top: -16px;
  text-align: left;
  width: 100%;
}

.cc-address-zip-code-suggestions {
  z-index: 500;
  margin-top: -16px;
  margin-bottom: 16px;
  text-align: left;
}

.cc-address-zip-code-suggestion-item {
  cursor: pointer;
}

.cc-address-zip-code-suggestion-item:hover {
  background-color: #ebeef0;
}

.cc-onboarding-validation-error {
  color: #d62b34;
  text-align: left;
  padding-left: 6px;
  margin-bottom: 8px;
}

.cc-onboarding-validation-error-topmargin {
  margin-top: 8px;
}

.cc-generic-form-floating-input-group {
  width: 50%;
  margin-right: 25px;
  margin-top: 25px;
}

.cc-generic-form-child-checkbox {
  margin-left: 80px;
}

.cc-generic-form-parent-checkbox {
  margin-left: 40px;
}

.cc-holding-col {
  padding-left: 0px !important;
  padding-right: 20px;
}

.cc-holding-row {
  padding-top: 16px;
}

.cc-holding-divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.cc-holding-add-button {
  text-align: center;
  margin-bottom: 24px;
}

.cc-investmet-policy-radio-group {
  text-align: left;
  margin-bottom: 16px;
  margin-top: 16px;
}

.cc-investmet-policy-radio-item {
  width: 100%;
}

.cc-investment-policy-upload {
  margin-bottom: 16px;
}

.cc-familyNetwork-investment-form {
  max-width: 556px !important;
}

.cc-familyNetwork-deals-text {
  margin-top: 50px;
  margin-bottom: 15px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.onboarding-form {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.onboarding-form:not([class*='max-w-']) {
  max-width: 28rem;
}

.onboarding-form .checkbox-group .form-label {
  margin-bottom: 0px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.onboarding-form .checkbox-group .form-message {
  margin-top: 0px;
}

.onboarding-form .checkbox-group:not(.border-none) .checkbox-group__options {
  border-width: 0px;
  border-top-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
  padding-top: 0.25rem;
}

.onboarding-form [role='radiogroup'] .form-label {
  margin-bottom: 0px;
}

.onboarding-form [role='radiogroup'] .form-message {
  margin-top: 0px;
  margin-bottom: 0.25rem;
}

.matchmaking-slider .ant-slider-rail {
  background-color: #e0e0e0;
}

.matchmaking-slider .ant-slider-handle {
  background-color: #052449;
  border-color: #052449;
}

.matchmaking-slider .ant-slider-mark {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 150%;
}

.matchmaking-slider .ant-slider-mark-text-active {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(5, 36, 73, var(--tw-text-opacity));
}

/* purgecss end ignore */

/* purgecss start ignore */

.round-profile-thumbnail {
  width: 32px;
  height: 32px;
  border-radius: 16px 16px;
  border: 2px solid #ffffff;
  margin-left: -10px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ant-table-content {
  background: #ffffff;
  padding-top: 8px;
}

.ant-table-thead {
  background-color: #ffffff;
}

.ant-table-thead > tr {
  background-color: #ffffff !important;
}

.cc-positive-number {
  color: #24c477;
}

.cc-negative-number {
  color: #d62b34;
}

.ant-table-tbody > tr > td {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1b1a1f;
}

.ant-table-header-column {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #92949c;
}

.table-filter-icon {
  width: 32px !important;
  padding: 8px;
  color: #c2c6cc;
  background: rgba(239, 241, 244, 0.4);
}

.ant-table-body tr td {
  border-bottom: solid 1px #eff1f4;
}

.ant-table-thead > tr > th {
  font-size: 12px;
  font-weight: 800;
}

.ant-table-thead tr th {
  border-bottom: solid 1px #eff1f4;
  text-transform: uppercase;
}

.ant-table-filter-column {
  display: block;
}

.ant-table-thead tr th span {
  margin-top: 0px;
  display: flex;
}

.ant-table-thead tr {
  height: 57px;
}

.ant-table-row:hover {
  background: #f0fffe;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f0fffe !important;
}

.ant-table-column-sorter {
  float: right;
}

.ant-table-column-sorter div {
  width: 16px;
  margin-top: -3px;
}

.anticon-caret-up,
.anticon-caret-down {
  font-size: 16px !important;
  margin-top: -10px;
}

.ant-table-fixed-right {
  top: 8px;
}

.ant-table-fixed-left {
  top: 8px;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #eff1f4;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-portfolio-card {
  height: 124px;
  vertical-align: middle;
}

.cc-portfolio-card .cc-card-money {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
  /* identical to box height, or 120% */
  color: #000000;
}

.cc-portfolio-card .cc-card-money-description {
  margin-top: 0;
  padding-top: 0;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* Greyscale/Dark Grey */
  color: #92949c;
}

.cc-portfolio-card .cc-portfolio-change-container {
  display: flex;
  justify-content: space-between;
}

.cc-portfolio-card .cc-percentage-change {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height, or 121% */
  margin-right: 10px;
  /* Actions/Success */
  color: #24c477;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-radio-button-wrapper {
  width: 155px;
  border: 1px solid #e0e4e8;
  font-size: 12px !important;
  line-height: 16px;
  box-sizing: border-box;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  text-transform: uppercase;
}

.cc-container-radio-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 88px;
  background: #f0f2f5;
}

.ant-radio-button-wrapper-checked {
  background-color: #052449 !important;
  color: #ffffff !important;
}

.ant-radio-button-wrapper:hover {
  border-color: #052449;
  background-color: #f1f4f9;
}

.cc-requests-count > sup.ant-badge-count {
  background-color: #d62b34;
  border: 0px;
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .cc-radio-button-wrapper {
    width: 100px;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-back-icon {
  background: #ffffff;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 12px;
  vertical-align: middle;
  display: inline;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bolder;
}

.cc-info-container {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.cc-info > span {
  display: block;
}

.cc-fund-details-header {
  display: flex;
  justify-content: space-between;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-count-details {
  display: inline-block;
  color: #151517;
  padding-right: 15px;
}

.cc-count-details > span {
  display: inline-block;
  width: 100%;
}

.cc-count-details > .cc-count-details-count {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.cc-count-details > .cc-count-details-label {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  opacity: 0.7;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-summit-date-label {
  display: inline-block;
  background: #f7f8fa;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  padding: 8px 18px;
}

.cc-summit-date-label > span {
  color: #052449;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.cc-summit-date-label > span .oneEventSpan {
  color: #c2c6cc;
  font-size: 18px;
}

.cc-summit-date-label > .cc-summit-date-label-days {
  margin-right: 8px;
}

.cc-summit-date-label > .cc-summit-date-label-month {
  color: #92949c;
}

.cc-summit-date-label-fancy {
  background: #052449;
  border-width: 0;
}

.cc-summit-date-label-oneEvent {
  background: none !important;
  border: 0px !important;
  border-radius: none !important;
  padding: none !important;
}

.cc-summit-date-label-fancy > .cc-summit-date-label-days,
.cc-summit-date-label-fancy > .cc-summit-date-label-month {
  color: #ffffff;
}

.cc-summit-date-label-fancy > .cc-summit-date-label-month {
  opacity: 0.5;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AttendingRoadshow {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 245px;
  transition: 200ms;
  margin-bottom: 20px;
  border-radius: 8px;
}

.AttendingRoadshow:hover {
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  transition: 200ms;
}

.AttendingRoadshow-empty {
  align-items: center;
  justify-content: center;
}

.AttendingRoadshow-poster {
  flex: 2 1;
  position: relative;
  border-radius: 8px 0 0 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.AttendingRoadshow-noSchedule {
  margin: 10px 10px;
  background: rgba(0, 141, 202, 0.1);
  border-radius: 4px;
  color: #24262b;
  padding: 12px;
}

.AttendingRoadshow-virtual {
  padding: 2px 16px;
  background: #2d81a8;
  border-radius: 24px;
  float: right;
  margin-top: 24px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 1px;
  font-size: 10px;
}

.AttendingRoadshow-info {
  flex: 6 1;
  color: #ffffff;
}

.AttendingRoadshow-info-details {
  display: flex;
  justify-content: space-between;
}

.AttendingRoadshow-header {
  flex: 6 1;
  padding: 20px 24px;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px;
}

.AttendingRoadshow-info h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 29px;
  color: #ffffff;
  font-size: 24px;
}

.AttendingRoadshow-info > p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0.25rem;
}

.AttendingRoadshow > .AttendingRoadshowposter > .AttendingRoadshow-detail {
  position: absolute;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  bottom: 5%;
}

.AttendingRoadshow-detail-text {
  opacity: 0.7;
}

.AttendingRoadshow-detail-count {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
}

.AttendingRoadshow-info-icon {
  color: #c2c6cc;
  display: inline-block;
  margin-right: 0.5rem;
}

.AttendingRoadshow-action {
  margin: 1rem 0 1rem;
  text-align: right;
}

@media screen and (max-width: 576px) {
  .AttendingRoadshow-action {
    text-align: left;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

/* purgecss end ignore */
/* purgecss start ignore */

.discover-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: -24px -24px 0px -24px;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
}

.discover-header-title {
  color: white;
  text-align: center;
  margin: 40px 32px;
}

.discover-header-smaller {
  background-color: #052449;
  width: 100%;
  height: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: -10px;
  z-index: 110;
}

.discoverSearch > .ant-input-lg {
  border: 1px solid #eff1f4;
}

.discoverSearch-container {
  background-color: white;
  padding: 16px;
  border-radius: 12px;
  width: 80vw;
}

.discoverTitleText {
  margin: 16px 16px 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  color: #3d4858;
}

.discoverTitleText-small {
  margin: 16px 16px 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #3d4858;
}

.discoverDescriptionText {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #8492a6;
}

.discoverDescriptionText-small {
  text-align: center;
  width: 70%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: #8492a6;
}

.cards-scrolled {
  min-height: calc(100vh - 300px);
}

.cards-scrolled > div > .discover-tab-containerLine {
  width: 80vw;
  height: auto;
  position: fixed;
  top: 165px;
}

.discoverOptions {
  padding: 50px 80px 30px 80px;
  width: 100%;
}

.discoverOptions-Container-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 25vh;
  padding: 24px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.discoverOptions-Container-small:hover {
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(186, 186, 201, 0.25);
}

.discoverOptions-item-small {
  width: 70px;
  height: 70px;
  border-radius: 60px;
  background-color: white;
  text-align: center;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(186, 186, 201, 0.25);
}

/* purgecss end ignore */

/* purgecss start ignore */

.ConfirmedMeetingsTable {
  background-color: #ffffff;
  padding: 12px;
}

.ConfirmedMeetingsTable-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052449;
  padding: 12px;
}

.Ratings-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.Ratings-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.Ratings-popover .ant-popover-inner-content .ant-list-item {
  color: #ffffff;
  border: none;
  padding: 4px 2px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.roadshow-container {
  background: #ffffff;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  background: #f0f2f5;
}

.roadshow-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height, or 121% */
  /* Greyscale/Black */
  color: #151517;
  margin-left: 24px;
  padding-top: 24px;
}

.roadshow-emptybody {
  text-align: center;
  align-self: center;
  flex: none;
}

.roadshow-emptybody-header {
  color: #24262b;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin: 4px 24px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.roadshow-emptybody-body {
  color: #92949c;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
  margin: 25px 24px;
  text-align: center;
  display: block;
}

.roadshow-emptybody-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.roadshow-emptybody-footer-icon {
  margin-right: 10px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ServiceProviderWizard {
  max-width: calc(100vw - 48px);
  background: #ffffff;
  min-height: calc(100vh - 108px);
  box-shadow: 0 16px 28px rgba(47, 56, 62, 0.08);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.ServiceProviderWizard-form {
  padding: 20px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.ServiceProviderWizard-row {
  width: 100%;
}

.ServiceProviderWizard-steps {
  background: #eff1f4;
}

.ServiceProviderWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  color: #4d4f56;
  font-weight: 600;
}

.ServiceProviderWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  display: none;
}

.ServiceProviderWizard-steps > .StepList {
  display: flex;
}

.ServiceProviderWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item {
  max-height: 80px;
  min-height: 80px;
}

.ServiceProviderWizard-steps > .StepList > .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  background: #e0e0e0;
  margin-top: 34px;
  height: 35px;
}

.ServiceProviderWizard .ServiceProviderWizard-steps {
  padding: 40px;
  min-height: calc(100vh - 136px);
}

.ServiceProviderWizard .ServiceProviderWizard-steps > .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  visibility: hidden;
}

.ServiceProviderWizard > .ServiceProviderWizard-form {
  flex-grow: 1;
  padding: 0 84px;
  min-height: 100vh;
}

.ServiceProviderWizard > .ServiceProviderWizard-form-actions {
  width: 95%;
  border-top: 1px solid #eff1f4;
  padding: 48px;
}

.ServiceProviderWizard .ServiceProviderWizard-field {
  margin-bottom: 16px;
}

.ServiceProviderWizard .ServiceProviderWizard-field-label {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  color: #24262b;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-input {
  height: 48px;
  border: none;
  border-radius: 4px;
  background-color: #f7f8fa;
  font-size: 14px;
  line-height: 24px;
  color: #4d4f56;
  margin-bottom: 8px;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-input-number {
  border: none;
  height: 48px;
  width: 100%;
  margin-bottom: 8px;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-input-number .ant-input-number-input {
  height: 46px;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-calendar-picker {
  display: inline-block;
  width: 100%;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-calendar-picker .ant-calendar-picker-input {
  height: 48px;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-select .ant-select-selection {
  height: 48px;
  border: none;
  background-color: #f7f8fa;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-select .ant-select-selection .ant-select-selection__rendered {
  line-height: 46px;
}

.ServiceProviderWizard .ServiceProviderWizard-field .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
  height: 30px;
}

.ServiceProviderWizard .ServiceProviderWizard-field-checkbox > .ant-checkbox-group-item {
  display: block;
  margin-bottom: 7px;
}

.ServiceProviderWizard .ServiceProviderWizard-field-boolean-options > .ant-radio-wrapper:first-child {
  margin-right: 48px;
}

.ServiceProviderWizard .ServiceProviderWizard-field-footer {
  margin: 16px 0;
  padding-left: 24px;
}

.ServiceProviderWizard .ServiceProviderWizard-Step > .ServiceProviderWizard-actions {
  display: flex;
  border-top: 1px solid #e0e0e0;
  justify-content: space-between;
  padding: 10px 0;
}

.ServiceProviderWizard .ServiceProviderWizard-Step > .ServiceProviderWizard-actions > .ant-btn-lg {
  min-width: 50px;
}

.ServiceProviderWizard.ServiceProviderWizard-initial {
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.ServiceProviderWizard .ServiceProviderWizard-step-title {
  text-align: center;
  margin-bottom: 40px;
}

.ServiceProviderWizard-initial-form > .ServiceProviderWizard-step-title {
  text-align: center;
  color: #24262b;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 64px;
}

.ServiceProviderWizard-initial-form-actions {
  margin-top: 64px;
}

.ServiceProviderStep-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ServiceProviderStep-label-helper {
  color: rgba(130, 130, 130, 0.5);
  font-size: 13px;
}

.ServiceProviderStep-container {
  width: 100%;
}

.ServiceProviderStep-container .ant-upload-list-picture-card-container {
  margin-top: 16px;
  margin-right: 24px;
  min-width: 160px;
  max-width: 160px;
}

.ServiceProviderStep-container .ant-upload-list-picture-card-container .ant-upload-list-item {
  border-radius: 8px;
  -webkit-filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.19));
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.19));
  border: none;
}

.ServiceProviderStep-container > div > .ant-row > .ant-form-item-label {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.ServiceProviderStep-container > div > .ant-row > .ant-form-item-label > label {
  width: 100%;
}

.ServiceProviderStep-container > div > .ant-row > .ant-form-item-label > .ant-form-item-required {
  width: 100%;
}

.ServiceProviderStep-container {
  width: 100%;
}

.ServiceProviderStep-container-title {
  margin-bottom: 10px;
  color: #828282;
  font-weight: 600;
}

.ServiceProviderStep-pre-icon {
  display: inline-block;
  margin-right: 8px;
  min-width: 24px;
  color: #92949c;
  font-size: 18px;
  vertical-align: top;
  padding-top: 8px;
}

.ServiceProviderStep-reduced,
.ServiceProviderStep-pre-reduced {
  display: inline-block;
  width: calc(100% - 40px) !important;
}

.ServiceProviderStep-reduced.ServiceProviderStep-pre-reduced {
  display: inline-block;
  width: calc(100% - 80px) !important;
}

.ServiceProviderStep-info {
  vertical-align: top;
  margin-top: 12px;
  display: inline-block;
  width: 32px;
  font-size: 16px;
  margin-left: 8px;
  color: #92949c;
}

.ServiceProviderStepButton {
  margin-top: 10px;
}

.ServiceProviderStepButton-back {
  margin-top: 15px;
  color: #92949c;
  cursor: pointer;
}

.ServiceProviderStepButton-save {
  margin-top: 10px;
  color: #92949c !important;
  border-color: #cbcbcb !important;
}

.ServiceProviderStepButton-save:hover {
  margin-top: 10px;
  color: #54d7de !important;
  border-color: #54d7de !important;
}

.ServiceProviderStepPublishButton {
  margin-top: 10px;
  background: #2d81a8;
  margin-left: 10px;
  color: #052449 !important;
  border-color: #cbcbcb !important;
}

.ServiceProviderStepPreviewButton {
  margin-top: 10px;
  background: #2d81a8;
  margin-right: 10px;
  color: #54d7de !important;
  border-color: #e0e0e0 !important;
}

.ArrayField-delete {
  float: right;
  margin-top: -32px;
}

.ArrayField-delete button {
  color: #d62b34;
  font-weight: bold;
}

.PersonField-container {
  display: flex;
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eff1f4;
}

.PersonField-image-column {
  display: inline-block;
  vertical-align: top;
}

.PersonField-fields-column {
  display: inline-block;
}

.PersonField-name,
.PersonField-position {
  vertical-align: top;
  display: inline-block;
  width: 50%;
  padding: 0px 8px 0px 8px;
}

.PersonField-description {
  padding: 0px 8px 0px 8px;
}

.TestimonialField-container {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eff1f4;
  text-align: center;
}

.TestimonialField-image-column {
  min-width: 250px;
  max-width: 250px;
  display: inline-block;
  vertical-align: top;
}

.TestimonialField-fields-column {
  display: inline-block;
  width: 100%;
}

.TestimonialField-title {
  padding: 0px 8px 0px 8px;
}

.TestimonialField-name,
.TestimonialField-position {
  vertical-align: top;
  display: inline-block;
  width: 50%;
  padding: 0px 8px 0px 8px;
}

.TestimonialField-description {
  padding: 0px 8px 0px 8px;
}

.ClientsField-container {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eff1f4;
  text-align: center;
}

.ClientsField-image-column {
  min-width: 250px;
  max-width: 250px;
  display: inline-block;
  vertical-align: top;
}

.ClientsField-fields-column {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
}

.ClientsField-title {
  padding: 0px 8px 0px 8px;
}

.ClientsField-name,
.ClientsField-position {
  vertical-align: top;
  display: inline-block;
  width: 50%;
  padding: 0px 8px 0px 8px;
}

.ClientsField-description {
  padding: 0px 8px 0px 8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

/* purgecss end ignore */
/* purgecss start ignore */

.subscription-content {
  max-height: 70vh;
  max-width: 1000px;
  overflow-y: scroll;
  margin-bottom: 24px;
  padding: 5px;
  margin-right: auto;
  margin-left: auto;
}

/* purgecss end ignore */

/* purgecss start ignore */

.meeting-avatar-pending {
  background-color: #052449;
}

.meeting-avatar-confirmed {
  background-color: #24c477;
}

.meeting-avatar-declined {
  background-color: #d62b34;
}

.meeting-avatar-cancelled {
  background-color: #d62b34;
}

/* purgecss end ignore */

/* purgecss start ignore */

.agenda-day-speakers-container,
.agenda-day-speaker-role,
.agenda-day-speakers-person,
.agenda-day-speakers-person > span {
  display: block;
}

.agenda-day-timezone-column {
  color: #052449 !important;
  cursor: pointer;
}

.agenda-day-speaker-role-title {
  font-size: 10px;
  line-height: 16px;
  color: #1b1a1f;
  font-weight: bolder;
  height: 16px;
}

.agenda-day-speaker-role {
  margin-top: 4px;
}

.agenda-day-speakers-person {
  margin-bottom: 16px;
}

.agenda-day-speakers-person-name {
  color: #1b1a1f;
  margin-top: -4px;
}

.agenda-day-speakers-person-title {
  color: #92949c;
}

/* purgecss end ignore */

/* purgecss start ignore */

.empty-div {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  width: 500px;
  height: 300px;
  margin: auto;
}

.empty-div > .ant-empty {
  padding-top: 24px;
}

@media screen and (max-width: 414px) {
  .empty-div {
    padding: 0px;
    width: 285px;
    height: 250px;
  }

  > .ant-empty {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.settingsRequest {
  background-color: #ebeef0;
  border-radius: 4px;
  border-style: none;
  width: 48px;
  height: 48px;
  padding: 0px;
  box-shadow: none;
}

.settingsRequest:hover,
.settingsRequest:active,
.settingsRequest:focus {
  border: 1px solid #052449;
  background-color: #052449;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
}

.settingsRequest:hover .icon,
.settingsRequest:active .icon,
.settingsRequest:focus .icon {
  color: #ffffff;
}

.settingsRequest .icon {
  color: #052449;
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}

.popover-limit-request .ant-popover-title {
  padding: 16px;
}

.requestsSwitch.ant-switch {
  background-color: #f19f00;
}

.requestsSwitch.ant-switch.ant-switch-checked {
  background-color: #24c477;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AttachFunds-Button {
  margin-top: 12px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.service-provider-details-header {
  vertical-align: top;
  border-bottom: 1px solid #e2e8ee;
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 24px;
}

.service-provider-details-location {
  color: #c2c6cc;
}

.service-provider-details-title {
  display: inline-block;
}

.service-provider-details-actions {
  vertical-align: top;
  display: inline-block;
  margin-left: 105px;
}

.service-provider-details-actions div {
  display: inline-block;
}

.service-provider-details-actions button {
  vertical-align: top;
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-left: 16px;
  font-size: 14px;
  padding: 0px;
}

.service-provider-details-actions-direct button {
  background: rgba(177, 52, 125, 0.1);
  font-size: 16px;
}

.service-provider-details-favicon {
  color: #f19f00;
  font-size: 21px !important;
}

.service-provider-details-document {
  background: #2d81a8;
}

.service-provider-details-reschedule {
  margin-left: 32px !important;
  color: #6a48c6;
}

.service-provider-details-attachment {
  color: #b1347d;
}

.service-provider-details-tabs {
  margin-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.service-provider-details-footer {
  height: 64px;
  width: 100%;
  background: #f7f8fa;
  display: block;
}

.service-provider-details-footer-buttons {
  float: right;
  padding-right: 24px;
  padding-top: 16px;
}

.service-provider-details-footer-buttons button {
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.service-provider-details-footer-buttons button svg {
  margin-right: 10px;
}

.service-provider-details-send-message {
  background: #2d81a8 !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.SummitAttendees {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.SummitAttendees .ant-spin-container {
  height: 100%;
}

.SummitAttendees-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052449;
  padding-top: 12px;
}

.SummitAttendees-list .ant-table-fixed-left table {
  width: -webkit-min-content;
  width: min-content;
}

.SummitAttendees-menu-item {
  padding-right: 8px;
}

.SummitAttendees-menu-item-icon {
  color: #052449;
}

.SummitAttendees-menu-item-label {
  margin-left: 10px;
}

.SummitAttendees-dropdown-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background-color: #f7f8fa;
  border: 0;
  border-radius: 8px;
  text-align: center;
  padding: 0;
}

.SummitAttendees-dropdown-btn > span {
  font-weight: 600;
  color: #000000;
  font-size: 17px;
  line-height: 18px;
  height: 18px;
}

.SummitAttendees .ant-badge-status-dot {
  height: 13px;
  width: 13px;
}

.SummitAttendees .ant-badge-status-text {
  display: none;
}

.SummitAttendees .SummitAttendees-btn-cols {
  line-height: normal;
  background: #052449;
  border-radius: 4px;
  height: 42px;
  width: 54px;
  font-size: 22px;
  background: rgba(47, 197, 210, 0.1);
  color: #052449;
  border: none;
  box-shadow: none !important;
}

.SummitAttendees .SummitAttendees-profile-link {
  padding-left: 0;
}

.SummitAttendees .SummitAttendees-profile-link > span {
  display: block;
  max-width: 272px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.FilterMeetingStatus-pending {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 141, 202, var(--tw-bg-opacity));
}

.FilterMeetingStatus-confirmed {
  background-color: #24c477;
}

.FilterMeetingStatus-declined {
  background-color: #d62b34;
}

.FilterMeetingStatus-cancelled {
  background-color: #f19f00;
}

.FilterMeetingStatus-label {
  display: inline-block;
  margin-left: 8px;
}

.filters {
  padding: 0px 22px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.SummitAttendeeTable button.action-menu-button {
  --tw-border-opacity: 1;
  border-color: rgba(137, 0, 202, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(137, 0, 202, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.SummitAttendeeTable button.action-menu-button:hover, .SummitAttendeeTable button.action-menu-button:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 9, 246, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(170, 9, 246, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.SummitAttendeeTable-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-attendee-category-picker {
  margin: 1.5rem 0;
  text-align: center;
}

/* purgecss end ignore */

/* purgecss start ignore */

.MeetingCard {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.MeetingCard-center {
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .MeetingCard-center-actions {
    text-align: left;
  }
}

@media only screen and (min-width: 992px) {
  .MeetingCard-center-actions {
    text-align: center;
  }
}

.MeetingCard:hover {
  border: 1px solid #4b70b8;
  box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.51);
}

/* purgecss end ignore */

/* purgecss start ignore */

.title-span {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 8px 0;
}

.desc-span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin: 8px 0;
  color: #92949c;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-responsive-card-block {
  display: flex;
  width: '100%';
  margin-bottom: 20px;
  justify-content: space-between;
}

.cc-card-body-group div {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 800px) {
  .cc-responsive-card-block {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 8px;
  }

  .cc-responsive-card-block .cc-card-body-group {
    margin-bottom: 16px;
  }

  .cc-meeting-actions {
    margin-top: 0;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.ConfirmedMeetingsTable {
  background-color: #ffffff;
  padding: 12px;
}

.ConfirmedMeetingsTable-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052449;
  padding: 12px;
}

.ConfirmedMeetingsTableCfn {
  padding: 12px;
}

.ConfirmedMeetingsTableCfn-title {
  color: #24262b;
  padding: 12px;
}

.Ratings-popover .ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
}

.Ratings-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.75);
  border-right-color: rgba(0, 0, 0, 0.75);
}

.Ratings-popover .ant-popover-inner-content .ant-list-item {
  color: #ffffff;
  border: none;
  padding: 4px 2px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.DeclinedRequestsTable {
  background-color: #ffffff;
  padding: 12px;
}

.DeclinedRequestsTable-title {
  color: #052449;
  padding: 12px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ReceivedRequestsTable {
  background-color: #ffffff;
  padding: 12px;
}

.ReceivedRequestsTable-category {
  color: #92949c;
}

.ReceivedRequestsTable-tag {
  background: rgba(77, 79, 86, 0.1);
  border-radius: 48px;
  margin-left: 8px;
  color: #4d4f56;
  padding: 0px 10px;
}

.ReceivedRequestsTable-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052449;
  padding: 12px;
  display: inline-block;
}

.ReceivedRequestsTable-DeclineAll {
  margin-left: 16px;
}

.MessageTooltip .ant-tooltip-inner {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-status-confirmed {
  color: #24c477;
}

.cc-status-pending {
  color: #052449;
}

.cc-status-declined {
  color: #d62b34;
}

.cc-status-cancelled {
  color: #f19f00;
}

.cc-status-expired {
  color: #92949c;
}

.SentRequestsTable {
  background-color: #ffffff;
  padding: 12px;
}

.SentRequestsTable-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #052449;
  padding: 12px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.schedule-day-switch {
  width: 100px;
}

.schedule-day-switch.ant-switch-checked {
  background-color: #24c477;
}

.schedule-day-row-event {
  height: 72px;
}

.schedule-day-row-grouping {
  background: #eff1f4;
  height: 32px;
}

.schedule-day-row-even {
  background: rgba(247, 248, 250, 0.5);
}

.schedule-day-row-grouping > td {
  padding: 0px !important;
  text-align: center;
  color: #1b1a1f;
}

.schedule-day-meeting div {
  display: inline-block;
}

.schedule-day-meeting-title {
  margin-right: 5px;
  color: #052449;
  vertical-align: middle;
}

.schedule-day-meeting-image {
  vertical-align: middle;
}

.schedule-day-meeting-card {
  margin-left: 12px;
  vertical-align: middle;
}

.schedule-day-meeting-card span {
  display: block;
}

.schedule-day-meeting-name {
  font-size: 14px;
  color: #1b1a1f;
  font-weight: bold;
  vertical-align: middle;
}

.schedule-day-meeting-company {
  color: #92949c;
  line-height: 16px;
}

.schedule-day-reschedule {
  color: #052449;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.schedule-day-cancel {
  color: #d62b34;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ScheduleWeekDropdown {
  text-align: center;
  margin-top: 10px;
}

.ScheduleWeekDropdown-week {
  color: #24262b;
  font-weight: bold;
}

.ScheduleWeekDropdown-text {
  color: #24262b;
  margin-left: 5px;
}

.ScheduleWeekDropdown > button {
  height: 40px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-content > .SummitDetails {
  margin-top: -23px;
  margin-left: -24px;
  margin-right: -24px;
}

.SummitDetails {
  display: flex;
  height: 100%;
  flex-flow: column;
}

.SummitDetails-wrapper {
  padding: 15px 24px 0 24px;
  background: #ffffff;
}

.SummitDetails-tabs > .ant-tabs-bar {
  padding-left: 24px;
  padding-right: 24px;
}

.SummitDetails-dropdownContent {
  background: #ffffff;
  border: 1px solid #eff1f4 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  padding: 6px 16px !important;
  height: auto !important;
}

.SummitDetails-switcherTab {
  border-radius: 8px;
  border: 1px solid #eff1f4;
}

.SummitDetails-tabs-tab {
  padding-left: 24px;
  padding-right: 24px;
}

.SummitTab {
  display: flex;
  flex-direction: column;
}

.SummitSwitcherTabs {
  position: relative;
  color: #1890ff !important;
  background-color: #ffffff !important;
}

.SummitSwitcherTabs .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
  position: relative;
  color: #1890ff !important;
  background-color: #ffffff !important;
}

.ant-radio-button-wrapper-disabled {
  cursor: pointer;
}

.SummitTab .SummitLabel-past {
  background-color: #f19f00;
}

.SummitTab .SummitLabel-virtual {
  background-color: #2d81a8;
  color: #ffffff;
  text-align: left;
  margin-left: 0px;
  margin-bottom: 6px;
  line-height: 14px !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.SummitLabel {
  padding: 0;
  margin-left: 8px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  text-align: end;
  border-radius: 24px;
}

.SummitLabel-past {
  background-color: #f19f00;
}

.SummitLabel-virtual {
  background-color: #2d81a8;
}

.SummitLabel > span {
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  height: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  font-style: normal;
  min-height: 16px;
  padding: 0px 16px;
  text-align: center;
  vertical-align: text-top;
  width: 100%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.TieFundsModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TieFundsModal-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.TieFundsModal-subtitle {
  margin-bottom: 20px;
}

.TieFundsModal-group {
  margin-bottom: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.FeaturedEvent {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.FeaturedEvent-header {
  padding: 12px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  color: #000000;
}

.FeaturedEvent-poster {
  width: 100%;
  height: 100%;
  min-height: 280px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0 0 0 8px;
}

.FeaturedEvent-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}

.FeaturedEvent-info-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #3d4858;
}

.FeaturedEvent-info-description {
  color: #616161;
  font-size: 12px;
  line-height: 20px;
}

.FeaturedEvent-info-location,
.FeaturedEvent-info-date,
.FeaturedEvent-info-booths {
  color: #9e9e9e;
  padding-bottom: 8px;
}

.FeaturedEvent-info-location > span,
.FeaturedEvent-info-date > span,
.FeaturedEvent-info-booths > span {
  font-size: 12px;
  line-height: 20px;
  color: #3d4858;
  margin-left: 5px;
}

.FeaturedEvent-info-booths > span.FeaturedEvent-booth-selection {
  color: #f19f00;
}

.FeaturedEvent-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.FeaturedEvent-registration-btn {
  border: none;
  border-radius: 4px;
}

.FeaturedEvent-registration-btn > svg {
  margin-right: 8px;
}

.FeaturedEvent-registration-text {
  color: #24c477;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.FeaturedEvent-registration-text > svg {
  margin-right: 8px;
}

.FeaturedEvent-actions {
  display: flex;
}

.FeaturedEvent-actions > button,
.FeaturedEvent-actions-action > a {
  margin-right: 8px;
}

.FeaturedEvent-booth-selection {
  background-color: #fffcf7;
  border: 1px solid #f19f00;
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
}

.FeaturedEvent-booth-selection > span {
  margin-left: 8px;
  color: #616161;
}

/* purgecss end ignore */

/* purgecss start ignore */

.EventListing {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.EventListing-header {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}

.EventListing-header > h1,
.EventListing-header > .EventListing-header-filters {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  height: 22px;
  vertical-align: middle;
}

.EventListing-header-filters > .EventListing-header-dropdown-trigger > span {
  margin-right: 8px;
}

.EventListing-header > h1 {
  color: #000000;
}

.EventListing-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.EventListing-item-name {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
}

.EventListing-item-actions {
  display: flex;
  justify-content: space-between;
}

.EventListing-item-actions > .AvatarGroup {
  display: flex;
  align-items: center;
}

.EventListing-item-actions > .AvatarGroup,
.EventListing-item-actions > a,
.EventListing-item-actions > button {
  margin: 0 8px;
}

.EventListing-body {
  padding: 12px;
}

.EventListing-body > .EventListing-item:last-child {
  margin-bottom: 0;
}

.EventListing-registration-btn {
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
}

.EventListing-registration-text {
  font-size: 12px;
  line-height: 20px;
  color: #24c477;
  display: flex;
  align-items: center;
}

.EventListing-registration-text > svg {
  margin-right: 4px;
}

.EventListing-link {
  background-color: #ffffff;
  border: 1px solid #052449;
  border-radius: 4px;
  color: #052449;
  font-size: 12px;
  line-height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
}

/* purgecss end ignore */

/* purgecss start ignore */

.banner {
  margin: 0;
}

.banner .space-around.ant-btn-primary {
  background-color: #24c477;
  border-color: transparent;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.banner .blue {
  background-color: #052449 !important;
}

.banner .ant-btn-background-ghost {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 4px;
  border-color: transparent;
}

.banner h4,
.banner h5 {
  color: #ffffff;
  margin-right: 8px;
  margin-bottom: 0 !important;
}

.banner h4 {
  font-size: 16px;
  line-height: 28px;
}

.banner h5 {
  font-size: 14px;
  line-height: 24px;
}

.banner .flex-childs {
  display: flex;
  align-items: center;
}

.banner .space-around {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 4px 16px;
}

.banner .button-icon-space {
  margin-right: 9.33px;
}

.banner .logo-icon-space {
  margin-right: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-background-input > input,
.cc-background-input > div,
.cc-background-input > div > input {
  background: #f7f8fa !important;
  border-style: none !important;
  border-radius: 4px;
}

.cc-VideoRoomSettings-header {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eff1f4;
}

.cc-VideoRoomSettings-body {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eff1f4;
}

.cc-VideoRoomSettings-title {
  display: flex;
  justify-content: space-between;
}

.cc-VideoRoomSettings-icon {
  margin-right: 5px;
}

.cc-VideoRoomSettings-input > .ant-select-selector {
  background: #202022 !important;
  border: #202022 !important;
}

.cc-VideoRoomSettings-input > .ant-select-selector > .ant-select-selection-item {
  color: #c4c4c4 !important;
}

.cc-VideoRoomSettings-input > .ant-select-arrow {
  color: #c4c4c4 !important;
}

.pids-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pid {
  width: calc(8%);
  height: 4px;
  display: inline-block;
  border-radius: 2px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-participant {
  display: flex;
}

.cc-participant-info {
  display: inline-grid;
}

.cc-participant-big-narrow {
  width: 75%;
  flex: 0 0 auto;
  position: fixed;
}

.cc-participant-big-wide {
  width: 100%;
  flex: 0 0 auto;
  position: fixed;
}

.cc-participant-video-big {
  height: 100vh;
  width: 100%;
}

.cc-participant-video-big-web {
  object-fit: cover;
}

.cc-participant-video-big-mobile {
  object-fit: contain;
}

.cc-participant-video-big-on {
  color: #ffffff;
  text-align: center;
  position: absolute;
  z-index: 160;
}

.cc-participant-video-big-off {
  color: #ffffff;
  background: #151517;
  padding: 30% 0;
  text-align: center;
}

.cc-participant-video-big-none {
  display: none;
}

.dominant-network-wrapper {
  margin: 20px 0px 0px 85px;
  z-index: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #eff1f4;
}

.dominant-network {
  width: 6px;
  display: inline-block;
  margin-right: 1px;
  margin-left: 1px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-participant {
  display: flex;
  margin-bottom: 24px;
}

.cc-participant-identity {
  margin: 15px 0;
  text-align: left;
  width: 60%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cc-participant-identity-name {
  font-weight: 600;
  font-size: 16px;
  color: #24262b;
}

.cc-participant-identity-title {
  font-size: 14px;
}

.cc-participant-identity-company {
  font-size: 14px;
  line-height: 18px;
  color: #92949c;
}

.cc-participant-small {
  width: 30%;
  flex: 0 0 auto;
  margin: auto;
}

.cc-participant-audio {
  text-align: center;
  margin: auto 0;
  width: 10%;
}

.cc-participant-video-small {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cc-participant-video-small-on {
  color: #ffffff;
  text-align: center;
  position: absolute;
  z-index: 160;
}

.cc-participant-video-small-off {
  color: #ffffff;
  background: #151517;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc-participant-video-small-none {
  display: none;
}

.sidebar-network-wrapper {
  width: 100%;
  color: #ffffff;
  margin-top: 10px;
  z-index: 999;
  position: absolute;
  top: 60%;
  left: 5%;
}

.sidebar-network {
  width: 3px;
  display: inline-block;
  margin-right: 0.5px;
  margin-left: 0.5px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-xsmall-font-white {
  color: #ffffff;
}

.messaging-chat-document-filename-white button,
.messaging-chat-document-filename-white a {
  color: #ffffff;
  max-width: 180px;
  width: 180px;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.messaging-chat-document-filename-white button {
  padding-left: 0px;
}

.messaging-chat-no-documents {
  color: #c2c6cc;
  font-size: 12px;
  margin-left: 20px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AllocatorCard {
  margin: 8px 8px 24px 8px;
  background: #ffffff;
  border: 1px solid #eff1f4;
  border-radius: 8px;
  padding: 32px 16px;
  box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.15);
  transition: border 300ms;
}

.AllocatorCard-click {
  cursor: pointer;
}

.AllocatorCard-click:hover {
  box-shadow: 0px 3px 10px rgba(186, 186, 201, 0.51);
  background: #fafcfc;
  border: 1px solid #052449;
}

.AllocatorCard-title {
  color: #3d4858;
  font-size: 18px;
  font-weight: 600;
}

.AllocatorCard-subtitle {
  color: #92949c;
  font-size: 14px;
}

.AllocatorCard-description {
  color: #4d4f56;
  font-size: 14px;
}

.AllocatorCard-row {
  display: flex;
  align-items: center;
}

.AllocatorCard-text {
  margin: 16px 16px 0px 0px;
}

.AllocatorCard-avatar {
  height: 70px;
  display: flex;
  align-items: flex-start;
}

/* purgecss end ignore */

/* purgecss start ignore */

.allocator-image {
  margin-bottom: 20px;
}

.allocator-website {
  font-weight: 600;
  font-size: 24px;
  color: #052449;
}

/* purgecss end ignore */

/* purgecss start ignore */

.allocator-modal-details-header {
  vertical-align: top;
  border-bottom: 1px solid #e2e8ee;
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 24px;
}

.allocator-modal-details-location {
  color: #c2c6cc;
}

.allocator-modal-details-title {
  display: inline-block;
}

.allocator-modal-details-actions {
  vertical-align: top;
  display: inline-block;
  margin-left: 105px;
}

.allocator-modal-details-actions div {
  display: inline-block;
}

.allocator-modal-details-actions button {
  vertical-align: top;
  height: 32px;
  width: 32px;
  display: inline-block;
  margin-left: 16px;
  font-size: 14px;
  padding: 0px;
}

.allocator-modal-details-actions-direct button {
  background: rgba(177, 52, 125, 0.1);
  font-size: 16px;
}

.allocator-modal-details-favicon {
  color: #f19f00;
  font-size: 21px !important;
}

.allocator-modal-details-document {
  background: #2d81a8;
}

.allocator-modal-details-reschedule {
  margin-left: 32px !important;
  color: #6a48c6;
}

.allocator-modal-details-attachment {
  color: #b1347d;
}

.allocator-modal-details-tabs {
  margin-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.allocator-modal-details-footer {
  height: 64px;
  width: 100%;
  background: #f7f8fa;
  display: block;
}

.allocator-modal-details-footer-buttons {
  float: right;
  padding-right: 24px;
  padding-top: 16px;
}

.allocator-modal-details-footer-buttons button {
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.allocator-modal-details-footer-buttons button svg {
  margin-right: 10px;
}

.allocator-modal-details-send-message {
  background: #2d81a8 !important;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messages-chat-colleagues-to-add {
  margin-bottom: 16px;
}

.messages-chat-colleagues-to-add div {
  display: inline-block;
}

.messages-chat-colleagues-to-add-messagebox {
  width: 100%;
}

.messages-chat-colleagues-to-add-messagebox textarea {
  width: 100%;
  min-height: 80px;
}

.chat-conversation-preference-label {
  display: block;
  margin-top: 24px;
}

.chat-conversation-no-colleagues {
  margin-top: 16px;
  margin-bottom: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.chat-header {
  max-height: 72px;
  min-height: 72px;
  border-bottom: 1px solid #eff1f4;
  background: #ffffff;
}

.chat-header-profile {
  margin-top: 26px;
  margin-left: 24px;
  display: inline-block;
  text-align: center;
}

.chat-header-name {
  color: #1b1a1f;
}

.chat-header-type {
  display: inline-block;
  margin-top: 29px;
  margin-left: 24px;
}

.chat-header-title {
  color: #92949c;
  margin-top: 4px;
}

.chat-header-profile-pictures {
  float: right;
  margin-top: 28px;
}

.chat-actions {
  float: right;
  margin-top: 28px;
  margin-right: 16px;
}

.chat-actions button {
  border: none;
  font-size: 20px;
  box-shadow: none;
}

.messaging-meeting-type-summit span {
  background: #052449;
}

.messaging-meeting-type-online span {
  background: #6a48c6;
}

.messaging-meeting-type-roadshow span {
  background: #962c6a;
}

.messaging-chat-action-item {
  padding-left: 24px !important;
  padding-right: 24px !important;
  box-shadow: inset 0px -1px 0px #eff1f4;
  color: #2d3239;
}

.messaging-chat-action-item svg {
  margin-right: 8px;
  color: #c2c6cc;
}

.messaging-chat-action-leave svg {
  color: #d62b34;
}

/* purgecss end ignore */

/* purgecss start ignore */

.forbidden-panel-container {
  width: 100%;
  padding-top: 240px;
}

.forbidden-panel-icon-background {
  margin-left: auto;
  margin-right: auto;
  max-width: 91px;
  max-height: 91px;
  min-width: 91px;
  min-height: 91px;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px rgba(154, 163, 197, 0.2);
  text-align: center;
  font-size: 11px;
  vertical-align: middle;
  border-radius: 46px 46px;
}

.forbidden-panel-icon-svg {
  width: 56px;
  margin-top: 16px;
}

.forbidden-panel-text {
  margin-top: 44px;
  max-width: 458px;
  text-align: center;
  line-height: 34px;
  font-family: Open Sans;
  margin-left: auto;
  margin-right: auto;
  color: #49494a;
  font-size: 24px;
}

.forbidden-panel-upgrade {
  width: 214px;
  margin-left: auto;
  margin-right: auto;
}

.forbidden-panel-upgrade-button {
  margin-top: 43px;
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.06) !important;
  border-radius: 4px;
  min-height: 45px;
  max-height: 45px;
  min-width: 214px;
  max-width: 214px;
}

.forbidden-panel-upgrade-button svg {
  margin-right: 8px;
  font-size: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messaging-chat-info-panel {
  margin-top: 20px;
  padding: 24px;
  padding-top: 0px !important;
}

.messaging-chat-company-profile {
  text-align: center;
}

.messaging-chat-company-image img {
  width: 81px;
  height: 81px;
  border-radius: 42px 42px;
}

.messaging-chat-company-name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1b1a1f;
}

.messaging-chat-company-actions {
  margin-top: 10px;
}

.messaging-chat-company-profile svg {
  margin-right: 8px;
}

.messaging-chat-company-no-funds {
  color: #c2c6cc;
  font-size: 12px;
  line-height: normal;
  margin-top: 8px;
}

.messaging-chat-documents-title {
  margin-top: 32px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #92949c;
}

.messaging-chat-document-container {
  margin-top: 16px;
}

.messaging-chat-document-image {
  width: 20px;
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
}

.messaging-chat-document-desc {
  width: 179px;
  display: inline-block;
  margin-left: 8px;
}

.messaging-chat-document-image img {
  width: 20px;
}

.messaging-chat-document-image svg {
  font-size: 28px;
}

.messaging-chat-document-red {
  color: #d62b34;
}

.messaging-chat-document-green {
  color: #24c477;
}

.messaging-chat-document-blue {
  color: #052449;
}

.messaging-chat-document-orange {
  color: #d04423;
}

.messaging-chat-document-filename button,
.messaging-chat-document-filename a {
  color: #000000;
  text-align: left;
  max-width: 500px;
  width: 500px;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.messaging-chat-document-filename button {
  padding-left: 0px;
}

.messaging-chat-action-item {
  padding-left: 24px !important;
  padding-right: 24px !important;
  box-shadow: inset 0px -1px 0px #eff1f4;
  color: #2d3239;
}

.conversations-panel-right .ant-drawer {
  top: 136px;
  max-height: calc(100vh - 162px);
}

.conversations-panel-right .ant-drawer-content-wrapper {
  box-shadow: none !important;
  border-left: 1px solid #eff1f4;
  border-bottom: none;
}

.messages-drawer .ant-drawer-body {
  padding: 0px !important;
}

.messaging-chat-info-panel {
  padding: 24px;
}

.messaging-chat-info-panel-scrollbar {
  max-height: calc(100vh - 198px);
  margin-top: 36px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messaging-message-divider {
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 24px;
}

.messaging-message-divider-time {
  z-index: 201;
  margin-top: -8px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  width: 15%;
  color: #c2c6cc;
  margin-bottom: 32px;
}

.messaging-message-divider-line {
  content: '';
  margin-top: 8px;
  border-top: 1px solid #eff1f4;
  background: #eff1f4;
  z-index: 200;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messaging-message-log {
  text-align: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
}

.messaging-message-log-time {
  color: #92949c;
}

.messaging-message-log-title {
  margin-top: 8px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.messaging-message-log-title svg {
  margin-right: 8px;
  font-weight: normal;
}

.messaging-message-log-description {
  margin-top: 8px;
}

.messaging-message-log-MeetingLogAccepted .messaging-message-log-title {
  color: #24c477;
}

.messaging-message-log-MeetingLogCancelled .messaging-message-log-title,
.messaging-message-log-MeetingLogDeclined .messaging-message-log-title {
  color: #d62b34;
}

.messaging-message-log-MeetingLogRequested .messaging-message-log-title,
.messaging-message-log-MeetingLogRescheduled .messaging-message-log-title,
.messaging-message-log-ConversationInvited .messaging-message-log-title,
.messaging-message-log-ConversationLeft .messaging-message-log-title {
  color: #052449;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messaging-message-container-other {
  display: block;
  margin-bottom: 16px;
  margin-left: 16px;
}

.messaging-message-name {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 600;
  color: #2d3239;
  line-height: 16px;
}

.messaging-message-profile-picture,
.messaging-message-name {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}

.messaging-message {
  display: block;
  margin-top: -4px;
  background: #eff1f4;
  margin-left: 32px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  padding: 8px 16px 8px 16px;
  color: #2d3239;
  margin-right: 120px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.messaging-message-profile-picture {
  margin-top: -8px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messaging-message-container-mine {
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 120px;
  margin-bottom: 8px;
}

.messaging-message-mine {
  background: #052449;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  padding: 8px 16px 8px 16px;
  display: block;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  color: #ffffff;
  direction: ltr;
}

.messaging-message-attachment {
  border: 1px solid #eff1f4;
  border-radius: 8px;
  min-width: 180px;
  padding-top: 18px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 30px;
}

.messaging-message-attachment span {
  color: #92949c;
}

.messaging-message-time {
  vertical-align: top;
  margin-right: 8px;
  color: #c2c6cc;
}

.messaging-message-timestamp {
  direction: ltr;
  display: inline-block;
}

.messaging-message-readby-tick {
  font-size: 12px;
  margin-right: 4px;
}

.messaging-message-readby-tick {
  color: #92949c;
}

.messaging-message-container-other .messaging-message-time {
  margin-left: 32px;
}

.messaging-message-readby-tick.read {
  color: #24c477;
}

.messaging-message-attachment-icon {
  font-size: 30px;
  color: #c5000c;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.messaging-message-attachment-download-link {
  margin-top: 10px;
  padding-top: 4px;
  border-top: 1px solid #eff1f4;
  padding-bottom: 0px;
}

.messaging-message-attachment-download-link button {
  max-height: 8px;
}

.messaging-message-attachment-download-link button span {
  color: #052449;
  font-weight: 600;
}

/* purgecss end ignore */

/* purgecss start ignore */

.messaging-message-current-result .message-highlighted-bit {
  background-color: #008dca;
  color: #ffffff;
}

.message-highlighted-bit {
  background-color: #f19f00;
  color: #ffffff;
}

.messaging-message-column b {
  font-weight: normal;
}

.messaging-message b {
  font-weight: normal;
}

/* purgecss end ignore */

/* purgecss start ignore */

.message-board-loading-older .ant-spin-dot {
  max-height: 20px;
  max-width: 20px;
}

.messaging-arrow-new-message {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  left: auto;
  right: auto;
  text-align: center;
  width: 100%;
  height: 42px;
}

.messaging-arrow-new-message-icon {
  background-color: #ebeef0;
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  padding-top: 8px;
  border: 1px solid #052449;
  border-radius: 8px 8px;
  cursor: pointer;
  color: #052449;
}

.messaging-arrow-new-message-icon svg {
  margin-right: 8px;
}

.message-board-last-message-notification {
  text-align: center;
  color: #2d3239;
  background: rgba(241, 159, 0, 0.1);
  border-radius: 4px;
  min-height: 80px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 64px;
  margin-right: 64px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 0 transparent;
}

.messaging-chat-search-bar {
  height: 48px;
  background-color: #ffffff;
  right: 24px;
  left: 0px;
  padding-top: 4px;
  padding-left: 12px;
  padding-right: 48px;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  z-index: 202;
  position: absolute;
  transition: right 200ms ease-in-out;
  width: 100%;
}

.messaging-chat-search-controls {
  border: 1px solid #eff1f4;
  border-radius: 4px;
  position: absolute;
  left: 8px;
  right: 50px;
  height: 40px;
}

.messaging-chat-search-input {
  top: 2px;
  position: absolute;
  left: 0px;
  right: 100px;
  transition: right 200ms ease-in-out;
}

.messaging-chat-search-input .ant-input {
  border: none;
  background-color: #ffffff;
  margin-left: 18px;
  height: 32px;
  color: #1b1a1f;
}

.messaging-chat-search-input .ant-input:focus,
.messaging-chat-search-input .ant-input:active {
  border: none;
  box-shadow: none;
}

.messaging-chat-search-input-control {
  border: none;
  background-color: #ffffff;
  height: 32px;
}

.messaging-chat-search-icon {
  margin-left: 8px;
  color: #c2c6cc;
  font-size: 17px;
}

.messaging-chat-results,
.messaging-chat-results div,
.messaging-chat-search-input {
  display: inline;
}

.messaging-chat-results {
  right: 8px;
  position: absolute;
  top: 3px;
}

.messaging-chat-results-label {
  margin-top: 2px;
  margin-right: 8px;
  display: inline-block;
  color: #92949c;
}

.messaging-chat-results-cursor-previous {
  margin-right: 4px;
}

.messaging-chat-results-cursors svg {
  font-size: 20px;
  padding-top: 2px;
  color: #92949c;
}

.messaging-chat-search-close {
  right: 0px;
  position: absolute;
  width: 50px;
  height: 42px;
  font-size: 27px;
  padding-left: 16px;
  cursor: pointer;
  color: #92949c;
}

.messaging-chat-search-icon {
  cursor: pointer;
}

/* purgecss end ignore */

/* purgecss start ignore */

.fifty-fifty-width .ant-radio-button-wrapper {
  width: 50%;
}

.one-third-width .ant-radio-button-wrapper {
  width: 33%;
}

.switch-conversation-radio-group label {
  width: 143px;
  text-align: center;
}

.switch-conversation-radio-group {
  width: 100%;
}

.switch-conversation-radio-group .ant-radio-button-wrapper {
  padding: 0px;
  min-height: 50px;
  padding-top: 15px;
}

.switch-conversation-radio-group .ant-radio-button-wrapper-checked svg {
  color: #ffffff !important;
}

.switch-conversation-radio-group .ant-radio-button-wrapper:hover svg {
  color: #052449 !important;
}

.switch-conversation-radio-icon {
  display: block;
  font-size: large;
  width: 100% !important;
  color: #c2c6cc;
}

.switch-conversation-radio-badge {
  position: absolute;
  top: 50%;
}

.badge-head {
  width: 42px;
  height: 42px;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
}

/* purgecss end ignore */

/* purgecss start ignore */

.empty-messages-main-container {
  text-align: center;
}

.empty-messages-icon {
  font-size: 24px;
  color: #92949c;
  margin-top: 24px;
}

.empty-messages-text {
  font-size: 14px;
  color: #92949c;
  margin-top: 16px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Sidebar-company {
  display: flex;
}

.Sidebar-company-profile {
  text-align: center;
}

.Sidebar-company-image img {
  width: 81px;
  height: 81px;
  border-radius: 42px 42px;
}

.Sidebar-company-name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #24262b;
  margin-bottom: 10px;
}

.Sidebar-company-actions {
  margin-top: 40px;
}

.Sidebar-company-profile svg {
  margin-right: 8px;
}

.Sidebar-company-type {
  padding: 0;
  /* margin: 0; */
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  text-align: end;
  margin-left: 10px;
}

.Sidebar-company-type-span {
  background: #2fc5d2;
  border-radius: 24px;
  font-size: 10px;
  line-height: 14px;
  height: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
  min-height: 16px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.Sidebar-company-type-online {
  background: #6a48c6;
}

.messaging-chat-documents-title {
  margin-top: 32px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #92949c;
}

.messaging-chat-document-container {
  margin-top: 16px;
}

.messaging-chat-document-image {
  width: 20px;
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
}

.messaging-chat-document-desc {
  display: inline-block;
  margin-left: 8px;
}

.messaging-chat-document-image img {
  width: 20px;
}

.messaging-chat-document-image svg {
  font-size: 28px;
}

.messaging-chat-document-red {
  color: #d62b34;
}

.messaging-chat-document-green {
  color: #24c477;
}

.messaging-chat-document-blue {
  color: #052449;
}

.messaging-chat-document-orange {
  color: #d04423;
}

.messaging-chat-document-filename button,
.messaging-chat-document-filename a {
  color: #000000;
  overflow-x: hidden;
  overflow-wrap: break-word;
}

.messaging-chat-document-filename button {
  padding-left: 0px;
}

.messaging-chat-action-item {
  padding-left: 24px !important;
  padding-right: 24px !important;
  box-shadow: inset 0px -1px 0px #eff1f4;
  color: #2d3239;
}

.conversations-panel-right .ant-drawer {
  top: 136px;
  max-height: calc(100vh - 162px);
}

.conversations-panel-right .ant-drawer-content-wrapper {
  box-shadow: none !important;
  border-left: 1px solid #eff1f4;
  border-bottom: none;
}

.messages-drawer .ant-drawer-body {
  padding: 0px !important;
}

.messaging-chat-info-panel {
  padding: 24px;
}

.messaging-chat-info-panel-scrollbar {
  max-height: calc(100vh - 198px);
  margin-top: 36px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.VideoButton {
  margin: 10px;
}

.SettingsButton {
  margin: 10px 85px 10px 10px;
  color: #eff1f4;
  display: flex;
  font-size: 14px;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.SettingsButton-icon {
  margin-left: 5px;
}

.SettingsButton.ant-btn-primary {
  background: rgba(239, 241, 244, 0.1);
  border-color: rgba(239, 241, 244, 0.1);
  color: #c2c6cc;
}

.SettingsButton.ant-btn-background-ghost.ant-btn-primary {
  color: #eff1f4;
  background-color: transparent;
  border-color: #eff1f4;
}

.RedVideoButton {
  margin: 10px;
}

.RedVideoButton.ant-btn-primary {
  background: #d62b34;
  border-color: #d62b34;
}

.RedVideoButton.ant-btn-background-ghost.ant-btn-primary {
  color: #d62b34;
  background-color: transparent;
  border-color: #d62b34;
}

.cc-meeting {
  padding: 0;
  margin: 0;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  text-align: end;
}

.cc-meeting-name {
  border-radius: 24px;
  font-size: 10px;
  line-height: 14px;
  height: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  font-style: normal;
  color: #ffffff;
  min-height: 16px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.cc-meeting-name-virtual {
  background: #2d81a8;
}

.cc-meeting-name-online {
  background: #6a48c6;
}

.cc-meeting-name-name {
  background: #f19f00;
  margin-right: 5px;
}

.cc-member-card {
  flex: 0 0 auto;
}

.VideoRoom {
  height: 100%;
  width: 100%;
}

.VideoRoom-title {
  display: block;
  margin-top: 60px;
  float: right;
  margin-left: 75px;
  position: absolute;
  z-index: 1;
}

.VideoRoom-shadow-up-narrow {
  width: 100%;
  margin-top: bottom;
  height: 20%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  z-index: 180;
}

.VideoRoom-shadow-up-wide {
  width: 100%;
  margin-top: bottom;
  height: 20%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  z-index: 180;
}

.VideoRoom-shadow-down-narrow {
  width: 75vw;
  margin-top: auto;
  justify-content: space-between;
  display: flex;
  height: 20%;
  padding-top: 6%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 20;
}

.VideoRoom-shadow-down-wide {
  width: 100vw;
  margin-top: auto;
  justify-content: space-between;
  display: flex;
  height: 20%;
  padding-top: 6%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 20;
}

.VideoRoom-narrow {
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  width: 75%;
}

.VideoRoom-narrow-name {
  position: absolute;
  margin-top: 100px;
  z-index: 300;
  padding-right: 75px;
  width: 100%;
  color: #ffffff;
}

.VideoRoom-wide {
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  width: 100%;
}

.VideoRoom-wide-name {
  position: absolute;
  margin-top: 100px;
  z-index: 300;
  padding-right: 75px;
  width: 100%;
  color: #ffffff;
}

.VideoRoom-settings {
  max-width: 350px !important;
}

.VideoRoom-settings > .ant-tooltip-content > .ant-tooltip-inner {
  border-radius: 10px;
  padding: 20px;
  width: 350px;
}

/* purgecss end ignore */

/* purgecss start ignore */

.cc-video-conference-container {
  background-color: #151517;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
}

.cc-white-heading1 {
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}

.delay-notify-video-conference {
  right: 130%;
  top: 15%;
}

/* purgecss end ignore */

