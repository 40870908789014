@import '../../styles/colors.less';

.action-menu {
  background: #ffffff;
  border: 1px solid #eff1f4;
  box-sizing: border-box;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04) !important;
  border-radius: 8px !important;
  padding: 0px !important;
}

.action-menu-item {
  padding: 12px 12px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.action-menu-item-disabled {
  background: @cc-lightest-grey;
  & > span > .action-menu-text {
    color: rgba(0, 0, 0, 0.25);
  }
}

.action-menu-item-disabled:hover {
  background: @cc-lightest-grey;
}

.action-menu-icon {
  display: inline-block;
  margin-left: 9px;
  margin-right: 9px;
  width: 20px;
  height: 16px;
  line-height: 22px !important;
  text-align: center;
}

.action-menu-icon > svg {
  font-size: 16px;
  //margin-left: 8px;
}

.action-menu-text {
  display: inline-block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #2d3039;
  line-height: 22px !important;
  margin-right: 16px;
}

.action-menu-divider {
  margin: 0px 8px 0px 8px !important;
}

.table-action-button {
  border: none;
  background: @cc-light-grey;
  min-width: 32px;
  width: fit-content;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.table-action-button span {
  width: 32px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;