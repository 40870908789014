@layer components {
  .onboarding-form {
    @apply mx-auto relative;
    @apply flex flex-col gap-4;

    &:not([class*='max-w-']) {
      @apply max-w-md;
    }

    .checkbox-group .form-label {
      @apply mb-0 py-0.5;
    }

    .checkbox-group .form-message {
      @apply mt-0;
    }

    .checkbox-group:not(.border-none) .checkbox-group__options {
      @apply border-0 border-t border-solid border-grey-300;
      @apply pt-1;
    }

    [role='radiogroup'] {
      .form-label {
        @apply mb-0;
      }

      .form-message {
        @apply mt-0 mb-1;
      }
    }
  }
}

.matchmaking-slider {
  .ant-slider-rail {
    background-color: theme('colors.grey.300');
  }

  .ant-slider-handle {
    background-color: theme('colors.primary.100');
    border-color: theme('colors.primary.100');
  }

  .ant-slider-mark {
    @apply type-body-regular-sm mt-2;
  }

  .ant-slider-mark-text-active {
    @apply text-primary-100 font-semibold;
  }
}
