@import '../../styles/colors.less';

.agenda-day-speakers-container,
.agenda-day-speaker-role,
.agenda-day-speakers-person,
.agenda-day-speakers-person > span {
  display: block;
}

.agenda-day-timezone-column {
  color: @cc-primary !important;
  cursor: pointer;
}

.agenda-day-speaker-role-title {
  font-size: 10px;
  line-height: 16px;
  color: @cc-light-black;
  font-weight: bolder;
  height: 16px;
}

.agenda-day-speaker-role {
  margin-top: 4px;
}

.agenda-day-speakers-person {
  margin-bottom: 16px;
}

.agenda-day-speakers-person-name {
  color: @cc-light-black;
  margin-top: -4px;
}

.agenda-day-speakers-person-title {
  color: @cc-dark-grey;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;