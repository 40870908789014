@import '../../styles/colors.less';
.cc-meeting-lobby-body {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #c2c6cc;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cc-meeting-lobby-header {
  margin-top: 30px;
}

.cc-meeting-lobby-body-white {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: @cc-white;
  & > .ant-checkbox-checked {
    background-color: @cc-primary;
  }
  & > .ant-checkbox > .ant-checkbox-inner {
    background-color: transparent;
  }
}

.cc-meeting-lobby-body-2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #c2c6cc;
}

.cc-meeting-lobby-tag {
  font-size: 14px;
  line-height: 24px;
  color: #c2c6cc;
  padding: 12px 30px 12px 30px;
  border: 1px solid #008dca;
}

.cc-meeting-lobby-container &.ant-spin-nested-loading {
  & .ant-spin-lg {
    & .ant-spin-dot {
      margin: 18% 0 !important;
    }
  }
}

.sponsors {
  height: calc(100vh - 190px);
  margin: 16px 0px;
}

.listTable .ant-table-tbody tr.ant-table-row-level-0:hover > td {
  background: linear-gradient(
    90deg,
    #24262b 0%,
    rgba(36, 38, 43, 0) 100%
  ) !important;
}

.listTableRow:hover {
  background: transparent !important;
}

.cc-meeting-lobby-scrollbar {
  max-height: calc(100vh - 190px);
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;