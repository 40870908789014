@import '../../styles/colors';

.cc-video-conference-container {
  background-color: #151517;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
}

.cc-white-heading1 {
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}

.delay-notify-video-conference {
  right: 130%;
  top: 15%;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;