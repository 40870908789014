@import '../../styles/colors.less';

.cc-meeting-lobby-container {
  background-color: #151517;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;

  &-divider {
    background-color: #8492a6;
    height: 35px;
  }

  &-settings {
    display: flex;
    margin-top: 60px;
    align-items: center;
    justify-content: flex-end;
  }

  &-logo {
    display: flex;
    margin-top: 60px;
    align-items: center;
  }
}

.cc-white-heading1 {
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}

.cc-grey-heading3 {
  color: #c4c4c4;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;