@import '../../styles/colors.less';

.fund-image {
  border-radius: 8px;
  cursor: pointer;
  height: 100% !important;
  object-fit: cover;
  width: 100% !important;
  &-col {
    padding: 8px;
  }
  &-col > .ant-image {
    width: 100%;
    height: 100%;
  }
}

.media-row {
  margin: 20px 0px;
}

.FundMedia {
  &-link {
    font-weight: 600;
    font-size: 24px;
    color: @cc-primary;
    margin-bottom: 20px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;