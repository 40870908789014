.AttendingRoadshow {
  background-color: #fff;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 245px;
  transition: 200ms;
  margin-bottom: 20px;
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
    transition: 200ms;
  }
  &-empty {
    align-items: center;
    justify-content: center;
  }

  &-poster {
    flex: 2;
    position: relative;
    border-radius: 8px 0 0 8px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-noSchedule {
    margin: 10px 10px;
    background: rgba(0, 141, 202, 0.1);
    border-radius: 4px;
    color: #24262b;
    padding: 12px;
  }

  &-virtual {
    padding: 2px 16px;
    background: #2d81a8;
    border-radius: 24px;
    float: right;
    margin-top: 24px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 1px;
    font-size: 10px;
  }

  &-info {
    flex: 6;
    // padding: 20px 24px;
    color: #ffffff;

    &-details {
      display: flex;
      justify-content: space-between;
    }
  }

  &-header {
    flex: 6;
    padding: 20px 24px;
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
  }

  &-info h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    color: #ffffff;
    font-size: 24px;
  }

  &-info > p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0.25rem;
  }

  & > &poster > &-detail {
    position: absolute;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    bottom: 5%;
  }

  &-detail-text {
    opacity: 0.7;
  }

  &-detail-count {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    opacity: 1;
  }

  &-info-icon {
    color: #c2c6cc;
    display: inline-block;
    margin-right: 0.5rem;
  }

  &-action {
    margin: 1rem 0 1rem;
    text-align: right;
  }
  @media screen and (max-width: 576px) {
    &-action {
      text-align: left;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;