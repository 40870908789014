@import '../../styles/colors.less';

.TimeSlots {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  // justify-content: space-between;
  // max-height: 350px;
  // overflow-y: auto;
}

.cc-online-meeting-hours-buttons {
  margin-bottom: 16px;
  width: 24%;
  margin-right: 1%;
  & .ant-radio-button-wrapper {
    padding: 0px;
    text-align: center;
    font-size: 14px;
    width: 100%;
  }
}
.online-meeting-date-picker {
  & .ant-calendar-picker-input {
    min-width: 340px;
  }
}
.cc-online-meeting-hours-badge {
  &.ant-badge {
    padding-right: 5px;
  }
}

.online-meeting-date-time {
  &-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid @cc-light-grey;
  }
  &-datePicer {
    display: flex;
    width: 100%;
    margin-top: 16px;
  }
  &-timezone {
    width: 50%;
    margin-top: 16px;
    padding-left: 10px;
  }
  &-radio-button-wrapper {
    width: 45%;
    border: 1px solid #e0e4e8;
    line-height: 16px;
    box-sizing: border-box;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
  }
  &-radio-group {
    margin-top: 10px;
    & > .ant-radio-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;