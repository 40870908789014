@import '../../styles/colors.less';

.messages-compose {
  &-container {
    min-width: 600px;
    margin-bottom: 24px;
  }

  &-title {
    color: @cc-darkest-grey;
    margin-top: 24px;
  }

  &-contacts {
    margin-top: 24px;
  }

  &-searchselect {
    width: 300px;
    border: none;

    & > div {
      height: 100% !important;
    }
    & .ant-select {
      &-selection {
        background-color: @cc-lightest-grey;
        height: 48px;
        border: none;

        &__placeholder {
          margin-top: 0px;
        }

        &-selected-value {
          min-width: 260px;
          margin-top: 4px;

          & > div {
            display: inline-block;
          }
        }
      }

      &-search__field {
        height: 48px;
      }
    }
  }

  &-more-contacts {
    margin-top: 16px;

    &-checks {
      margin-top: 16px;
      display: inline-block;
    }
  }

  &-textbox {
    margin-top: 18px;
  }

  &-contact {
    &-option {
      min-width: 300px;
      box-shadow: inset 0px -1px 0px #eff1f4;
      display: inline-block;

      &-text {
        margin-left: 8px;
        width: 65%;
      }

      & > div {
        display: flex;
      }
    }

    &-checkbox {
      margin-top: 10px;
      margin-left: 8px;

      &-card {
        min-width: 183px;
        max-width: 183px;
        border: 1px solid @cc-light-grey;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 49px;
        max-height: 49px;
        display: inline-block;
        margin-right: 13px;
        margin-bottom: 8px;

        & > div {
          display: inline-block;
          vertical-align: top;
        }
      }

      & .ant-checkbox-inner {
        min-width: 20px;
        min-height: 20px;

        &::after {
          left: 28%;
        }
      }

      &-image {
        margin-left: 8px;
        margin-top: 2px;
      }

      &-text {
        margin-left: 8px;
        margin-top: 8px;
      }

      &-name {
        max-width: 90px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-height: 20px;
        white-space: nowrap;
        color: @cc-darkest-grey;
      }

      &-title {
        max-width: 90px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        max-height: 20px;
        white-space: nowrap;
        color: @cc-grey;
      }

      &-summit-presence {
        float: right;
        margin-right: 4px;
        color: @cc-dark-grey;
      }
    }
  }

  &-option-company {
    &-category {
      float: right;
      vertical-align: top;
      padding-top: 12px;
      color: @cc-dark-grey;
    }

    &-name {
      margin-top: -4px;
      color: @cc-dark-grey;
    }
  }

  &-option-contact-image {
    vertical-align: top;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;