@import '../../styles/colors.less';

.cc-content > .SummitDetails {
  margin-top: -23px;
  margin-left: -24px;
  margin-right: -24px;
}

.SummitDetails {
  display: flex;
  height: 100%;
  flex-flow: column;

  &-wrapper {
    padding: 15px 24px 0 24px;
    background: @cc-white;
  }

  &-tabs {
    & > .ant-tabs-bar {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  &-dropdownContent {
    background: @cc-white;
    border: 1px solid @cc-light-grey !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    padding: 6px 16px !important;
    height: auto !important;
  }

  &-switcherTab {
    border-radius: 8px;
    border: 1px solid #eff1f4;
  }
}
.SummitDetails-tabs-tab {
  padding-left: 24px;
  padding-right: 24px;
}

.SummitTab {
  display: flex;
  flex-direction: column;
}

.SummitSwitcherTabs {
  position: relative;
  color: #1890ff !important;
  background-color: @cc-white !important;

  & .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
    position: relative;
    color: #1890ff !important;
    background-color: @cc-white !important;
  }
}

.ant-radio-button-wrapper-disabled {
  cursor: pointer;
}

.SummitTab {
  .SummitLabel {
    &-past {
      background-color: @cc-notice;
    }
    &-virtual {
      background-color: @cc-secondary;
      color: @cc-white;
      text-align: left;
      margin-left: 0px;
      margin-bottom: 6px;
      line-height: 14px !important;
      width: fit-content;
    }
  }
}

.SummitLabel {
  &-past {
    background-color: @cc-notice;
  }

  &-virtual {
    background-color: @cc-secondary;
  }

  padding: 0;
  margin-left: 8px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  text-align: end;
  border-radius: 24px;
  & > span {
    color: @cc-white;
    font-size: 10px;
    line-height: 16px;
    height: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    font-style: normal;
    min-height: 16px;
    padding: 0px 16px;
    text-align: center;
    vertical-align: text-top;
    width: 100%;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;