.MandateWizard {
  @apply bg-white p-6;
  @apply flex flex-row flex-nowrap;
  @apply shadow-12;

  max-width: calc(100vw - 48px);
  min-height: calc(100vh - 108px);

  & &-steps {
    min-width: 390px;
    min-height: calc(100vh - 136px);
  }

  .Step {
    @apply flex flex-col;
    @apply w-full;

    &-title {
      @apply mb-5;
    }

    &-form {
      max-width: 1000px;
    }
  }

  .StepList .ant-steps-item-description {
    visibility: hidden;
  }
}
