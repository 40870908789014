.SummitAttendeeTable {
  button.action-menu-button {
    @apply bg-purple-100 border-purple-100 text-white;
    &:hover,
    &:focus {
      @apply bg-purple-90 border-purple-90 text-white;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;