@import '../../styles/colors.less';

.ReceivedRequestsTable {
  background-color: @cc-white;
  padding: 12px;

  &-category {
    color: @cc-dark-grey;
  }
  &-tag {
    background: rgba(77, 79, 86, 0.1);
    border-radius: 48px;
    margin-left: 8px;
    color: @cc-greyscale-darkestblack;
    padding: 0px 10px;
  }
  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: @cc-primary;
    padding: 12px;
    display: inline-block;
  }

  &-DeclineAll {
    margin-left: 16px;
  }
}

.MessageTooltip {
  & .ant-tooltip-inner {
    max-width: 400px;
    // max-height: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// .EditPresentationRoleButton {
//   visibility: hidden;
//   position: absolute;
// }

.PresentationRoleLabel {
  visibility: visible;
  position: relative;
}

// .PresentationRole:hover {
//   .EditPresentationRoleButton {
//     visibility: visible;
//   }
//   .PresentationRoleLabel {
//     visibility: hidden;
//   }
// }

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;