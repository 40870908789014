@import '../../styles/colors.less';

.settings-investor-preferences-board {
  background-color: @cc-white;
  margin-bottom: 32px !important;
  padding-bottom: 32px;
  text-align: center;
  width: 55%;
  margin: auto;

  & .cc-settings-investor-status {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #eff1f4;
    border-radius: 3px;
    width: 80%;
  }

  & .cc-generic-form-container {
    margin-left: 64px;
    width: 90%;
  }

  & button {
    width: 150px;
  }

  & .cc-onboard-section-row-control {
    width: 50%;
  }

  & .cc-strategies-checkbox .ant-checkbox-wrapper {
    width: 50%;
  }

  & .ant-checkbox-group {
    width: 100%;
  }

  & .form-section-submit {
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;