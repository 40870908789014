@import '../../styles/colors.less';

.cc-radio-button-wrapper {
  width: 155px;
  border: 1px solid #e0e4e8;
  font-size: 12px !important;
  line-height: 16px;
  box-sizing: border-box;
  box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.04);
  text-transform: uppercase;
}

.cc-container-radio-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 88px;
  background: #f0f2f5;
}

.ant-radio-button-wrapper-checked {
  background-color: @cc-primary !important;
  color: @cc-white !important;
}

.ant-radio-button-wrapper:hover {
  border-color: @cc-primary;
  background-color: theme('colors.secondary.2');
}
.cc-requests-count > sup.ant-badge-count {
  background-color: @cc-danger;
  border: 0px;
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .cc-radio-button-wrapper {
    width: 100px;
  }
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;