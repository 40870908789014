@import '../../../styles/colors.less';

.messaging-message-current-result .message-highlighted-bit {
  background-color: #008dca;
  color: @cc-white;
}

.message-highlighted-bit {
  background-color: #f19f00;
  color: @cc-white;
}

.messaging-message-column b {
  font-weight: normal;
}

.messaging-message b {
  font-weight: normal;
}

@font-family: 'Open Sans','Helvetica Neue','HelveticaNeue',Helvetica,Arial,sans-serif;@layout-sider-background: #000000;@grid-gutter-width: 20px;@menu-dark-bg: #000000;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-dark-item-active-bg: transparent;@table-header-bg: #ffffff;@table-header-color: #7f848a;@table-padding-vertical: 12px;@primary-color: #052449;@input-bg: #ffffff;@input-border-color: #cbcbcb;@spin-dot-size-lg: 40px;